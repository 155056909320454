/**
	@param {boxData, newVector, onCollisions, newPosition, minObjDrag}
	@return вернет объект с геометрией для линий и данные для рендера сфер
 */
export const deviceConnectorSerialized = ({
  boxData,
  newVector,
  onCollisions,
  newPosition,
  minObjDrag,
}) => {
  let idContextElement = minObjDrag.uuid;
  let newData = boxData.map((el, i) => {
    if (el.id === idContextElement) {
      return {
        ...el,
        position: newPosition,
      };
    } else {
      return el;
    }
  });
  return newData;
};
