import React from "react";
import Container from "../Container";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import Slide1 from "../../assets/images/projects.png";
import Slide2 from "../../assets/images/project.png";
import Slide3 from "../../assets/images/pfml.png";
import Slide4 from "../../assets/images/dc.png";
import Slide5 from "../../assets/images/pfml.png";
import Slide6 from "../../assets/images/pfml.png";



function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"landing-how_does_it_work-wrapper-slider-wrapper-arrow right"}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"landing-how_does_it_work-wrapper-slider-wrapper-arrow left"}
      onClick={onClick}
    />
  );
}

class HowDoesItWork extends React.Component {
  constructor(props) {
    super()
    this.state = {
      photos: [
        {
          type: "img",
          src: Slide1,
          title: "Проекты пользователя",
          text:
            "Зарегистрированный пользователь получает доступ к нескольким разделам, через которые может создавать проекты, сортировать их, редактировать. Можно добавлять проекты в Избранное, в Корзину.",
        },
        {
          type: "img",
          src: Slide2,
          title: "Создание проектов",
          text:
            "Проект представляет собой два раздела – Лабораторный стенд для создания физических цепей (одной или нескольких) и Библиотека магнитных полей для создания быстродействующих полевых моделей (одной или нескольких). В каждом разделе также сохраняются все сделанные расчеты – по цепи приборов и по полевой модели соответственно.",
        },
        {
          type: "img",
          src: Slide3,
          title: "Библиотека магнитных полей",
          text:
            "Позволяет с помощью заполнения параметров в проектном расчете создать динамическую полевую модель – упрощенную или расширенную, отредактировать ее, произвести расчет с помощью функции, вынесенных в меню Библиотеки. Например, осуществить серию расчетов квазистационарного двухмерного магнитного поля во всем диапазоне изменения величин, характеризующих работу заданного устройства.",
        },
        {
          type: "img",
          src: Slide4,
          title: "Лабораторный стенд",
          text:
            "Позволяет смоделировать сложные разветвленные физические цепи, чтобы осуществить операции имитации произвольных режимов работы цепных моделей электротехнических устройств. Доступно несколько форматов отображения. Если в панели приборов не будет хватать нужных именно вам, вы можете создать дополнительный прибор самостоятельно, задав его параметры.",
        },
        // {
        //   type: "img",
        //   src: Slide5,
        //   title: "Личный кабинет",
        //   text:
        //     "В этом разделе вы можете управлять своим аккаунтом: изменять персональные данные, продлевать тариф или же переходить на другой, сменять тему отображения (темная/светлая).",
        // },
        // {
        //   type: "img",
        //   src: Slide6,
        //   title: "Вопросы и ответы. Помощь",
        //   text:
        //     "Для всех пользователей доступна полезная информация в формате вопрос-ответ, инструкции по использованию сервиса. Также вы можете задать свой вопрос, сообщить об ошибке на сайте, сделать предложения по улучшению, связаться со технической поддержкой.",
        // },
      ],
      nav1: null,
      nav2: null,
    }
    this.settingsMedia = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      centerPadding: 30,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      asNavFor: '.slider-content',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: true,
            arrows: false
          }
        },
      ]
    };
    this.settingsContent = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      centerPadding: 30,
      slidesToScroll: 1,
      arrows: false,
      draggable: false,
      asNavFor: '.slider-media',
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }
  render() {
    const { photos } = this.state
    return (
      <section className={"landing-how_does_it_work"}>
        <div className="landing-how_does_it_work-wrapper" id={"link-how_does_it_work"}>
          <h3 className={"landing-how_does_it_work-wrapper-title"}>
            Как работает
          </h3>
          {photos.length ? (
            <div className={"landing-how_does_it_work-wrapper-slider"}>
              <Slider
                className="slider-media"
                {...this.settingsMedia}
                asNavFor={this.state.nav1, this.state.nav2}
                ref={slider => (this.slider1 = slider)}
              >
                {photos.map((el, i) => {
                  return (
                    <Container key={i}>
                      <React.Fragment key={i}>
                        <div
                          className={
                            "landing-how_does_it_work-wrapper-slider-wrapper"
                          }
                        >
                          <div
                            className={
                              "landing-how_does_it_work-wrapper-slider-wrapper-bg"
                            }
                          >
                            {el.type === "video" ? (
                              <div
                                className={
                                  "landing-how_does_it_work-wrapper-slider-wrapper-video_wrapper"
                                }
                              >
                                <ReactPlayer
                                  className="react-player"
                                  url={el.src}
                                  width="100%"
                                  height="100%"
                                  style={{
                                    borderRadius: "30px",
                                  }}
                                />
                              </div>
                            ) : (
                                <div
                                  className={
                                    "landing-how_does_it_work-wrapper-slider-wrapper-img_wrapper"
                                  }
                                >
                                  <img
                                    className={
                                      "landing-how_does_it_work-wrapper-slider-wrapper-img"
                                    }
                                    src={el.src}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                      </React.Fragment>
                    </Container>
                  );
                })}
              </Slider>
              <Slider
                className="slider-content"
                {...this.settingsContent}
                asNavFor={this.state.nav2, this.state.nav1}
                ref={slider => (this.slider2 = slider)}
              >
                {photos.map((el, i) => {
                  return (
                    <Container key={i}>
                      <React.Fragment key={i}>
                        <div className="landing-how_does_it_work-wrapper-slider-content">
                          <h4
                            className={
                              "landing-how_does_it_work-wrapper-slider-title"
                            }
                          >
                            {el.title}
                          </h4>
                          <span
                            className={
                              "landing-how_does_it_work-wrapper-slider-text_content"
                            }
                          >
                            {el.text}
                          </span>
                        </div>
                      </React.Fragment>
                    </Container>
                  );
                })}
              </Slider>
            </div>
          ) : null}
        </div>
      </section>
    );
  }
};

export default HowDoesItWork;
