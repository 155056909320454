import React from 'react'
import PersonalPageLayout from '../../Views/Layout/PersonalPageLayout'
import Layout from '../../Views/Layout'
import FavoriteProject from '../../components/FavoriteProject'

const PersonalPageFavoriteProject = () =>{
  return(
  <Layout title={"Избранные"}>
    <PersonalPageLayout>
      <FavoriteProject />
    </PersonalPageLayout>
  </Layout>
  )
}
export default PersonalPageFavoriteProject