import React from "react";
import { Table } from "react-bootstrap";
import Th from "./Th";
import Tb from "./Tb";

class TableGenerate extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { TableHeadData, TableBodyData, variant } = this.props;
    return (
      <Table hover variant={variant} responsive className={"table_generate"}>
        <thead>
          <Th TableHeadData={TableHeadData} />
        </thead>
        <tbody>
          <Tb TableBodyData={TableBodyData} />
        </tbody>
      </Table>
    );
  }
}
export default TableGenerate;
