import React from "react";

const ConfirmComponent = (props) => {
  const { cansel = "Отмена", success = "ОК", loader = false } = props
  return (
    <div
      className={`confirm_component-wrap ${
        props.addClassName ? props.addClassName : ""
        }`}
    >
      <button
        onClick={props.callbackCancellation}
        className={"confirm_component-cancellation"}
      >
        {cansel}
      </button>
      <button onClick={props.callbackOk} className={"confirm_component-ok"}>
        { success }
      </button>
    </div>
  );
};

export default ConfirmComponent;
