import React from 'react';
import {
  AmbientLight,
  Canvas,
  PerspectiveCamera,
  OrthoganalControl,
  Raycast,
  DebugMode,
  Box,
} from '../../gengine';
import * as _ from 'lodash';
import connect from 'storeon/react/connect';
import DragAndDropControl from '../CustomComponentToCanvas/DragAndDropControl';
import WiresComponentCustom from '../CustomComponentToCanvas/WiresComponentCustom';
import CouplingSleeveCustom from '../CustomComponentToCanvas/CouplingSleeveCustom';
import AbstractComponentFactory from '../CustomComponentToCanvas/AbstractComponentFactory';
import ContextMenuForVLS from '../../common/ContextMenuForVLS';
import ZoomBlock from '../../common/ZoomBlock';
import isEqual from 'react-fast-compare';
import ModalGenerateOnlyCanvas from '../ModalGenerateOnlyCanvas';
import ToolTipVLSDevice from '../ToolTipVLSDevice';
import AbstarctVLS from './AbstractVLS'; //вынес логику в абстрактный класс
import ModalContentForGLTFLoader from '../../common/ModalContentForGLTFLoader';
import defaultState from './DefaultState';
import api from '../../api';

class CanvasVLS extends AbstarctVLS {
  constructor(props) {
    super(props);
    this.stateControler = {
      default: 'default',
      start: 'start',
      progress: 'progress',
      error: 'error',
    };
    this.timeOut = null;
    this.state = {
      ...defaultState,
    };
  }

  checkDevices = () => {
    let { devices } = this.props;
    let { visibleModal, dataAllObjects } = this.state;
    const checkLoadData = (data) => {
      if (data.componentData.length) return true;
      if (data.couplingSleeve.length) return true;
      return false;
    };
    if (devices.componentData) {
      if (
        dataAllObjects.componentData.length !== devices.componentData.length ||
        devices.couplingSleeve.length !== dataAllObjects.couplingSleeve.length
      ) {
        if (devices) {
          this.setState({
            mounted: true,
            isLoaded: true,
            visibleModal: {
              ...visibleModal,
              isVisible: checkLoadData(devices),
              data: {
                content: checkLoadData(devices) ? <ModalContentForGLTFLoader /> : null,
              },
            },
            dataAllObjects: devices,
          });
        }
      }
    }
  };

  callbackOnClickForWire = (e, iterArrToAllVertices, iterArrToContextVertices) => {
    this.setState(
      {
        lineData: {
          obj: e,
          iterArrToAllVertices,
          iterArrToContextVertices,
        },
      },
      () => this.handleClick(e),
    );
  };

  clearAllScene = () => {
    const { id } = this.props;
    api.ResearchApi.dropSceneResearch(id);
  };

  checkSceneHandler = (watcherHandler) => {
    let watch = false;
    if (watcherHandler.clear) {
      watch = true;
      this.setState(
        {
          dataAllObjects: {
            wiresData: [],
            componentData: [],
            couplingSleeve: [],
          },
        },
        () => {
          this.clearAllScene();
          this.clearLineFromWiresForDropAllScene();
          this.props.dispatch('SceneHandler', {
            ...watcherHandler,
            clear: false,
          });
        },
      );
    }

    return watch;
  };

  contextMenuDefault = (e) => {
    e.preventDefault();
    return false;
  };

  componentWillUnmount() {
    window.removeEventListener('contextmenu', this.contextMenuDefault);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { LoadDevice, SidebarDataFromDevice } = this.props;
    if (SidebarDataFromDevice?.isUpdate) {
      this.saveParamsDeviceFromSidebar(SidebarDataFromDevice.hash_id, SidebarDataFromDevice.data);
      this.props.dispatch("SidebarDataFromDevice", {})
    }
    if (LoadDevice !== prevProps.LoadDevice) {
      if (LoadDevice) {
        this.setState({
          mounted: true,
          isLoaded: true,
          visibleModal: {
            ...this.state.visibleModal,
            isVisible: true,
            data: {
              content: <ModalContentForGLTFLoader title={'Загрузка данных прибора...'} />,
            },
          },
        });
      } else {
        this.setState({
          mounted: false,
          isLoaded: false,
          visibleModal: {
            ...this.state.visibleModal,
            isVisible: false,
            data: {
              content: null,
            },
          },
        });
      }
    }
    if (this.props.UpdateComponentDevice !== prevProps.UpdateComponentDevice) {
      if (!this.props.UpdateComponentDevice) {
        this.setState({
          isUpdate: false,
        });
      }
      if (!this.state.isUpdate && this.props.UpdateComponentDevice) {
        this.setState({
          dataAllObjects: this.props.UpdateComponentDevice,
          isUpdate: true,
        });
      }
      return true;
    }
    if (this.props.devices !== prevProps.devices) {
      this.checkDevices();
      return true;
    }
    if (this.state.dataAllObjects.wiresData.length !== prevState.dataAllObjects.wiresData.length) {
      return true;
    }
    //должен закинуть данные в стор
    if (this.props.isSaveScene) {
      //todo: добавить обработку промиса метод сохранния асинк
      this.saveScene().then((res) => {
        this.props.dispatch('DataSceneSaving', res);
      });
      return false;
    }
    if (!isEqual(prevState.dataAllObjects, this.state.dataAllObjects)) {
      return true;
    }
    if (prevProps.ElementsVLS !== this.props.ElementsVLS) {
      if (prevProps.ElementsVLS.length >= this.props.ElementsVLS.length) {
        return false;
      }
      if (prevProps.ElementsVLS.length < this.props.ElementsVLS.length) {
        this.createElement(prevProps.ElementsVLS, this.props.ElementsVLS);
      }
      return true;
    }
    let watch = this.checkSceneHandler(this.props.SceneHandler);
    if (watch) return true;
    return false;
  }

  componentDidMount() {
    this.checkDevices();
    window.addEventListener('contextmenu', this.contextMenuDefault);
    this.props.dispatch('CallbackCollector', {
      unmountController: this.clearMemoryAndUnmountController,
    });
  }

  render() {
    const {
      objectsDrag,
      isUpdateLine,
      visibleContextMenu,
      visibleToolTipVLSDevice,
      visibleModal,
      dataAllObjects,
      isDisconnectedWire,
      linkToSceneObject,
      zoomOrhoganal,
      isUpdate,
    } = this.state;
    const { wiresData, componentData, couplingSleeve } = dataAllObjects;
    return (
      <React.Fragment>
        <ZoomBlock clickSetZoomInc={this.clickSetZoomInc} clickSetZoomDec={this.clickSetZoomDec} />
        <ModalGenerateOnlyCanvas visibleModal={visibleModal} />
        <ToolTipVLSDevice
          callbackCloseTooltipDeviceVLS={this.callbackCloseTooltipDeviceVLS}
          visibleToolTipVLSDevice={visibleToolTipVLSDevice}
        />
        {this.props.UpdateComponentDevice ? null : (
          <ContextMenuForVLS
            visibleContextMenu={visibleContextMenu.isVisible}
            data={visibleContextMenu.data}
            dataAllObjects={dataAllObjects}
            callbackCloseContextMenu={this.callbackCloseContextMenu}
            callbackAddPointToWires={this.callbackAddPointToWires}
            callbackColorPicker={this.callbackColorPicker}
            callbackDeleteElementScene={this.callbackDeleteElementScene}
            handlerClickDisconnectConnector={this.handlerClickDisconnectConnector}
            handlerIsDisconnectedWire={this.handlerIsDisconnectedWire}
            callbackCreateOuterTerminal={this.callbackCreateOuterTerminal}
            callbackDeactivateOuterTerminal={this.callbackDeactivateOuterTerminal}
            linkToSceneObject={linkToSceneObject}
          />
        )}

        <canvas
          id="charts-generator"
          className={'canvas-charts_generate'}
          width="400"
          height="400"
        ></canvas>
        <Canvas
          // className={'canvas_render'}
          getScene={!linkToSceneObject ? this.getLinkScene : null}
          enableShadows={false}
          id={'canvas'}
          debug={false}
          fullscreen={true}
          //fps={process.env.REACT_APP_FPS_ENABLED}
        >
          <PerspectiveCamera
            orthoganalEnable={true}
            position={[0, 20, 0]}
            zoomOrhoganal={zoomOrhoganal}
          >
            <OrthoganalControl />
            {/* <OrbitControls /> */}
            <Raycast />
            {this.props.UpdateComponentDevice ? null : (
              <DragAndDropControl
                dataAllObjects={dataAllObjects}
                detachForMemoryClear={this.clearMemory}
                callbackDrag={this.callbackDrag}
                callbackDragForCoplingSleeve={this.callbackDragForCoplingSleeve}
                object={objectsDrag}
                updateComponent={this.updateComponent}
              />
            )}

            {/* <DebugMode /> */}
          </PerspectiveCamera>
          <WiresComponentCustom
            handleRightClick={this.handleRightClick}
            callbackOnClick={this.callbackOnClickForWire}
            isUpdateLine={isUpdateLine}
            isDisconnectedWire={isDisconnectedWire}
            handlerIsDisconnectedWire={this.handlerIsDisconnectedWire}
            wiresData={wiresData}
          />
          <CouplingSleeveCustom
            couplingSleeveData={couplingSleeve}
            handleRightClick={this.handleRightClick}
            callbackOnClick={(e) => {
              this.handleClick(e);
            }}
            startLoadGLTF={this.startLoadGLTF}
            progressLoadGLTF={this.progressLoadGLTF}
            errorLoadGLTF={this.errorLoadGLTF}
            readyComponentGLTF={this.readyComponentGLTF}
          />
          <AbstractComponentFactory
            callbackOnClick={(e) => {
              this.handleClick(e);
            }}
            addWireFromConnectorCallback={this.addWireFromConnectorCallback}
            handleToolTipsElement={this.handleToolTipsElement}
            handleRightClick={this.handleRightClick}
            startLoadGLTF={this.startLoadGLTF}
            progressLoadGLTF={this.progressLoadGLTF}
            errorLoadGLTF={this.errorLoadGLTF}
            readyComponentGLTF={this.readyComponentGLTF}
            componentData={componentData}
            setUpdate={this.setUpdate}
            isUpdate={isUpdate}
          />
          <Box
            position={[0, -50, 0]}
            scale={[100000, 0.1, 1000000]}
            material={{ color: '#F2F3F7' }}
          />
          {/* F2F3F7 */}
          <AmbientLight position={[0, 10, 0]} intensity={2} />
          {/* <DirectionalLight intensity={0.1} position={[0, 10, 0]} /> */}
        </Canvas>
      </React.Fragment>
    );
  }
}

export default connect(
  'DeleteElementData',
  'SceneHandler',
  'ElementsContext',
  'DataSelectedElementForSidebar',
  'ElementsToolTips',
  'ElementsVLS',
  'DataSceneSaving',
  'UpdateComponentDevice',
  'CallbackCollector',
  'LoadDevice',
  'SidebarDataFromDevice',
  CanvasVLS,
);
