import React from 'react'
import AbstractObject from '../abstract/AbstractObject'
import { GenericGroupObject as GenericGroupObjectAbstract } from '../../abstract/GenericGroupObject'

class GenericGroupObject extends AbstractObject {
  constructor() {
    super()
    this.obj = null
    this.timeOutControls = null
    this.state = {
      ready: false,
      parent: false,
      objectScene: null
    }
  };

  componentWillUnmount() {
    const { objectScene } = this.state
    if (!objectScene) return
    objectScene.removeObject(objectScene.parent, this.props.scene)
    clearTimeout(this.timeOutControls)
    this.setState({
      ready: false
    })
  }

  componentDidMount() {
    const params = this.props
    this.initComponent()
    let Parent = new GenericGroupObjectAbstract(params)
    this.setState({
      parent: Parent.getObject(),
      objectScene: Parent
    }, () => {
      this.onPropsUpdate({}, this.props)
      this.timeOutControls = setTimeout(() => {
        this.readyComponent()
        this.setState({
          ready: true,
        })
      }, 1)
    })
  }

  render() {
    const { ready, parent } = this.state
    if (!ready) return null
    let paramsToPropsChildren = {
      scene: this.props.scene,
      renderer: this.props.renderer,
      enableVR: this.props.enableVR,
      effect: this.props.effect,
      canvasWidth: this.props.canvasWidth,
      canvasHeight: this.props.canvasHeight,
      enableShadows: this.props.enableShadows,
      onComponentInit: this.props.onComponentInit,
      onComponentReady: this.props.onComponentReady,
      getComponentByName: this.props.getComponentByName,
      getComponentByUuid: this.props.getComponentByUuid,
      debug: this.props.debug,
      parent: parent
    }

    if (React.Children) {
      const childrenWithProps = React.Children.map(this.props.children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...paramsToPropsChildren,
          })
        }
        return null
      })
      return (<>{childrenWithProps}</>)
    }
    return null

  };
}

export default GenericGroupObject