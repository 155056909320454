import React from 'react';
import AbstractObject from "../../abstract/AbstractObject";
import { PerspectiveCamera } from '../../../abstract/cameras/PerspectiveCamera'
class PerspectiveCameraCustom extends AbstractObject {

  constructor(props) {
    super();
    this.obj = null;
    this.frustumSize = 5
    this.state = {
      ready: false,
    }
  };

  componentDidMount() {
    const {
      // default from parent
      scene,
      canvasHeight,
      canvasWidth,
      renderer,
      addRenderCall,
      effect,
      enableVR,
      enableQuadCamera,
      // custom,
      zoomOrhoganal = null,
      position = [0, 0, 0],
      // zoom = 1,
      degrisToCameraPerspectiv,
      aspect,
      far,
      near,
      orthoganalEnable,
      frustumSize = 5
    } = this.props;
    this.initComponent();
    this.frustumSize = frustumSize
    const camera = new PerspectiveCamera({
      scene,
      position,
      canvasHeight,
      canvasWidth,
      addRenderCall,
      renderer,
      effect,
      enableVR,
      enableQuadCamera,
      degrisToCameraPerspectiv,
      aspect,
      far,
      near,
      orthoganalEnable,
      zoomOrhoganal,
      frustumSize
    });
    this.obj = camera.obj
    this.resize()
    window.addEventListener("resize", this.resize);
    this.timeOutControls = setTimeout(() => {
      this.readyComponent();
      this.setState({
        ready: true,
      })
    }, 1);
  };

  resize = () => {
    const {
      renderer,
    } = this.props;
    let aspect = window.innerWidth / window.innerHeight;
    this.obj.left = this.frustumSize * aspect / - 2;
    this.obj.right = this.frustumSize * aspect / 2;
    this.obj.top = this.frustumSize / 2;
    this.obj.bottom = - this.frustumSize / 2;
    this.obj.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);

  }
  setZoomOrhoganal = (zoomOrhoganal) => {
    if (!zoomOrhoganal) return;
    this.obj.zoom = zoomOrhoganal;
    this.obj.updateProjectionMatrix();
  };

  shouldComponentUpdate(nextProps) {
    const {
      zoomOrhoganal,
    } = this.props;
    if (nextProps.zoomOrhoganal) {
      if (zoomOrhoganal !== nextProps.zoomOrhoganal) {
        this.setZoomOrhoganal(nextProps.zoomOrhoganal);
      }
    }
    this.onPropsUpdate(this.props, nextProps);
    return true
  };

  render() {
    const { ready } = this.state;
    if (!ready) return null;
    const childrenWithProps = React.Children.map(this.props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          ...this.props,
          camera: this.obj,
        })
      }
    });

    return (<>{childrenWithProps}</>);
  };

}

export default PerspectiveCameraCustom;
