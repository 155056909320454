import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2'
import { MtlObjBridge } from "three/examples/jsm/loaders/obj2/bridge/MtlObjBridge.js";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader.js";
import React from 'react'

class OBJLoaderAbs extends React.Component {
	constructor(props) {
		super()
	}

	componentDidMount() {
		const {
			scene,
			addRenderCall,
			enableShadows,
			renderer,
			url = null, // required!!!
			urlMLT = null,
			position = [0, 0, 0],
			rotation = [0, 0, 0],
			quaternion = false,
			scale = [1, 1, 1],
			pivot = false,
			onLoadComplete = null,
			animation = null,
			visible = true,
			name = "OBJ",
			callback,
			parent,
			startLoadGLTF,
			progressLoadGLTF,
			errorLoadGLTF,
		} = this.props;

		//! exemple use 
		// 	<OBJLoader
		// 	urlMLT={'/models/OBJ/test/11595_CEE_7_16_Male_Plug_v3_l3.mtl'}
		// 	url={'/models/OBJ/test/11595_CEE_7_16_Male_Plug_v3_l3.obj'}
		//   />
		let objLoader2 = new OBJLoader2()
			.setModelName(name)
			.setUseIndices(true);
		function callbackOnLoad(object3d, message) {
			scene.add(object3d);
		}

		function onLoadMtl(mtlParseResult) {
			objLoader2.addMaterials(MtlObjBridge.addMaterialsFromMtlLoader(mtlParseResult), true);
			objLoader2.load(url, callbackOnLoad, null, null, null);
		}

		let mtlLoader = new MTLLoader();
		mtlLoader.load(urlMLT, onLoadMtl);
	}

	render() {
		return null
	}
}

export default OBJLoaderAbs