import { Vector2 } from "three";
import { v4 } from "uuid";
/**
 * сериализует множество подобластей в набор точек
 * @param {*} data
 */
export const GetAllPointsFromSubareas = (data = []) => {
  let result = {};
  data.forEach((el, i) => {
    if (i === 2) {
      let uuid = v4();
      result[uuid] = [];
      el.verticesForCreateMesh[0].forEach((elDot) => {
        result[uuid].push(new Vector2(elDot.x, elDot.y));
        elDot.edge.forEach((elEdge) => {
          elEdge.aproximationVertices.forEach((elNode) => {
            result[uuid].push(new Vector2(elNode.x, elNode.y));
          });
        });
      });
    }
  });

  return result;
};
