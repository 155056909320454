import React, { createRef } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { data } from "jquery";

class ColorPicker extends React.Component {
  colorPickerRef = createRef();
  state = {
    displayColorPicker: false,
    color: false,
    styleBTN: {
      color: {
        width: "41px",
        height: "20px",
        borderRadius: "2px",
        background: `#000`,
      },
    },
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState(
      {
        color: color.rgb,
        styleBTN: {
          color: {
            width: "41px",
            height: "20px",
            borderRadius: "2px",
            background: `${color.hex}`,
          },
        },
      },
      () => {
        this.props.onChangeColor(color.hex);
      }
    );
  };

  componentWillUnmount() {
    this.setState({
      color: "#000",
      styleBTN: {
        color: {
          width: "41px",
          height: "20px",
          borderRadius: "2px",
          background: `#000`,
        },
      },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.displayColorPicker !== this.state.displayColorPicker &&
      this.colorPickerRef.current
    ) {
      const { dataEvent } = this.props;
      let originalEvent = dataEvent.data.originalEvent;
      // debugger
      let { offsetX, screenY } = originalEvent;
      screenY += 307;
      let windowHeight = window.innerHeight;
      if (windowHeight <= screenY) {
        this.colorPickerRef.current.style.top = `-307px`;
        console.log(
          "this.colorPickerRef.current?.style?.top",
          this.colorPickerRef.current.style
        );
      }
    }
  }

  componentDidMount() {
    this.setState({
      color: this.props.defaultColor,
      styleBTN: {
        color: {
          width: "41px",
          height: "20px",
          borderRadius: "2px",
          background: `${this.props.defaultColor}`,
        },
      },
    });
  }

  render() {
    const { styleBTN } = this.state;
    let color = this.props?.defaultColor;
    if (color) {
      const styles = reactCSS({
        default: {
          color: {
            width: "41px",
            height: "20px",
            borderRadius: "2px",
            backgroundColor: `rgba(${color?.r}, ${color?.g}, ${
              color?.b
            }, ${1})`,
          },
          swatch: {
            borderRadius: "1px",
            boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
            cursor: "pointer",
          },
          popover: {
            position: "absolute",
            zIndex: "2",
          },
          cover: {
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
          },
        },
      });

      return (
        <div className={"color_picker-wrapper"}>
          <div style={styles.swatch} onClick={this.handleClick}>
            <div style={styleBTN.color} />
          </div>
          {this.state.displayColorPicker ? (
            <div ref={this.colorPickerRef} style={styles.popover}>
              <div style={styles.cover} onClick={this.handleClose} />
              <SketchPicker
                color={this.state.color}
                onChange={this.handleChange}
              />
            </div>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ColorPicker;
