import { useEffect } from 'react';
import {
  Raycaster,
  Vector3,
  Vector2,
  Texture,
  TextureLoader,
  RepeatWrapping,
  CanvasTexture,
} from 'three';
import { drawChart } from './ChartCreator';
import { DATA_OBJECT_SCENE, allTypeDevice } from '../const';
import PromiseWorker from './WorkerUtils';
import isEqual from 'react-fast-compare';
import { cloneDeep } from 'lodash';

/**
 * проверит обновления в проводах а конекретнее проверит изменения внешних клемм
 * @param {*} newWiresData
 * @param {*} prevWiresData
 */
export const checkUpdateOuterClemm = (newWiresData, prevWiresData) => {
  let isUpdate = false;
  newWiresData.forEach((el, i) => {
    prevWiresData.forEach((elPrev, iPrev) => {
      if (el.id === elPrev.id) {
        if (el.status !== elPrev.status) {
          isUpdate = true;
        }
      }
    });
  });
  if (newWiresData.length < prevWiresData.length) isUpdate = true;
  return isUpdate;
};
/**
 * просто на основе типа девайса вернет его название
 * @param {*} type
 */
export const getDeviceNameFromDeviceType = (type) => {
  switch (type) {
    case 'voltmeter':
      return 'Вольтметр';
    case 'ampermeter':
      return 'Амперметр';
    case 'watmeter':
      return 'Ваттметр';
    case 'oscilograf':
      return 'Осциллограф';
    case 'oneComponentReostat':
    case 'twoComponentReostat':
    case 'threeComponentReostat':
      return 'Реостат';
    case 'rezistor':
      return 'Резистор';
    case 'capacitor':
      return 'Конденсатор';
    case 'inductor':
      return 'Индуктор';
    case 'oneComponentVendingMachine':
    case 'twoComponentVendingMachine':
    case 'threeComponentVendingMachine':
      return 'Автомат';
    case 'voltageSource':
      return 'Источник напряжения';
    case 'terminal':
      return 'Терминал';
    case 'elementEarth':
      return 'Земля';
    case 'constantCurrentMachine':
      return 'Машина с постоянным током';
    case 'asyncMachine':
      return 'Асинхронная машина';
    case 'onePhaseTransformer':
    case 'twoPhaseTransformer':
    case 'threePhaseTransformer':
      return 'Трансформатор';
    case 'mechanicalLoad':
      return 'Механическая нагрузка';
    default:
      return 'Неопознанный прибор';
  }
};
/**
 * делает поиск по всем примитивам стандарных точек пока не расширеный метод
 * !вынести его в воркер
 * todo: дописать его чтоб он мог парсить и кривые и другие нестанждарные объекты
 * ? возможно надо будет дополнить парсер новыми типами
 * @param {*} renderDots
 * @param {*} setMaterial
 * @param {*} coords
 */
export const sercheColizionStraightToPFML = (
  renderDots = [],
  setMaterial = () => {},
  coords = {},
) => {
  const lerpVector2 = (startVector, stopVector) => {
    const lerp = (v1, v2, alpha) => {
      let x = v1.x + (v2.x - v1.x) * alpha;
      let y = v1.y + (v2.y - v1.y) * alpha;
      return { x, y };
    };
    if (stopVector && startVector) {
      let resLerp = lerp(
        new Vector2(startVector.x, startVector.y),
        new Vector2(stopVector.x, stopVector.y),
        0.98,
      );

      let result = new Vector2(resLerp.x, resLerp.y);
      return result;
    }
  };
  const intersecCheck = (setMaterial, coords) => {
    let intersection = lineIntersection(
      coords.startPoint,
      coords.endPoint,
      coords.startPointLine,
      coords.endPointLine,
    );
    if (!intersection) {
      setMaterial('blue');
    } else {
      outLoop = true;
      setMaterial('green');
    }
  };
  let outLoop = false;
  let newRenderDot = [];
  //!тут происходит пересбор масива для более удобной проверки пересечений
  renderDots.forEach((el) => {
    if (el.type === 'arc') {
      newRenderDot.push(el);
      newRenderDot.push(el.startPoint);
      newRenderDot.push(el.centerPoint);
      newRenderDot.push(el.endPoint);
    } else {
      newRenderDot.push(el);
    }
  });
  newRenderDot.forEach((itemDot) => {
    if (itemDot.type !== 'arc') {
      if (itemDot.linkToStraight.length) {
        itemDot.linkToStraight.forEach((itemLink) => {
          newRenderDot.forEach((itemDot2) => {
            if (outLoop) return;
            if (itemDot.uuidDot !== itemDot2.uuidDot) {
              if (itemLink === itemDot2.uuidDot) {
                coords.startPointLine = lerpVector2(
                  new Vector2(itemDot.x, itemDot.y),
                  new Vector2(itemDot2.x, itemDot2.y),
                );
                coords.endPointLine = lerpVector2(
                  new Vector2(itemDot2.x, itemDot2.y),
                  new Vector2(itemDot.x, itemDot.y),
                );
                intersecCheck(setMaterial, coords);
              }
            }
          });
        });
      }
    } else {
      let vertices = itemDot.verticesForArc.map((el) => new Vector2(el.x, el.z));
      vertices.forEach((elVer, j) => {
        if (outLoop) return;
        let nextIndex = j + 1;
        let index = nextIndex >= vertices.length ? false : nextIndex;
        if (!index) return;
        coords.startPointLine = lerpVector2(
          new Vector2(elVer.x, elVer.y),
          new Vector2(vertices[index].x, vertices[index].y),
        );
        coords.endPointLine = lerpVector2(
          new Vector2(vertices[index].x, vertices[index].y),
          new Vector2(elVer.x, elVer.y),
        );
        intersecCheck(setMaterial, coords);
      });
    }
  });
};
/**
 * Принимает 4 вектора по 2 на линию и обработает колизии линии
 * @param {*} A  vector2
 * @param {*} B  vector2
 * @param {*} C  vector2
 * @param {*} D  vector2
 */
export const lineIntersection = (A, B, C, D) => {
  let vector1Collizion;
  let vector2Collizion;
  // 1vector
  let z_11 = (B.x - A.x) * (C.y - A.y) - (B.y - A.y) * (C.x - A.x);
  let z_12 = (B.x - A.x) * (D.y - A.y) - (B.y - A.y) * (D.x - A.x);
  // 2vector
  let z_21 = (C.x - D.x) * (A.y - C.y) - (C.y - D.y) * (A.x - C.x);
  let z_22 = (C.x - D.x) * (B.y - C.y) - (C.y - D.y) * (B.x - C.x);
  if (z_11 * z_12 > 0) {
    vector1Collizion = false;
  } else {
    vector1Collizion = true;
  }
  if (z_21 * z_22 > 0) {
    vector2Collizion = false;
  } else {
    vector2Collizion = true;
  }
  if (vector1Collizion && vector2Collizion) {
    return true;
  } else {
    return false;
  }
};

export const nugol = (x1, y1, x2, y2) => {
  var xf = x2 - x1;
  var yf = y2 - y1;
  var result = 0;
  if (xf != 0) {
    result = Math.atan(yf / xf);
  } else {
    if (yf > 0) {
      result = Math.PI / 2;
    } else {
      result = (Math.PI * 3) / 2;
    }
  }
  if (xf < 0) result += Math.PI;
  if (result < 0) result += Math.PI * 2; //todo: wat
  if (result >= Math.PI * 2) result -= Math.PI * 2;
  return result;
};

export const checkDirectionForVector2 = (vectorStart, vectroEnd) => {
  let result = Math.sqrt(
    Math.pow(vectorStart.x - vectroEnd.x, 2) + Math.pow(vectorStart.y - vectroEnd.y, 2),
  );
  return result;
};

export const debounce = (functionContext, wait, immediate) => {
  let timeout;
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) functionContext.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) functionContext.apply(context, args);
  };
};

export const replaceResponseHelpText = (text) => {
  text = `${text}`;
  const newText = text.replace(/\[|\]|\'/g, '');
  return newText;
};
export const clipBoardNavigator = (text) => {
  const textClean = `${window.location.origin}${text.trim()}`;
  if (textClean) {
    //todo: не работает в некоторых браузерах
    navigator.clipboard
      .writeText(textClean)
      .then(() => {
        console.log('copy'); //todo: Добавь уведомление о удачно скопированном тексте
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  }
};

export const createLinearGradient = (color) => {
  let canvas = document.createElement('canvas');
  let ctx = canvas.getContext('2d');
  canvas.width = 700;
  canvas.height = 400;
  // linear-gradient(48.65deg, rgba(204, 229, 255, 0.7) 27.65%, rgba(0, 126, 255, 0.7) 86.61%);
  var gradient = ctx.createLinearGradient(0, 0, 100, 100);

  // Добавление трёх контрольных точек
  gradient.addColorStop(0, '#cce5ff');
  gradient.addColorStop(0.5, '#007eff');
  gradient.addColorStop(1, '#007dff');

  // Установка стиля заливки и отрисовка прямоугольника градиента
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, 2000, 1000);
  let map = new Texture(canvas);
  map.needsUpdate = true;
  return map;
};
/**
 * рисует графики как текстуру
 * !добавить отрисовку
 * @param {*} dataChart
 */
export const generateCartForCanvasDevice = (data) => {
  let image = drawChart(data);
  var texture = new CanvasTexture();
  texture.image = image;
  image.onload = function () {
    texture.needsUpdate = true;
  };
  return texture;
};

export const generateTextureForMesh = (url = '') => {
  let map = new TextureLoader().load(url);
  map.needsUpdate = true;
  return map;
};
/**
 * Позволяет использовать канвас в место изображения для материалов (Для динамики можно использовать)
 * @param text
 * @param color
 * @param width
 * @param height
 * @return {Texture}
 */
export const generateLabelMaterial = (text, color = '#000', width, height, textParams = {}) => {
  const { fillTextXAxias = 10, fillTextYAxias = 15, font = '14pt a_lcdnova' } = textParams;
  let canvas = document.createElement('canvas');
  let xc = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  xc.font = font;
  xc.fillStyle = color;
  xc.strokeStyle = color;
  xc.fill();
  xc.fillText(text, fillTextXAxias, fillTextYAxias);
  xc.textAlign = 'center';
  xc.stroke();
  let map = new CanvasTexture(canvas);
  map.needsUpdate = true;
  return map;
};
/**
 * проверка на обновления
 * @param oldArr
 * @param newArr
 * @return {boolean}
 */
export const isUpdateArr = (oldArr = [], newArr = []) => {
  let results = false;
  if (!isEqual(oldArr, newArr)) {
    results = true;
  }
  // let lengthOld = oldArr.map((el) => el.verticesLength);
  // let lengthNew = newArr.map((el) => el.verticesLength);

  // for (let i = 0; i < lengthNew.length; i++) {
  //   if (lengthNew[i] && lengthOld[i]) {
  //     if (lengthNew[i] !== lengthOld[i]) {
  //       results = true;
  //     }
  //   }
  // }
  return results;
};

export const checkUpdateVertices = (wiresData, prevWiresData) => {
  let result = false;
  if (prevWiresData.length) {
    if (prevWiresData.length === wiresData.length)
      wiresData.forEach((el, i) => {
        if (prevWiresData[i]) {
          if (el.vertices)
            if (JSON.stringify(el.vertices) !== JSON.stringify(prevWiresData[i].vertices)) {
              result = true;
            }
        }
      });
  }

  return result;
};
/**
 * проверка на удаление
 * @param oldArr
 * @param newArr
 * @return {boolean}
 */
export const isDeleteElement = (oldArr = [], newArr = []) => {
  let results = false;
  let lengthOld = oldArr.map((el) => el.vertices.length);
  let lengthNew = newArr.map((el) => el.vertices.length);
  for (let i = 0; i < lengthNew.length; i++) {
    if (lengthNew[i] && lengthOld[i]) {
      if (lengthNew[i] < lengthOld[i]) {
        results = true;
      }
    }
  }
  return results;
};
/**
 * @param mesh
 * @param data
 * @param material
 * @param callbackClick
 * @param callbackRightClick
 * развешивает события на объекты
 */
export const eventListenerAdd = (mesh, data, material, callbackClick, callbackRightClick) => {
  let obj;
  if (mesh.scene) {
    obj = mesh.scene.getObjectByProperty('uuid', mesh.uuid);
  } else {
    obj = mesh;
  }
  if (data.allowClick) {
    obj.cursor = 'pointer';
    obj.on('mouseover', (ev) => {
      if (material) material.color.set('#ff001a');
      // callbackClick(ev);
    });
    obj.on('mouseout', (ev) => {
      if (material) material.color.set('#000cff');
    });
    obj.on('click', (ev) => {
      callbackClick(ev);
    });
  }
  if (data.rightClick) {
    obj.on('rightclick', (ev) => {
      let dataOriginalEvent = ev.data.originalEvent;
      callbackRightClick(ev);
    });
  }
};
/**
 * @returns новый объект со всеми данными dataAllObjects
 * @param {*} sceneParent это сцена которую я взял из параметра parent  в target
 * @param {*} target
 * @param {*} dataAllObjects все данные из стейта отвечающие за отрисовку от сюда веть тоже надо удалять
 */
export const DeleteObjectFromSceneAndState = (sceneParent, target, dataAllObjects) => {
  console.log('TARGET', target);
  let { wiresData, componentData, couplingSleeve } = dataAllObjects;
  let typeConnector = DATA_OBJECT_SCENE.connector.name;
  let typeDevice = DATA_OBJECT_SCENE.deviceConnector.name;
  let typeTransformController = DATA_OBJECT_SCENE.transformControler.name;
  let newWiresData = cloneDeep(wiresData);
  let newComponent = cloneDeep(componentData);
  let newCouplingSleeve = cloneDeep(couplingSleeve);
  const checkDeleteGlobaly = (name, uuid) => {
    let isAlldeleteted = {
      parentId: null,
      isDeleted: false,
    };
    if (name === typeConnector) {
      wiresData.map((el) => {
        el.vertices.map((elVertices) => {
          if (elVertices.id === uuid) {
            isAlldeleteted = {
              parentId: el.id,
            };
          }
        });
      });
    }
    return isAlldeleteted;
  };
  const deleteElementWiresData = (paramsTarget) => {
    const getVertices = (vertices) => {
      if (vertices.length > 2) {
        return vertices.filter((elVertices) => elVertices.id !== paramsTarget.uuid);
      } else {
        return false;
      }
    };
    for (let iterWire = 0; iterWire <= newWiresData.length - 1; iterWire++) {
      let el = newWiresData[iterWire];
      if (el.id === paramsTarget.deleteGlobally.parentId) {
        let vertices = getVertices(el.vertices);
        if (vertices) {
          newWiresData[iterWire] = {
            ...el,
            vertices: vertices,
          };
        } else {
          newWiresData = newWiresData.filter((el, i) => i !== iterWire);
        }
      }
    }
  };
  const deleteToSceneDevice = (element) => {
    let obj = sceneParent.getObjectByProperty('uuid', element.id);
    let transformController = sercheAllObjectByProperty(
      'name',
      typeTransformController,
      sceneParent,
    );
    transformController.forEach((el) => {
      el.detach();
      sceneParent.remove(el);
    });
    obj.geometry.dispose();
    obj.material.dispose();
    sceneParent.remove(obj);
  };
  const deleteElementDevice = (paramsTarget) => {
    for (let iterComponent = 0; iterComponent < newComponent.length; iterComponent++) {
      const el = newComponent[iterComponent];
      if (el.id === target.uuid) {
        newComponent = newComponent.filter((component, i) => component.id !== target.uuid);
        deleteToSceneDevice(el);
      }
    }
  };
  const deleteElementCulpingSleeve = (paramsTarget) => {
    for (let iterComponent = 0; iterComponent < newCouplingSleeve.length; iterComponent++) {
      const el = newCouplingSleeve[iterComponent];
      if (el.id === target.uuid) {
        newCouplingSleeve = newCouplingSleeve.filter(
          (component, i) => component.id !== target.uuid,
        );
        deleteToSceneDevice(el);
      }
    }
  };

  let paramsTarget = {
    type: target.name,
    deleteGlobally: checkDeleteGlobaly(target.name, target.uuid, dataAllObjects),
    uuid: target.uuid,
  };

  switch (paramsTarget.type) {
    case typeConnector:
      deleteElementWiresData(paramsTarget);
      break;
    case typeDevice:
      deleteElementDevice(paramsTarget);
      break;
    case DATA_OBJECT_SCENE.couplingSleeveDAD.name:
    case DATA_OBJECT_SCENE.couplingSleeveMUM.name:
      deleteElementCulpingSleeve(paramsTarget);
      break;
    default:
  }
  return {
    wiresData: newWiresData,
    componentData: newComponent,
    couplingSleeve: couplingSleeve,
  };
};
/**
 * 	метод для очистки от мусора удаляет не используемые объекты из сцены
 * ! закоменчен
 * @param {*} scene
 * @param {*} target
 * @param {*} disponsObj
 */
export const DeleteObjectScene = (scene, target, disponsObj = []) => {
  //scene, nameObj, geometry, materials, textures
  // if (scene && target) {
  //   scene.remove(target);
  // }
  // if (disponsObj) {
  //   for (let i = 0; i < disponsObj.Length; i++) {
  //     disponsObj[i].dispose();
  //   }
  // }
};
/**
 * - глубокий поиск всех элементов подходящих по параметрам
 * todo: переделать на веб воркер
 * @param {*} property
 * @param {*} value
 * @param {*} startNode
 */
export const sercheAllObjectByProperty = (property, value, startNode) => {
  let result = [];
  /**
   * DFS
   * @param {*} start
   * @param {*} target
   */
  const getObjectByProperty = (start, target) => {
    if (start[property] === target) {
      // debugger
      // if (start.children.length)
      result.push(start);
    }
    for (var i = 0; i < start.children.length; i++) {
      getObjectByProperty(start.children[i], target);
    }
    return null;
  };
  getObjectByProperty(startNode, value);
  return result;
};

/**
 * - глубокий поиск 1 элемента подходящего по параметрам
 * todo: переделать на веб воркер
 * @param {*} property
 * @param {*} value
 * @param {*} startNode
 */
export const sercheObjectByProperty = (property, value, startNode) => {
  /**
   * DFS
   * @param {*} start
   * @param {*} target
   */
  const getObjectByProperty = (start, target) => {
    if (start[property] === target) {
      return start;
    }
    for (var i = 0; i < start.children.length; i++) {
      getObjectByProperty(start.children[i], target);
    }
    return null;
  };
  return getObjectByProperty(startNode, value);
};

/**
 * главный обработчик колизии принимает элемент откоторого будут строится лучи для обработки колизии
 * @param {*} scene
 * @param {*} contextElement
 * @param {*} dataInsert
 * @param {*} handlProcessingFoundCollision
 * @returns element collision
 */
export const collisionHandler = (
  scene,
  contextElement,
  dataInsert,
  handlProcessingFoundCollision = (el) => el,
  distansToCheck = 1,
  recurs = true,
) => {
  let dataCollisions = [];
  let defaultPosition = new Vector3();
  let obj = scene.getObjectByProperty('uuid', contextElement.uuid);
  if (obj) obj.getWorldPosition(defaultPosition);
  if (!contextElement.geometry.vertices) {
    return console.log('Отсутсвуют вершины');
  }
  for (let vertexIndex = 0; vertexIndex < contextElement.geometry.vertices.length; vertexIndex++) {
    let localVertex = contextElement.geometry.vertices[vertexIndex].clone();
    let globalVertex = localVertex.applyMatrix4(contextElement.matrix);
    let directionVector = globalVertex.sub(contextElement.position);
    let ray = new Raycaster(defaultPosition, directionVector.clone().normalize());
    let collisionResults = ray.intersectObjects(dataInsert, recurs, []);
    if (collisionResults.length > 0 && collisionResults[0].distance < distansToCheck) {
      let data = handlProcessingFoundCollision(collisionResults[0], contextElement);
      if (data) {
        dataCollisions.push(collisionResults[0]);
      }
    }
  }
  return dataCollisions[0];
};
/**
 *
 * @param {*} params contextElement, dataInsert
 */
export const checkCollisionCustomObjects = (params) => {
  const { contextElement, dataInsert, scene, handlProcessingFoundCollision = () => true } = params;
  return collisionHandler(scene, contextElement, dataInsert, handlProcessingFoundCollision);
};
/**
 * 	метод для проверки столкновения объектов принимает сцену со всем её детьми и контекстный элемент
 * @param scene   object
 * @param contextElement  object
 * @param name  имя набора объектов по которым происходит колизии
 * @param serche  имя искомого объекта внутри одного из списка объектов проверяемых колизией
 * @return bool(false) or object
 */
export const checkCollisionDefault = (
  scene,
  contextElement,
  name = DATA_OBJECT_SCENE.deviceConnector.name,
  serche = DATA_OBJECT_SCENE.objectConnector.name,
) => {
  let ObjectsToScene = [];
  for (let i = 0; i < scene.children.length; i++) {
    if (scene.children[i].name === name) {
      ObjectsToScene.push(...scene.children[i].children);
    }
  }
  return collisionHandler(scene, contextElement, ObjectsToScene, (el) => {
    if (el) {
      let childObject = el.object.getObjectByName(serche);
      if (el.object.name === serche || childObject) {
        console.log(' Hit ');
        return el;
      }
    }
  });
};

export const unicArr = (arr) => {
  const result = [];
  const duplicatesIndices = [];

  // Перебираем каждый элемент в исходном массиве
  arr.forEach((current, index) => {
    if (duplicatesIndices.includes(index)) return;

    result.push(current);

    // Сравниваем каждый элемент в массиве после текущего
    for (let comparisonIndex = index + 1; comparisonIndex < arr.length; comparisonIndex++) {
      const comparison = arr[comparisonIndex];
      const currentKeys = Object.keys(current);
      const comparisonKeys = Object.keys(comparison);

      // Проверяем длину массивов
      if (currentKeys.length !== comparisonKeys.length) continue;

      // Проверяем значение ключей
      const currentKeysString = currentKeys.sort().join('').toLowerCase();
      const comparisonKeysString = comparisonKeys.sort().join('').toLowerCase();
      if (currentKeysString !== comparisonKeysString) continue;

      // Проверяем индексы ключей
      let valuesEqual = true;
      for (let i = 0; i < currentKeys.length; i++) {
        const key = currentKeys[i];
        if (current[key] !== comparison[key]) {
          valuesEqual = false;
          break;
        }
      }
      if (valuesEqual) duplicatesIndices.push(comparisonIndex);
    }
  });
  return result;
};

export const valueNameUser = (val) => {
  if (!val) return val;
  let x = val.replace(/[\W\d][^А-я]/gm, '');
  return x;
};

export const valuePhoneNumberRU = (val) => {
  if (!val) return val;
  let x = val.replace(/\D/g, '');
  let input = x
    .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
    .filter((el) => el.replace(/\D/g, ''));
  let value = '';
  input.map((el, i) => {
    if (!el.length) return;
    switch (i) {
      case 1:
        return (value += `+${el}`);
      case 2:
        return input[3] ? (value += `(${el})`) : (value += `(${el}`);
      case 3:
        return (value += `${el}`);
      case 4:
        return (value += `-${el}`);
      case 5:
        return (value += `-${el}`);
    }
  });
  return value;
};
export const valueEmailRU = (val) => {
  var email = val.match(/^[0-9a-z-\.]+\@[0-9a-z-]{2,}\.[a-z]{2,}$/i);
  if (!email) {
    return false;
  } else {
    return true;
  }
};

export function useDocumentTitle(title) {
  useEffect(() => {
    document.title = title;
  }, [title]);
}
