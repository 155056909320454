import React from 'react'
import ProjectFolderRenderGrid from "../ProjectFolderRenderGrid";
import ProjectFolderRenderList from "../ProjectFolderRenderList";


const ProjectFolderRender = (props) => {


  const {
    onBlurTextAreaNameProject,
    changeNameProject,
    updateGetProjects,
    resultResponse,
    typeViewContent,
    typePage,
    customScroll,
    isRerenderProjects
  } = props

  return (
    <>
      {typeViewContent === "grid" ? (
        <div className='wrapper-scroll_wrap-grid'>
          <div onScroll={customScroll} className={`personal_page-content-content_wrap_grid`}>
            {resultResponse.map((el, i) => {
              return (
                <ProjectFolderRenderGrid
                  isRerenderProjects={isRerenderProjects}
                  typePage={typePage}
                  key={i}
                  onBlurTextAreaNameProject={onBlurTextAreaNameProject}
                  changeNameProject={changeNameProject}
                  updateGetProjects={updateGetProjects}
                  projects={el}
                />
              );
            })}
          </div>
        </div>
      ) : (
          <div onScroll={customScroll} className={`personal_page-content-content_wrap_list`}>
            <ProjectFolderRenderList
              isRerenderProjects={isRerenderProjects}
              typePage={typePage}
              onBlurTextAreaNameProject={onBlurTextAreaNameProject}
              changeNameProject={changeNameProject}
              updateGetProjects={updateGetProjects}
              projects={resultResponse}
            />
          </div>
        )}
    </>
  )
}

export default ProjectFolderRender