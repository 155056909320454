import React from "react";
import uuid from 'uuid'
const CheckBox = ({ id = uuid.v4(), label, helpText, checked = false, onChange = () => { }, addClass = "" }) => {
  return (
    <div className={`default_components ${addClass}`}>
      <div className="checkbox">
        <input
          className="custom-checkbox"
          type="checkbox"
          checked={checked}
          id={id}
          name="color-1"
          onChange={onChange}
        // defaultValue="indigo"
        />
        <label htmlFor={id}>{label}</label>
      </div>
      {
        helpText ? (
          <p className={"default_components-help_text"}>{helpText}</p>
        ) : null
      }
    </div>
  );
};
export default CheckBox;
