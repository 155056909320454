import React from 'react'
import connect from 'storeon/react/connect'
import isEqual from 'react-fast-compare'


class DefaultDataSidebar extends React.Component {
  constructor(props) {
    super()
    this.state = {
      dots: 0,
      primitive: 0,
      subareas: 0,
      nodes: 0,
      elements: 0
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { DataCountAllPrimitiveScenePFML } = this.props
      this.setState({
        dots: DataCountAllPrimitiveScenePFML.dots,
        primitive: DataCountAllPrimitiveScenePFML.primitive,
        subareas: DataCountAllPrimitiveScenePFML.subareas,
        nodes: DataCountAllPrimitiveScenePFML.nodes,
        elements: DataCountAllPrimitiveScenePFML.elements
      })
    }
  }

  componentDidMount() {
    const { DataCountAllPrimitiveScenePFML } = this.props
    this.setState({
      dots: DataCountAllPrimitiveScenePFML.dots,
      primitive: DataCountAllPrimitiveScenePFML.primitive,
      subareas: DataCountAllPrimitiveScenePFML.subareas,
      nodes: DataCountAllPrimitiveScenePFML.nodes,
      elements: DataCountAllPrimitiveScenePFML.elements
    })
  }

  render() {
    const {
      dots,
      primitive,
      subareas,
      nodes,
      elements
    } = this.state
    return (
      <React.Fragment>
        <span className={"pfml-sidebar-device_title"}>ИССЛЕДОВАТЕЛЬСКАЯ СРЕДА</span>
        <div className={"pfml-sidebar-researche_env"}>
          <ul>
            <li>N точек: {dots}</li>
            <li>N примитивов: {primitive}</li>
            <li>N подобластей: {subareas}</li>
            <li>N узлов: {nodes}</li>
            <li>N элементов: {elements}</li>
          </ul>
        </div>

      </React.Fragment>
    )
  }
}

export default connect("DataCountAllPrimitiveScenePFML", DefaultDataSidebar)