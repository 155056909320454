export let UserData = store => {
    // Initial state
    let defaultState = {
        "email": "user@example.com",
        "username": "string",
        "first_name": "string",
        "last_name": "string",
        "patronymic": "string",
        "phone": "string"
    }
    store.on('@init', () => ({
        UserData: defaultState
    }))
    // Reducers returns only changed part of the state
    store.on("UserData", (UserData, data) => {
        if (data === "clear") {
            return { UserData: defaultState };
        }
        return {
            UserData: data
        };
    });
}