export let CreateSubareas = store => {
    let initialState = {
        isShowModal: false,
        coordsPoint: { x: 0, y: 0, z: 0 },
        count: 0,
        callback: () => { }
    }
    store.on('@init', () => ({ CreateSubareas: initialState }));
    store.on("CreateSubareas", ({ CreateSubareas }, data) => {
        if (data === "clear") {
            return { CreateSubareas: initialState };
        }
        return {
            CreateSubareas: data
        };
    });
};