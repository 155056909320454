import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import CanvasVLS from '../../../components/CanvasVLS';
import { Container, Row, Col, Navbar } from 'react-bootstrap';
import ElementsToVLSPanel from '../../../components/ElementsToVLSPanel';
import ButtonMain from '../ButtonMain';
import connect from 'storeon/react/connect';
import ToolTipCreator from '../../../common/ToolTipCreator';
import OptionsVLSDropDown from '../../../common/OptionsVLSDropDown';
import ModalConfirmWarningLayout from '../ModalConfirmWarningLayout';
import ModalInfoSuccessLayout from '../ModalInfoSuccessLayout';
import SidebarVLS from '../../../components/SidebarVLS';
import serialized from '../../../utils/SerializedObject';
import { PATH } from '../../../const';
import WarningIcon from '../../../assets/images/alert-triangle.svg';
import SuccessIcon from '../../../assets/images/shield-ok.svg';
import DefaultState from './DefaultState';
import Api from '../../../api';
import { getDeviceNameFromDeviceType } from '../../../utils';

const ApiSocket = Api;
const ApiResearch = Api.ResearchApi;

class LayoutVLS extends Component {
  constructor(props) {
    super(props);
    this.socketForResearch = ApiSocket.Socket({
      onOpen: this.onOpenSocket,
      onConnect: this.onConnectSocket,
      onDisconnect: this.onDisconnectSocket,
      onError: this.onErrorSocket,
    });
    this.initilizeSocketForResearch = false;
    this.deltaTimeForSocket = 1;
    this.socketTimeOut = null;
    this.fileRef = React.createRef();
    this.optionsRef = React.createRef();
    this.viewRef = React.createRef();
    this.calculationRef = React.createRef();
    this.textModalConfirmSave =
      'Сохранить изменения в файле 116 “Лабораторный стенд .rsm” перед закрытием?';
    this.state = {
      ...DefaultState,
    };
  }

  saveScene = (redirect = false, callbackOk = false, reject = (err) => {}) => {
    this.setState(
      {
        loader: true,
      },
      () => {
        this.setResearchSave(
          (res) => {
            this.closeConfirmWarningModal();
            if (redirect) {
              window.location.href = PATH.PersonalPageAllProject;
            }
            if (callbackOk) {
              callbackOk();
            }
          },
          (err) => {
            reject();
            if (redirect) {
              window.location.href = PATH.PersonalPageAllProject;
            }
          },
        );
      },
    );
  };

  closeConfirmWarningModal = () => {
    this.setState({
      loader: false,
      showConfirmModal: false,
      paramsConfirmWarningOrSuccessModal: {
        text: null,
        icon: null,
        altForIcon: '',
        callbackOk: (e) => console.log('method is not found'),
      },
    });
  };

  closeModalForCustomEvent = () => {
    const { ConnectedWireToDevice } = this.props;
    if (ConnectedWireToDevice) {
      this.props.dispatch('ConnectedWireToDevice', 'clear');
    }
  };

  setUpdate = (data = false) => {
    this.setState({
      isUpdatets: data,
      isUpdate: false,
    });
  };

  closeSuccessInfoModal = () => {
    this.closeModalForCustomEvent();
    this.setState({
      showSuccessInfoModal: false,
      paramsSuccessInfoModal: {
        text: null,
        icon: null,
        altForIcon: '',
      },
    });
  };

  openSuccessInfoModal = (type, text) => {
    const { typeSuccessInfoModal, paramsSuccessInfoModal } = this.state;
    if (type === typeSuccessInfoModal.default) {
      this.setState({
        showSuccessInfoModal: true,
        paramsSuccessInfoModal: {
          text: text,
          icon: SuccessIcon,
          altForIcon: 'success',
        },
      });
    } else if (type === typeSuccessInfoModal.error) {
      this.setState({
        showSuccessInfoModal: true,
        paramsSuccessInfoModal: {
          text: text,
          icon: WarningIcon,
          altForIcon: 'error',
        },
      });
    }
  };

  openConfirmModal = (type, redirect = false, callbackOk, callbackCansel) => {
    const { typeConfirmWarningModal, title, paramsConfirmWarningOrSuccessModal } = this.state;
    if (type === typeConfirmWarningModal.save) {
      this.setState({
        showConfirmModal: true,
        paramsConfirmWarningOrSuccessModal: {
          text: `Сохранить изменения в файле “${title}”?`,
          icon: SuccessIcon,
          altForIcon: 'success',
          callbackOk: () => {
            this.saveScene(redirect, callbackOk, callbackCansel);
          },
        },
      });
    } else if (type === typeConfirmWarningModal.saveAndClose) {
      this.setState({
        showConfirmModal: true,
        paramsConfirmWarningOrSuccessModal: {
          text: `Сохранить изменения в файле “${title}” перед закрытием?`,
          icon: WarningIcon,
          altForIcon: 'warning',
          btnConfig: {
            cansel: 'Не сохранять',
          },
          callbackOk: () => {
            this.saveScene(redirect, callbackOk, callbackCansel);
          },
          callbackCansel: () => {
            this.closeConfirmWarningModal();
            window.location.href = PATH.PersonalPageAllProject;
          },
        },
      });
    } else if (type === typeConfirmWarningModal.addPole) {
      this.setState({
        showConfirmModal: true,
        paramsConfirmWarningOrSuccessModal: {
          ...paramsConfirmWarningOrSuccessModal,
          text: `Магнитное поле не создано. Создать магнитное поле к этому прибору?`,
          icon: WarningIcon,
          altForIcon: 'warning',
        },
      });
    } else if (type === typeConfirmWarningModal.saveAs) {
      this.setState({
        saveType: type,
        showConfirmModal: true,
        paramsConfirmWarningOrSuccessModal: {
          text: `Сохранить расчет как прибор “${title}”?`,
          icon: SuccessIcon,
          altForIcon: 'success',
          callbackOk: () => {
            this.saveScene(redirect, callbackOk, callbackCansel);
          },
        },
      });
    }
  };

  getDataScene = () => {
    this.props.dispatch('DataSceneSaving', 'clear');
    this.setState({
      isSaveScene: true,
      dropMenuVls: false,
    });
  };

  saveVLS = () => {
    this.toggleContextMenu();
    this.getDataScene();
    let { typeSuccessInfoModal } = this.state;
    const { save } = this.state.typeConfirmWarningModal;
    this.openConfirmModal(
      save,
      false,
      () => {
        this.openSuccessInfoModal(typeSuccessInfoModal.default, 'Расчет сохранён');
      },
      () => {
        this.closeConfirmWarningModal();
        this.openSuccessInfoModal(typeSuccessInfoModal.error, 'Ошибка сохранения');
      },
    );
  };

  errorSaveAs = () => {
    let { typeSuccessInfoModal } = this.state;
    this.closeConfirmWarningModal();
    this.openSuccessInfoModal(typeSuccessInfoModal.error, 'Не обнаружены внешние клеммы!');
  };

  saveAsDevice = () => {
    this.toggleContextMenu();
    this.getDataScene();
    let { typeSuccessInfoModal } = this.state;
    const { saveAs } = this.state.typeConfirmWarningModal;
    this.openConfirmModal(
      saveAs,
      false,
      () => {
        this.openSuccessInfoModal(typeSuccessInfoModal.default, 'Отчет сохранён как прибор');
        this.setState({
          saveType: null,
        });
      },
      () => {
        this.closeConfirmWarningModal();
        this.openSuccessInfoModal(typeSuccessInfoModal.error, 'Ошибка сохранения!');
      },
    );
  };

  outVLS = (callbackOk) => {
    let { typeSuccessInfoModal } = this.state;
    this.toggleContextMenu();
    this.getDataScene();
    const { saveAndClose } = this.state.typeConfirmWarningModal;
    this.openConfirmModal(saveAndClose, true, callbackOk, () => {
      this.closeConfirmWarningModal();
      this.openSuccessInfoModal(typeSuccessInfoModal.error, 'Ошибка сохранения');
    });
  };

  clearScene = () => {
    this.setState({
      showConfirmModal: true,
      paramsConfirmWarningOrSuccessModal: {
        text: `Очистить Виртуальный лабораторный стенд?`,
        icon: WarningIcon,
        altForIcon: 'warning',
        callbackOk: () => {
          this.props.dispatch('SceneHandler', {
            clear: true,
          });
          this.closeConfirmWarningModal();
        },
        callbackCansel: () => {
          this.closeConfirmWarningModal();
        },
      },
    });
  };

  closeMenu = () => {
    this.setState({
      target: null,
      showContextMenu: false,
    });
  };

  contentFileDropDownMenu = () => {
    return (
      <div className={'global-drop_down__options'}>
        <ul>
          <li
            onClick={() => {
              this.closeMenu();
              this.clearScene();
            }}
          >
            Очистить
          </li>
          <li className={'context_menu-border'}></li>
          <li>Открыть</li>
          <li
            onClick={() => {
              this.closeMenu();
              this.saveVLS();
            }}
          >
            Сохранить
          </li>
          <li
            onClick={() => {
              this.saveAsDevice();
            }}
          >
            Сохранить как...
          </li>
          <li className={'context_menu-border'}></li>
          <li
            onClick={() => {
              this.closeMenu();
              this.outVLS();
            }}
          >
            Выход
          </li>
        </ul>
      </div>
    );
  };

  contentViewsDropDownMenu = () => {
    return (
      <div className={'global-drop_down__options'}>
        <ul>
          {/* <li>Цепь 3D</li> */}
          <li>Упрощенная цепь</li>
        </ul>
      </div>
    );
  };
  contentCalculateDropDownMenu = () => {
    return (
      <div className={'global-drop_down__options'}>
        <ul>
          <li>Расчитать</li>
          <li>Обнулить</li>
        </ul>
      </div>
    );
  };

  clickLogout = () => {
    this.outVLS(() => {
      window.location.href = PATH.PersonalPageAllProject;
    });
  };

  contentOptionsDropDownMenu = () => {
    return <OptionsVLSDropDown close={this.fadeOutContextMenu} />;
  };

  getContentForMenu = (target) => {
    if (target === this.fileRef) {
      return this.contentFileDropDownMenu();
    } else if (target === this.optionsRef) {
      return this.contentOptionsDropDownMenu();
    } else if (target === this.viewRef) {
      return this.contentViewsDropDownMenu();
    } else if (target === this.calculationRef) {
      return this.contentCalculateDropDownMenu();
    }
  };

  openDropDownMenu = (target) => {
    let isShow = true;
    if (target === this.state.target) {
      isShow = false;
      target = null;
    }
    return this.setState({
      content: this.getContentForMenu(target),
      target: target,
      showContextMenu: isShow,
      addClassNameDropDownWrap: this.getDropDownMenuBsPrefix(),
    });
  };

  getDropDownMenuBsPrefix = () => {
    const { dropMenuVls, typeMenu } = this.state;
    if (dropMenuVls) {
      if (typeMenu === 'classic') {
        return 'vls-menu-classic-custom_translate';
      } else if (typeMenu === 'burger') {
        return 'vls-menu-burger-custom_translate';
      }
    }
  };

  dropMenuVls = () => {
    this.setState({ dropMenuVls: !this.state.dropMenuVls }, () => this.toggleContextMenu());
  };

  fadeOutContextMenu = () => {
    this.setState({
      showContextMenu: false,
    });
  };

  toggleContextMenu = (toggle) => {
    this.setState({
      showContextMenu: toggle,
    });
  };

  getDataAllObjects = () => {
    const hashId = this.props.match.params.id;
    this.setState(
      {
        processLoadDevice: true,
      },
      () => {
        ApiResearch.getResearch(hashId).then(
          (res) => {
            this.setState({
              created_at: res.created_at,
              devices: res.devices,
              id: res.id,
              extension: res.extension,
              link: res.link,
              map: res.map,
              size: res.size,
              title: res.title,
              type_name: res.type_name,
              processLoadDevice: false,
            });
          },
          (err) => {
            this.setState({
              showSuccessInfoModal: true,
              paramsSuccessInfoModal: {
                text: `Не удалось загрузить проект!`,
                icon: WarningIcon,
                altForIcon: 'warning',
                callbackClickBtn: () => {
                  window.location.href = PATH.PersonalPageMyProject;
                },
              },
            });
          },
        );
      },
    );
  };

  setInnerWindowTargetWidth = (innerWidth) => {
    if (innerWidth < 1800) {
      this.setState({
        typeMenu: 'burger',
      });
    }
  };

  setResizeDom = (event) => {
    if (event.target.innerWidth < 1800) {
      this.setState({
        typeMenu: 'burger',
      });
    } else {
      this.setState({
        typeMenu: 'classic',
      });
    }
  };

  setResearchSave = (resolve, reject) => {
    const { dataSceneSaving, saveType } = this.state;
    const hashId = this.props.match.params.id;
    let data = serialized.generateSaveData(
      dataSceneSaving,
      this.state.type_name,
      this.state.title,
      saveType,
    );
    if (data === 'errorSaveAs') return this.errorSaveAs();
    let params = {
      hash_id: hashId,
      params: {
        ...data,
      },
    };
    ApiResearch.putResearch(params).then(resolve).catch(reject);
  };

  updateDeviceComponent = (data) => {
    if (!data) {
      clearTimeout(this.socketTimeOut);
      this.getDataFromSocket();
      return false;
    }
    const hashId = this.props.match.params.id;
    const { isUpdateDeviceComponent } = this.state;
    const stopUpdateAndCheck = () => {
      this.setState(
        {
          isUpdateDeviceComponent: false,
        },
        () => {
          clearTimeout(this.socketTimeOut);
          this.unmountController();
          this.getDataFromSocket();
        },
      );
    };
    if (!isUpdateDeviceComponent) {
      this.setState(
        {
          isUpdateDeviceComponent: true,
        },
        () => {
          let newData = JSON.parse(data);
          if (!newData.devices || !newData.devices.length) {
            stopUpdateAndCheck();
            return false;
          }
          if (!Array.isArray(newData.devices)) {
            this.setState({
              isUpdateDeviceComponent: false,
            });
          }
          let params = {
            hash_id: hashId,
            params: {
              devices: newData.devices,
            },
          };
          ApiResearch.checkUpdateDeviceComponents(params)
            .then((res) => {
              if (this.props.StartOrStopVLS.socketState === 'start') {
                let newDevices = serialized.UpdateDeviceList(
                  res,
                  this.state.devices.componentData,
                  this.state.devices,
                );
                this.props.dispatch('UpdateComponentDevice', newDevices);
                stopUpdateAndCheck();
              }
            })
            .catch((err) => {
              stopUpdateAndCheck();
            });
        },
      );
    }
  };

  /**
   * базовый метод для сокета отвечающий за запуск
   * @param {*} e
   */
  onOpenSocket = (e) => {
    this.socketForResearch.emitData('start', { type: 'research' }, 'command');
    console.log(e, 'open');
  };

  /**
   * базовый метод для сокета отвечающий за подключение
   * @param {*} e
   */
  onConnectSocket = (e) => {
    console.log(e, 'onConnectSocket');
  };
  /**
   * базовый метод для сокета отвечающий за отключение
   * @param {*} e
   */
  onDisconnectSocket = (e) => {
    this.setState({
      showSuccessInfoModal: true,
      paramsSuccessInfoModal: {
        text: `Сервис расчета отключён!`,
        icon: WarningIcon,
        altForIcon: 'warning',
        callbackClickBtn: () => {
          this.props.dispatch('StartOrStopVLS', { socketState: 'stop' });
          this.props.dispatch('UpdateComponentDevice', false);
          //todo: сделать обработку отключения расчета
        },
      },
    });
    console.log(e, 'onDisconnectSocket');
  };
  /**
   * базовый метод для сокета отвечающий за ошибки
   * @param {*} e
   */
  onErrorSocket = (e) => {
    this.setState({
      showSuccessInfoModal: true,
      paramsSuccessInfoModal: {
        text: `Ошибка расчета!`,
        icon: WarningIcon,
        altForIcon: 'warning',
        callbackClickBtn: () => {
          clearTimeout(this.socketTimeOut);
          this.setState(
            {
              isUpdateDeviceComponent: false,
            },
            () => {
              this.props.dispatch('UpdateComponentDevice', 'clear');
            },
          );
        },
      },
    });
    console.log(e, 'onErrorSocket');
  };
  /**
   * сработает когда прийдут новые данные о запуске расчета
   * @param {*} e
   */
  startSocket = (data) => {
    this.getDataFromSocket();
  };
  /**
   * пошлет запрос по сокету за обновлением
   * todo: переделать на конец рендеринга а не интервал
   * !оптимизация
   */
  getDataFromSocket = () => {
    this.socketTimeOut = setTimeout(() => {
      this.socketForResearch.emitData('get', { type: 'research' }, 'command');
    }, 1000 / 60);
  };

  stopSocket = (data) => {
    clearTimeout(this.socketTimeOut);
    this.props.dispatch('StartOrStopVLS', 'clear');
  };

  getSocket = (data) => {
    this.updateDeviceComponent(data);
  };

  doneResearche = (data) => {
    this.stopSocket();
    this.setState({
      showSuccessInfoModal: true,
      paramsSuccessInfoModal: {
        text: `Расчет окончен!`,
        icon: SuccessIcon,
        altForIcon: 'Расчет окончен',
        callbackClickBtn: () => {
          this.setState({
            showSuccessInfoModal: false,
            paramsSuccessInfoModal: {},
          });
        },
      },
    });
  };
  /**
   * кастомный метод сработает когда пользователь нажмет на запуск
   * @param {*} e
   */
  startVLS = (socketState) => {
    const hashId = this.props.match.params.id;
    if (!this.initilizeSocketForResearch) {
      this.initilizeSocketForResearch = true;
      this.socketForResearch.startSceneVLS(
        hashId,
        this.startSocket,
        this.stopSocket, //метод обрабатывающий отклик об остановке сокета
        (e) => {
          console.log('метод обрабатывающий отклик об клике по кнопке плей сокета', e);
        }, //метод обрабатывающий отклик об клике по кнопке плей сокета
        (e) => {
          console.log('метод обрабатывающий отклик об клике по кнопке паузы сокета', e);
        }, //метод обрабатывающий отклик об клике по кнопке паузы сокета
        this.getSocket,
        this.doneResearche,
      ); // this.startSocket, undefined, this.playVLS, this.pauseVLS
    } else {
      this.onOpenSocket();
    }
  };
  /**
   * кастомный метод сработает когда пользователь нажмет на остановку сцены
   * @param {*} e
   */
  stopVLS = (socketState) => {
    if (socketState === 'stop') {
      clearTimeout(this.socketTimeOut);
      this.socketForResearch.emitData('stop', { type: 'research' }, 'command');
      this.setState(
        {
          isUpdateDeviceComponent: false,
        },
        () => {
          this.props.dispatch('UpdateComponentDevice', 'clear');
        },
      );
    } else {
      clearTimeout(this.socketTimeOut);
    }
  };
  /**
   * кастомный метод сработает когда пользователь нажмет на запуск сцены
   * @param {*} e
   */
  playVLS = (socketState) => {
    console.log(socketState, 'playVLS');
    if (socketState === 'play') {
      this.socketForResearch.emitData('play', { type: 'research' }, 'command');
      this.getSocket();
    }
  };
  /**
   * кастомный метод сработает когда пользователь нажмет на паузу
   * @param {*} e
   */
  pauseVLS = (socketState) => {
    console.log(socketState, 'pauseVLS');
    clearInterval(this.socketTimeOut);
    this.socketForResearch.emitData('pause', { type: 'research' }, 'command');
  };
  /**
   * основной метод распорягающийся сокетом
   * @param {*} socketState
   */
  playControllerForVLS = (data) => {
    const { socketState } = data;
    switch (socketState) {
      case 'start':
        this.unmountController();
        return this.startVLS(socketState);
      case 'stop':
        return this.stopVLS(socketState);
      case 'play':
        return this.playVLS(socketState);
      case 'pause':
        return this.pauseVLS(socketState);
      default:
        return false;
    }
  };

  unmountController = () => {
    const unmountController = this.props.CallbackCollector.unmountController;
    if (unmountController) {
      unmountController();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.setResizeDom);
  }

  componentDidUpdate(prevProps, prevState) {
    const { StartOrStopVLS, ConnectedWireToDevice, LoadDevice } = this.props;
    if (ConnectedWireToDevice?.openInfoModal !== prevProps.ConnectedWireToDevice?.openInfoModal) {
      this.setState({
        showSuccessInfoModal: ConnectedWireToDevice.openInfoModal,
        paramsSuccessInfoModal: {
          text: `Вы подключаетесь к прибору "${getDeviceNameFromDeviceType(
            ConnectedWireToDevice.deviceType,
          )}"!`,
          icon: SuccessIcon,
          altForIcon: 'connected',
          callbackClickBtn: () => {
            this.props.dispatch('ConnectedWireToDevice', {
              openInfoModal: false,
              deviceType: false,
            });
            this.setState({
              showSuccessInfoModal: false,
              paramsSuccessInfoModal: {},
            });
          },
        },
      });
    }
    if (StartOrStopVLS !== prevProps.StartOrStopVLS) {
      this.playControllerForVLS(StartOrStopVLS);
    }
    if (this.state.dropMenuVls) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
    if (prevProps.DataSceneSaving !== this.props.DataSceneSaving) {
      const { DataSceneSaving } = this.props;
      if (DataSceneSaving.componentData) {
      }
      this.setState({
        isSaveScene: false,
        dataSceneSaving: DataSceneSaving,
        devices: DataSceneSaving ? DataSceneSaving : this.state.devices,
      });
    }
    if (this.state.devices !== prevState.devices) {
      this.setState({
        devices: this.state.devices,
      });
    }
    return false;
  }
  componentDidMount() {
    window.addEventListener('resize', this.setResizeDom);
    this.setInnerWindowTargetWidth(window.innerWidth);
    this.getDataAllObjects();
  }

  render() {
    const {
      devices,
      id,
      title,
      target,
      content,
      showContextMenu,
      addClassNameDropDownWrap,
      showConfirmModal,
      showSuccessInfoModal,
      paramsConfirmWarningOrSuccessModal,
      paramsSuccessInfoModal,
      projectName,
      isSaveScene,
      dropMenuVls,
      contentSidebarMenu,
      typeMenu,
      loader,
      processLoadDevice,
    } = this.state;
    let theme = this.props.ProjectTheme === 'dark' ? '' : 'light';
    return (
      <div className={`vls-container ${theme}`}>
        <ModalConfirmWarningLayout
          closeConfirmWarningModal={this.closeConfirmWarningModal}
          paramsConfirmWarningOrSuccessModal={paramsConfirmWarningOrSuccessModal}
          showConfirmModal={showConfirmModal}
          loader={loader}
        />
        <ModalInfoSuccessLayout
          closeSuccessInfoModal={this.closeSuccessInfoModal}
          paramsSuccessInfoModal={paramsSuccessInfoModal}
          showSuccessInfoModal={showSuccessInfoModal}
        />
        <Row className={`vls-row_header`}>
          <div className="vls-header-block">
            <NavLink className={`vls-header-logo`} to={PATH.MainPage} title={'Главная'}></NavLink>
            <div className={`vls-header-menu-classic`}>
              <ul>
                <li
                  ref={this.fileRef}
                  onClick={(e) => {
                    this.openDropDownMenu(this.fileRef);
                  }}
                >
                  Файл
                </li>
                <li
                  ref={this.optionsRef}
                  onClick={(e) => {
                    this.openDropDownMenu(this.optionsRef);
                  }}
                >
                  Опции
                </li>
                <li
                  ref={this.viewRef}
                  onClick={(e) => {
                    this.openDropDownMenu(this.viewRef);
                  }}
                >
                  Вид
                </li>
                {/* <li
                  ref={this.calculationRef}
                  onClick={(e) => this.openDropDownMenu(this.calculationRef)}>
                  Расчет</li>
                <li>Обработка</li> */}
              </ul>
            </div>
          </div>
          <div className="vls-header-block">
            <div className={`vls-header-project_info`}>
              <span className={`vls-header-project_info-text`}>{projectName}</span>
              <span className={`vls-header-project_info-project_name`}>{title}</span>
            </div>
          </div>
          <div className="vls-header-block">
            <div className={`vls-header-profile`}>
              <NavLink
                to={PATH.PersonalPagePersonalAreaSettingProfile}
                className={`vls-header-profile-link_avatar`}
              >
                Y
              </NavLink>
              <div className={`vls-header-profile-will_share`}>
                {/* <ButtonMain className={`vls-header-profile-will_share-button`}>
                  Поделиться
                </ButtonMain> */}
              </div>
            </div>
            <ButtonMain onClick={this.clickLogout} className={`vls-header-logout`}></ButtonMain>
          </div>
        </Row>
        <Row className={'vls-row_canvas'}>
          <Col bsPrefix={'col_vls'} xs={`header-menu_left`}>
            <ElementsToVLSPanel processLoadDevice={processLoadDevice} {...this.props} />
          </Col>
          <Col bsPrefix={'col_vls'} xs={'canvas'} className={'margin-def'}>
            <CanvasVLS
              setUpdate={this.setUpdate}
              id={id}
              devices={devices}
              isSaveScene={isSaveScene}
            />
          </Col>
          <Col bsPrefix={'col_vls'} xs={'sidebar'}>
            <SidebarVLS />
          </Col>
        </Row>
        <ToolTipCreator
          typeClosed={'move'}
          isWrapper={typeMenu !== 'burger'}
          target={target}
          addClassNameDropDownWrap={addClassNameDropDownWrap}
          viewPosition={'bottom'} //typeMenu === "burger" ? "right-end" :
          contentForModal={content}
          toggleContextMenu={this.toggleContextMenu}
          fadeOutContextMenu={this.fadeOutContextMenu}
          show={showContextMenu}
        />
      </div>
    );
  }
}

export default connect(
  'ElementsContext',
  'SceneHandler',
  'ProjectTheme',
  'DataSceneSaving',
  'StartOrStopVLS',
  'ConnectedWireToDevice',
  'UpdateComponentDevice',
  'CallbackCollector',
  LayoutVLS,
);
