import React, { useState, useCallback, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Container from "../Container";
import Button from "../../Views/Layout/Button";
import { PATH } from "../../const";
import Logo from "../../assets/images/logo.svg";


const DefaultHeader = (props) => {
  const { main = false } = props;
  let clientWidth = document.documentElement.clientWidth;
  let [viewMobMenu, setViewMobMenu] = useState(false);
  let [openMobMenu, setOpenMobMenu] = useState(false);
  const clickMobMenu = (e) => {
    setOpenMobMenu(!openMobMenu);
  };
  if (clientWidth <= 1200) {
    if (!viewMobMenu) setViewMobMenu(true);
  } else {
    if (viewMobMenu) setViewMobMenu(false);
  }

  useEffect(function setupListener () {
    function handleResize(e) {
      if (e.target.innerWidth <= 1200) {
        if (!viewMobMenu) setViewMobMenu(true);
      } else {
        if (viewMobMenu) setViewMobMenu(false);
      }
    }
    window.addEventListener("resize", handleResize);

    return function cleanupListener() {
      window.removeEventListener("resize", handleResize);
    }
  }, [openMobMenu, viewMobMenu])

  return (
    <Container>
      <div className={`default_page-header ${ main ? "default_page-header_main" : ""}`}>
        <div className={"default_page-header-col_header"}>
          <NavLink to={PATH.MainPage} className={"default_page-header-logo"}>
            <div className={"default_page-header-logo_img"}>
              <img src={Logo} alt="logo"/>
            </div>
            <div className={"default_page-header-logo_text"}>
              GARPIX <br/> DIGITAL <br/> TWIN
            </div>
          </NavLink>
          <div className={"default_page-header-menu"}>
            <nav className={"default_page-header-menu-nav"}>
              <ul>
                <li>
                  <a href={`${ main ? "#link-service_info" : `${PATH.MainPage}#link-service_info`}`}>О сервисе</a>
                </li>
                <li>
                  <NavLink to={PATH.Home}>Новости</NavLink>
                </li>
                <li>
                  <a href={`${ main ? "#link-whom_is_it_suitable" : `${PATH.MainPage}#link-whom_is_it_suitable`}`}>Для кого</a>
                </li>
                <li>
                  <NavLink to={PATH.Faq}>FAQ</NavLink>
                </li>
                
              </ul>
            </nav>
          </div>
        </div>
        { !viewMobMenu ? (
        <div className={"default_page-header-btn_nav"}>
          <div className={"default_page-header-btn_nav-call_info"}>
            <span
              className={"default_page-header-btn_nav-call_info-number"}
            >
              <a href={"tel:88002345056"}>
                8 800 234-50-56
              </a>
            </span>
            <br />
            <span
              className={"default_page-header-btn_nav-call_info-text"}
            >
              Бесплатно по России
            </span>
          </div>
          <NavLink
            to={PATH.Authorization}
            className={"default_page-header-btn_nav-sign_in_btn"}
          >
            Вход
          </NavLink>
          <NavLink
            to={PATH.Registration}
            className={"default_page-header-btn_nav-registrations_btn"}
          >
            Регистрация
          </NavLink>
        </div>
        ) : (
        <div
          onClick={clickMobMenu}
          className={`default_page-header-mob-burger_menu ${openMobMenu ? "active" : ""
            }`}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        )}
        {openMobMenu ? (
        <div
          className={`default_page-header-mob-block_modal_for_menu ${viewMobMenu ? "active" : ""
            }`}
        >
          <div
            className={`default_page-header-mob-menu ${viewMobMenu ? "active_mob" : ""
              }`}
          >
            <nav className={"default_page-header-menu-nav"}>
              <ul>
                <li>
                  <a href={`${ main ? "#link-service_info" : `${PATH.MainPage}#link-service_info`}`}>О сервисе</a>
                </li>
                <li>
                  <NavLink to={PATH.Home}>Новости</NavLink>
                </li>
                <li>
                  <a href={`${ main ? "#link-whom_is_it_suitable" : `${PATH.MainPage}#link-whom_is_it_suitable`}`}>Для кого</a>
                </li>
                <li>
                  <NavLink to={PATH.Faq}>FAQ</NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className={`default_page-header-mob-btn_nav ${viewMobMenu ? "active_mob" : ""}`}>
            <div className={"default_page-header-btn_nav-call_info"}>
              <span
                className={"default_page-header-btn_nav-call_info-number"}
              >
                <a href={"tel:88002345056"}>
                  8 800 234-50-56
                </a>
              </span>
              <br />
              <span
                className={"default_page-header-btn_nav-call_info-text"}
              >
                Бесплатно по России
              </span>
            </div>
            <NavLink
              to={PATH.Authorization}
              className={"default_page-header-btn_nav-sign_in_btn"}
            >
              Вход
            </NavLink>
            <NavLink
              to={PATH.Registration}
              className={"default_page-header-btn_nav-registrations_btn"}
            >
              Регистрация
            </NavLink>
          </div>
        </div>
        ) : null }
      </div>
    </Container>
  );
};

export default DefaultHeader;
