import React from 'react';
import AbstractObject from "../../abstract/AbstractObject";
import { OrthoganalControl as OrthoganalControlAbstract } from '../../../abstract/controls/OrthoganalControl'

class OrthoganalControl extends AbstractObject {

  componentDidMount() {
    const {
      canvasDomElement,
      camera,
      // custom
      minDistance = 3,
      maxDistance = 20,
      position,
      type = ''
    } = this.props;
    this.initComponent();
    new OrthoganalControlAbstract({
      camera,
      canvasDomElement,
      minDistance,
      maxDistance,
      position,
      type
    })
   setTimeout(() => {
      this.readyComponent();
    }, 1);
  }
}

export default OrthoganalControl;
