export default () => {
  return {
    arc: {
      type: "arc",
    },
    dot: {
      type: "dot",
    },
    straight: {
      type: "straight",
    },
    subareas: {
      type: "subareas",
    },
    section: {
      type: "section",
    },
    rotatingArray: {
      type: "rotatingArray",
    },
  };
};
