export let ElementsToolTips = store => {
	// Initial state
	store.on('@init', () => ({ElementsToolTips: null}));
	// Reducers returns only changed part of the state
	store.on("ElementsToolTips", ({ ElementsToolTips }, data) => {
		if (data === "clear") {
			return { ElementsToolTips: null };
		}
		return {
			ElementsToolTips: data
		};
	});
};