import React from 'react'
import Button from '../Button'
import {ReactComponent as ReactLogo } from '../../../assets/images/authAnimate.svg'

const HelloHeadLine = ({ title = 'ЛИЧНЫЙ КАБИНЕТ', className = 'personal-page', styleWelcome = 'center', sale = false }) => {
  return (
    <div className={`${className}-content-header`}>
      {className === 'setting' ? (
         <ReactLogo
         width={"100%"}
         height={"100%"}
         className={"auth_and_reg-left_column-bottom_animate_img"}
       />
      ) : null
    }
      <p className={`${className}-content-header_text_welcome-${styleWelcome}`}>
        {title}
      </p>
      {sale ?
        <div className={`${className}-content-header_special-offer`}>
          <p className={`${className}-content-header_special-offer-sale`}>Скидка 50% на Premium</p>
          <span>Предложение действует до 1 июля</span>
          <Button className='download' name='Купить тариф' />
        </div>
        :
        false
      }
    </div>
  )
}
export default HelloHeadLine