import React from 'react'
import TableProjectDetailsPFML from "../TableProjectDetailsPFML";
import TableProjectDetailsVLS from "../TableProjectDetailsVLS";
import ButtonMain from "../../Views/Layout/ButtonMain";
import { NavLink } from "react-router-dom";
import { PATH } from "../../const";
import connect from 'storeon/react/connect';

const RenderProjectDetails = (data) => {
	const { createPFML, createVLS, updateListResearch } = data
	const { infoProject, VLS, PFML } = data.projectData
	let theme = data.ProjectTheme === 'light' ? 'light-project-details' : ''
	return (
		<div className={`project_details-content-wrapper ${theme}`}>
			<div className={"project_details-content-heading"}>
				<NavLink to={PATH.PersonalPageAllProject}>
					{infoProject.title}
				</NavLink>
				{/* <ButtonMain></ButtonMain> */}
			</div>
			<div className={"project_details-content-line"}></div>
			<div className={"project_details-content-table_wraper"}>
				<div className={"project_details-content-table_wraper-pfml_wrap"}>
					<div className={"pfml_wrap-label"}>
						Библиотека магнитных полей
            </div>
					<div className={"pfml_wrap-content"}>
						<div
							className={
								"pfml_wrap-content-table_wrap scrollbar-wrap_table"
							}
						>
							<TableProjectDetailsPFML updateListResearch={updateListResearch} projectData={PFML} />
						</div>
						<div className={"pfml_wrap-content-button_wrap"}>
							<ButtonMain onClick={createPFML} className={"pfml_wrap-content-button_wrap-text"}>
								Создать магнитное поле
							</ButtonMain>
						</div>
					</div>
				</div>
				<div className={"project_details-content-table_wraper-vls_wrap"}>
					<div className={"vls_wrap-label"}>
						Виртуальный лабораторный стенд
            </div>
					<div className={"vls_wrap-content"}>
						<div
							className={
								"vls_wrap-content-table_wrap scrollbar-wrap_table"
							}
						>
							<TableProjectDetailsVLS updateListResearch={updateListResearch} projectData={VLS} />
						</div>
						<div className={"vls_wrap-content-button_wrap"}>
							<ButtonMain onClick={createVLS} className={"vls_wrap-content-button_wrap-text"}>
								Создать цепь приборов
							</ButtonMain>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default connect('ProjectTheme', RenderProjectDetails)