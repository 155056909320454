/**
 * просто сериалайзер который на основе новых данных построит объект с новыми значениями
 * @param {*} data 
 * @param {*} prevData 
 */
export const dataPrimitivesSerialize = (data, prevData) => {
    let result = {}
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const element = data[key];
            if (prevData[key] !== element) {
                result[key] = element
            }
        }
    }
    return result
}