import {
  PlaneGeometry,
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera as ThreePerspectiveCamera,
  OrthographicCamera,
} from 'three';
import { Object3D } from '../../Object3D';
import { DATA_OBJECT_SCENE } from '../../../constants';

class PerspectiveCamera extends Object3D {
  constructor({
    scene,
    position = [0, 0, 0],
    canvasHeight,
    canvasWidth,
    addRenderCall,
    renderer,
    effect,
    enableVR,
    enableQuadCamera,
    degrisToCameraPerspectiv,
    aspect,
    far,
    near,
    orthoganalEnable,
    zoomOrhoganal,
    frustumSize,
  } = {}) {
    super();
    this.aspect = enableVR ? canvasWidth / canvasHeight : 1;
    this.degrisToCameraPerspectiv = enableVR ? 60 : 100;
    if (degrisToCameraPerspectiv && !enableVR) {
      this.degrisToCameraPerspectiv = degrisToCameraPerspectiv;
    }
    if (aspect && !enableVR) {
      this.aspect = aspect;
    }
    this.far = far ? far : 1000;
    this.near = near ? near : 0.1;
    if (orthoganalEnable) {
      //сделал ортоганальную камеру
      this.obj = new OrthographicCamera(
        canvasWidth / canvasHeight / -2,
        canvasWidth / canvasHeight / 2,
        canvasHeight / 2,
        canvasHeight / -2,
        0.1,
        100,
      );
      if (zoomOrhoganal) {
        this.obj.zoom = zoomOrhoganal;
      }
    } else {
      this.obj = new ThreePerspectiveCamera(60, this.aspect, 0.1, 1000);
    }
    this.obj.position.set(...position);
    this.obj.lookAt(scene.position);
    if (enableVR) {
      this.rayMesh = this.createMeshToRay();
      this.rayMesh.position.set(0, 0, 5);
      this.aimMesh = this.createMeshToRay();
      this.aimMesh.position.set(0, 0, -5);
      this.obj.add(this.aimMesh);
      this.obj.add(this.rayMesh);
    }
    this.obj.name = DATA_OBJECT_SCENE.MAIN_CAMERA.name;
    this.obj.layers.enable(0);
    this.obj.layers.enable(1);
    this.obj.layers.enable(2);
    scene.add(this.obj);
    if (enableVR) {
      addRenderCall(() => this.rendererEffect(effect, scene));
    }
    if (enableQuadCamera) {
      addRenderCall(() => this.rendererEffect(effect, scene));
    }
    if (!enableVR && !enableQuadCamera) {
      addRenderCall(() => renderer.render(scene, this.obj));
    }
    window.addEventListener('resize', this.resize);
  }
  rendererEffect = (effect, scene) => {
    effect.render(scene, this.obj);
  };

  createMeshToRay = () => {
    let geometry = new PlaneGeometry(0.1, 0.1);
    let material = new MeshBasicMaterial({ color: 0xffff00, side: DoubleSide });
    return new Mesh(geometry, material);
  };
}

export default PerspectiveCamera;
