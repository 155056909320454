import React from "react";
import connect from "storeon/react/connect";
import MeasuringDeviceComponentForSidebar from '../MeasuringDeviceComponentForSidebar'
import VoltageSourceComponentForSidebar from '../VoltageSourceComponentForSidebar'
import OscilloscopeComponentForSidebar from '../OscilloscopeComponentForSidebar'
import RheostatComponentForSidebar from '../RheostatComponentForSidebar'
import ResistorComponentForSidebar from '../ResistorComponentForSidebar'
import CapacitorComponentForSidebar from '../CapacitorComponentForSidebar'
import InductanceComponentForSidebar from '../InductanceComponentForSidebar'
import AutomatComponentForSidebar from '../AutomatComponentForSidebar'
import TerminalsComponentForSidebar from '../TerminalsComponentForSidebar'
import TachometerComponentForSidebar from '../TachometerComponentForSidebar'
import DCMachineComponentForSidebar from '../DCMachineComponentForSidebar'
import TranformerComponentForSidebar from '../TranformerComponentForSidebar'
import isEqual from "react-fast-compare";

class SidebarVLS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentRender: []
    }
  }

  setContentRender = (data) => {
    this.setState({
      contentRender: [data]
    })
  }

  checkTypeDevice = (data) => {
    if (!data) return this.setContentRender(null)
    if (!data.element.meta) return this.setContentRender(null)
    switch (data.type) {
      case "voltmeter":
      case "ampermeter":
      case "watmeter":
        return this.setContentRender(<MeasuringDeviceComponentForSidebar element={data.element} />)
      case "voltageSource":
        return this.setContentRender(<VoltageSourceComponentForSidebar element={data.element} />)
      case "oscilograf":
        return this.setContentRender(<OscilloscopeComponentForSidebar element={data.element} />)
      case "oneComponentReostat":
      case "twoComponentReostat":
      case "threeComponentReostat":
        return this.setContentRender(<RheostatComponentForSidebar element={data.element} />)
      case "rezistor":
        return this.setContentRender(<ResistorComponentForSidebar element={data.element} />)
      case "capacitor":
        return this.setContentRender(<CapacitorComponentForSidebar element={data.element} />)
      case "inductor":
        return this.setContentRender(<InductanceComponentForSidebar element={data.element} />)
      case "oneComponentVendingMachine":
      case "twoComponentVendingMachine":
      case "threeComponentVendingMachine":
        return this.setContentRender(<AutomatComponentForSidebar element={data.element} />)
      case "terminal":
      case "elementEarth":
        return this.setContentRender(<TerminalsComponentForSidebar element={data.element} />)
      case "constantCurrentMachine":
      case "asyncMachine":
        return this.setContentRender(<DCMachineComponentForSidebar element={data.element} />)
      case "onePhaseTransformer":
      case "twoPhaseTransformer":
      case "threePhaseTransformer":
        return this.setContentRender(<TranformerComponentForSidebar element={data.element} />)
      case "mechanicalLoad":
        return this.setContentRender(<TachometerComponentForSidebar element={data.element} />)
      default:
        return this.setContentRender(null)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isEqual(this.props.DataSelectedElementForSidebar, prevProps.DataSelectedElementForSidebar)) {
      this.checkTypeDevice(this.props.DataSelectedElementForSidebar)
      return true
    }
    return false
  }

  componentDidMount() {
  }

  render() {
    const { contentRender } = this.state
    let theme = this.props.ProjectTheme === 'dark' ? '' : 'light'


    return (
      <div className={`sidebar ${theme}`}>
        {
          contentRender.map((el, i) => (
            <React.Fragment key={i}>
              {el}
            </React.Fragment>
          ))
        }
      </div>
    )
  }
}

export default connect("ElementsContext", 'DataSelectedElementForSidebar', 'ProjectTheme', SidebarVLS);