import React from "react";
import { Overlay, Popover } from "react-bootstrap";
import connect from "storeon/react/connect";

const ToolTipCreator = (props) => {
  let container = document.getElementById("root");
  const {
    show,
    target,
    fadeOutContextMenu = () => {},
    contentForModal,
    viewPosition = "bottom",
    isWrapper = true,
    showMenuVls = () => {},
    addClassNameDropDownWrap = '',
    typeClosed = "classic",
    contextMenuCustomWidth = "",
  } = props;
  let theme = props.ProjectTheme === "dark" ? "dark_theme" : "light-popover";
  if (!target) {
    return null;
  }
  return (
    <>
      {show ? (
        <>
          {isWrapper ? (
            <Overlay
              show={show}
              target={target.current}
              placement={viewPosition}
              flip
              container={container}
              containerPadding={20}
            >
              <Popover
                onClick={
                  typeClosed === "click" ? () => fadeOutContextMenu() : null
                }
                onMouseEnter={
                  typeClosed === "move" ? () => fadeOutContextMenu() : null
                }
                className={`context_menu-fadeout`}
              ></Popover>
            </Overlay>
          ) : null}
          <Overlay
            show={show}
            target={target.current}
            placement={viewPosition}
            flip
            container={container}
            containerPadding={20}
          >
            <Popover
              bsPrefix={addClassNameDropDownWrap}
              className={`context_menu-wrap ${theme} ${contextMenuCustomWidth} `}
            >
              <Popover.Content
              // onMouseEnter={showMenuVls}
              // onMouseLeave={()=>fadeOutContextMenu(false)}
              >
                {contentForModal}
              </Popover.Content>
            </Popover>
          </Overlay>
        </>
      ) : null}
    </>
  );
};
export default connect("ProjectTheme", ToolTipCreator);
