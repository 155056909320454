import React from "react";
import {Tab, Tabs as RBTabs} from "react-bootstrap";
import DropDownGeneralData from "../../Views/Layout/DropDownGeneralData";
import ButtonMain from "../../Views/Layout/ButtonMain";
import LayoutForPFMLForSettings from "../../Views/Layout/LayoutForPFMLForSettings/LayoutForPFMLForSettings";
import InputValueData from "../../Views/Layout/InputValueData";
import TableGenerate from "../../common/TableGenerate";


class ResearchEnvironment extends React.Component {
  constructor() {
    super();
    this.state = {
      activeTabKey: 'settings',
      tableHeadData: [
        [
          { name: '№', attr: {} },
          { name: '1', attr: {} },
          { name: '2', attr: {} },
          { name: '3', attr: {} },
          { name: '4', attr: {} },
          { name: '5', attr: {} },
          { name: '6', attr: {} },
          { name: '7', attr: {} },
          { name: '8', attr: {} },
          { name: '10', attr: {} },
          { name: '11', attr: {} },
        ]
      ],
      tableBodyData: [],
      dataForDropDownGeneralData: ['Номинальная мощность, Вт', 'Длина стержня ротора, мм', 'Удельное сопротивление меди, Ом мм', 'Сопротивление участка К3 кольца ротора, Ом', 'Номинальная мощность, Вт']
    }
  }
  setData = (data) => {
    const {
      meta,
    } = data.element
    console.log(data)
    // this.setState({
    //   tableBodyData: this.generateTableBody(meta, 'd')
    // })
  }


  contentForModal = () =>{
    this.setState({

    })
  }

  selectTab = (e) => {
    this.setState({
      activeTabKey: e
    })
  }
  componentDidMount() {
    this.setData(this.props)
  }

  render() {
    console.log(this.state.tableBodyData)
    const {
      activeTabKey,
      dataForDropDownGeneralData,
      tableHeadData,
      tableBodyData,
    } = this.state
    return (
      <React.Fragment>
        <span className={"pfml-sidebar-device_title"}>ИССЛЕДОВАТЕЛЬСКАЯ СРЕДА</span>
        <RBTabs
          id="pfml-sidebar_tabs_wraper"
          defaultActiveKey={'base'}
          activeKey={activeTabKey}
          onSelect={this.selectTab}
        >
          <Tab tabClassName={"pfml-sidebar_tabs_wraper-tab"} eventKey="settings" title="Параметры">
            <DropDownGeneralData data={dataForDropDownGeneralData} helpText={'Открыть окно с более подробными параметрами намагничивания полей'} />
            <div className={'pfml-sidebar-more_details'}>
              <span className={'pfml-sidebar-more_details-text_help'}>Открыть окно с более подробными параметрами намагничивания полей</span>
              <ButtonMain onClick={this.contentForModal} className={'button__option-menu'}>Дополнительные параметры</ButtonMain>
            </div>
          </Tab>
        </RBTabs>
      </React.Fragment>
    )
  }
}

export default ResearchEnvironment