import React from "react";
import Pagination from "react-paginating";
import { Link } from "react-router-dom";
import { PAGE_SIZE } from "../../const";
import qs from "query-string";

const Paginate = ({ activePage, count = 0, params = {} }) => {
  return (
    <Pagination
      total={count}
      limit={PAGE_SIZE}
      pageCount={5}
      currentPage={activePage}
    >
      {({
        pages,
        currentPage,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
        // totalPages,
        // getPageItemProps
      }) => {
      if(pages)
       if(pages.length <= 1 ) return null

      return(
        <div className="paginations_default">
          {hasPreviousPage ? (
            <Link
              className="arrow_btn left"
              to={`?${qs.stringify({ ...params, page: previousPage })}`}
            />
          ) : null}
          {pages.map((page) => (
            <Link
              key={page}
              className={`page-item ${
                currentPage === page ? "active" : ""
              }`}
              to={`?${qs.stringify({ ...params, page: page })}`}
            >
              {page}
            </Link>
          ))}
          {hasNextPage ? (
            <Link
              className="arrow_btn right"
              to={`?${qs.stringify({ ...params, page: nextPage })}`}
            />
          ) : null}
        </div>
      )}
      }
    </Pagination>
  );
};

export default Paginate;
