export let ElementsContext = store => {
	// Initial state
	store.on('@init', () => ({ElementsContext: null}));
	// Reducers returns only changed part of the state
	store.on("ElementsContext", ({ ElementsContext }, data) => {
		if (data === "clear") {
			return { ElementsContext: null };
		}
		return {
			ElementsContext: data
		};
	});
};