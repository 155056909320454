import React from 'react';
import { AbstractObject } from '../../../gengine';
import { DATA_OBJECT_SCENE } from '../../../const';
import { sercheAllObjectByProperty } from '../../../utils';
import {
  CatmullRomCurve3,
  Geometry,
  LineBasicMaterial,
  Line,
  RingBufferGeometry,
  MeshBasicMaterial,
  MathUtils,
  DoubleSide,
  Mesh,
  CylinderBufferGeometry,
} from 'three';
import { Vector3 } from 'three';

class SectionsCreator extends AbstractObject {
  constructor(props) {
    super();
    this.state = {};
  }

  createEndPoint = (position) => {
    const { scene } = this.props;
    const geometry = new RingBufferGeometry(0.1 * 0.3, 0.2 * 0.3, 32);
    const material = new MeshBasicMaterial({
      color: 0xffff00,
      side: DoubleSide,
    });
    const mesh = new Mesh(geometry, material);
    mesh.rotation.set(MathUtils.degToRad(90), 0, 0);
    mesh.position.set(position.x, position.y + 1, position.z);
    mesh.name = DATA_OBJECT_SCENE.LINE_SECTION.name;
    scene.add(mesh);
  };
  createStartPoint = (position) => {
    const { scene } = this.props;
    const geometry = new CylinderBufferGeometry(0.2 * 0.2, 0.2 * 0.3, 0.1, 32);
    const material = new MeshBasicMaterial({
      color: 0xffff00,
      side: DoubleSide,
    });
    const mesh = new Mesh(geometry, material);
    mesh.position.set(position.x, position.y + 1, position.z);
    mesh.name = DATA_OBJECT_SCENE.LINE_SECTION.name;
    scene.add(mesh);
  };

  init = () => {
    const { sections, scene } = this.props;
    sections.forEach((element) => {
      let vertices = [];
      element.vertices.forEach((el) => {
        if (el instanceof Vector3) {
          vertices.push(el);
        } else {
          vertices.push(new Vector3(el));
        }
      });
      let numPoints = vertices.length;
      let spline = new CatmullRomCurve3(vertices, false, undefined, 1);
      let points = spline.getPoints(numPoints);
      let geometry = new Geometry().setFromPoints(points);
      geometry.computeBoundingSphere();
      let material = new LineBasicMaterial({
        color: 'blue',
        linewidth: 6,
      });
      let mesh = new Line(geometry, material);
      mesh.name = DATA_OBJECT_SCENE.LINE_SECTION.name;
      this.createEndPoint(element.vertices[element.vertices.length - 1]);
      this.createStartPoint(element.vertices[0]);
      scene.add(mesh);
    });
  };

  clearMemory = () => {
    const { scene } = this.props;
    let lineSection = sercheAllObjectByProperty('name', DATA_OBJECT_SCENE.LINE_SECTION.name, scene);
    const clearScene = (data = []) => {
      data.forEach((el) => {
        el.geometry.dispose();
        el.material.dispose();
        scene.remove(el);
      });
    };
    clearScene(lineSection);
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.clearMemory();
      this.init();
    }
  }

  componentDidMount() {
    this.clearMemory();
    this.init();
  }

  render() {
    const {} = this.props;
    return null;
  }
}

export default SectionsCreator;
