import PromiseWorker from '../../WorkerUtils'


export const vlsDTB = async (data) => {
  return new PromiseWorker({
    url: "/web-worker/CalculationsVLS.js"
  }).init().invoke(data).then((el) => {
    return el
  })
};
