import React from 'react'
import HelloHeadLine from '../../Views/Layout/HelloHeadLine'
import { PATH } from '../../const'
import { NavLink } from 'react-router-dom'
import connect from 'storeon/react/connect'
class AreaPersonPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {children, ...props} = this.props
    return (
      <>
        <HelloHeadLine {...props} />
        <div className={`personal-page_setting`}>
          <div className={`personal-page_setting_toggle`}>
            <NavLink
              to={PATH.PersonalPagePersonalAreaSettingProfile}
              exact
              className={`personal-page_setting_toggle-link`}
              activeClassName={`personal-page_setting_toggle-active`}>
              НАСТРОЙКИ
            </NavLink>
            <NavLink
              to={PATH.PersonalPagePersonalAreaMyRate}
              exact
              className={`personal-page_setting_toggle-link`}
              activeClassName={`personal-page_setting_toggle-active`}>
              ТАРИФНЫЙ ПЛАН
            </NavLink>
          </div>
        </div>
        {children}
      </>
    )
  }
}
export default connect('ProjectTheme', AreaPersonPage)
