import React from 'react'
import Section from './Section'
import InputValueData from '../../Views/Layout/InputValueData'
import Button from '../../Views/Layout/Button'

const SubareasParams = (props) => {
  const { paramsDeviceDataNamefields, numberSubareas, setParamsDeviceDataNamefields, setNumberSubareas } = props
  const changeNumberSubareas = (e) => {
    setNumberSubareas(e.target.value)
  }
  const coordsDot = (e) => {
    let newData = []
    paramsDeviceDataNamefields.forEach(element => {
      if (element.id === e.target.id) {
        newData.push({
          ...element,
          value: e.target.value
        })
      } else {
        newData.push(element)
      }
    });
    setParamsDeviceDataNamefields(newData)
  }
  return (
    <Section>
      <div className={"modal-params_subareas-wrapper-content-section-subareas_params"}>
        <div className={"modal-params_subareas-wrapper-content-section-subareas_params-section_wrap"}>
          <InputValueData
            min={0}
            wrapperClassName={"modal-params_subareas-wrapper-content-section-subareas_params-wrapper_input"}
            defaultClassWrapperDisabled={true}
            classNameFirstDescription={" modal-params_subareas-wrapper-content-section-subareas_params-nubmer_subareas"}
            firstItemClassName={" modal-params_subareas-wrapper-content-section-subareas_params-input"}
            className={"data"}
            firstDescription={"Номер подобласти"}
            type={'number_custom'}
            value={numberSubareas}
            onChange={changeNumberSubareas}
          />
        </div>
        <h4 className={"modal-params_subareas-wrapper-content-section-subareas_params-title_params"}>Координаты точки привязки подобласти</h4>
        <div className={"modal-params_subareas-wrapper-content-section-subareas_params-params_wrap"}>
          <div>
            {
              paramsDeviceDataNamefields.map((el, i) => {
                return <InputValueData
                  key={i}
                  firstItemClassName={" modal-params_subareas-wrapper-content-section-subareas_params-params_wrap-input"}
                  className={'data '}
                  defaultValue={el.value}
                  firstDescription={el.nameOfValue}
                  secondDescription={el.units}
                  id={el.id}
                  onChange={coordsDot}
                />
              })
            }
          </div>
          <div className={"modal-params_subareas-wrapper-content-section-subareas_params-params_wrap-text_info"}>
            <p>
              Задаются в пределе <br />
              {`-1 < = Px, Рy < =1`}
            </p>
          </div>
        </div>
      </div>
      <div className={"modal-params_subareas-wrapper-content-section-subareas_creator-wrapper"}>
        <div className={"modal-params_subareas-wrapper-content-section-subareas_creator"}>
          <div className={"modal-params_subareas-wrapper-content-section-subareas_creator-btn_wrapp"}>
            <Button
              className={" modal-params_subareas-wrapper-content-section-subareas_creator-btn_create_subareas"}
            >
              Создать новую
              подобласть без
              координатной привязки
              </Button>
          </div>
          <div className={"modal-params_subareas-wrapper-content-section-subareas_creator-btn_wrapp"}>
            <Button
              className={" modal-params_subareas-wrapper-content-section-subareas_creator-btn_create_subareas"}
            >
              Редактирование характеристики <br />материала
              </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}
export default SubareasParams