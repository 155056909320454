import React from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { useDocumentTitle } from "../../utils";

export default ({ children, title = "Main title", isVisibleHeader = true }) => {
  useDocumentTitle(title);
  return (
    <div>
      {isVisibleHeader ? <Header /> : null}
      {children}
    </div>
  );
};
