import React from 'react'
import { Route, Switch } from "react-router-dom";
import {
	PersonalPageAllProject,
	PersonalAreaPage,
	SettingPage,
	DetailsProjectFormPersonalPage,
	VirtualLibraryStandPage,
	PhysicalFieldModelingLibraryPage,
	PersonAreaMyRate,
	PersonalPageSettingProfile,
	PersonalPageMyProject,
	PersonalPageSearchProject,
	PersonalPageFavoriteProject,
	PersonalPageBagTrash,
} from '../../pages'
import { PATH } from '../../const'



class PersonalPageRoutes extends React.Component {
	constructor(props) {
		super()
	}

	render() {
		return (
			<Switch>
				<Route
					path={PATH.PersonalPageAllProject}
					exact
					component={PersonalPageAllProject}
				/>
				<Route
					path={PATH.PersonalPageMyProject}
					exact
					component={PersonalPageMyProject}
				/>
				<Route
					path={PATH.PersonalPageBagTrash}
					exact
					component={PersonalPageBagTrash}
				/>
				<Route
					path={PATH.PersonalPageSearch}
					exact
					component={PersonalPageSearchProject}
				/>
				<Route
					path={PATH.PersonalPageFavoriteProject}
					exact
					component={PersonalPageFavoriteProject}
				/>
				<Route
					path={PATH.PersonalPagePersonalAreaMyRate}
					exact 
					component={PersonAreaMyRate}
				/>
				<Route
					path={PATH.PersonalPagePersonalAreaSettingProfile}
					exact
					component={PersonalPageSettingProfile}
				/>
				<Route
					path={PATH.PersonalPagePersonalArea}
					exact
					component={PersonalAreaPage}
				/>
				<Route
					path={PATH.PersonalPageSettingPage}
					exact
					component={SettingPage}
				/>
				<Route
					path={`${PATH.DetailsProjectFormPersonalPage}:id`}
					exact
					component={DetailsProjectFormPersonalPage}
				/>
				<Route
					path={`${PATH.VirtualLibraryStand}:id`}
					exact
					component={VirtualLibraryStandPage}
				/>
				<Route
					path={`${PATH.PhysicalFieldModelingLibrary}:id`}
					exact
					component={PhysicalFieldModelingLibraryPage}
				/>
			</Switch>
		)
	}
}

export default PersonalPageRoutes