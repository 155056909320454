import React from 'react'
import { PATH } from '../../const'
import ModalPersonalPage from '../../common/ModalPersonalPage'
import InputForm from '../../Views/Layout/InputForm'
import ConfirmComponent from '../../common/ConfirmComponent'
import Button from '../../Views/Layout/Button'
class SettingProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowModal: false,
      contentForModal: null,
    }
  }

  clickBtnHandler = (type) => {
    if (type === 'name') {
      this.openModalChangeName()
    }
    else if (type === 'password') {
      this.openModalChangePassword()
    }
    else if (type === 'email') {
      this.openModalChangeEmail()
    }
  }

  createContentFormModal = (title, palceholder, placeholderForTwoInput, text) => {
    return (
      <>
        <h4>{title}</h4>
        <div className={"modal-change_password-wrapper-wrapper_fields"}>
          <InputForm placeholder={palceholder} />
          <div className={"modal-change_password-wrapper-wrapper_fields-wrapper_custom_field"}>
            <div
              className={`modal-change_password-wrapper-wrapper_fields-wrapper_custom_field-eye  hidden_pass`}
              onClick={(e) => {
                let newClass = e.currentTarget.classList[1] === "hidden_pass" ? "visible_pass" : "hidden_pass"
                e.currentTarget.parentNode.children[1].type = e.currentTarget.classList[1] === "hidden_pass" ? "text" : "password"
                e.currentTarget.classList.remove(e.currentTarget.classList[1])
                e.currentTarget.classList.add(newClass)
              }}
            ></div>
            <InputForm
              type={"password"}
              className={"input_form modal-change_password-wrapper-wrapper_fields-password_custom_field"}
              placeholder={placeholderForTwoInput}
            />
          </div>
        </div>
        <div className='wrapper-button-group'>
          <span>{text}</span>
          <Button className={'ok'} />
        </div>
      </>
    )
  }
  createContentFormModalName = () => {
    return (
      <>
        <h4>Изменить имя</h4>
        <div className={"modal-change_name-wrapper-wrapper_fields"}>
          <InputForm placeholder='Введите имя' />
        </div>
        <div className='wrapper-button-group'>
          <ConfirmComponent callbackCancellation={this.closeModal} />
        </div>
      </>
    )
  }
  closeModal = () => {
    this.setState({
      isShowModal: false,
      contentModal: null
    })
  }

  openModalChangeName = () => {
    this.setState({
      isShowModal: true,
      classNameModal: "modal-change_name-wrapper",
      contentForModal: this.createContentFormModalName()
    })
  }

  openModalChangeEmail = () => {
    this.setState({
      isShowModal: true,
      classNameModal: "modal-change_password-wrapper",
      contentForModal: this.createContentFormModal('Изменить Email', 'Новый Email', 'Введите пароль', 'Изменить пароль')
    })
  }

  openModalChangePassword = () => {
    this.setState({
      isShowModal: true,
      classNameModal: "modal-change_password-wrapper",
      contentForModal: this.createContentFormModal('Изменить пароль', 'Старый пароль', 'Новый пароль', 'Забыли пароль')
    })
  }

  outIn = (e, type) => {
    if (type === 'account') {
      localStorage.clear();
      window.location.href = PATH.Home;
    }
  };

  render() {
    const editDataProfile = [
      { title: 'Имя', yourParam: 'yusa', setNewParam: 'Изменить имя', type: 'name' },
      { title: 'E-mail', yourParam: 'yuliya.salautina@garpix.com', setNewParam: 'Изменить e-mail', type: 'email' },
      { title: 'Пароль', yourParam: '', setNewParam: 'Изменить пароль', type: 'password' },
      { title: 'Аккаунт', yourParam: 'Выйти', setNewParam: 'Удалить аккаунт', classNameOut: 'out', classNameDelite: 'delite', type: 'account' },
    ]
    return (
      <>
        <ModalPersonalPage className={this.state.classNameModal} close={this.closeModal} show={this.state.isShowModal} content={this.state.contentForModal} />
        {editDataProfile.map((item) => {
          return (
            <div key={item.type} className={`person-area_setting-option`}>
              <h4 className={`person-area_setting-option-title`}>{item.title}</h4>
              <p onClick={(e) => this.outIn(e, item.type)} className={`person-area_setting-option-your ${item.classNameOut}`}>{item.yourParam}</p>
              <p onClick={() => this.clickBtnHandler(item.type)} className={`person-area_setting-option-set ${item.classNameDelite}`}>{item.setNewParam}</p>
            </div>
          )
        })}
      </>
    )
  }
}
export default SettingProfile 