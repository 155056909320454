import React from 'react'


const ContentForContextMenuTrash = (props) =>{
  const {fullDeliteElement, id, restoreElement} = props
  return(
    <ul>
    <li>Дублировать в проекты</li>
    <li className={`context_menu-border`}></li>
    <li onClick={()=>restoreElement(id)}>Восстановить</li>
    <li onClick={()=>fullDeliteElement(id)}>Удалить навсегда</li>
  </ul>
  )
}
export default ContentForContextMenuTrash