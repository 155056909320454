import React from "react";
import Layout from "../../Views/Layout";
import DefaultPageLayout from "../../Views/Layout/DefaultPageLayout";
import Error404 from "../../common/Error404";

const Error404Page = () => {
  return (
    <Layout title={"404"} isVisibleHeader={false}>
      <DefaultPageLayout FullWidthContent={Error404}></DefaultPageLayout>
    </Layout>
  );
};

export default Error404Page;
