import React from "react";
import LoaderSpiner from "../LoaderSpiner";

const ModalContentForGLTFLoader = (props) => {
  let progress = Math.round(props.progress);
  if (isNaN(progress)) {
    progress = false;
  }
  return (
    <div className={"modal_gltf_loader-wraper"}>
      <h2 className={"modal_gltf_loader-title"}>
        {props.title ? props.title : "Загрузка модели..."}
      </h2>
      <LoaderSpiner className={"modal_gltf_loader-spiner"} />
      {progress ? (
        <p className={"modal_gltf_loader-progress"}>{`${progress} %`}</p>
      ) : null}
    </div>
  );
};

export default ModalContentForGLTFLoader;
