import React from 'react'
import { v4 } from 'uuid'


class Select extends React.Component {
  constructor(props) {
    super()
    this.selectRef = React.createRef()
    this.state = {
      openSelect: false,
      focusSelect: false
    }
  }

  focusSelect = (e) => {
    this.setState({
      focusSelect: true
    })
  }

  blurSelect = (e) => {
    this.setState({
      focusSelect: false
    })
  }

  openSelect = (state) => {
    this.setState({
      openSelect: state
    }, () => {
      if (state) {
        this.selectRef.current.focus()
      }
    })
  }

  render() {
    const { options, className, disabled, autoComplete, value, onChange, label } = this.props
    const { focusSelect } = this.state
    const unicID = v4()
    return (
      <>
        <label htmlFor={unicID}>
          {label}
        </label>
        <div className={`custom-select_main ${focusSelect ? "focus" : ""}`} onClick={() => this.openSelect(true)}>
          <select
            ref={this.selectRef}
            id={unicID}
            autoComplete={autoComplete}
            disabled={disabled}
            onChange={onChange}
            value={value}
            className={`select ${className}`}
            onFocus={this.focusSelect}
            onBlur={this.blurSelect}
          >
            <option disabled>не выбрано</option>
            {
              options.map((el, i) => {
                return <option
                  key={i}
                  disabled={el.disabled}
                  // selected={el.selected}
                  value={el.value}
                >
                  {el.name}
                </option>
              })
            }
          </select>
        </div>

      </>
    )
  }
}

export default Select