import React from "react";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";
import connect from 'storeon/react/connect'

const QuestionHelpers = (props) => {
  let theme = props.ProjectTheme === 'light' ? 'light-question' : ''
  const popover = (
    <Popover arrowProps={null} id="popover-basic" className={""}>
      {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
      <Popover.Content>Помощь</Popover.Content>
    </Popover>
  );
  return (
    <div className={`question_helpers-button ${theme}`}> ? </div>
  );
};

export default connect('ProjectTheme', QuestionHelpers);
{/* <OverlayTrigger trigger="click" placement="top" overlay={popover}>
<div className={`question_helpers-button ${theme}`}> ? </div>
</OverlayTrigger> */}