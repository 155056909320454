const SubmitForm = (
  method,
  params,
  callbackSuccess = (e) => {},
  error = (e) => {},
  redirectTo = '#',
) => {
  method(params)
    .then((res) => {
      callbackSuccess(res);

      //todo: добавить нотифашки
    })
    .catch((err) => {
      error(err);
      console.log(err);
      //todo: добавить нотифашки
    });
};
export default SubmitForm;
