export let CallbackCollector = store => {
    // Initial state
    store.on('@init', () => ({ CallbackCollector: false, deviceType: null }));
    // Reducers returns only changed part of the state
    store.on("CallbackCollector", ({ CallbackCollector }, data) => {
        if (data === "clear") {
            return { CallbackCollector: false, deviceType: null  };
        }
        return {
            CallbackCollector: data
        };
    });
};