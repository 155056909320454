import React from "react";
import { NavLink } from "react-router-dom";
import { PATH } from "../../const";
// type={type}
// imgSrc={imgSrc}
// title={title}
// date={date}
const NewsItemHomePage = (props) => {
  const getClassNameForType = (type) => {
    switch (type.category) {
      case 0:
        return "government";
      case 1:
        return "government";
      case 2:
        return "energy";
      case 3:
        return "achievements";
      case 4:
        return "achievements";
      case 5:
        return "industry";
      default:
        return "";
    }
  };

  return (
    <NavLink to={`${PATH.NewsDetailsInHomePage}${props.id}`}>
      <div className={"news-content-wrap_news_item"}>
        <div className={"news-content-wrap_news_item-wrap_img"}>
          <div
            // src={props.imgSrc}
            // alt={props.altImg}
            className={"news-content-wrap_news_item-img"}
            style={{backgroundImage: `url(${props.imgSrc})`}}
          ></div>
        </div>
        <div className="news-content-wrap_news_item-content">
          <div
            className={`news-content-wrap_news_item-type ${getClassNameForType(
              props.type
            )}`}
          >
            {props.type.title}
          </div>
          <div className={"news-content-wrap_news_item-text"}>
            <span>{props.text}</span>
          </div>
          <div className={"news-content-wrap_news_item-date"}>{props.date}</div>
        </div>
      </div>
    </NavLink>
  );
};

export default NewsItemHomePage;
