import React from "react";
import { Plane as PlaneAbstract } from "../../../abstract/primitives/Plane";
import AbstractObject from "../../abstract/AbstractObject";

class Plane extends AbstractObject {
  constructor(props) {
    super();
    this.timeOutControls = null;
    this.state = {
      objectScene: null,
    };
  }

  componentWillUnmount() {
    const { objectScene } = this.state;
    if (objectScene !== null) {
      objectScene.removeObject(objectScene.parent);
      clearTimeout(this.timeOutControls);
    }
  }

  componentDidMount() {
    const {
      scene,
      enableShadows,
      width = "100",
      height = "100",
      segments = "10",
      color = "#ff001a",
      position = [0, 0, 0],
      rotation = [0, 0, 0],
      scale= [1,1,1],
      pivot= false,
      callback = null,
      parent = false,
      material = null,
      uuid = null,
      customAttribute = {},
      texture = null,
      name = "Plane",
    } = this.props;
    this.initComponent();
    let objectScene = new PlaneAbstract({
      scene,
      enableShadows,
      width,
      height,
      segments,
      color,
      pivot,
      material,
      position,
      rotation,
      scale,
      texture,
      uuid,
      customAttribute,
      callback,
      parent,
      name,
    });
    this.obj = objectScene;
    this.setState({
      objectScene: objectScene,
    });
    this.onPropsUpdate({}, this.props);
    this.timeOutControls = setTimeout(() => {
      this.readyComponent();
    }, 1);
  }

  render() {
    const { color } = this.props;
    this.setColor(color);
    return null;
  }
}

export default Plane;
