import React from 'react'
import PersonalPageLayout from '../../Views/Layout/PersonalPageLayout'
import Layout from '../../Views/Layout'
import Trash from '../../components/Trash'

const PersonalPageBagTrash = () =>{
  return(
    <>
      <Layout title={"Корзина"}>
        <PersonalPageLayout>
          <Trash />
        </PersonalPageLayout>
      </Layout>
    </>
  )
}
export default PersonalPageBagTrash