import React from 'react'
import ParamsDeviceValue from '../../Views/Layout/ParamsDeviceValue';
import { Tabs as RBTabs, Tab } from 'react-bootstrap'
import CheckBox from '../../Views/Layout/CheckBox';
import Chart from '../../Views/Layout/Chart'
import ButtonMain from '../../Views/Layout/ButtonMain';
import InputValueData from '../../Views/Layout/InputValueData'
import TableGenerate from '../../common/TableGenerate'
import isEqual from 'react-fast-compare'
import v4 from 'uuid/v4';
import Api from '../../api'
import SubmitForm from '../../common/SubmitForm'
import connect from 'storeon/react/connect'

const ApiResearch = Api.ResearchApi
class InductanceComponentForSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabKey: 'settings',
      corePresence: false,
      dataChart: [0, 0, 50, 0, 0, 0, 50, 0, 50, 0, 50],
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Имя', value: 9.9999e+42 },
        { id: v4(), nameOfValue: 'Индуктивность (Гн)', value: 9.9999e+42, },
        { id: v4(), nameOfValue: 'Max. ток (А)', value: 9.9999e+42, },
      ],
      TableHeadData: [
        [{ name: 'Сила тока (А)', attr: {} }, { name: 'L (Гн)', attr: {} }],
      ],
      TableBodyData: [],
      initDataForTable: [],
      variant: "vls-table_data-oscilloscope-table"
    }
  }

  submitData = () => {
   let { hash_id, type } = this.props.element
    if (!hash_id) hash_id = this.props.element.id
    const {
      paramsDeviceDataNamefields,
      corePresence,
      initDataForTable,
      dataChart
    } = this.state
    let params = {
      device_id: hash_id,
      params: {
        type: type,
        meta: {
          defaultSettings: {
            name: paramsDeviceDataNamefields[0].value,
            inductanceGn: paramsDeviceDataNamefields[1].value,
            maxVoltageA: paramsDeviceDataNamefields[2].value
          },
          dataChart: dataChart,
          corePresence: corePresence,
          tableBodyData: initDataForTable
        }
      }
    }
    this.props.dispatch('SidebarDataFromDevice', {
      isUpdate: true,
      hash_id: hash_id,
      data: params.params.meta,
    });
    SubmitForm(ApiResearch.putResearchUpdateDeviceData, params, this.successSubmit)
  }

  successSubmit = (response) => {
    console.log(response)
  }

  updateDeviceData = (data) => {
    this.setState(data, this.submitData)
  }

  corePresenceChange = (e) => {
    let data = {
      corePresence: e.target.checked
    }
    this.updateDeviceData(data)
  }

  defaultDeviceDataChange = (e) => {
    const { paramsDeviceDataNamefields } = this.state
    let resultsUpdate = []
    for (let i = 0; i < paramsDeviceDataNamefields.length; i++) {
      const element = paramsDeviceDataNamefields[i];
      if (element.id === e.target.id) {
        resultsUpdate.push({
          ...element,
          value: e.target.value
        })
      } else {
        resultsUpdate.push(element)
      }
    }
    let newData = {
      paramsDeviceDataNamefields: resultsUpdate
    }
    this.updateDeviceData(newData)
  }

  changeTableData = (data) => {
    const { initDataForTable } = this.state
    this.updateDeviceData({
      initDataForTable: initDataForTable.map((el, i) => {
        if (data.iter === i) {
          el[data.key] = data.target.value
          return el
        } else {
          return el
        }
      })
    })
  }

  generateTableBody = (data) => {
    let arr = []
    for (let i = 0; i < data.length; i++) {
      let valueDivision = {
        tdParams: { className: "td_padding_null" },
        content: (
          <div key={i}>
            <InputValueData
              wrapperClassName={'vls-table_data-oscilloscope-input_wrapper'}
              className={"data vls-table_data-oscilloscope-input"}
              type={'number'}
              value={data[i].amperage}
              onChange={(e) => {
                let data = {
                  target: e.target,
                  iter: i,
                  key: "amperage"
                }
                this.changeTableData(data)
              }}
            />
          </div>
        ),
      };
      let startCount = {
        tdParams: { className: "td_padding_null" },
        content: <div key={i}>
          <InputValueData
            wrapperClassName={'vls-table_data-oscilloscope-input_wrapper'}
            className={"data vls-table_data-oscilloscope-input"}
            type={'number'}
            value={data[i].LGn}
            onChange={(e) => {
              let data = {
                target: e.target,
                iter: i,
                key: "LGn"
              }
              this.changeTableData(data)
            }}
          />
        </div>,
      };
      arr.push([valueDivision, startCount])

    }
    return arr
  }

  selectTab = (e) => {
    this.setState({
      activeTabKey: e
    })
  }

  setDataDevice = (props) => {
    const { element } = props
    const { defaultSettings, dataChart, corePresence, tableBodyData } = element.meta
    this.setState({
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Имя', value: defaultSettings.name },
        { id: v4(), nameOfValue: 'Индуктивность (Гн)', value: defaultSettings.inductanceGn },
        { id: v4(), nameOfValue: 'Max. ток (А)', value: defaultSettings.maxVoltageA },
      ],
      dataChart: dataChart,
      corePresence: corePresence,
      initDataForTable: tableBodyData,
      TableBodyData: this.generateTableBody(tableBodyData)
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.element, prevProps.element)) {
      this.setDataDevice(this.props)
    }
    if (this.state.initDataForTable !== prevState.initDataForTable) {
      this.setState({
        TableBodyData: this.generateTableBody(this.state.initDataForTable)
      })
    }
  }


  componentDidMount() {
    this.setDataDevice(this.props)
  }

  render() {
    const {
      activeTabKey,
      paramsDeviceDataNamefields,
      corePresence,
      dataChart,
      TableHeadData,
      TableBodyData,
      variant
    } = this.state
    return (
      <React.Fragment>
        <span className={"vls-sidebar-device_title"}>ИНДУКТИВНОСТЬ</span>
        <RBTabs
          id="vls-sidebar_tabs_wraper"
          defaultActiveKey={'base'}
          activeKey={activeTabKey}
          onSelect={this.selectTab}
        >
          <Tab tabClassName={"vls-sidebar_tabs_wraper-tab vls-sidebar_tabs_wraper-tab_only"} eventKey="settings" title="Параметры">
            <ParamsDeviceValue onChange={this.defaultDeviceDataChange} classNameForInput={"vls-sidebar_tabs_wraper-tab-input"} data={paramsDeviceDataNamefields} />
            <div className={"vls-checkbox_data-inductance"}>
              <CheckBox
                onChange={this.corePresenceChange}
                checked={corePresence}
                label={"Наличие сердечника"}
                helpText={"Сердечник - это разновидность катушки индуктивности..."}
              />
            </div>
            <div className='content-border-line'></div>
          </Tab>
          <Tab tabClassName={"vls-sidebar_tabs_wraper-tab"} eventKey="chart" title="L = f">
            <Chart width={280} height={230} dataChart={dataChart} />
            <ButtonMain className={'button__option-menu vls-btn-rebuild-inductance'}>Перестроить</ButtonMain>
            <div className={"vls-inductance-table_wrapper"}>
              <TableGenerate
                variant={variant}
                TableHeadData={TableHeadData}
                TableBodyData={TableBodyData}
              />
            </div>
            <div className='content-border-line'></div>
          </Tab>
        </RBTabs>
      </React.Fragment>
    )
  }
}
export default connect("SidebarDataFromDevice", InductanceComponentForSidebar)