import BaseApi from "@garpix/base-api";

class UserApi extends BaseApi {
  getUserData = async (id = null, params = {}) => {
    let res = await this.get(`/user/${id}/`, params);
    return res.data.results;
  };
  login = async (params = {}) => {
    let res = await this.post(`/user/auth/login/`, params);
    return res;
  };
  registration = async (params = {}) => {
    let res = await this.post(`/user/`, params);
    return res;
  };
  resetPassword = async (params = {}) => {
    let res = await this.post(`/user/password_reset/`, params);
    return res;
  };
}

export default UserApi;
