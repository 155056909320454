export let DataSelectedElementForSidebar = store => {
    store.on('@init', () => ({ DataSelectedElementForSidebar: null }));
    store.on("DataSelectedElementForSidebar", ({ DataSelectedElementForSidebar }, data) => {
        if (data === "clear") {
            return { DataSelectedElementForSidebar: null };
        }
        return {
            DataSelectedElementForSidebar: data
        };
    });
};