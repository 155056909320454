import moment from "moment";

export const getSerializedItemProjects = (data) => {
  return {
    id: data.hash_id,
    hash_id: "0a8846a22fc82ea2",
    name: data.title,
    project_id: data.project_id,
    description: data.description,
    date: moment(data.date).format("DD MMMM YYYY"),
    isEmpty: !data.is_empty,
    link: data.link,
    favorites: data.favorites,
    archive: data.archive,
    size: data.size,
    typeView: data.type_view,
  };
};
export const getSerializedVLS = (data) => {
  return {
    hash_id: data.id,
    title: data.title,
    date: moment(data.date).format("DD MMMM YYYY"),
    link: data.link,
    size: data.size,
    type: data.type_name,
    typeView: data.extension,
  };
}