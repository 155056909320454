import React, { useState, useEffect } from 'react'
import defaultData from "./initialParamsDeviceDataNamefield"
import BtnComponent from './BtnComponent'
import SubareasParams from './SubareasParams'
import TriangulationGrooves from './TriangulationGrooves'
import VoltageParams from "./VoltageParams"
import { v4 } from 'uuid'
import Api from '../../api'


const ApiMaterial = Api.MaterialApi
const ParamsSubareas = (props) => {
  let initDataCoords = [
    { id: v4(), nameOfValue: 'Xр = ', units: "(мм)", value: props.coordsPoint.x, },
    { id: v4(), nameOfValue: 'Yр = ', units: "(мм)", value: props.coordsPoint.z, },
    { id: v4(), nameOfValue: 'Rр = ', units: "(мм)", value: 20 },
  ]
  const [magnetizationCoords, setMagnetizationCoords] = useState(defaultData.magnetizationCoords)
  const [paramsDeviceDataNamefields, setParamsDeviceDataNamefields] = useState(initDataCoords)
  const [optionsTypeEnv, setOptionsTypeEnv] = useState(defaultData.initialOptionsTypeEnv)
  const [optionsNameParams, setOptionsNameParams] = useState(defaultData.initialOptionsNameParams)
  const [selectTypeEnvData, setSelectTypeEnvData] = useState(defaultData.initialSelectTypeEnvData)
  const [selectSelectParamsName, setSelectSelectParamsName] = useState(defaultData.initialSelectParamsName)
  const [allParams, setAllParams] = useState(defaultData.initialAllParams)
  const [numberSubareas, setNumberSubareas] = useState(props.count)
  const [voltage, setVoltage] = useState(true)
  const [voltageDensity, setVoltageDensity] = useState(false)
  const agregationsStateModal = () => {
    return {
      magnetizationCoords: magnetizationCoords,
      paramsDeviceDataNamefields: paramsDeviceDataNamefields,
      selectTypeEnvData: selectTypeEnvData,
      selectSelectParamsName: selectSelectParamsName,
      allParams: allParams,
      numberSubareas: numberSubareas,
      voltage: voltage,
      voltageDensity: voltageDensity
    }
  }

  //!Observer selectTypeEnvData
  useEffect(() => {
    if (selectTypeEnvData.value) {
      ApiMaterial.getMaterialSpec({ material: selectTypeEnvData.value }).then(res => {
        setOptionsNameParams(res.map((el, i) => {
          return {
            disabled: false,
            selected: i === 0,
            value: el.slug,
            name: el.title,
          }
        }))
      })
    }
  }, [selectTypeEnvData])

  //!componentDidMount
  useEffect(() => {
    ApiMaterial.getMaterialType().then(res => {
      setOptionsTypeEnv(res.map((el, i) => {
        return {
          disabled: false,
          selected: i === 0,
          value: el.slug,
          name: el.title,
        }
      }))
    })
  }, [])

  console.log('paramsDeviceDataNamefields', allParams)
  return (
    <React.Fragment>
      <div className={"modal-params_subareas-back_side"}></div>
      <div className={"modal-params_subareas-position_center"}>
        <div className={"modal-params_subareas-wrapper"}>
          <div className={"modal-params_subareas-wrapper-header"}>
            <h5 className={"modal-params_subareas-wrapper-title"}>Параметры подобласти</h5>
            <div className={"modal-params_subareas-wrapper-close_btn"}></div>
          </div>
          <div className={"modal-params_subareas-wrapper-content"}>
            <SubareasParams
              paramsDeviceDataNamefields={paramsDeviceDataNamefields}
              numberSubareas={numberSubareas}
              setNumberSubareas={setNumberSubareas}
              setParamsDeviceDataNamefields={setParamsDeviceDataNamefields}
            />
            <TriangulationGrooves
              selectTypeEnvData={selectTypeEnvData}
              optionsTypeEnv={optionsTypeEnv}
              selectSelectParamsName={selectSelectParamsName}
              optionsNameParams={optionsNameParams}
              magnetizationCoords={magnetizationCoords}
              setMagnetizationCoords={setMagnetizationCoords}
              setSelectTypeEnvData={setSelectTypeEnvData}
            />
            <VoltageParams
              voltage={voltage}
              setVoltage={setVoltage}
              allParams={allParams}
              setAllParams={setAllParams}
              voltageDensity={voltageDensity}
              setVoltageDensity={setVoltageDensity}
            />
            <BtnComponent
              verticesForCreateMesh={props.verticesForCreateMesh}
              agregationsStateModal={agregationsStateModal}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ParamsSubareas