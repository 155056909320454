import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
import connect from 'storeon/react/connect'
import {COLOR_FOR_CHARTS} from '../../../const'
const Chart = ({width, height, dataChart, ...props}) => {
  const { 
    colorForChartsBackgroundDark,
    colorForChartsBackgroundLight,
    colorForChartsDataLine,
    colorForChartsGridLine,
    colorForChartsGridLineDark,
    colorForChartsDark,
    colorForChartsLight
  } = COLOR_FOR_CHARTS
  const theme = props.ProjectTheme === 'light' ? true : false;
    const options = {
      lang: {
        viewFullscreen: 'View data table'
      },
        title: {
          text: ''
        },
        yAxis:{
          
          title: {
            text: ''
          },
          tickWidth: 0,
          gridLineColor: theme ? colorForChartsGridLine : colorForChartsGridLineDark,
          gridLineDashStyle: 'Dash',
          labels: {
            rotation: 0,
            autoRotation: false,
            style: {
                color: theme ? colorForChartsDark : colorForChartsLight,
            }
        },
        },
        xAxis:{
          // #A1ACBD
          gridLineColor: theme ? colorForChartsGridLine : colorForChartsGridLineDark,
          gridLineWidth: 1,
          tickWidth: 1,
          categories: dataChart.map((el,i) => i),
          gridLineDashStyle: 'Dash',
          title: {
            text: ''
          },
          crosshair: {
            enabled: true,
            width: 2
          },
          labels: {
            autoRotation: false,
            rotation: 0,
            align: 'right',
            style: {
                color: theme ? colorForChartsDark : colorForChartsLight,
            }
          },
        }, 
        series: [{
          data: dataChart,
          type: 'areaspline',
          threshold: null,
          fillColor: {
            linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
            },
            stops: [
              [0, colorForChartsDataLine],
              [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
          ]
        },
        }],
        chart: {
          width: width,
          height: height,
          backgroundColor: theme ? colorForChartsBackgroundDark : colorForChartsBackgroundLight,
        },
        plotOptions: {
          series: {
            color: colorForChartsDataLine,
            cursor: 'pointer',
          }
        },
        legend: {
          enabled: false,
       },
       credits: {
        enabled: false
      },
    }
    
    
    return(
        <div className='wrapper-charts'>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
        </div>
    )
    }
export default connect('ProjectTheme', Chart)