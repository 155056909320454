export let SaveScene = (store) => {
  // Initial state
  store.on("@init", () => ({ DataSceneSaving: {} }));
  // Reducers returns only changed part of the state
  store.on("DataSceneSaving", ({ DataSceneSaving }, data) => {

    if (data === "clear") {
      return { DataSceneSaving: {} };
    }
    DataSceneSaving = {}
    return {
      DataSceneSaving: data,
    };
  });
  //   store.on('users/add', async (state, user) => {
  //   try {
  //     await api.addUser(user)
  //     store.dispatch('users/save', user)
  //   } catch (e) {
  //     store.dispatch('errors/server-error')
  //   }
  // })
};
