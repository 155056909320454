import React from "react";
import ButtonMain from "../../Views/Layout/ButtonMain";

export default (props) => {
  const { paramsSuccessInfoModal, closeSuccessInfoModal } = props;
  return (
    <React.Fragment>
      <div className={"modal_pfml-wrapper"}>
        <div className={"modal_pfml-content"}>
          <div
            onClick={closeSuccessInfoModal}
            className={"modal-close_btn"}
          ></div>
          <img
            className={"modal-icon"}
            src={paramsSuccessInfoModal.icon}
            alt={paramsSuccessInfoModal.altForIcon}
          />
          <div className={"modal-text_info_block"}>
            {paramsSuccessInfoModal.text}
          </div>
          <div>
            <ButtonMain
              onClick={() => {
                if (paramsSuccessInfoModal.callbackClickBtn)
                  paramsSuccessInfoModal.callbackClickBtn();
                closeSuccessInfoModal();
              }}
              className={"modal-btn_success"}
            >
              Хорошо
            </ButtonMain>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
