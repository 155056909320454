import createStore from 'storeon';
import { ElementsVLS } from './ComponentsStore/ElementsVLS';
import { ColisionsElemVLS } from './ColisionsElemVLS';
import { ElementsContext } from './ComponentsStore/ContextElement';
import { DataSelectedElementForSidebar } from './ComponentsStore/DataSelectedElementForSidebar';
import { ElementsToolTips } from './ComponentsStore/ElementsToolTips';
import { DeleteElementData } from './ComponentsStore/DeleteElementData';
import { ElementsPFML } from './ComponentsStore/ElementsPFML';
import { SelectedToolPFML } from './ComponentsStore/SelectedToolPFML';
import { NewsCategoryForNewsDetails } from './ComponentsStore/NewsCategoryForNewsDetails';
import { SaveScene } from './ComponentsStore/SaveScene';
import { ProjectTheme } from './ComponentsStore/ProjectTheme';
import { SceneHandler } from './ComponentsStore/SceneHandler';
import { StartOrStopVLS } from './ComponentsStore/StartOrStopVLS';
import { UserData } from './ComponentsStore/UserData';
import { DotData } from './ComponentsStore/DotData';
import { DataCountAllPrimitiveScenePFML } from './ComponentsStore/DataCountAllPrimitiveScenePFML';
import { DataPrimitiveFromSidebar } from './ComponentsStore/DataPrimitiveFromSidebar';
import { ConnectedWireToDevice } from './ComponentsStore/ConnectedWireToDevice';
import { UpdateComponentDevice } from './ComponentsStore/UpdateComponentDevice';
import { CreateSubareas } from './ComponentsStore/CreateSubareas';
import { CreateSubareasState } from './ComponentsStore/CreateSubareasState';
import { CallbackCollector } from './ComponentsStore/CallbackCollector';
import { LoadDevice } from './ComponentsStore/LoadDevice';
import { SavePFML } from './ComponentsStore/SavePFML';
import { SidebarDataFromDevice } from './ComponentsStore/SidebarDataFromDevice';
import { Notification } from './ComponentsStore/Notification';

export const store = createStore([
  Notification,
  SidebarDataFromDevice,
  SavePFML,
  LoadDevice,
  CallbackCollector,
  CreateSubareasState,
  CreateSubareas,
  UpdateComponentDevice,
  ConnectedWireToDevice,
  DataPrimitiveFromSidebar,
  DotData,
  UserData,
  StartOrStopVLS,
  ProjectTheme,
  SceneHandler,
  SaveScene,
  NewsCategoryForNewsDetails,
  DeleteElementData,
  ElementsContext,
  DataSelectedElementForSidebar,
  ElementsToolTips,
  ColisionsElemVLS,
  ElementsVLS,
  ElementsPFML,
  SelectedToolPFML,
  DataCountAllPrimitiveScenePFML,
  process.env.NODE_ENV !== 'production' && require('storeon/devtools'),
]);
