import React from 'react';
import ProjectDetailsFromPersonalPage from '../../components/ProjectDetailsFromPersonalPage'
import Layout from '../../Views/Layout';

const DetailsProjectFormPersonalPage = (props) => {
	return (
		<Layout title={"Детали проекта"}>
			<ProjectDetailsFromPersonalPage {...props}/>
		</Layout>
	)
};

export default DetailsProjectFormPersonalPage