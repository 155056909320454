import React from 'react'
import ProjectToPersonalPage from '../ProjectToPersonalPage'
import { LIMIT_GET_PROJECTS } from '../../const';
import Api from '../../api'

const ApiProject = Api.ProjectApi
class MyProjects extends React.Component{
  constructor(props){
    super(props)
  }

  getProjects = () => {
    return ApiProject.getProjects({ is_trash: false, limit: LIMIT_GET_PROJECTS})
  }

  render(){
    let { updatecomponent, stopupdate } = this.props
    updatecomponent = updatecomponent === "true"
    return(
      <>
        <ProjectToPersonalPage
          typePage={'MyProject'}
          title={'МОИ ПРОЕКТЫ'}
          api={this.getProjects}
          stopUpdate={stopupdate}
          updateComponent={updatecomponent}
          limit={LIMIT_GET_PROJECTS}
        />
      </>
    )
  }
}
export default MyProjects