import React from 'react';
import Container from '../Container';
import Screen from '../../assets/images/bmp_2_4.svg';
import ReactPlayer from 'react-player';
import { BASE_URL_STATIC } from '../../const';

const FirstScreenSection = (props) => {
  let { head_video, head_text } = props;
  return (
    <section className={'landing-first_screen_section'}>
      <Container>
        <div className="landing-first_screen_section-wrapper">
          <h1 className={'landing-first_screen_section-wrapper-title'}>
            {head_text ? head_text : 'GARPIX DIGITAL TWIN'}
          </h1>
          <span className={'landing-first_screen_section-wrapper-subtitle'}>
            Система имитационного моделирования для разработки и мониторинга электрооборудования и
            электросетей с помощью цифровых двойников
          </span>
          <div className={'landing-first_screen_section-wrapper-screen'}>
            {/* <ReactPlayer
              playing={true}
              poster={`${BASE_URL_STATIC}${`${head_video}`.substr(1)}`}
              url={`${BASE_URL_STATIC}${head_video}`}
              width="100%"
              height="100%"
              style={{
                borderRadius: '30px',
              }}
            /> */}
            <img
              className={'landing-first_screen_section-wrapper-screen-img'}
              src={head_video ? `${BASE_URL_STATIC}${head_video}` : Screen}
              alt={'screen'}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FirstScreenSection;
