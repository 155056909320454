import React from 'react'
import AreaPersonPage from '../../components/AreaPersonPage'
import PersonalPageLayout from '../../Views/Layout/PersonalPageLayout'
import Layout from '../../Views/Layout';

const PersonalAreaPage = ({children, ...props})=> {
    return (
      <Layout title={"Личный кабинет"}>
			  <PersonalPageLayout>
          <AreaPersonPage {...props}>
            {children}
          </AreaPersonPage>
			  </PersonalPageLayout>
		</Layout>
    );
}

export default PersonalAreaPage;


