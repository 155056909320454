import React from "react";
import Api from "../../api";
import { NavLink } from "react-router-dom";
import { Popover, Overlay } from "react-bootstrap";
import { PATH } from "../../const";
import connect from "storeon/react/connect";

const ApiResearch = Api.ResearchApi;
class ContextMenuProjectDetailsMenu extends React.Component {
  copyResearch = (hash_id, type, update = () => {}) => {
    this.props.fadeOutContextMenu();
    ApiResearch.copyResearch(hash_id)
      .then((res) => {
        update(type);
      })
      .catch((err) => {});
  };
  /**
   * todo: add handle rejected
   * @param {*} hash_id
   * @param Func update
   */
  deleteResearch = (hash_id, type, update = () => {}) => {
    this.props.fadeOutContextMenu();
    ApiResearch.deleteResearch(hash_id)
      .then((res) => {
        update(type);
      })
      .catch((err) => {});
  };

  render() {
    let theme =
      this.props.ProjectTheme === "light" ? "light-popover" : "dark_theme";
    let container = document.getElementById("root"); // измени потом на портал ректовский
    if (!this.props.dataProject) return null;
    const { hash_id, type } = this.props.dataProject;
    const {
      updateListResearch,
      setChangeTitle = () => {},
      fadeOutContextMenu = () => {},
    } = this.props;
    let CUSTOM_PATH =
      type === "VLS"
        ? PATH.VirtualLibraryStand
        : PATH.PhysicalFieldModelingLibrary;
    if (!this.props.target.current) return null;
    return (
      <React.Fragment>
        {this.props.showContextMenu ? (
          <>
            <Overlay
              show={this.props.showContextMenu}
              target={this.props.target.current}
              placement="bottom"
              flip
              container={container}
              containerPadding={20}
            >
              <Popover
                className={"context_menu-fadeout"}
                onClick={this.props.fadeOutContextMenu}
              ></Popover>
            </Overlay>
            <Overlay
              show={this.props.showContextMenu}
              target={this.props.target.current}
              placement="bottom"
              flip
              container={container}
              containerPadding={20}
            >
              <Popover className={`context_menu-wrap ${theme}`}>
                <Popover.Content>
                  <div className={"global-drop_down__options"}>
                    <ul>
                      <li>
                        <a href={`${CUSTOM_PATH}${hash_id}`}>Открыть</a>
                      </li>
                      <li>
                        <NavLink
                          to={`${CUSTOM_PATH}${hash_id}`}
                          target="_blank"
                        >
                          Открыть в новой вкладке
                        </NavLink>
                      </li>
                      <li className={"context_menu-border"}> </li>
                      <li
                        onClick={() =>
                          this.copyResearch(hash_id, type, updateListResearch)
                        }
                      >
                        Копировать
                      </li>
                      {/* <li>Перенести в папку</li> */}
                      <li
                        onClick={(e) => {
                          setChangeTitle(hash_id);
                          fadeOutContextMenu();
                        }}
                      >
                        Переименовать
                      </li>
                      <li className={"context_menu-border"}> </li>
                      {/* <li>Скачать</li> */}
                      <li
                        onClick={() =>
                          this.deleteResearch(hash_id, type, updateListResearch)
                        }
                        className={"context_menu-delete_button"}
                      >
                        Удалить
                      </li>
                    </ul>
                  </div>
                </Popover.Content>
              </Popover>
            </Overlay>
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

export default connect("ProjectTheme", ContextMenuProjectDetailsMenu);
