import React from 'react'



const Container = (props) => {
  return (
    <div className={"container container-landing"}>
      {props.children}
    </div>
  )
}

export default Container