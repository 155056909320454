import { CatmullRomCurve3, BufferGeometry, Geometry, Line as ThreeLine, LineBasicMaterial, Vector3 } from 'three'
import { Object3D } from '../../Object3D'
import { addEventCustomListener } from '../../utils'

class Line extends Object3D {
  constructor({
    scene,
    enableShadows,
    color = '#000000',
    name = 'Line',
    vertices = [new Vector3(-1, 1, -1), new Vector3(1, 1, 1)],
    numPoints = vertices.length,
    linewidth,
    geometry,
    opacity,
    callback,
    parent
  } = {}) {
    super()
    if (!geometry) {
      this.numPoints = numPoints
      const points = [];
      for (let i = 0; i < vertices.length; i++) {
        const element = vertices[i];
        points.push(element);
      }
      this.geometry = new BufferGeometry().setFromPoints(points);
    } else {
      this.geometry = geometry
    }
    this.obj = new ThreeLine(this.geometry, new LineBasicMaterial({
      color: color,
      opacity: opacity,
      linewidth: linewidth
    }))
    addEventCustomListener(this.obj, callback)
    this.obj.castShadow = true
    this.setPosition(this.position)
    this.obj.castShadow = enableShadows
    this.obj.receiveShadow = enableShadows
    this.obj.name = name
    if (parent) {
      parent.add(this.obj)
    } else {
      this.addToScene(scene)
    }
  }
}

export default Line