import React from 'react'
import connect from 'storeon/react/connect'
import {NavLink} from 'react-router-dom'
import {PATH} from '../../const'
import HelloHeadLine from '../../Views/Layout/HelloHeadLine'
const ToMyRate = (props) => {
    let theme = props.ProjectTheme === 'dark' ? 'person_page' : 'light-person-page'
    return (
        <>
            <div className='personal-page_my-rate'>
                <p className='title-of-rate' >Здесь будут тарифы из лендинга!</p>
            </div>
        </>
    )
}
export default connect('ProjectTheme', ToMyRate)