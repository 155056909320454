import React from "react";
import Container from "../../common/Container";
import NewsItemHomePage from "../../common/NewsItemHomePage";
import connect from "storeon/react/connect";
import Api from "../../api";

const ApiContentNews = Api.ContentApi;
class NewsAddForNewsDetails extends React.Component {
  constructor(props) {
    super();
    this.state = {
      newsAddList: [],
      category: null,
      limitNewsAdd: 3,
    };
  }

  getAddNews = (category) => {
    ApiContentNews.getNewsContent({
      category: category.number,
      limit: this.state.limitNewsAdd,
    }).then((res) => {
      this.setState({
        newsAddList: res.results,
        category: category,
      });
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.NewsCategoryForNewsDetails !==
      this.props.NewsCategoryForNewsDetails
    ) {
      this.getAddNews(this.props.NewsCategoryForNewsDetails);
    }
  }

  render() {
    const { newsAddList, category } = this.state;
    return (
      <div className={"details_news-add_news"}>
        <Container>
          {category ? (
            <React.Fragment>
              <h2 className={"details_news-add_news-title"}>
                Еще из «{category.title}»
              </h2>
              <div className={"details_news-add_news-items"}>
                {newsAddList.map((el, i) => {
                  const { type, imgSrc, text, date, id } = el;
                  return (
                    <NewsItemHomePage
                      key={i}
                      id={id}
                      type={type}
                      imgSrc={imgSrc}
                      text={text}
                      date={date}
                    />
                  );
                })}
              </div>
            </React.Fragment>
          ) : null}
        </Container>
      </div>
    );
  }
}
export default connect("NewsCategoryForNewsDetails", NewsAddForNewsDetails);
