import { Vector3 } from "three"
import { DATA_OBJECT_SCENE } from '../../../const'
/**
 * проверит тип объекта
 * !сделал так потомучто колизия не всегда работает на внутренем объекте в куплинг сливе и по мимо того еще и 2 типов бывает
 * @param {*} collizionObject 
 */
const getTypeObject = (collizionObject) => {
	if (collizionObject.name === DATA_OBJECT_SCENE.couplingSleeveMUM.name) {
		return {
			type: collizionObject._customAttribute.type,
			obj: collizionObject
		}
	} else if (collizionObject.name === DATA_OBJECT_SCENE.couplingSleeveDAD.name) {
		return {
			type: collizionObject._customAttribute.type,
			obj: collizionObject
		}
	} else if (collizionObject.parent.name === DATA_OBJECT_SCENE.couplingSleeveDAD.name) {
		return {
			type: collizionObject.parent._customAttribute.type,
			obj: collizionObject.parent
		}
	} else if (collizionObject.parent.name === DATA_OBJECT_SCENE.couplingSleeveMUM.name) {
		return {
			type: collizionObject.parent._customAttribute.type,
			obj: collizionObject.parent
		}
	} else {
		return {
			type: collizionObject.children[0]._customAttribute.type,
			obj: collizionObject.children[0]
		}
	}
}
/**
 * в зависимости от типа объекта колизии либо склет их и в дальнейшем обновить стейт либо просто выйдет и вернет старый
 * @param {*} scene 
 * @param {*} dragElement 
 * @param {*} collizionElement 
 */
const setPositionsForCollizion = (scene, dragElement, collizionElement) => {
	let dragObject = scene.getObjectByProperty("uuid", dragElement)
	let collizionObject = scene.getObjectByProperty("uuid", collizionElement)
	let typeCollizionObject = getTypeObject(collizionObject)
	let typeDragObject = dragObject._customAttribute.type
	if (typeDragObject === typeCollizionObject.type) {
		return false
	} else {
		let attrCO = ''
		if (collizionObject.name !== DATA_OBJECT_SCENE.outerShaft.name) {
			attrCO = collizionObject.parent._customAttribute.direction
		} else {
			attrCO = collizionObject._customAttribute.direction
		}
		let deviceDragObject = dragObject.parent.parent
		let deviceCollizionObject = collizionObject.parent
		let dragObjectWorldPosition = new Vector3()
		let deviceForDragObjectWorldPosition = new Vector3()
		let collizionObjectWordPosition = new Vector3()
		collizionObject.getWorldPosition(collizionObjectWordPosition)
		deviceDragObject.getWorldPosition(deviceForDragObjectWorldPosition)
		dragObject.getWorldPosition(dragObjectWorldPosition)
		let distansXAxiasForCenterDevice = dragObjectWorldPosition.x - deviceForDragObjectWorldPosition.x
		let x = 0
		if (attrCO != 0) {
			x = collizionObjectWordPosition.x - distansXAxiasForCenterDevice
		} else {
			x = collizionObjectWordPosition.x - distansXAxiasForCenterDevice
		}
		deviceDragObject.position.setX(x)
		deviceDragObject.position.setZ(deviceCollizionObject.position.z)
		return true
	}

}
/**
 * обновляет стейт
 * @param {*} wiresData 
 * @param {*} componentData 
 * @param {*} couplingSleeve 
 * @returns newState
 */
const updateDataComponentsAndcouplingSleeve = (dataAllObjects, dataDrag) => {
	const DAD = "DAD"
	const MUM = "MUM"
	const { dragElement, collizionObject: collizionElement, scene } = dataDrag
	const setCouplingSleeve = (arr, dadObject, mumObject) => {
		return arr.map((el, i) => {
			if (el.id === dadObject.uuid) {
				return {
					...el,
					inDevice: {
						...el.inDevice,
						connection: {
							idDevice: dadObject.parent.parent.uuid,
							clema: dadObject.parent.uuid
						}
					},
					outDevice: {
						...el.outDevice,
						connection: {
							idDevice: mumObject.parent.parent.uuid,
							clema: mumObject.parent.uuid
						}
					}
				}
			}
			else {
				return el
			}
		})
	}
	let { wiresData, componentData, couplingSleeve } = dataAllObjects
	let dragObject = scene.getObjectByProperty("uuid", dragElement)
	let collizionObject = scene.getObjectByProperty("uuid", collizionElement)
	let typeCollizionObject = getTypeObject(collizionObject)
	let typeDragObject = dragObject._customAttribute.type

	if (typeCollizionObject.type === DAD) {
		couplingSleeve = setCouplingSleeve(couplingSleeve, typeCollizionObject.obj, dragObject)
	} else if (typeDragObject === DAD) {
		couplingSleeve = setCouplingSleeve(couplingSleeve, dragObject, typeCollizionObject.obj)
	}
	return { wiresData, componentData, couplingSleeve }
}

/**
 * собирает данные для изменения стейта
 * @param dataDrag
 * @param dataAllObjects
 */
export const couplingSleeveStateCreator = (dataDrag, dataAllObjects) => {
	const { dragElement, collizionObject: collizionElement, scene } = dataDrag
	let isSetPosition = setPositionsForCollizion(scene, dragElement, collizionElement)
	if (isSetPosition) {
		return updateDataComponentsAndcouplingSleeve(dataAllObjects, dataDrag)
	} else {
		return dataAllObjects
	}
}