import React from 'react';
import ParamsDeviceValue from '../../Views/Layout/ParamsDeviceValue';
import { Tabs as RBTabs, Tab } from 'react-bootstrap';
import CheckBox from '../../Views/Layout/CheckBox';
import InputValueData from '../../Views/Layout/InputValueData';
import isEqual from 'react-fast-compare';
import v4 from 'uuid/v4';
import Api from '../../api';
import SubmitForm from '../../common/SubmitForm';
import connect from 'storeon/react/connect';

const ApiResearch = Api.ResearchApi;
class AutomatComponentForSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabKey: 'settings',
      countItem: 0,
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Max. ток (А)', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Ток отсечки (А)', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Внутр. сопротивление', value: 9.9999e42 },
      ],
    };
  }

  submitData = () => {
    let { hash_id, type } = this.props.element;
    if (!hash_id) hash_id = this.props.element.id;
    const { paramsDeviceDataNamefields } = this.state;
    let params = {
      device_id: hash_id,
      params: {
        type: type,
        meta: {
          defaultSettings: {
            maxVoltageA: paramsDeviceDataNamefields[0].value,
            pinchCurrent: paramsDeviceDataNamefields[1].value,
            innerResist: paramsDeviceDataNamefields[2].value,
          },
        },
      },
    };
    this.props.dispatch('SidebarDataFromDevice', {
      isUpdate: true,
      hash_id: hash_id,
      data: params.params.meta,
    });
    SubmitForm(ApiResearch.putResearchUpdateDeviceData, params, this.successSubmit);
  };

  successSubmit = (response) => {
    console.log(response);
  };

  updateDeviceData = (data) => {
    this.setState(data, this.submitData);
  };

  defaultDeviceDataChange = (e) => {
    const { paramsDeviceDataNamefields } = this.state;
    let resultsUpdate = [];
    for (let i = 0; i < paramsDeviceDataNamefields.length; i++) {
      const element = paramsDeviceDataNamefields[i];
      if (element.id === e.target.id) {
        resultsUpdate.push({
          ...element,
          value: e.target.value,
        });
      } else {
        resultsUpdate.push(element);
      }
    }
    let newData = {
      paramsDeviceDataNamefields: resultsUpdate,
    };
    this.updateDeviceData(newData);
  };

  selectTab = (e) => {
    this.setState({
      activeTabKey: e,
    });
  };

  handlerNumberPhases = (e) => {};

  setDataDevice = (props) => {
    const { element } = props;
    const { defaultSettings, countItem } = element.meta;
    this.setState({
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Max. ток (А)', value: defaultSettings.maxVoltageA },
        { id: v4(), nameOfValue: 'Ток отсечки (А)', value: defaultSettings.pinchCurrent },
        { id: v4(), nameOfValue: 'Внутр. сопротивление', value: defaultSettings.innerResist },
      ],
      countItem: countItem,
    });
  };

  handlerCountAutomat = (e) => {};

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.element, prevProps.element)) {
      this.setDataDevice(this.props);
    }
  }

  componentDidMount() {
    this.setDataDevice(this.props);
  }

  render() {
    const { activeTabKey, paramsDeviceDataNamefields, countItem } = this.state;
    return (
      <React.Fragment>
        <span className={'vls-sidebar-device_title'}>АВТОМАТ</span>
        <RBTabs
          id="vls-sidebar_tabs_wraper"
          defaultActiveKey={'base'}
          activeKey={activeTabKey}
          onSelect={this.selectTab}
        >
          <Tab
            tabClassName={'vls-sidebar_tabs_wraper-tab vls-sidebar_tabs_wraper-tab_only'}
            eventKey="settings"
            title="Параметры"
          >
            <ParamsDeviceValue
              onChange={this.defaultDeviceDataChange}
              classNameForInput={'vls-sidebar_tabs_wraper-tab-input'}
              data={paramsDeviceDataNamefields}
            />
            <div className={'vls-input_data-automat'}>
              <InputValueData
                onChange={this.handlerCountAutomat}
                value={countItem}
                type={'number_custom'}
                firstDescription={'Количество элементов'}
              />
            </div>
            <div className="content-border-line"></div>
          </Tab>
        </RBTabs>
      </React.Fragment>
    );
  }
}
export default connect('SidebarDataFromDevice', AutomatComponentForSidebar);
