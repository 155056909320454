import React from 'react'
import Tabs from '../Tabs'
import ChooseRadioButton from '../ChooseRadioButton'

const DeviceSetting = (props) => {
  const { tabs, valueRadioButton, titleClassName } = props.data
  return (
    <>
      <div className='wrapper-device-setting'>
        <Tabs
          tabs={tabs}
          aboutOfTabs='Прибор'
          titleClassName={titleClassName}
          onClick={props.tabsHandler}
        />
        <ChooseRadioButton onChange={props.handlerChooseRadioButton} data={valueRadioButton} />
      </div>
      <div className='content-border-line'></div>
    </>
  )
}
export default DeviceSetting
