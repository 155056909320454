import React from 'react'
import AbstractObject from '../../abstract/AbstractObject'
import { Ring as AsbtractRing } from '../../../abstract/primitives/Ring'

/**
 * Примитивный объект - Ring.
 */
class Ring extends AbstractObject {
    constructor(props) {
        super()
        this.timeOutControls = null;
        this.state = {
            objectScene: null
        }
    }

    componentWillUnmount() {
        const { objectScene } = this.state;
        if (objectScene !== null) {
            // debugger
            objectScene.remove();
            clearTimeout(this.timeOutControls);
        }
    }

    componentDidMount() {
        const {
            // default
            scene,
            enableShadows,
            // custom
            innerRadius = 21,
            outerRadius = 17,
            phiSegments = 30,
            thetaLength = 6.3,//max
            thetaStart = 0,
            thetaSegments = 30,
            color = '#ffffff',
            texture = null,
            material = null,
            position = [0, 0, 0],
            rotation = [0, 0, 0],
            scale = [1, 1, 1],
            mesh = false,
            callback = null,
            parent,
            name = 'ring'
        } = this.props
        this.initComponent()
        let paramsCylinder = {
            scene,
            enableShadows,
            innerRadius,
            outerRadius,
            scale,
            phiSegments,
            thetaLength,
            thetaStart,
            thetaSegments,
            color,
            mesh,
            texture,
            material,
            position,
            rotation,
            callback,
            parent,
            name
        }
        let objectScene = new AsbtractRing(paramsCylinder)
        this.obj = objectScene.obj
        this.setState({
            objectScene: this.obj
        })
        this.onPropsUpdate({}, this.props)
        this.timeOutControls = setTimeout(() => {
            this.readyComponent()
        }, 1)
    }

    render() {
        const { color } = this.props
        this.setColor(color)
        return null
    }
}

export default Ring
