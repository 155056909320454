import React from 'react';
import {AmbientLight as ThreeAmbientLight} from "../../../abstract/Light";
import AbstractObject from "../../abstract/AbstractObject";

class AmbientLight extends AbstractObject {
    constructor (prosp) {
        super();
        this.timeOutControls = null
        this.state = {
           objectScene: null
        }
    }

    componentWillUnmount() {
        const { objectScene } = this.state;
        objectScene.removeObject(objectScene.parent)
        clearTimeout(this.timeOutControls)
    }

    componentDidMount() {
        const {
            // default
            scene,
            // custom
            color = 0xFFFFFF,
            intensity = 1,
        } = this.props;
        this.initComponent();
        let paramsToLight = {
            scene,
            color,
            intensity
        }
        let objectScene = new ThreeAmbientLight(paramsToLight)
        this.setState({
            objectScene: objectScene
        })
        this.timeOutControls = setTimeout(() => {
            this.readyComponent();
        }, 1);
    }
}

export default AmbientLight;