import { v4 } from "uuid";
/**
 * сериализует кривую в структуру точек
 * @param paramsArc
 */
export const addPointToRenderDots = (paramsArc) => {
  let {
    radiusAStartPoint,
    radiusAEndPoint,
    renderDots,
    verticesForArc,
    renderArc,
  } = paramsArc;
  renderArc[1].uuidDot = `${renderArc[1].uuidDot}`;
  renderArc[0].uuidDot = `${renderArc[0].uuidDot}`;
  renderArc[2].uuidDot = `${renderArc[2].uuidDot}`;

  const getIdEdge = (idConfig, data, iter, verticesForArcLength) => {
    if (iter === verticesForArcLength) {
      return [
        {
          aproximationVertices: [],
          color: "red",
          direction: 28.983979306529573,
          idEndLink: renderArc[2].uuidDot,
        },
        {
          aproximationVertices: [],
          color: "red",
          direction: 28.983979306529573,
          idEndLink: idConfig.prev,
        },
      ];
    } else if (iter === 0) {
      return [
        {
          aproximationVertices: [],
          color: "red",
          direction: 28.983979306529573,
          idEndLink: idConfig.next,
        },
        {
          aproximationVertices: [],
          color: "red",
          direction: 28.983979306529573,
          idEndLink: renderArc[1].uuidDot,
        },
      ];
    } else {
      return [
        {
          aproximationVertices: [],
          color: "red",
          direction: 28.983979306529573,
          idEndLink: idConfig.prev,
        },
        {
          aproximationVertices: [],
          color: "red",
          direction: 28.983979306529573,
          idEndLink: idConfig.next,
        },
      ];
    }
  };
  const getIdLinks = (idConfig, data, iter, verticesForArcLength) => {
    if (iter === verticesForArcLength) {
      return [renderArc[2].uuidDot, idConfig.prev];
    } else if (iter === 0) {
      return [renderArc[1].uuidDot, idConfig.next];
    } else {
      return [idConfig.prev, idConfig.next];
    }
  };
  let idConfig = {
    prev: `${v4()}`,
    current: `${v4()}`,
    next: `${v4()}`,
  };
  const setNextStepForConfig = () => {
    idConfig = {
      prev: idConfig.current,
      current: idConfig.next,
      next: `${v4()}`,
    };
  };
  let temporaryDot = verticesForArc.map((el, i) => {
    setNextStepForConfig();
    return {
      coeficentR: 0,
      edge: getIdEdge(idConfig, el, i, verticesForArc.length - 1),
      linkToStraight: getIdLinks(idConfig, el, i, verticesForArc.length - 1),
      position: el,
      uuidDot: idConfig.current,
      type: "temporary",
      x: el.x,
      y: el.z,
    };
  });
  const setLinksForRequiredDot = (data, idEndLink, linkToStraight) => {
    let edge = [...data.edge];
    let link = [...data.linkToStraight];
    edge.push({
      aproximationVertices: [],
      color: "red",
      direction: 0,
      idEndLink: idEndLink,
    });
    link.push(linkToStraight);
    return { edge: edge, link: link };
  };
  let result = {
    type: "arc",
    radiusAStartPoint: radiusAStartPoint,
    radiusAEndPoint: radiusAEndPoint,
    startPoint: renderArc[1],
    centerPoint: renderArc[0],
    endPoint: renderArc[2],
    verticesForArc: verticesForArc,
    temporaryDot: temporaryDot,
    startPointLinks: setLinksForRequiredDot(
      renderArc[1],
      temporaryDot[0].uuidDot,
      temporaryDot[0].uuidDot
    ),
    endPointLinks: setLinksForRequiredDot(
      renderArc[2],
      temporaryDot[temporaryDot.length - 1].uuidDot,
      temporaryDot[temporaryDot.length - 1].uuidDot
    ),
    //! начальная и последняя вершины это старт поин и енд поинт
    linkConnetedDot: [],
  };
  console.log("result", result);
  renderDots.push(result);
  return renderDots;
};
