import React from 'react'
import { Switch, Route } from "react-router-dom";
import PersonalPageRoutes from '../PersonalPageRoutes'
import OuterPageRoutes from '../OuterPageRoutes'

class RootRoutes extends React.Component {
    constructor(props) {
        super()
    }

    render() {
        return (
            <Switch>
                <Route path={"/personal-page"} component={PersonalPageRoutes}/>
                <Route path={"/"} component={OuterPageRoutes}/>
            </Switch>
        )
    }
}

export default RootRoutes