export let SidebarDataFromDevice = store => {
    // Initial state
    let defaultState = {}
    store.on('@init', () => ({
        SidebarDataFromDevice: defaultState
    }))
    // Reducers returns only changed part of the state
    store.on("SidebarDataFromDevice", (SidebarDataFromDevice, data) => {
        if (data === "clear") {
            return { SidebarDataFromDevice: defaultState };
        }
        return {
            SidebarDataFromDevice: data
        };
    });
}