import React from 'react';
import LayoutVLS from '../../Views/Layout/LayoutVLS'
import Layout from '../../Views/Layout';

const VirtualLibraryStandPage = (props) => {
	return (
		<Layout isVisibleHeader={false} title={"Стенд"}>
			<LayoutVLS {...props}/>
		</Layout>
	)
}

export default VirtualLibraryStandPage
