import PromiseWorker from '../../WorkerUtils'


export const SercheSubareas = async (data) => {
  return new PromiseWorker({
    url: "/web-worker/SercheSubareas.js"
  }).init().invoke(data).then((el) => {
    return el
  }).catch(err => err)
};
