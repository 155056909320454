import { BoxBufferGeometry, Mesh, MeshBasicMaterial } from "three";
import { Object3D } from "../../Object3D";
import { addEventCustomListener } from "../../utils";

class Box extends Object3D {
  constructor({
    scene,
    enableShadows,
    scale,
    color = "#000000",
    position = [0, 0, 0],
    rotation = [0, 0, 0],
    texture,
    uuid,
    customAttribute,
    callback,
    material,
    parent,
    name,
  } = {}) {
    super();
    const geometry = new BoxBufferGeometry(...scale);
    let objectMaterial = {
      color: color
    }
    if (material) {
      objectMaterial = material
    } else {
      this.setColor(color);
    }
    this.material = new MeshBasicMaterial(objectMaterial);
    this.obj = new Mesh(geometry, this.material);
    this.obj.material.needsUpdate = true;
    addEventCustomListener(this.obj, callback);
    this.setPosition(position);
    this.setRotation(rotation);
    this.obj.name = name;
    this.obj.castShadow = enableShadows;
    this.obj.receiveShadow = enableShadows;
    this.obj.uuid = uuid ? uuid : this.obj.uuid;
    this.obj._customAttribute = customAttribute
    if (texture) {
      this.setTexture(texture)
    }
    if (parent) {
      parent.add(this.obj);
    } else {
      this.addToScene(scene);
    }
  }
}

export default Box;
