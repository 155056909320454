// localStorage.getItem('theme') === null ? localStorage.setItem('theme', 'dark') : false;
const getTheme = () => {
	if(localStorage.getItem('theme') === null){
		localStorage.setItem('theme', 'dark')
	}else{
		localStorage.getItem('theme');
	}
	return localStorage.getItem('theme')
}
export let ProjectTheme = (store) => {
	// Initial state
	store.on("@init", () => ({ ProjectTheme: getTheme() }));
	// Reducers returns only changed part of the state
	store.on("ProjectTheme", ({ProjectTheme}, data) => {
	  if(data === 'dark'){
		data = 'light'
		localStorage.setItem('theme', data)
		return {ProjectTheme: data}
	  }else{
		data = 'dark'
		localStorage.setItem('theme', data)
		return {ProjectTheme: data}
	  }
	})};