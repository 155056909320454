import React from 'react';
import ParamsDeviceValue from '../../Views/Layout/ParamsDeviceValue';
import { Tabs as RBTabs, Tab } from 'react-bootstrap';
import TableDataForOscilloscope from './TableDataForOscilloscope';
import CheckBox from '../../Views/Layout/CheckBox';
import ButtonMain from '../../Views/Layout/ButtonMain';
import isEqual from 'react-fast-compare';
import v4 from 'uuid/v4';
import Api from '../../api';
import SubmitForm from '../../common/SubmitForm';
import connect from 'storeon/react/connect';

const ApiResearch = Api.ResearchApi;
class OscilloscopeComponentForSidebar extends React.Component {
  constructor(porps) {
    super();
    this.state = {
      activeTabKey: 'base',
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Масштаб по времени', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Min. по Y:', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Max. по Y:', value: 9.9999e42 },
      ],
      autoformat: false,
      autoComlite: false,
      measurementQuantity: [],
    };
  }

  submitData = () => {
    let { hash_id, type } = this.props.element;
    if (!hash_id) hash_id = this.props.element.id;
    const { paramsDeviceDataNamefields, autoformat, autoComlite, measurementQuantity } = this.state;
    let params = {
      device_id: hash_id,
      params: {
        type: type,
        meta: {
          defaultSettings: {
            timeScale: paramsDeviceDataNamefields[0].value,
            minY: paramsDeviceDataNamefields[1].value,
            maxY: paramsDeviceDataNamefields[2].value,
          },
          autoformat: autoformat,
          autoComlite: autoComlite,
          measurementQuantity: measurementQuantity,
        },
      },
    };
    this.props.dispatch('SidebarDataFromDevice', {
      isUpdate: true,
      hash_id: hash_id,
      data: params.params.meta,
    });
    SubmitForm(ApiResearch.putResearchUpdateDeviceData, params, this.successSubmit);
  };

  successSubmit = (response) => {
    console.log(response);
  };

  updateDeviceData = (data) => {
    this.setState(data, this.submitData);
  };

  autoComliteChange = (e) => {
    let data = {
      autoComlite: e.target.checked,
    };
    this.updateDeviceData(data);
  };

  autoFormatChange = (e) => {
    let data = {
      autoformat: e.target.checked,
    };
    this.updateDeviceData(data);
  };

  defaultDeviceDataChange = (e) => {
    const { paramsDeviceDataNamefields } = this.state;
    let resultsUpdate = [];
    for (let i = 0; i < paramsDeviceDataNamefields.length; i++) {
      const element = paramsDeviceDataNamefields[i];
      if (element.id === e.target.id) {
        resultsUpdate.push({
          ...element,
          value: e.target.value,
        });
      } else {
        resultsUpdate.push(element);
      }
    }
    let newData = {
      paramsDeviceDataNamefields: resultsUpdate,
    };
    this.updateDeviceData(newData);
  };

  changeTable = (data) => {
    this.updateDeviceData({
      measurementQuantity: {
        voltage: data.tabs[0].active,
        pairPotential: data.tabs[1].active,
        tableData: data.dataForTable,
      },
    });
  };

  setDataDevice = (props) => {
    const { element } = props;
    const { defaultSettings, autoformat, autoComlite, measurementQuantity } = element.meta;
    this.setState({
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Масштаб по времени', value: defaultSettings.timeScale },
        { id: v4(), nameOfValue: 'Min. по Y:', value: defaultSettings.minY },
        { id: v4(), nameOfValue: 'Max. по Y:', value: defaultSettings.maxY },
      ],
      autoformat: autoformat,
      autoComlite: autoComlite,
      measurementQuantity: measurementQuantity,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.element, prevProps.element)) {
      this.setDataDevice(this.props);
    }
  }

  componentDidMount() {
    this.setDataDevice(this.props);
  }

  render() {
    const { activeTabKey, paramsDeviceDataNamefields, autoformat, autoComlite } = this.state;
    const { element } = this.props;
    const { measurementQuantity } = element.meta;
    return (
      <React.Fragment>
        <span className={'vls-sidebar-device_title'}>ОСЦИЛЛОГРАФ</span>
        <RBTabs
          id="vls-sidebar_tabs_wraper"
          defaultActiveKey={'base'}
          activeKey={activeTabKey}
          onSelect={this.selectTab}
        >
          <Tab
            tabClassName={'vls-sidebar_tabs_wraper-tab vls-sidebar_tabs_wraper-tab_only'}
            eventKey="base"
            title="Параметры"
          >
            <ParamsDeviceValue
              onChange={this.defaultDeviceDataChange}
              classNameForInput={'vls-sidebar_tabs_wraper-tab-input'}
              data={paramsDeviceDataNamefields}
            />
            <TableDataForOscilloscope
              changeTable={this.changeTable}
              measurementQuantity={measurementQuantity}
            />
            <div className={'vls-checkbox_data-oscilloscope'}>
              <CheckBox
                checked={autoformat}
                onChange={this.autoFormatChange}
                label={'Автоформатирование'}
                helpText={'(Учитываются данные min. и max. по оси Y)'}
              />
            </div>
            <div className={'vls-checkbox_data-oscilloscope'}>
              <CheckBox
                onChange={this.autoComliteChange}
                checked={autoComlite}
                label={'Режим с запоминанием'}
              />
            </div>

            <div className="content-border-line vls-table_data-oscilloscope-line_end_out"></div>
            <span className={'vls-table_data-oscilloscope-btn-help_text'}>
              Открыть окно с более подробными параметрами осциллографа
            </span>
            <ButtonMain className={'button__option-menu vls-table_data-oscilloscope-btn'}>
              Подробный экран
            </ButtonMain>
          </Tab>
        </RBTabs>
      </React.Fragment>
    );
  }
}
export default connect('SidebarDataFromDevice', OscilloscopeComponentForSidebar);
