import { BufferGeometry, Line, LineBasicMaterial, MeshLambertMaterial, Raycaster, Vector3 } from 'three'
import { DATA_OBJECT_SCENE } from '../../../constants'
import { testRay } from '../../utils/RayTest'


class AimControl {
  constructor (camera, direction, paramsEvent) {
    // debugger
    this.camera = camera
    this.scene = camera.parent
    this.direction = direction
    this.setParamsEvent = (name, value) => paramsEvent.set(name, value)
    this.getParamsEvent = (name) => paramsEvent.get(name)
    this.collisionResults = []
    this.checkRaycaster()
  }

  checkNewOrOldEvents = (object) => {
    if (this.getParamsEvent('typeObjectAction') === object.name) {
      return true
    } else {
      if (this.getParamsEvent('timeOutTogo')) clearTimeout(this.getParamsEvent('timeOutTogo'))
      if (this.getParamsEvent('intervalGo')) clearInterval(this.getParamsEvent('intervalGo'))
      this.setParamsEvent('eventsActions', null)
      this.setParamsEvent('intervalGo', null)
      this.setParamsEvent('timeOutTogo', null)
      this.setParamsEvent('objectActions', object)
      this.setParamsEvent('typeObjectAction', object.name)
      return false
    }
  }

  newEventsCreate = () => {
    let intervalGoLoop = this.getParamsEvent('intervalGo')
    clearInterval(intervalGoLoop)
    let dataControls = DATA_OBJECT_SCENE.GVRNaviControls
    let object = this.getParamsEvent('objectActions')
    switch (object.name) {
      case dataControls.ringControlGo.name:
        this.selectedObject(object, dataControls.ringControlGo.name)
        this.startGoSteps(object)
        break
      case dataControls.buttonController.name:
        this.selectedObject(object, dataControls.ringControlGo.name)
        object.vrListener()
        break
      default:
        let intervalLoop = this.getParamsEvent('intervalGo')
        clearInterval(intervalLoop)
        this.unSelectedObject()
        this.setParamsEvent('intervalGo', null)
    }
  }

  unSelectedObject = () => {
    let oldMaterial = this.getParamsEvent('oldDataMaterial')
    let oldScale = this.getParamsEvent('oldScale')
    let object = this.getParamsEvent('selectedObject')
    if (object && oldMaterial) {
      object.material = oldMaterial
      this.setParamsEvent('oldDataMaterial', null)
    }
    if (object && oldScale) {
      object.scale.set(oldScale.x, oldScale.y, oldScale.z)
      this.setParamsEvent('oldScale', null)
    }
  }

  selectedObject = (object) => {
    let dataControls = DATA_OBJECT_SCENE.GVRNaviControls
    let oldScale = this.getParamsEvent('oldScale')
    let oldMaterial = this.getParamsEvent('oldDataMaterial')

    this.setParamsEvent('selectedObject', object)
    if (!oldMaterial) {
      delete object.material._listeners
      delete object.material.program
      let material = new MeshLambertMaterial({ ...object.material })
      this.setParamsEvent('oldDataMaterial', material)
    }
    if (!oldScale) {
      this.setParamsEvent('oldScale', { x: object.scale.x, y: object.scale.y, z: object.scale.z })
      if (object.name === dataControls.ringControlGo.name) {
        object.scale.set(object.scale.x, object.scale.y, 2)
      }
    }

    object.material.opacity = 0.6
  }

  startGoSteps = (object) => {
    let intervalGoLoop = this.getParamsEvent('intervalGo')
    clearInterval(intervalGoLoop)
    intervalGoLoop = setInterval(() => {
      this.setParamsEvent('intervalGo', intervalGoLoop)
      const direction = new Vector3
      this.camera.getWorldDirection(direction)
      this.camera.position.addScaledVector(new Vector3(direction.x, 0, direction.z), this.getParamsEvent('speed') / 2)
      object.parent.position.addScaledVector(new Vector3(direction.x, 0, direction.z), this.getParamsEvent('speed') / 2)
    }, 1000 / 16, false)
  }

  oldEventsContinue = () => {
    // let timeOutOld = this.getParamsEvent('timeOutTogo')
    // clearTimeout( timeOutOld )
    // timeOutOld = setTimeout(() => {
    //   console.log(timeOutOld, 'timeOut old')
    //   this.setParamsEvent('timeOutTogo', timeOutOld)
    // let params = this.getParamsEvent('objectActions')
    // this.startGo(params)
    // }, 3000 - timeOutOld)
  }

  aimToControls = (collisionResults) => {
    if (!collisionResults) return
    if (collisionResults.object.type === 'Line') return
    let isNewEvents = this.checkNewOrOldEvents(collisionResults.object)
    if (isNewEvents) {
      this.newEventsCreate()
    } else {
      this.oldEventsContinue()
    }
  }

  checkRaycaster = () => {
    let vectorPositionRayMesh = new Vector3()
    let vectorPositionRayCamera = new Vector3()
    // debugger
    let sercheListElement = this.scene.children.filter(el => el.name === 'wrapController')
    vectorPositionRayMesh = this.camera.children[0].getWorldPosition(vectorPositionRayMesh)
    this.camera.getWorldPosition(vectorPositionRayCamera)
    let ray = new Raycaster(vectorPositionRayMesh, this.direction, this.camera.near, this.camera.far)
    this.collisionResults = ray.intersectObjects(sercheListElement, true)
    // this.testRay([vectorPositionRayCamera, vectorPositionRayMesh])
    this.aimToControls(this.collisionResults[0] ? this.collisionResults[0] : null)
  }
}

export default AimControl