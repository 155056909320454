import React from 'react'
import Button from '../Button'

 
const Tabs = ({ addClassWrapper = '', tabs = [], aboutOfTabs, titleClassName = '', onClick = (e) => { }, helpText = false }) => {
  if (!tabs.length) return null
  return (
    <div className={`wrapper-tabs ${addClassWrapper}`}>
      <p className={titleClassName}>{aboutOfTabs}</p>
      <div className='tabs_custom'>
        <div className='tabs_button-group'>
          {tabs.map((item, index) => {
            return (
              <Button
                onClick={onClick}
                key={index} name={item.name}
                className={item.isActive || item.active ? 'active-btn' : 'tab'}
              />
            )
          })}
        </div>
        {
          helpText ? (
            <span className={"tabs-help_text"}>
              {helpText}
            </span>
          ) : null
        }
      </div>
    </div>
  )
}
export default Tabs