export let StartOrStopVLS = store => {
    // Initial state
    let defaultState = {
        socketState: 'stop',
        linkScene: null
    }
    store.on('@init', () => ({
        StartOrStopVLS: defaultState
    }))
    // Reducers returns only changed part of the state
    store.on("StartOrStopVLS", (StartOrStopVLS, data) => {
        if (data === "clear") {
            return { StartOrStopVLS: defaultState };
        }
        let selected = data
        return {
            StartOrStopVLS: selected
        };
    });
}