export let LoadDevice = store => {
	// Initial state
	store.on('@init', () => ({LoadDevice: false}))
	// Reducers returns only changed part of the state
	store.on("LoadDevice", ({ LoadDevice }, data) => {
		if (data === "clear") {
			return { LoadDevice: false };
		}
		return {
			LoadDevice: data
		};
	});
}