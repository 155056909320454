import {
    Vector3,
    CatmullRomCurve3,
    Geometry,
    MeshBasicMaterial,
    Line
} from 'three'
import { DATA_OBJECT_SCENE } from '../../../const'


/**
 * Начертит временную линию показывающюю направление построения кривой
 * @param {*} startVector - vector2
 * @param {*} stopVector - vector2
 * @param {*} scene 
 */
export const createArcPreStraight = (startVector, stopVector, scene, clearScene) => {
    clearScene(scene)
    let vertices = [
        new Vector3(startVector.x, -1, startVector.y),
        new Vector3(stopVector.x, -1, stopVector.y)
    ]
    let numPoints = vertices.length;
    let spline = new CatmullRomCurve3(vertices);
    let geometry = new Geometry();
    let splinePoints = spline.getPoints(numPoints);
    geometry.lineDistancesNeedUpdate = true;
    for (let i = 0; i < splinePoints.length; i++) {
        geometry.vertices.push(splinePoints[i]);
    }
    let material = new MeshBasicMaterial({
        color: "blue"
    })
    let mesh = new Line(geometry, material)
    mesh.name = DATA_OBJECT_SCENE.line_direction_for_create_arc.name

    scene.add(mesh)
}