export default {
  zoomOrhoganal: 0.2,
  mounted: false,
  linkToSceneObject: null,
  isDisconnectedWire: false,
  callStackLoadGLTF: [],
  stateGLTFLoader: "default",
  visibleModal: {
    isVisible: false,
    data: {
      content: null,
    },
  },
  visibleToolTipVLSDevice: {
    isVisible: false,
    data: null,
  },
  visibleContextMenu: {
    isVisible: false,
    data: null,
  },
  isUpdateLine: false,
  color: "#999999",
  dataAllObjects: {
    wiresData: [],
    componentData: [],
    couplingSleeve: [],
  },
  objectsDrag: null,
  lineData: null,
  contextElementData: null,
  isLoaded: false,
  isUpdate: false
};