import React from 'react'

class ModalCreatorPFML extends React.Component {
  constructor(props) {
    super()
    this.state = {
      show: false,
      content: null
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        show: this.props.show,
        content: this.props.content,
      })
    }
  }

  componentDidMount() {
    const {
      show = false,
      content = null,
    } = this.props
    this.setState({
      show: show,
      content: content,
    })
  }

  render() {
    const {
      content,
      show
    } = this.state;
    return (
      <React.Fragment>
        {show ? content : null}
      </React.Fragment>
    );
  }
}

export default ModalCreatorPFML