import React from 'react';
import {
  AmbientLight,
  Canvas,
  DirectionalLight,
  Grid,
  PerspectiveCamera,
  OrthoganalControl,
  OrbitControls,
  Raycast,
  Box,
  DebugMode,
} from '../../gengine';
// import ContextMenu from "../../common/ContextMenu";
import connect from 'storeon/react/connect';
import DotCreator from '../CustomComponentToCanvas/DotCreator';
import StraightCreator from '../CustomComponentToCanvas/StraightCreator';
import CurveCreator from '../CustomComponentToCanvas/CurveCreator';
import SubregionCreator from '../CustomComponentToCanvas/SubregionCreator';
import SectionsCreator from '../CustomComponentToCanvas/SectionsCreator';
import CheckPositionMoseToScene from '../CustomComponentToCanvas/CheckPositionMoseToScene';
import SyncGeometry from '../CustomComponentToCanvas/SyncGeometry';
import { DATA_OBJECT_SCENE } from '../../const';
import Triangulation from '../CustomComponentToCanvas/Triangulation';
import ZoomBlock from '../../common/ZoomBlock';
import isEqual from 'react-fast-compare';
import testData from './testData';
import AbstractPFML from './AbstractPFML';

class CanvasPFML extends AbstractPFML {
  constructor(props) {
    super();
    this.toolEnum = {
      straight: 'straight',
      dot: 'dot',
      arc: 'arc',
      subareas: 'subareas',
      section: 'section',
    };
    this.state = {
      heightCanvas: null,
      widthCanvas: null,
      tool: null,
      uslesCheckPosMouse: true,
      activeDot: null,
      activeDotForCreatePrimitive: [],
      sectionTemporaryData: null,
      oldActiveDotForCreateStaight: false,
      renderDots: [],
      subareas: [],
      sections: [],
      renderStraight: [],
      renderArc: [],
      verticesForArc: [],
      dataFraments: {},
      eventClickFullForSubareas: null,
      enableCurveDrawing: false,
      straightCreatorFirst: false,
      positionDotMoveMouse: null,
      straightTargetMoveOrOut: null,
      arcTargetMoveOrOut: null,
      isAddLastDotForArc: false,
      clickEvt: false,
      paramsPrimitiveData: {
        //!тут праметры из сайд бара при добавлнении новых не забудь дописать их в контейнер
        linkToDotsChangeCheckbox: false,
      },
    };
  }

  getResultObject = () => {
    const { renderDots, subareas, sections } = this.state;
    return JSON.stringify({
      renderDots,
      subareas,
      sections,
    });
  };

  initSetDataModel = (data) => {
    if (data) {
      this.setState(
        {
          renderDots: data.renderDots,
          subareas: data.subareas,
          sections: data.sections,
        },
        () => {
          if (data) this.parseDataForCounterResearchEnv(data);
        },
      );
    }
  };

  /**
   * Используется для визуальной части не должен перерендеривать в случае прохода хоть в 1 if
   * так сделано из-за того что тут можно контролировать ререндер
   * @param {*} nextProps
   * @param {*} nextState
   * @param {*} nextContext
   */
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { activeDotForCreatePrimitive, tool, activeDot } = nextState;
    let { activeDot: thisActiveDot, oldActiveDotForCreateStaight } = this.state;
    // проверяет какие пользователь выбрал точки для построения прямой
    if (activeDotForCreatePrimitive) {
      if (oldActiveDotForCreateStaight) {
        //!выделение снято
        this.setDefaultColorDot(oldActiveDotForCreateStaight);
      }
      activeDotForCreatePrimitive.forEach((el, i) => {
        //!выделенные точки
        this.setSelectedColorDot(el);
      });
    }
    //проверит обновился ли выделеный примитив точка
    if (thisActiveDot !== activeDot) {
      //!выделение снято
      if (thisActiveDot) {
        this.setDefaultColorDot(thisActiveDot);
      }
      //!выделенная точка
      if (!tool) {
        if (thisActiveDot) {
          this.setSelectedColorDot(activeDot);
          this.props.dispatch('DotData', {
            x: activeDot._customAttribute.x,
            y: activeDot._customAttribute.y,
            r: activeDot._customAttribute.coeficentR,
            r: activeDot._customAttribute.coeficentR,
            id: activeDot._customAttribute.countId,
          });
        }
      }
      return true;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.model !== prevProps.model) {
      if (this.props.model) this.initSetDataModel(this.props.model);
    }
    if (this.props.SavePFML?.isSave) {
      const { renderDots, subareas, sections } = this.state;
      let data = {
        renderDots,
        subareas,
        sections,
      };
      this.props.SavePFML.saveFunCallback(data);
      this.props.dispatch('SavePFML', {
        isSave: false,
        saveFunCallback: () => {},
      });
    }
    if (this.props.CreateSubareasState) {
      this.addSubareas(this.props.CreateSubareasState);
      this.props.dispatch('CreateSubareasState', 'clear');
    }
    if (this.props.DataPrimitiveFromSidebar !== prevProps.DataPrimitiveFromSidebar) {
      this.clearMemory();
      this.setState(
        {
          renderArc: [],
          renderStraight: [],
          uslesCheckPosMouse: this.props.DataPrimitiveFromSidebar.linkToDotsChangeCheckbox,
          activeDotForCreatePrimitive: [],
        },
        () => {
          this.setPrimitiveData(this.props.DataPrimitiveFromSidebar);
        },
      );
      return true;
    }
    //Проверяет обновился ли выбранный инструмент
    if (!isEqual(this.props.SelectedToolPFML, prevProps.SelectedToolPFML)) {
      let tool = this.props.SelectedToolPFML;
      this.clearMemory();
      let uslesCheckPosMouse = this.getUslesCheckPosMouse(tool);
      this.setState({
        tool: tool,
        uslesCheckPosMouse: uslesCheckPosMouse,
        activeDotForCreatePrimitive: [],
        straightCreatorFirst: false,
        straightTargetMoveOrOut: null,
        eventClickFullForSubareas: null,
      });
      return true;
    }
    //Проверяет обновился ли массив с точками
    if (this.state.renderDots !== prevState.renderDots) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.initSetDataModel(false);
  }
  //todo: сделай метод обновляющий количество мусора на сцене для сайдбара
  render() {
    const {
      renderDots,
      renderArc,
      subareas,
      sections,
      renderStraight,
      straightCreatorFirst,
      positionDotMoveMouse,
      tool,
      activeDotForCreatePrimitive,
      clickEvt,
      eventClickFullForSubareas,
      uslesCheckPosMouse,
      linkToSceneObject,
      zoomOrhoganal = 0.2,
      widthCanvas,
    } = this.state;
    // console.log('PFML', linkToSceneObject)
    // console.log('renderDots', JSON.stringify(renderDots));
    // console.log(this.getResultObject());
    // console.log('renderDots', renderDots)
    // console.log('subareas', JSON.stringify(subareas))
    return (
      <React.Fragment>
        <ZoomBlock
        // clickSetZoomInc={this.clickSetZoomInc}
        // clickSetZoomDec={this.clickSetZoomDec}
        />
        <Canvas
          // className={'canvas_render'}
          getScene={!linkToSceneObject ? this.getLinkScene : null}
          getSize={!widthCanvas ? this.getSizeCanvasDomEl : null}
          enableShadows={false}
          id={'canvas'}
          debug={false}
          fullscreen={true}
        >
          <PerspectiveCamera
            orthoganalEnable={true}
            position={[0, 20, 0]}
            zoomOrhoganal={zoomOrhoganal}
          >
            <OrthoganalControl />
            {/* <OrbitControls /> */}
            <Raycast />
            <CheckPositionMoseToScene
              usles={uslesCheckPosMouse}
              getPositionMove={this.getPositionCanvasMove}
              getPositionClick={this.getPositionCanvasClick}
            />
          </PerspectiveCamera>
          <DotCreator
            addEventListenerDotWrapperClick={this.addEventListenerDotWrapperClick}
            addEventListenerDotWrapperMove={this.addEventListenerDotWrapperMove}
            addEventListenerDotWrapperOut={this.addEventListenerDotWrapperOut}
            renderDots={renderDots}
            renderArc={renderArc}
          />
          <StraightCreator renderDots={renderDots} />
          <CurveCreator
            addEventListenerDotWrapperClick={this.addEventListenerDotWrapperClick}
            addEventListenerDotWrapperMove={this.addEventListenerDotWrapperMove}
            addEventListenerDotWrapperOut={this.addEventListenerDotWrapperOut}
            renderDots={renderDots}
            renderArc={renderArc}
          />
          <SubregionCreator
            subareas={subareas}
            renderDots={renderDots}
            clickEvt={clickEvt}
            setSinglClick={this.setSinglClick}
            eventClickFullForSubareas={eventClickFullForSubareas}
          />
          <SectionsCreator sections={sections} />
          {/* <Triangulation subareas={subareas} /> */}
          {/* <SyncGeometry name={DATA_OBJECT_SCENE.PRIMITIVE_LINE.name}/> */}
          <SyncGeometry name={DATA_OBJECT_SCENE.PRIMITIVE_DOT_APROXIMATION.name} />
          <Box
            position={[0, -100, 0]}
            scale={[100000, 0.1, 1000000]}
            material={{ color: '#F2F3F7' }}
          />
          <AmbientLight position={[0, 10, 0]} intensity={1} />
        </Canvas>
      </React.Fragment>
    );
  }
}

export default connect(
  'DataPrimitiveFromSidebar',
  'DataCountAllPrimitiveScenePFML',
  'DeleteElementData',
  'ElementsContext',
  'ElementsPFML',
  'SelectedToolPFML',
  'DotData',
  'CreateSubareasState',
  'SavePFML',
  CanvasPFML,
);
