import React from 'react';
import { Toast } from 'react-bootstrap';

const defaultContent = {
  contentHeader: null,
  contentBody: null,
};
const defaultMethod = () => {};
class Notification extends React.Component {
  constructor(props) {
    super();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      return true;
    }
  }

  render() {
    const { content = defaultContent, isShow, close = defaultMethod } = this.props;
    const { contentHeader, contentBody } = content;
    console.log('content', content);
    return (
      <div>
        <Toast
          show={isShow}
          autohide={true}
          onClose={close}
          animation={true}
          style={{
            position: 'absolute',
            top: 5,
            right: 0,
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '2',
            letterSpacing: 0.2,
            color: '#25282b',
            zIndex: 999999999999,
          }}
        >
          {contentHeader ? <Toast.Header>{contentHeader}</Toast.Header> : null}
          <Toast.Body>{contentBody}</Toast.Body>
        </Toast>
      </div>
    );
  }
}

export default Notification;
