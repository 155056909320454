import React, { forwardRef } from "react";
import uuid from 'uuid'

const RadioButtonCustom = ({
  disabled = false,
  id = uuid.v4(),
  onClick = (e) => { },
  onChange = (e) => { },
  checked = false,
  label,
  classNameLabel,
  ...props }) => {

  return (
    <div className={`default_components-radio_btn`}>
      <input
        type="radio"
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
        {...props}
      />
      <label className={classNameLabel} htmlFor={id}>

        {label}
      </label>
    </div>
  );
};
export default RadioButtonCustom;
