import React from 'react';
import Delaunator from 'delaunator';
import serializers from '../../../utils/SerializedObject';
import {
  BufferGeometry,
  MathUtils,
  Mesh,
  MeshLambertMaterial,
  Shape,
  ExtrudeGeometry,
  Points,
  PointsMaterial,
} from 'three';

class Triangulation extends React.Component {
  constructor(props) {
    super();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.init(this.props.subareas);
    }
  }

  init = (data) => {
    const { scene } = this.props;
    let allPoints = serializers.GetAllPointsFromSubareas(data);
    for (const key in allPoints) {
      if (Object.hasOwnProperty.call(allPoints, key)) {
        const element = allPoints[key];
        let geometry = new BufferGeometry().setFromPoints(element);
        let arr = [];
        let indexDelaunay = Delaunator.from(
          element.map((v) => {
            return [v.x, v.y];
          }),
        );
        let meshIndex = []; // delaunay index => three.js index
        for (let i = 0; i < indexDelaunay.triangles.length; i++) {
          meshIndex.push(indexDelaunay.triangles[i]);
        }
        geometry.setIndex(meshIndex); // add three.js index to the existing geometry
        geometry.computeVertexNormals();

        let mesh = new Mesh(
          geometry, // re-use the existing geometry
          new MeshLambertMaterial({ color: 'purple', wireframe: true }),
        );
        var cloud = new Points(geometry, new PointsMaterial({ color: 0x99ccff, size: 10 }));
        cloud.position.setY(5);
        cloud.rotation.set(MathUtils.degToRad(90), 0, 0);
        scene.add(cloud);
        mesh.position.setY(5);
        mesh.rotation.set(MathUtils.degToRad(90), 0, 0);
        scene.add(mesh);
      }
    }
  };

  componentDidMount() {
    this.init(this.props.subareas);
  }

  render() {
    return null;
  }
}

export default Triangulation;
