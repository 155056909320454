import React from 'react'
import { Popover, Overlay } from "react-bootstrap";
import image from '../../assets/images/default_tooltip_device.png'

const ToolTipPanelDevice = (props) => {
    const { target, data = {}, showhelper } = props
    let container = document.getElementById("root");
    return (
        <Overlay
            show={true}
            target={target}
            placement="right-start"
            container={container}
        >
            <Popover className={"tooltip_panel_device"}>
                <Popover.Content>
                    <div className={"tooltip_panel_device-wrap"}>
                        <span className={"tooltip_panel_device-wrap-title"}>{data.title}</span>
                    </div>
                </Popover.Content>
            </Popover>
        </Overlay>
    )
}

export default ToolTipPanelDevice