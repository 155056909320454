import BaseApi from "@garpix/base-api";
import { serializedGetDeviceForScene, serilizedGetDeviceData } from './serializers'

class ResearchApi extends BaseApi {

  /**
 * проверит на обновление компоненты девайса 
 * @param {*} data 
 */
  checkUpdateDeviceComponents = async (data) => {
    const { hash_id, params } = data
    const res = await this.post(`/research/${hash_id}/devices_components/`, params);
    const results = res.data;
    return results;
  };
  /**
   * обновил данные по девайсу
   * @param {*} data 
   */
  putResearchUpdateDeviceData = async (data) => {
    const { device_id, params } = data
    const res = await this.put(`/research/device/${device_id}/`, params);
    const results = res.data;
    return results;
  };
  /**
   * обновить(сохранит новые данные о стенде)
   * @param {*} dataForApi 
   */
  putResearch = async (dataForApi) => {
    const { hash_id, params } = dataForApi
    const res = await this.put(`/research/${hash_id}/`, params);
    const data = res.data;
    return data;
  };
  /**
   * получит данные для конкретного расчета
   * @param {*} hash_id 
   * @param {*} params 
   */
  getResearch = async (hash_id, params = {}) => {
    const res = await this.get(`/research/${hash_id}/`, params);
    const data = res.data;
    data.devices = serializedGetDeviceForScene(data)
    return data;
  };
  /**
   * Создаст расчет на основе входных данных принимает тип, название и ид проекта
   * @param {*} params 
   */
  createResearch = async (params = {}) => {
    const res = await this.post(`/research/`, params);
    const data = res.data;
    return data;
  };
  /**
   * копирует расчет 
   * @param {*} hash_id 
   * @param {*} params 
   */
  copyResearch = async (hash_id, params = {}) => {
    const res = await this.post(`/research/${hash_id}/copy/`, params);
    const data = res.data;
    return data;
  };
  /**
   * удалит расчет
   * @param {*} hash_id 
   * @param {*} params 
   */
  deleteResearch = async (hash_id, params = {}) => {
    const res = await this.delete(`/research/${hash_id}/`, params);
    const data = res.data;
    return data;
  };
  deleteDeviceFormResearch = async (hash_id, params = {}) => {
    const res = await this.delete(`/research/device/${hash_id}/`, params);
    const data = res.data;
    return data;
  };
  /**
   * Обновит 1 или несколько параметров нрасчета на пример название
   * @param {*} hash_id 
   * @param {*} params 
   */
  patchResearch = async (hash_id, params = {}) => {
    const res = await this.patch(`/research/${hash_id}/`, params);
    const data = res.data;
    return data;
  };
  /**
   * добавит девайс на сцену 
   * @param {*} hash_id 
   * @param {*} params device_type: "type"
   */
  addDeviceToScene = async (hash_id, params = {}) => {
    const res = await this.post(`/research/${hash_id}/add_device/`, params);
    const data = res.data;
    let resultData = serilizedGetDeviceData(data);
    return resultData
  };
  /**
   * удалит все приборы со сцены
   * @param {*} hash_id 
   * @param {*} params 
   */
  dropSceneResearch = async (hash_id, params = {}) => {
    const res = await this.post(`/research/${hash_id}/drop_scene/`, params);
    const data = res.data;
    return data;
  };
}

export default ResearchApi;
