import { Object3D } from '../../Object3D'
import ThreeOrthoganalControl from './ThreeOrthoganalControl'


class OrthoganalControl extends Object3D {
  constructor({
    canvasDomElement,
    camera,
    // custom
    minDistance = 3,
    maxDistance = 20,
    type
  }) {
    super()

    this.obj = new ThreeOrthoganalControl(camera, canvasDomElement, type);

    this.obj.rotateSpeed = 0.3;
    this.obj.zoomSpeed = 0.9;

    this.obj.minDistance = minDistance;
    this.obj.maxDistance = maxDistance;
  }

  resize = () => {
    const {
      fullscreen = true,
    } = this.props;
    if (fullscreen) {
      this.obj.aspect = window.innerWidth / window.innerHeight;
      this.obj.updateProjectionMatrix();
    }
  };
}

export default OrthoganalControl