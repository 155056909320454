import {
  CatmullRomCurve3,
  Geometry,
  Vector3,
  Line,
  MeshBasicMaterial,
  Vector2,
} from "three"
import { DATA_OBJECT_SCENE } from "../../../const"
import { lineIntersection, sercheColizionStraightToPFML } from '../../../utils'

/**
 * создаем меш который будет проверять колизии
 * @param {*} objSelectedDot 
 * @param {*} objMovedDot 
 */
export const creatorCheckedMesh = (objSelectedDot, objMovedDot, scene, renderDots) => {
  let resultIntesect = true
  if (!objSelectedDot.position) {
    objSelectedDot.position = { x: objSelectedDot.x, y: objSelectedDot.y }
  }
  if(!objMovedDot) return true
  if (!objMovedDot.position) {
    objMovedDot.position = { x: objMovedDot.x, y: objMovedDot.y }
  }
  let vertices = [
    new Vector3(objSelectedDot.position.x, 2, objSelectedDot.position.z),
    new Vector3(objMovedDot.position.x, 2, objMovedDot.position.z)
  ]
  let numPoints = vertices.length;
  let spline = new CatmullRomCurve3(vertices);
  let geometry = new Geometry();
  let splinePoints = spline.getPoints(numPoints);
  geometry.lineDistancesNeedUpdate = true;
  for (let i = 0; i < splinePoints.length; i++) {
    geometry.vertices.push(splinePoints[i]);
  }
  let material = new MeshBasicMaterial({
    color: "blue"
  })
  let mesh = new Line(geometry, material)
  mesh.name = DATA_OBJECT_SCENE.PRIMITIVE_LINE_CHECKED.name
  scene.add(mesh)
  let startPoint = new Vector2(objSelectedDot.position.x, objSelectedDot.position.z)
  let endPoint = new Vector2(objMovedDot.position.x, objMovedDot.position.z)
  let startPointLine = new Vector2()
  let endPointLine = new Vector2()
  const setMaterial = (color) => {
    if (color === "blue") {
      mesh.material.color.set('blue')
    } else if ("green") {
      resultIntesect = false
      mesh.material.color.set('green')
    }
  }
  const coords = {
    startPoint,
    endPoint,
    startPointLine,
    endPointLine,
  }
  sercheColizionStraightToPFML(renderDots, setMaterial, coords)
  return resultIntesect
}