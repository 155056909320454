import React from 'react'
import ConfirmComponent from '../ConfirmComponent'
import connect from "storeon/react/connect";

const BtnComponent = (props) => {
    const callbackOk = (e) => {
        props.CreateSubareas.callback()
        let dataStates = props.agregationsStateModal()
        props.dispatch("CreateSubareasState", {
            numberSubareas: dataStates.numberSubareas,
            paramsDeviceDataNamefields: dataStates.paramsDeviceDataNamefields,
            typeEnv: dataStates.selectTypeEnvData.value,
            nameMaterial: dataStates.selectSelectParamsName.value,
            relativeMagneticPermeability: dataStates.magnetizationCoords[0].value,
            coordsVectorMagnetic: [
                dataStates.magnetizationCoords[1].value,
                dataStates.magnetizationCoords[2].value
            ],
            numberOfConductorsInSubArea: dataStates.allParams[0].value,
            conductorCurrent: dataStates.allParams[1].value,
            currentDensityInSubdomain: dataStates.allParams[2].value,
            subdomainArea: dataStates.allParams[3].value,
            amperage: dataStates.voltage,
            currentDensity: dataStates.voltageDensity,
            verticesForCreateMesh: props.verticesForCreateMesh
        })
        props.dispatch("CreateSubareas", "clear")
    }
    const callbackCancellation = (e) => {
        props.dispatch("CreateSubareas", "clear")
    }
    return (
        <div className={"modal-params_subareas-wrapper-content-btn_confirm_component"}>
            <ConfirmComponent success={"Сохранить"} callbackOk={callbackOk} callbackCancellation={callbackCancellation} />
        </div>
    )
}

export default connect("CreateSubareas", "CreateSubareasState", BtnComponent)