import React from "react";
import PersonalPageLayout from "../../Views/Layout/PersonalPageLayout";
import ButtonMain from "../../Views/Layout/ButtonMain";
import InputForm from '../../Views/Layout/InputForm';
import SubmitForm from '../../common/SubmitForm'
import RenderProjectDetails from './RenderProjectDetails'
import LoaderSpiner from "../../common/LoaderSpiner";
import Api from '../../api'

const ApiProject = Api.ProjectApi
const ApiResearchApi = Api.ResearchApi

class ProjectDetailsFromPersonalPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      isShowModal: false,
      contentModal: <></>,
      createResearchData: {
        title: null,
        type: null
      },
      projectData: {
        infoProject: {
          description: null,
          group: null,
          hash_id: null,
          email: null,
          first_name: null,
          last_name: null,
          patronymic: null,
          phone: null,
          username: null,
          permissions: null,
          title: null,
        },
        VLS: [],
        PFML: [],
      },
    };
  }

  getVLSData = (res) => {
    const { projectData } = this.state;
    this.setState({
      projectData: {
        ...projectData,
        VLS: res
      }
    });
  }

  getPFMLData = (res) => {
    const { projectData } = this.state;
    this.setState({
      projectData: {
        ...projectData,
        PFML: res
      }
    });
  }

  submitFormAddProject = (e) => {
    e.preventDefault()
    e.persist()
    const { createResearchData, projectData } = this.state
    let params = {
      type_name: createResearchData.type,
      title: createResearchData.title,
      project_hash: projectData.infoProject.hash_id
    }
    this.closeModal()
    SubmitForm(
      ApiResearchApi.createResearch,
      params,
      (res) => {
        this.updateResearchData()

      },
      (err) => {
      }
    )
  }

  changeResearchName = (value) => {
    const { createResearchData } = this.state
    this.setState({
      createResearchData: {
        ...createResearchData,
        title: value
      }
    })
  }

  createContentModal = () => {
    const { createResearchData } = this.state
    return (
      <div className={"add_project-wrap"}>
        <h3 className={"add_project-wrap-title"}>
          {createResearchData.type === "VLS" ? "Создать магнитное поле" : "Создать цепь приборов"}
        </h3>
        <form onSubmit={this.submitFormAddProject} className={"add_project-wrap-form"}>
          <InputForm name={"name"} onChange={(ev) => this.changeResearchName(ev.target.value)} placeholder={"Название расчета"} />
          <ButtonMain type={"submit"} className={"add_project-wrap-form-btn"}>Создать</ButtonMain>
        </form>
      </div>
    )
  }

  createPFML = () => {
    const { createResearchData } = this.state
    this.setState({
      isShowModal: true,
      contentModal: this.createContentModal(),
      createResearchData: {
        ...createResearchData,
        type: "PFML"
      }
    })
  }

  createVLS = () => {
    const { createResearchData } = this.state
    this.setState({
      isShowModal: true,
      contentModal: this.createContentModal(),
      createResearchData: {
        ...createResearchData,
        type: "VLS"
      }
    })
  }

  closeModal = () => {
    this.setState({
      isShowModal: false,
      contentModal: <></>
    })
  }

  updateResearchData = (type) => {
    this.getResearcheData(type)
  }

  asyncMethodForGetProject = (id) => {
    let projectData = ApiProject.getProjectData(id, {})
    let vls = ApiProject.getProjectVLS(id, {})
    let pfml = ApiProject.getProjectPFML(id, {})
    Promise.all([projectData, pfml, vls]).then((res) => {
      const { projectData } = this.state
      let resInfoProject = res[0]
      let resPFMLProject = res[1]
      let resVLSProject = res[2]
      this.setState({
        projectData: {
          ...projectData,
          infoProject: {
            description: resInfoProject.description,
            group: resInfoProject.group,
            hash_id: resInfoProject.hash_id,
            email: resInfoProject.owner_user.email,
            first_name: resInfoProject.owner_user.first_name,
            last_name: resInfoProject.owner_user.last_name,
            patronymic: resInfoProject.owner_user.patronymic,
            phone: resInfoProject.owner_user.phone,
            username: resInfoProject.owner_user.username,
            permissions: resInfoProject.permissions,
            title: resInfoProject.title,
          },
          PFML: resPFMLProject,
          VLS: resVLSProject,
        }
      })
    })
  }

  getResearcheData = (type) => {
    const { id } = this.props.match.params;
    const { projectData } = this.state
    if (id) {
      let newData = {
        PFML: null,
        VLS: null
      }
      if (type === "PFML") {
        newData.VLS = projectData.VLS
      } else if (type === "VLS") {
        newData.PFML = projectData.PFML
      }
      this.setState({
        projectData: {
          ...projectData,
          PFML: newData.PFML,
          VLS: newData.VLS,
        }
      }, this.asyncMethodForGetProject(id))
    }
  }

  componentDidMount() {
    this.getResearcheData()
  }

  render() {
    const { projectData, isShowModal, contentModal } = this.state
    return (
      <React.Fragment>
        <PersonalPageLayout closed={this.closeModal} isShowModal={isShowModal} content={contentModal}>
          {projectData ?
            <RenderProjectDetails
              createPFML={this.createPFML}
              createVLS={this.createVLS}
              projectData={projectData}
              updateListResearch={this.updateResearchData}
            />
            : <LoaderSpiner />
          }
        </PersonalPageLayout>
      </React.Fragment>
    );
  }
}
export default ProjectDetailsFromPersonalPage;