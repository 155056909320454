const getComponentData = (data) => {
  let result = []
  for (const key in data.component) {
    if (data.component.hasOwnProperty(key)) {
      const element = data.component[key];
      result.push({
        ...element
      })
    }
  }
  return {
    ...data,
    component: result
  }
}
export const serializedGetDeviceForScene = (data) => {
  let newdata = {
    wiresData: [],
    componentData: [],
    couplingSleeve: [],
  }
  for (const key in data.devices) {
    if (data.devices.hasOwnProperty(key)) {
      if (data.devices.hasOwnProperty(key)) {
        const element = data.devices[key];
        if (element.type === 'wire') {
          element.id = key
          element.hash_id = key
          newdata.wiresData.push(element)
        } else if (element.type === "dadCouplingSleeve" || element.type === "mumCouplingSleeve") {
          element.id = key
          element.hash_id = key
          newdata.couplingSleeve.push(element)
        } else {
          element.id = key
          element.hash_id = key
          newdata.componentData.push(getComponentData(element))
        }
      }
    }
  }
  return newdata
}

export const serilizedGetDeviceData = (data) => {
  let { device_data } = data
  let resultComponentDevice = []

  for (const key in device_data.component) {
    if (device_data.component.hasOwnProperty(key)) {
      const element = device_data.component[key];
      resultComponentDevice.push(element)
    }
  }
  return {
    ...data,
    device_data: {
      ...device_data,
      component: resultComponentDevice
    },
  }
}