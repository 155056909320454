import React from 'react'
import ProjectToPersonalPage from '../ProjectToPersonalPage'
import { LIMIT_GET_PROJECTS } from '../../const'
import Api from '../../api'

const ApiProject = Api.ProjectApi
class SearchProject extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchWords: '',
      countProject: '',
    }
  }
  getCountProject = (coutProject) =>{
    this.setState({countProject: coutProject})
  }
  searchProject = (e) => {
    this.setState({ searchWords: e.target.value })
  }
  getProjects = () =>{
    return ApiProject.getProjects({limit: LIMIT_GET_PROJECTS, search: this.state.searchWords, is_trash: false})
  }
  render() {
    const {searchWords, countProject} = this.state
    let { updatecomponent, stopupdate } = this.props
    updatecomponent = updatecomponent === "true"
    return (
      <>
        <div className={'wrapper-search-page'}>
          <div className='personal_page-search-project'>
            <button className='search-project-result'></button>
            <input 
              onChange={this.searchProject} 
              name='serchProject' type='text' 
              placeholder='Поиск' 
              defaultValue={this.state.searchWords} />
          </div>
          <p 
            className='personal_page-done-search'>
              Найдено проектов: 
              <span className='count-projects'>
                {countProject}
              </span>
          </p>
          <ProjectToPersonalPage
            typePage={'search'}
            api={this.getProjects}
            updateComponent={updatecomponent}
            stopUpdate={stopupdate}
            getCountProject={this.getCountProject}
            limit={LIMIT_GET_PROJECTS}
            searchWords={searchWords}
          />
        </div>
      </>
    )
  }
}
export default SearchProject;