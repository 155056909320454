import React from "react";
import { Route, Switch } from "react-router-dom";
import RootRoutes from './Routes/RootRoutes'
import { PATH } from "./const";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorizationToken: false,
    };
  }

  componentDidMount() {
    let token = localStorage.getItem("authorization");
    this.setState({
      authorizationToken: token,
    });
  }

  render() {
    return (
      <Switch>
        <Route component={RootRoutes} path={"/"} />
      </Switch>
    );
  }
}

export default App;
