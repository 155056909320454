export const generateSaveData = (data, type_name, title, saveType) => {
    const { componentData, couplingSleeve, map, wiresData } = data
    let status = "research"
    let outer_terminals = []
    let deviceData = {}
    const getComponent = (data) => {
        // debugger
        if (!data) return {}
        if (!Array.isArray(data)) return {}
        // type: "outerShaft"
        let result = {}
        // debugger
        data.forEach((el, i) => {
            result[el.id] = el
        })
        return result
    }
    const setDeviceData = (arr = []) => {
        arr.map((el, i) => {
            deviceData[el.id] = {
                ...el,
                component: getComponent(el.component),
                hash_id: el.id,
                device_type: el.type
            }
        })
    }
    const getOutTerminalFromWire = (arr) => {
        arr.forEach((el, i) => {
            if (el.status === "outerTerminal") {
                status = "device"
                outer_terminals.push(el.id)
            }
        })
    }
    setDeviceData(componentData)
    setDeviceData(couplingSleeve)
    setDeviceData(wiresData)
    if (saveType === "saveAs") {
        getOutTerminalFromWire(wiresData)
        if(status === "research") {
            return "errorSaveAs"
        }
    }
    console.log(`outer_terminals`, outer_terminals)
    return {
        "devices": deviceData,
        "map": map,
        "type_name": type_name,
        "status": status,
        "outer_terminals": outer_terminals,
        "title": title
    }
}