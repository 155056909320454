import React from "react";
import {
  LineDashedMaterial,
  CircleGeometry,
  Line as ThreeLine,
  Vector3,
} from "three";
import {
  AbstractObject,
  GenericGroupObject,
  Ring,
  Line,
} from "../../../gengine";
import { DATA_OBJECT_SCENE } from "../../../const";
import { DoubleSide } from "three";
import serializers from "../../../utils/SerializedObject";

class DotCreator extends AbstractObject {
  constructor(props) {
    super();
    this.coeficentParentScale = 20;
    this.defaultOffset = 0.05;
    this.state = {
      renderElements: [],
      countLine: 0,
    };
  }

  renderLine = (el, id) => {
    const { vertices = [new Vector3(-1, 1, -1), new Vector3(1, 1, 1)] } = el;
    return (
      <Line
        {...this.props}
        vertices={vertices}
        key={id}
        name={DATA_OBJECT_SCENE.ARC_FRAGMETS.name}
      />
    );
  };

  customMeshForRing = () => {
    let dashMaterial = new LineDashedMaterial({
      color: 0xee6666,
      dashSize: (1 * Math.PI * 10) / 40,
      gapSize: (1 * Math.PI * 10) / 40,
      linewidth: 2,
    });
    let circGeom = new CircleGeometry(10, 60);
    circGeom.vertices.shift();
    let circ = new ThreeLine(circGeom, dashMaterial);
    return circ.computeLineDistances();
  };

  render() {
    const {
      addEventListenerDotWrapperClick,
      addEventListenerDotWrapperMove,
      addEventListenerDotWrapperOut,
    } = this.props;
    return serializers
      .getDotFromPrimitivesGraf(this.props.renderDots)
      .map((el, i) => {
        if (!el.position) {
          el.position = { x: el.x, y: el.y };
        }
        return (
          <GenericGroupObject
            {...this.props}
            position={[el.position.x, -1, el.position.z]}
            key={el.uuidDot}
            color={"blue"}
            opacity={0.3}
            // visible={true}
            name={DATA_OBJECT_SCENE.dotGroup.name}
            scale={[
              this.defaultOffset * el.coeficentR * this.coeficentParentScale,
              this.defaultOffset * el.coeficentR,
              this.defaultOffset * el.coeficentR * this.coeficentParentScale,
            ]}
            callback={[
              {
                type: "click",
                event: addEventListenerDotWrapperClick,
              },
              {
                type: "mousemove",
                event: addEventListenerDotWrapperMove,
              },
              {
                type: "pointerout",
                event: addEventListenerDotWrapperOut,
              },
            ]}
            uuid={el.uuidDot}
            customAttribute={{
              countId: el.countId,
              coeficentR: el.coeficentR,
              x: el.position.x,
              y: el.position.z,
              edge: el.edge ? el.edge : [],
              linkToStraight: el.linkToStraight ? el.linkToStraight : [],
            }}
          >
            <Ring
              outerRadius={20}
              scale={[
                this.defaultOffset * el.coeficentR,
                this.defaultOffset * el.coeficentR,
                this.defaultOffset * el.coeficentR,
              ]}
              rotation={[90, 0, 0]}
              position={[0, -1, 0]}
              material={{
                side: DoubleSide,
                color: "#f54248",
              }}
              mesh={this.customMeshForRing()}
            />
            <Ring
              scale={[0.01, 0.01, 0.01]}
              rotation={[90, 0, 0]}
              position={[0, -1, 0]}
              material={{
                side: DoubleSide,
                color: "#d1db16",
              }}
            />
          </GenericGroupObject>
        );
      });
  }
}

export default DotCreator;
