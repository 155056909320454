import React from 'react'
import Section from './Section'
import InputValueData from '../../Views/Layout/InputValueData'
import RadioButtonCustom from '../../Views/Layout/RadioButtonCustom'

class VoltageParams extends React.Component {
  constructor(props) {
    super()
  }

  changeCoordMagnetic = (e) => {
    const { allParams, setAllParams } = this.props
    let newData = []
    allParams.forEach(element => {
      if (element.id === e.target.id) {
        newData.push({
          ...element,
          value: e.target.value
        })
      } else {
        newData.push(element)
      }
    });
    setAllParams(newData)
  }

  disabledFields = (data) => {
    const { allParams, setAllParams } = this.props
    let newData = []
    allParams.forEach((element, i) => {
      if ("voltage" === data) {
        if (i === 0 || i === 1) {
          newData.push({
            ...element,
            disabled: false
          })
        } else {
          newData.push({
            ...element,
            disabled: true
          })
        }
      } else if ("voltageDensity" === data) {
        if (i === 2) {
          newData.push({
            ...element,
            disabled: false
          })
        } else {
          newData.push({
            ...element,
            disabled: true
          })
        }
      }
    });
    setAllParams(newData)
  }

  changeVoltage = (e) => {
    const { setVoltage, setVoltageDensity } = this.props
    this.disabledFields("voltage")
    setVoltageDensity(!e.target.checked)
    setVoltage(e.target.checked)
  }

  changeDensityVoltage = (e) => {
    const { setVoltageDensity, setVoltage } = this.props
    this.disabledFields("voltageDensity")
    setVoltage(!e.target.checked)
    setVoltageDensity(e.target.checked)
  }

  render() {
    const { allParams, voltage, voltageDensity } = this.props
    return (
      <Section>
        <div className={"modal-params_subareas-wrapper-content-section-voltage_params"}>
          <div className={"modal-params_subareas-wrapper-content-section-voltage_params-all_params"}>
            {
              allParams.map((el, i) => {
                return <div key={i} className={"modal-params_subareas-wrapper-content-section-voltage_params-all_params-fields"}>
                  <span>{el.title}</span>
                  <InputValueData
                    id={el.id}
                    disabled={el.disabled}
                    firstItemClassName={" modal-params_subareas-wrapper-content-section-voltage_params-all_params-input"}
                    className={'data '}
                    value={el.value}
                    firstDescription={el.label}
                    onChange={this.changeCoordMagnetic}
                  />
                </div>
              })
            }
          </div>
          <div className={"modal-params_subareas-wrapper-content-section-voltage_params-source_pole"}>
            <h4 className={"modal-params_subareas-wrapper-content-section-voltage_params-source_pole-title"}>Источник поля:</h4>
            <div className={"modal-params_subareas-wrapper-content-section-voltage_params-source_pole-wrapper_radio"}>
              <RadioButtonCustom
                className={"modal-params_subareas-wrapper-content-section-voltage_params-source_pole-wrapper_radio-input"}
                label={"Ток"}
                checked={voltage}
                onChange={this.changeVoltage}
              />
              <RadioButtonCustom
                className={"modal-params_subareas-wrapper-content-section-voltage_params-source_pole-wrapper_radio-input"}
                label={"Плотность тока"}
                checked={voltageDensity}
                onChange={this.changeDensityVoltage}
              />
            </div>
          </div>
        </div>
      </Section>
    )
  }
}

export default VoltageParams