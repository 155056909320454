import React from 'react'
import InputValueData from '../InputValueData'

const ValueForPosition = (props) => {
  return (
    <>
      <div className='sidebar__value'>
        {props.data.map((item, index) => {
          return (
            <div key={index} className='sidebar__value__input'>
              <p>{item}</p>
              <InputValueData
                className='data'
                defaultValue={1}
              />
            </div>
          )
        })}

      </div>
      {
        !props.isLine ? (
          null
        ) : <div className='content-border-line'></div>
      }

    </>
  )
}
export default ValueForPosition
