import {
  Vector3,
  CatmullRomCurve3,
  Geometry,
  LineBasicMaterial,
  MeshBasicMaterial,
  BufferGeometry,
  Line,
  Vector2
} from 'three'
import { DATA_OBJECT_SCENE } from '../../../const'
import { sercheColizionStraightToPFML } from '../../../utils'

/**
 * Начертит временную кривую на основе вершин собранных путем апроксимации
 * @param {*} startVector - vector2
 * @param {*} stopVector - vector2
 * @param {*} scene 
 */
export const createArcAllPoints = (
  startPoint,
  endPoint,
  verticesFromAproximationArcscene = [],
  scene,
  clearScene,
  heightCanvas,
  widthCanvas,
  renderArc,
  renderDots
) => { 
  clearScene(scene)
  if (verticesFromAproximationArcscene.length) {
    let vertices = [new Vector3(endPoint.x - widthCanvas, 0, endPoint.y - heightCanvas)]
    let newVertices = verticesFromAproximationArcscene.map(el => {
      return new Vector3(el.x - widthCanvas, 0, el.y - heightCanvas)
    })
    vertices.push(...newVertices)
    vertices.push(new Vector3(startPoint.x - widthCanvas, 0, startPoint.y - heightCanvas))
    let numPoints = vertices.length;
    let spline = new CatmullRomCurve3(vertices, false, undefined, 1);
    let points = spline.getPoints(numPoints);
    let geometry = new Geometry().setFromPoints(points);
    geometry.computeBoundingSphere()
    let material = new LineBasicMaterial({
      color: "blue"
    })
    let mesh = new Line(geometry, material)
    mesh.name = DATA_OBJECT_SCENE.line_direction_for_create_arc.name
    let outChecked = false

    const setMaterial = (color) => {
      console.log('color', color)
      if (color === "blue") {
        mesh.material.color.set('blue')
      } else if ("green") {
        outChecked = true
        mesh.material.color.set('green')
      }
    }
    vertices.forEach((el, i) => {
      if (!outChecked) {
        let nextIndex = i++
        let index = (nextIndex) <= (vertices.length - 2) ? i : nextIndex
        let startPoint = new Vector2(el.x, el.z)
        let endPoint = new Vector2(
          vertices[index].x,
          vertices[index].z
        )
        let startPointLine = new Vector2()
        let endPointLine = new Vector2()
        const coords = {
          startPoint,
          endPoint,
          startPointLine,
          endPointLine
        }
        sercheColizionStraightToPFML(renderDots, setMaterial, coords)
      }
    });
    scene.add(mesh)
    return !outChecked ? vertices : outChecked
  }

}