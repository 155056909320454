/**
 *@return вернет объект с геометрией для линий и данные для рендера сфер
 *@param data {wiresData,	target, hex}
 */
export const setColorWireData = ({wiresData,	target, hex}) => {
	let idContextElement = target.uuid;
	let newWireData = wiresData;
	let indexWireData = null;
	let indexVertices = null;
	wiresData.map((el,i) => {
		el.vertices.map((el2, iter2) => {
			if(el2.id === idContextElement) {
				indexVertices = iter2;
				indexWireData = i;
			}
		})
	});
	newWireData[indexWireData].color = hex;
	return newWireData
};
