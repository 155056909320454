import React, { useState } from 'react'
import PersonalAreaPage from '../PersonalAreaPage'
import SettingProfile from '../../components/SettingProfile'

const PersonalPageSettingProfile = (props) => {
	return(
		<PersonalAreaPage title={'ЛИЧНЫЙ КАБИНЕТ'} className={'personal-page'} styleWelcome='center'>
			<SettingProfile />
		</PersonalAreaPage > 
	)
}
export default PersonalPageSettingProfile