import React from 'react';

const TogglerSwitcher = ({ firstTogglerValue = 'a', secondTogglerValue = 'a', className = 'toggle-switcher', active, onChange = () => { } }) => {

  return (
    <div onChange={onChange} className={className}>
      <p className={active ? '' : 'active'}>{firstTogglerValue}</p>
      <label className="toggle-switcher__toggle-control">
        <input onChange={(e) => {}} checked={active} className='toggle-control__input' type="checkbox" />
        <span className="toggle-control"></span>
      </label>
      <p className={active ? 'active' : ''}>{secondTogglerValue}</p>
    </div>
  )
}
export default TogglerSwitcher;

