import { Vector3 } from "three"

const sercheValueByProperty = (property = "id", value = "1", nodes = []) => {
  let result = null
  nodes.filter((el, i) => {
    if (el[property] === value) {
      result = el
    }
    if (el.type === "arc") {
      if (el.startPoint[property] === value) {
        result = el.startPoint
      }
      if (el.centerPoint[property] === value) {
        result = el.centerPoint
      }
      if (el.endPoint[property] === value) {
        result = el.endPoint
      }
    }
  })
  return result
}

const collectorStraight = (dot, dots) => {
  let newRenderStraight = []
  let newRenderDotAproximation = []
  dot.edge.forEach((edgeItem) => {
    edgeItem.aproximationVertices.forEach((itemAproximation) => {
      //! по усмолчанию работаем в 2д координатах по сем у ребра есть только x and y
      newRenderDotAproximation.push({
        position: new Vector3(itemAproximation.x, dot.position.y, itemAproximation.y),
        uuid: itemAproximation.uuid,
        linkDot: dot.uuidDot
      })
    })
  })
  dot.linkToStraight.forEach((elLink) => {
    let dotsPos = sercheValueByProperty("uuidDot", elLink, dots)
    let edgeForElLink = sercheValueByProperty("idEndLink", elLink, dot.edge)
    newRenderStraight.push({
      vertices: [
        new Vector3(dot.position.x, -1, dot.position.z),
        new Vector3(dotsPos.x, -1, dotsPos.y)
      ],
      color: edgeForElLink.color,
      direction: edgeForElLink.direction
    })
  })
  return [newRenderStraight, newRenderDotAproximation]
}
export const parseDotForStraight = (dots = []) => {
  let renderStraight = []
  let renderDotAproximation = []

  dots.forEach((dot, i) => {
    if (dot.type === "arc") {
      let [newRenderStraightStartPoint, newRenderDotAproximationStartPoint] = collectorStraight(dot.startPoint, dots)
      let [newRenderStraightCenterPoint, newRenderDotAproximationCenterPoint] = collectorStraight(dot.centerPoint, dots)
      let [newRenderStraightEndPoint, newRenderDotAproximationEndPoint] = collectorStraight(dot.endPoint, dots)
      //!пушим все что нашли по начальной точке кривой
      renderStraight.push(...newRenderStraightStartPoint)
      renderDotAproximation.push(...newRenderDotAproximationStartPoint)
      //!пушим все что нашли по центральной точке кривой
      renderStraight.push(...newRenderStraightCenterPoint)
      renderDotAproximation.push(...newRenderDotAproximationCenterPoint)
      //!пушим все что нашли по последней точке кривой
      renderStraight.push(...newRenderStraightEndPoint)
      renderDotAproximation.push(...newRenderDotAproximationEndPoint)
    } else {
      let [newRenderStraight, newRenderDotAproximation] = collectorStraight(dot, dots)
      renderStraight.push(...newRenderStraight)
      renderDotAproximation.push(...newRenderDotAproximation)
    }

  })

  return {
    renderStraight,
    renderDotAproximation
  }
}