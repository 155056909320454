export let NewsCategoryForNewsDetails = (store) => {
  store.on("@init", () => ({ NewsCategoryForNewsDetails: null }));
  store.on(
    "NewsCategoryForNewsDetails",
    ({ NewsCategoryForNewsDetails }, data) => {
      if (data === "clear") {
        return { NewsCategoryForNewsDetails: null };
      }
      return {
        NewsCategoryForNewsDetails: data,
      };
    }
  );
};
