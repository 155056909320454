import { PlaneGeometry, DoubleSide, Mesh, MeshLambertMaterial, MeshBasicMaterial, Vector3, BoxBufferGeometry } from "three";
import { Object3D } from "../../Object3D";
import { addEventCustomListener } from "../../utils";

class Plane extends Object3D {
  constructor({
    scene,
    enableShadows,
    width,
    height,
    segments,
    color = "#000000",
    position = [0, 0, 0],
    rotation = [0, 0, 0],
    scale,
    texture,
    uuid,
    pivot,
    customAttribute,
    callback,
    material,
    parent,
    name,
  } = {}) {
    super();
    const geometry = new PlaneGeometry(width, height, segments);
    let objectMaterial = {
      color: color,
      side: DoubleSide
    }
    if (material) {
      objectMaterial = { ...material, side: DoubleSide }
    } else {
      this.setColor(color);
    }
    this.material = new MeshBasicMaterial(objectMaterial);

    this.obj = new Mesh(geometry, this.material);
    if (pivot) {
      let pivotGlobalPosition = new Vector3(pivot[0], pivot[1], pivot[2]);
      this.obj.pivot = pivotGlobalPosition;
      this.createVisiblePivot(this.obj.localToWorld(pivotGlobalPosition));
    }
    addEventCustomListener(this.obj, callback);
    this.setPosition(position);
    this.setRotation(rotation);
    this.setScale(scale);
    this.obj.name = name;
    this.obj.castShadow = enableShadows;
    this.obj.receiveShadow = enableShadows;
    this.obj.uuid = uuid ? uuid : this.obj.uuid;
    this.obj._customAttribute = customAttribute
    if (texture) {
      this.setTexture(texture)
    }
    if (parent) {
      parent.add(this.obj);
    } else {
      this.addToScene(scene);
    }
  }
  createVisiblePivot = (pivot) => {
    let geometry = new BoxBufferGeometry(1, 1, 1);
    let material = new MeshLambertMaterial({
      wireframe: true,
      color: "#eb4034",
    });
    let mesh = new Mesh(geometry, material);
    mesh.position.setX(pivot.x);
    mesh.position.setY(pivot.y);
    mesh.position.setZ(pivot.z);
    mesh.name = "pivot";
    mesh.scale.set(0.2, 0.17, 0.84);
    mesh.on("click", (e) => {
    });
    this.obj.add(mesh);
  };
}

export default Plane;
