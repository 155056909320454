export let DeleteElementData = store => {
	store.on('@init', () => ({DeleteElementData: null}));
	store.on("DeleteElementData", ({ DeleteElementData }, data) => {
		if (data === "clear") {
			return { DeleteElementData: null };
		}
		return {
			DeleteElementData: data
		};
	});
};
