import React from 'react';
import PersonalPageLayout from '../../Views/Layout/PersonalPageLayout'
import AllprojectPersonalPage from '../../components/AllprojectPersonalPage'
import Layout from '../../Views/Layout';
const PersonalPageAllProject = () => {
	return (
		<Layout title={"Все проекты"}>
			<PersonalPageLayout>
				<AllprojectPersonalPage />
			</PersonalPageLayout>
		</Layout>
	)
};
 
export default PersonalPageAllProject