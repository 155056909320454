import React from 'react';
import { AbstractObject } from '../../../gengine';
import { BoxGeometry, Line, LineBasicMaterial, Mesh, MeshBasicMaterial, Vector3 } from 'three';
import { DATA_OBJECT_SCENE } from '../../../const';
import serialisers from '../../../utils/SerializedObject';
import {
  eventListenerAdd,
  isUpdateArr,
  isDeleteElement,
  checkUpdateVertices,
  checkUpdateOuterClemm,
} from '../../../utils';
import isEqual from 'react-fast-compare';
import { addEvent } from 'highcharts';

class WiresComponentCustom extends AbstractObject {
  constructor(props) {
    super();
    this.state = {
      isRenderConnector: false,
    };
  }
  /**
   * отрисует клему превращенную во внешний терминал
   * @param {*} data
   */
  outerTerminalRender = (data) => {
    let { scene } = this.props;
    this.geometry = new BoxGeometry(1, 1, 1);
    this.material = new MeshBasicMaterial({
      color: 'red',
      // wireframe: true,
    });
    this.obj = new Mesh(this.geometry, this.material);
    let position = [];
    this.obj.name = DATA_OBJECT_SCENE.outerTerminalFromConnector.name;
    this.obj.uuid = data.id;
    if (data.position instanceof Vector3 || data.position instanceof Object) {
      position = [data.position.x, data.position.y, data.position.z];
    }
    if (Array.isArray(data.position)) {
      position = [data.position[0], data.position[1], data.position[2]];
    }
    const addEvent = () => {
      eventListenerAdd(
        this.obj,
        data.data,
        this.material,
        (ev) => {
          this.props.callbackOnClick(
            ev.data.target.uuid,
            data.iterArrToAllVertices,
            data.iterArrToContextVertices,
          );
        },
        (ev) => {
          this.props.handleRightClick(ev);
        },
      );
    };
    // if (data.mesh) {
    //   if (!data.mesh.idMesh) {
    //     addEvent()
    //   }
    // } else {
    addEvent();
    // }

    this.setPosition(position);
    scene.add(this.obj);
  };
  /**
   * @param data
   * рендерит конекторы
   */
  renderPointConnector = (data) => {
    let { scene, enableShadows } = this.props;
    // debugger
    this.material = new MeshBasicMaterial({
      color: data.color,
      wireframe: true,
    });
    this.obj = new Mesh(data.geometry, this.material);
    let position = [];
    this.obj.name = DATA_OBJECT_SCENE.connector.name;
    this.obj.uuid = data.id;
    if (
      data.startPositions.position instanceof Vector3 ||
      data.startPositions.position instanceof Object
    ) {
      position = [
        data.startPositions.position.x,
        data.startPositions.position.y,
        data.startPositions.position.z,
      ];
    }
    if (Array.isArray(data.startPositions.position)) {
      position = [
        data.startPositions.position[0],
        data.startPositions.position[1],
        data.startPositions.position[2],
      ];
    }
    const addEvent = () => {
      eventListenerAdd(
        this.obj,
        data.data,
        this.material,
        (ev) => {
          this.props.callbackOnClick(
            ev.data.target.uuid,
            data.iterArrToAllVertices,
            data.iterArrToContextVertices,
          );
        },
        (ev) => {
          this.props.handleRightClick(ev);
        },
      );
    };
    // if (data.mesh) {
    //   if (!data.mesh.idMesh) {
    //     addEvent()
    //   }
    // } else {
    addEvent();
    // }

    this.setPosition(position);
    scene.add(this.obj);
  };
  /**
   * Очистит старые конекторы и добавит новые (сделано так во избежание утечек памяти)
   */
  clearConnector = () => {
    let { scene } = this.props;
    let ObjectsToScene = [];
    for (let i = 0; i < scene.children.length; i++) {
      if (scene.children[i].name === DATA_OBJECT_SCENE.connector.name) {
        ObjectsToScene.push(scene.children[i]);
      } else if (scene.children[i].name === DATA_OBJECT_SCENE.line.name) {
        ObjectsToScene.push(scene.children[i]);
      } else if (scene.children[i].name === DATA_OBJECT_SCENE.temporary_line.name) {
        ObjectsToScene.push(scene.children[i]);
      } else if (scene.children[i].name === DATA_OBJECT_SCENE.transformControler.name) {
        scene.children[i].detach();
        scene.children[i].dispose();
        scene.remove(scene.children[i]);
      } else if (scene.children[i].name === DATA_OBJECT_SCENE.outerTerminalFromConnector.name) {
        ObjectsToScene.push(scene.children[i]);
      }
    }
    for (let i = 0; i < ObjectsToScene.length; i++) {
      const el = ObjectsToScene[i];
      el.material.dispose();
      el.geometry.dispose();
      scene.remove(el);
    }
    return true;
  };
  /**
   * метод удалит ненужные геометрии от внешних клемм
   * вынесен в отдельную функцию потому что в некоторых случайях обновления нужно удалить лишь геометрию от клемм
   */
  clearOuterClemm = () => {
    let { scene } = this.props;
    let ObjectsToScene = [];

    for (let i = 0; i < scene.children.length; i++) {
      if (scene.children[i].name === DATA_OBJECT_SCENE.outerTerminalFromConnector.name) {
        ObjectsToScene.push(scene.children[i]);
      }
    }
    ObjectsToScene.forEach((el) => {
      el.material.dispose();
      el.geometry.dispose();
      scene.remove(el);
    });
    return true;
  };
  /**
   * рендерит линию
   * @param data
   */
  lineInit = (data) => {
    this.obj = new Line(
      data.geometry,
      new LineBasicMaterial({
        color: data.color,
        opacity: 1,
        linewidth: 4,
      }),
    );
    this.obj.castShadow = false;
    this.setPosition(data.position);
    this.obj.uuid = data.uuid;
    this.obj.name = DATA_OBJECT_SCENE.line.name;
    data.scene.add(this.obj);
    data._listeners = {};
  };
  /*
    Инициализация линий и конекторов сделано в отдельном методе для удобства обновения
   */
  initComponentRender = (data) => {
    let {
      // default
      scene,
      enableShadows,
      // custom
      wiresData = [],
      isUpdateLine = false,
      isConnectorUpdate = false,
    } = data;

    let { geometryArr, dataConnector, dataMesh } = serialisers.wiresAndConnectorSerialized(
      wiresData,
      scene,
      enableShadows,
      isUpdateLine,
      isConnectorUpdate,
    );
    dataMesh.map((el) => this.outerTerminalRender(el));
    geometryArr.map((el) => this.lineInit(el));
    dataConnector.map((el) => this.renderPointConnector(el));
  };

  componentDidUpdate(prevProps, prevState) {
    if (isEqual(this.props.wiresData, prevProps.wiresData)) {
      return false;
    }

    if (!isEqual(this.props, prevProps)) {
      let isConnectorUpdate = false;
      //проверяет на обновление данных в массва проводов(пример добавление нового)
      let testUpdateWireData = isUpdateArr(prevProps.wiresData, this.props.wiresData);
      //проверяет на добавление провода или вершины провода(метод пришлось разделить на 2 из-за некоректной работы)
      if (testUpdateWireData) {
        isConnectorUpdate = true;
        this.clearConnector();
      }
      //todo: очень дорогая проверка
      if (this.props.isDisconnectedWire) {
        if (checkUpdateVertices(this.props.wiresData, prevProps.wiresData)) {
          isConnectorUpdate = true;
          this.clearConnector();
          this.props.handlerIsDisconnectedWire(false);
        }
      }
      //проверит обновились ли внешние клеммы
      if (checkUpdateOuterClemm(this.props.wiresData, prevProps.wiresData)) {
        this.clearOuterClemm();
      }
      // тут отрисовка и рендер новых проводов и вершин
      this.initComponentRender({
        ...this.props,
        isUpdateLine: true,
        isConnectorUpdate,
      });
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.initComponentRender(this.props);
  }

  render() {
    return null;
  }
}

export default WiresComponentCustom;
