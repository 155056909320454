export let DataPrimitiveFromSidebar = store => {
    // Initial state
    let defaultData = {
        linkToDotsChangeCheckbox: false
    }
    store.on('@init', () => ({ DataPrimitiveFromSidebar: defaultData }));
    // Reducers returns only changed part of the state
    store.on("DataPrimitiveFromSidebar", ({ DataPrimitiveFromSidebar }, data) => {
        if (data === "clear") {
            return { DataPrimitiveFromSidebar: null };
        }
        return {
            DataPrimitiveFromSidebar: data
        };
    });
};