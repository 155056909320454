import { Vector3 } from 'three'
import { cloneDeep } from 'lodash'

const getPositionComponent = (data, el) => {
  if (data.minObjDrag.uuid === el.id) {
    return [data.minObjDrag.position.x, data.minObjDrag.position.y, data.minObjDrag.position.z]
  } else {
    return el.position;
  }
};

const setPositionsForConnectorWire = (data, linkScene, wiresData) => {
  let newWiresData = cloneDeep(wiresData)
  if (data.length) {
    data.forEach((el, i) => {
      let outerTerminalObject = linkScene.getObjectByProperty("uuid", el.outerTerminal)
      let connectorObject = linkScene.getObjectByProperty("uuid", el.verticesId)
      let positionOuterTerminal = new Vector3()
      outerTerminalObject.getWorldPosition(positionOuterTerminal)
      connectorObject.position.setX(positionOuterTerminal.x)
      // connectorObject.position.setY(positionOuterTerminal.y)
      connectorObject.position.setZ(positionOuterTerminal.z)
      // debugger
      newWiresData.forEach((wire, iWire) => {
        if (wire.id === el.idWire) {
          wire.vertices.forEach((vertices, iVertices) => {
            if (vertices.id === el.verticesId) {
              let customYAxias
              if (Array.isArray(newWiresData[iWire].vertices[iVertices].position)) {
                customYAxias = newWiresData[iWire].vertices[iVertices].position[1]
              } else {
                customYAxias = newWiresData[iWire].vertices[iVertices].position.y
              }
              newWiresData[iWire].vertices[iVertices].position = [positionOuterTerminal.x, customYAxias, positionOuterTerminal.z]
            }
          })
        }
      })
    })
  }
  return newWiresData
}

const checkIdClemForDevice = (el, id) => {
  let result = false
  if (el.connectors)
    el.connectors.forEach((el, i) => {
      if (el.id === id) {
        result = true
      }
    })

  return result
}
/**
 * Соберет новый масив с компонентами сцены и данными о подключенных приборах
 * @param { 
            onCollisions,
            newPosition,
            minObjDrag,
            newVector,
            contextElementData
        } data 
 */
export const getNewComponentData = (data) => {
  let newData = [];
  let newWiresData = []
  const { componentData, wiresData, linkToSceneObject } = data;
  newData = componentData.map((el, i) => {
    let verticesData = []
    wiresData.forEach((wire, i) => {
      wire.vertices.forEach(vertices => {
        let checkId = checkIdClemForDevice(el, vertices.idMesh)
        if (checkId) {
          verticesData.push({
            idWire: wire.id,
            verticesId: vertices.id,
            outerTerminal: vertices.idMesh
          })
        }
      })
    })
    if (data.minObjDrag.uuid === el.id) {
      newWiresData = setPositionsForConnectorWire(verticesData, linkToSceneObject, wiresData)
    }

    let newPosition = getPositionComponent(data, el)

    return {
      ...el,
      position: newPosition,
    };
  });

  return {
    componentData: newData,
    wiresData: newWiresData
  };
};
