import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalTitle from 'react-bootstrap/ModalTitle';
import closeImg from '../../../assets/images/closeModal.svg';

class ModalDefaultLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      isShow,
      contentForModal,
      closeModal,
      titleModal,
      imgModal,
      btnText,
      submitBtnHandler = () => {},
    } = this.props;

    return (
      <Modal
        animation={false}
        show={isShow}
        backdropClassName={'modal-default__backdrop'}
        className={'modal-default'}
        onHide={closeModal}
      >
        <ModalHeader className="modal-default__header">
          <div className="modal-default__image">
            <img className="modal-default__img" src={imgModal} alt={titleModal} />
          </div>
          <ModalTitle className="modal-default__title">{titleModal}</ModalTitle>
          <div className="modal-default__close" onClick={closeModal}>
            <img className="modal-default__close-img" src={closeImg} alt="close" />
          </div>
        </ModalHeader>
        <ModalBody>{contentForModal}</ModalBody>
        <ModalFooter>
          <button
            onClick={() => {
              if (submitBtnHandler) {
                submitBtnHandler();
              }
              closeModal();
            }}
            className="modal-default__btn"
          >
            {btnText}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalDefaultLayout;
