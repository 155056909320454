export let ConnectedWireToDevice = store => {
    // Initial state
    store.on('@init', () => ({}));
    // Reducers returns only changed part of the state
    store.on("ConnectedWireToDevice", ({ ConnectedWireToDevice }, data) => {
        if (data === "clear") {
            return {};
        }
        return {
            ConnectedWireToDevice: data
        };
    });
};