import React from 'react';
import InputValueData from '../../Views/Layout/InputValueData';
import ValueForPosition from '../../Views/Layout/ValueForPosition';
import CheckBox from '../../Views/Layout/CheckBox';
import ButtonMain from '../../Views/Layout/ButtonMain';
import { v4 } from 'uuid';

class SubareasSidebar extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <React.Fragment>
        <span className={'pfml-sidebar-device_title'}>ПОДОБЛАСТЬ</span>
        <div className={'pfml-sidebar-dot-position_btn'}>
          <span>Координаты подобласти</span>
          <ButtonMain
            onClick={this.contentForModal}
            className={'button__option-menu'}
            disabled={true}
          >
            Сбросить
          </ButtonMain>
        </div>
        <div className={'pfml-sidebar-wrapper_custom_inp-tree_inp'}>
          <InputValueData
            firstItemClassName={''}
            // className={' pfml-sidebar-wrapper_custom_inp-input_one'}
            defaultValue={0}
            firstDescription={'ID'}
            // secondDescription={item.units}
            id={v4()}
            // onChange={props.onChange}
          />
          <InputValueData
            firstItemClassName={''}
            // className={' pfml-sidebar-wrapper_custom_inp-input_one double_lable'}
            defaultValue={0}
            firstDescription={'Xс'}
            secondDescription={'(мм)'}
            id={v4()}
            // onChange={props.onChange}
          />
          <InputValueData
            firstItemClassName={''}
            // className={' pfml-sidebar-wrapper_custom_inp-input_one double_lable'}
            defaultValue={0}
            firstDescription={'Yс'}
            secondDescription={'(мм)'}
            id={v4()}
            // onChange={props.onChange}
          />
          <InputValueData
            firstItemClassName={''}
            // className={' pfml-sidebar-wrapper_custom_inp-input_one double_lable'}
            defaultValue={0}
            firstDescription={'Rс'}
            secondDescription={'(мм)'}
            id={v4()}
            // onChange={props.onChange}
          />
        </div>

        <div className={'pfml-sidebar-wrapper_custom_check_box'}>
          <CheckBox
            checked={false}
            // onChange={this.autoFormatChange}
            label={'Привязка к сетке'}
            helpText={'Граница: расчет'}
          />
        </div>
      </React.Fragment>
    );
  }
}
export default SubareasSidebar;
