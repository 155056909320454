
export let SavePFML = (store) => {
  // Initial state
  let initialState = {
    isSave: false,
    saveFunCallback: () => {},
  };
  store.on("@init", () => initialState);
  // Reducers returns only changed part of the state
  store.on("SavePFML", ({ SavePFML }, data) => {
    if (data === "clear") {
      return initialState;
    }
    return {
      SavePFML: data,
    };
  });
};
