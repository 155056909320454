import { DATA_OBJECT_SCENE } from "../../constants";
import { BufferGeometry, Line, LineBasicMaterial } from "three";

/**
 * Развешивает собития на объекты сцены
 * @param obj = {scene object}
 * @param callback = {type,event} || [{},{}]
 * @return void
 */
export const addEventCustomListener = (obj, callback) => {
  //выглядит отстойно но сорян)
  // сделал так потому что можно много событий кинуть а можно одно
  if (callback) {
    if (callback.length) {
      callback.map((el, i) => {
        if (el.type && el.event) {
          if (obj.on) {
            obj.on(el.type, (e) => el.event(e));
          }
        } else {
          console.log("Тип события не определен!");
        }
      });
    }
  }
};
/**
 * @param scene
 */
export const clearMemory = (scene) => {
  scene.children.map((el, i) => {
    let selectedtransformControler = scene.getObjectByName(
      DATA_OBJECT_SCENE.transformControler.name
    );
    let selectedDragControls = scene.getObjectByName(
      DATA_OBJECT_SCENE.dragControls.name
    );
    scene.remove(selectedDragControls);
    scene.remove(selectedtransformControler);
    // dragcontrols.dispose();
    // transformControl.dispose();
  });
};
