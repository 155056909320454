import React from 'react';
import DefaultHeader from '../../common/DefaultHeader';
import FirstScreenSection from '../../common/FirstScreenSection';
import AboutServiceSection from '../../common/AboutServiceSection';
import WhomIsItSuitable from '../../common/WhomIsItSuitable';
import HowDoesItWork from '../../common/HowDoesItWork';
import FeaturesSection from '../../common/FeaturesSection';
import DefaultFooter from '../../common/DefaultFooter';
import ModalQuestion from '../../common/ModalQuestion';
import ModalApply from '../../common/ModalApply';
import ModalOk from '../../common/ModalOk';
import ModalNotOk from '../../common/ModalNotOk';
import ModalDefaultLayout from '../../Views/Layout/ModalDefaultLayout/ModalDefaultLayout';
import QuestionImg from '../../assets/images/questionModal.svg';
import ApplyImg from '../../assets/images/applyModal.svg';
import NotOkImg from '../../assets/images/notOkModal.svg';
import OkImg from '../../assets/images/okModal.svg';
import Api from '../../api';
import connect from 'storeon/react/connect';
import Notification from '../../common/Notification';

const ApiContent = Api.ContentApi;
class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.notifiDataQuestrion = {
      content: {
        contentHeader: null,
        contentBody: <span>Заявка отправлена!</span>,
      },
      isShow: true,
      close: () => {
        this.props.dispatch('Notification', {
          content: {
            contentHeader: null,
            contentBody: null,
          },
          isShow: false,
          close: () => {},
        });
      },
    };
    this.enumTypesContentModal = {
      question: {
        key: 'question',
        title: 'Отправить вопрос',
        img: QuestionImg,
        btnText: 'Отправить',
      },
      apply: {
        key: 'apply',
        title: 'Оставить заявку',
        img: ApplyImg,
        btnText: 'Отправить',
      },
      ok: {
        key: 'ok',
        title: 'Отправлено',
        img: OkImg,
        btnText: 'Хорошо',
      },
      notOk: {
        key: 'notOk',
        title: 'Не удалось отправить',
        img: NotOkImg,
        btnText: 'Попробовать заново',
      },
    };

    this.state = {
      type: null,
      isShow: false,
      contentForModal: null,
      titleModal: '', 
      imgModal: '',
      btnText: '',
      //conent
      banner_features: [],
      banner_for_who: [],
      banner_how_work: [],
      banner_service_allow: [],
      head_text: '',
      head_video: null,
      service_button_link: '',
      service_button_text: '',
      service_text: '',
      service_video: null,
      settingsModalQuestrion: {
        name: { valid: false, value: '' },
        email: { valid: false, value: '' },
        questrion: { valid: false, value: '' },
      },
      settingsModalRequest: {
        name: { valid: false, value: '' },
        email: { valid: false, value: '' },
        phone: { valid: false, value: '' },
        fase: { valid: false, value: '' },
      },
    };
  }

  submitBtnHandler = (type, settingsModalQuestrion, settingsModalRequest) => {
    let requiredFieldIsEmpty = false;
    const settings =
      type !== this.enumTypesContentModal.apply.key ? settingsModalQuestrion : settingsModalRequest;
    for (const key in settings) {
      if (Object.hasOwnProperty.call(settings, key)) {
        const element = settings[key];
        if (!element.valid) {
          requiredFieldIsEmpty = true;
        }
      }
    }
    if (!requiredFieldIsEmpty) {
      switch (type) {
        case this.enumTypesContentModal.question.key:
          this.questionPostData(settingsModalQuestrion);
          break;
        case this.enumTypesContentModal.apply.key:
          this.requestPostData(settingsModalRequest);
          break;
      }
    }
  };

  requestPostData = (settingsModalRequest) => {
    ApiContent.postRequests({
      name: settingsModalRequest.name.value,
      email: settingsModalRequest.email.value,
      phone: settingsModalRequest.phone.value,
      company_title: settingsModalRequest.fase.value,
    })
      .then((res) => {
        this.closeModal();
        this.props.dispatch('Notification', this.notifiDataQuestrion);
      })
      .catch((err) => {});
  };

  questionPostData = (settingsModalQuestrion) => {
    ApiContent.postQuestrion({
      name: settingsModalQuestrion.name.value,
      email: settingsModalQuestrion.email.value,
      message: settingsModalQuestrion.questrion.value,
    })
      .then((res) => {
        this.closeModal();
        this.props.dispatch('Notification', this.notifiDataQuestrion);
      })
      .catch((err) => {});
  };

  setDataQuestrionHandler = (e, isValid = false) => {
    const { type } = this.state;
    const key = e.target.dataset?.name;
    const keyObject =
      type !== this.enumTypesContentModal.apply.key
        ? 'settingsModalQuestrion'
        : 'settingsModalRequest';
    this.setState({
      [keyObject]: {
        ...this.state[keyObject],
        [key]: {
          value: e.target.value,
          valid: isValid,
        },
      },
    });
  };

  closeModal = () => {
    this.setState({
      isShow: false,
      contentForModal: null,
      titleModal: '',
      imgModal: '',
      btnText: '',
    });
  };

  getContentFromType = (type) => {
    switch (type) {
      case this.enumTypesContentModal.question.key:
        return (
          <ModalQuestion
            dataStates={this.state.settingsModalQuestrion}
            onChangeData={this.setDataQuestrionHandler}
            closeModal={this.closeModal}
          />
        );
      case this.enumTypesContentModal.apply.key:
        return (
          <ModalApply
            onChangeData={this.setDataQuestrionHandler}
            dataStates={this.state.settingsModalRequest}
            closeModal={this.closeModal}
          />
        );
      default:
        return 'default content';
    }
  };

  getTitleFromType = (type) => {
    switch (type) {
      case this.enumTypesContentModal.question.key:
        return this.enumTypesContentModal.question.title;
      case this.enumTypesContentModal.apply.key:
        return this.enumTypesContentModal.apply.title;
      default:
        return 'default title';
    }
  };

  getImgFromType = (type) => {
    switch (type) {
      case this.enumTypesContentModal.question.key:
        return this.enumTypesContentModal.question.img;
      case this.enumTypesContentModal.apply.key:
        return this.enumTypesContentModal.apply.img;
      default:
        return 'default content';
    }
  };

  getBtnTextFromType = (type) => {
    switch (type) {
      case this.enumTypesContentModal.question.key:
        return this.enumTypesContentModal.question.btnText;
      case this.enumTypesContentModal.apply.key:
        return this.enumTypesContentModal.apply.btnText;
      default:
        return 'default content';
    }
  };

  openModal = (type) => {
    this.setState({
      type: type,
      isShow: true,
      contentForModal: this.getContentFromType(type),
      titleModal: this.getTitleFromType(type),
      imgModal: this.getImgFromType(type),
      btnText: this.getBtnTextFromType(type),
    });
  };

  getData = () => {
    ApiContent.getLandingData().then((res) => {
      this.setState({
        banner_features: [],
        banner_for_who: [],
        banner_how_work: [],
        banner_service_allow: res.banner_service_allow,
        head_text: res.head_text ? res.head_text : false,
        head_video: res.head_video ? res.head_video : false,
        service_button_link: '',
        service_button_text: res.service_button_text,
        service_text: res.service_text,
        service_video: res.service_video,
      });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.settingsModalQuestrion !== prevState.settingsModalQuestrion) {
      this.openModal(this.state.type);
      return true;
    }
    if (this.state.settingsModalRequest !== prevState.settingsModalRequest) {
      this.openModal(this.state.type);
      return true;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {});
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    const {
      isShow,
      contentForModal,
      closeModal,
      titleModal,
      imgModal,
      btnText,
      head_text,
      head_video,
      banner_service_allow,
      service_video,
      service_text,
      service_button_text,
      settingsModalQuestrion,
      settingsModalRequest,
      type,
    } = this.state;
    return (
      <React.Fragment>
        <Notification
          isShow={this.props.Notification.isShow}
          content={this.props.Notification.content}
          close={this.props.Notification.close}
        />
        <ModalDefaultLayout
          isShow={isShow}
          contentForModal={contentForModal}
          titleModal={titleModal}
          imgModal={imgModal}
          btnText={btnText}
          submitBtnHandler={() =>
            this.submitBtnHandler(type, settingsModalQuestrion, settingsModalRequest)
          }
          closeModal={this.closeModal}
        />
        <main className="landing-main">
          <DefaultHeader main={true} />
          <FirstScreenSection head_text={head_text} head_video={head_video} />
          <AboutServiceSection
            openModal={this.openModal}
            modalType={this.enumTypesContentModal.apply.key}
            banner_service_allow={[]}
            service_video={service_video}
            service_text={service_text}
            service_button_text={service_button_text}
          />
          <WhomIsItSuitable />
          <HowDoesItWork />
          <FeaturesSection />
          <DefaultFooter
            main={true}
            openModal={this.openModal}
            modalType={this.enumTypesContentModal.question.key}
          />
        </main>
      </React.Fragment>
    );
  }
}

export default connect('Notification', LandingPage);
