import BaseApi from '@garpix/base-api'

class MaterialApi extends BaseApi {
  /**
   * запрос за  типом материалов для модалки при построении подобластей
   * @param {*} params 
   */
  getMaterialType = async (params = {}) => {
    const res = await this.get(`/material/type/`, params);
    const data = res.data;
    return data.results;
  };
  /**
   * запрос за названием конкретного материала для модалки при построении подобластей
   * @param {*} params 
   */
  getMaterialSpec = async (params) => {
    const res = await this.get(`/material/spec/`, params)
    const data = res.data;
    return data.results
  }
}

export default MaterialApi