import React from 'react'
import CheckBox from '../CheckBox'


const TableCheckBox = (props) => {
  const { data } = props
  return (
    <div className='wrapper-check-box'>
      {data.map((item, index) => <CheckBox id={item.id} key={index} onChange={props.onChange} label={item.name} checked={item.checked} />)}
    </div>
  )
}
export default TableCheckBox