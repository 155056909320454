import React from 'react';
import Layout from '../../Views/Layout';

const Error500 = () => {
	return (
		<Layout title="500">500</Layout>
	)
}

export default Error500;
