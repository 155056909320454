import React, { useState } from "react";

const MenuNewsHomePage = (props) => {
  let [categories, setCategories] = useState([
    {
      name: "Все",
      category: 0,
    },
    {
      name: "Правительство",
      category: 1,
    },
    {
      name: "Энергетика",
      category: 2,
    },
    {
      name: "Достижения",
      category: 3,
    },
    {
      name: "Новинки",
      category: 4,
    },
    {
      name: "Отрасль",
      category: 5,
    },
  ]);

  let [contextCategory, setContextCategory] = useState(
    props?.filterParams?.category
  );
  // console.log(props.filterParams, "filterParams");
  const category = props?.filterParams?.category;
  // console.log(category);
  const getClassName = (category) => {
    if (contextCategory) {
      if (category === contextCategory) return "active";
      else return "";
    } else {
      if (category === 0) return "active";
    }
  };

  return (
    <div className={"news-header-menu"}>
      <ul>
        {categories.map((el, i) => {
          return (
            <li
              key={i}
              className={getClassName(el.category)}
              onClick={(e) => {
                let params = el.category ? { category: el.category } : {};
                setContextCategory(el.category);
                props.getCategories(1, params);
              }}
            >
              {el.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default MenuNewsHomePage;
