import React from "react";
import ButtonMain from "../../Views/Layout/ButtonMain";

const ErrorBlockAuth = (props) => {
  const { isVisible, text, closeErrorBlockHead } = props;
  if (isVisible) {
    return (
      <div className={"error-block_header"}>
        <div className={"error-block_header-text"}>{text}</div>{" "}
        <ButtonMain
          onClick={closeErrorBlockHead}
          className={"error-block_header-closed_btn"}
        ></ButtonMain>
      </div>
    );
  } else {
    return null;
  }
};

export default ErrorBlockAuth;
