import React, { useState } from 'react';
import InputValueData from '../InputValueData';
import {Tab} from "react-bootstrap";


const DropDownGeneralData = ({ aboutData = 'Общие данные', data}) => {
  const [isModal, setModal] = useState(false)
  return (
    <>
      <div className='sidebar-modal'>
        <div 
          onClick={() => setModal(!isModal)} 
          className={isModal ? 'sidebar-modal__drop-modal-active' : 'sidebar-modal__drop-modal'}>
          <h4 className='sidebar-modal__drop-modal__all-data'>{aboutData}</h4>
        </div>
        <div className={isModal ? 'sidebar-modal__select' : 'sidebar-modal__select-none'}>
         {data.map((item, index) => {
            return (
              <div key={index}>
                <InputValueData
                  className='data'
                  defaultValue='5925959,56'
                  firstDescription={item}
                />
              </div>
            ) 
          })}
        </div>
      </div>
      <div className='content-border-line'></div>
    </>
  )
}
export default DropDownGeneralData