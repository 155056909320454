export let ColisionsElemVLS = store => {
	// Initial state
	store.on('@init', () => ({ColisionsElemVLS: []}));
	// Reducers returns only changed part of the state
	store.on("ElementsVLS", ({ ColisionsElemVLS }, data) => {
		if (data === "clear") {
			return { ColisionsElemVLS: [] };
		}
		let newColisionsElemVLS = [...ColisionsElemVLS,data];
		return {
			ColisionsElemVLS: newColisionsElemVLS
		};
	});
};