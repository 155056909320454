import { Vector3 } from 'three'
import AimControl from './AimControl'

/**
 * @author richt / http://richt.me
 * @author WestLangley / http://github.com/WestLangley
 *
 * W3C Device Orientation control (http://w3c.github.io/deviceorientation/spec-source-orientation.html)
 */

let THREE = require('three')

THREE.DeviceOrientationControls = function (object, boolean, paramsEvent, meshRings) {
  let scope = this
  this.meshRings = meshRings
  this.paramsEvent = paramsEvent
  this.object = object
  this.object.rotation.reorder('YXZ')
  this.enabled = true
  this.deviceOrientation = {}
  this.screenOrientation = 0
  this.alphaOffset = 0 // radians

  let onDeviceOrientationChangeEvent = function (event) {
    scope.deviceOrientation = event
  }

  let onScreenOrientationChangeEvent = function () {
    scope.screenOrientation = window.orientation || 0
  }

  // The angles alpha, beta and gamma form a set of intrinsic Tait-Bryan angles of type Z-X'-Y''

  let setObjectQuaternion = function () {
    let zee = new THREE.Vector3(0, 0, 1)
    let euler = new THREE.Euler()
    let q0 = new THREE.Quaternion()
    let q1 = new THREE.Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5)) // - PI/2 around the x-axis

    return function (quaternion, alpha, beta, gamma, orient) {
      euler.set(beta, alpha, -gamma, 'YXZ') // 'ZXY' for the device, but 'YXZ' for us
      quaternion.setFromEuler(euler) // orient the device
      quaternion.multiply(q1) // camera looks out the back of the device, not the top
      quaternion.multiply(q0.setFromAxisAngle(zee, -orient)) // adjust for screen orientation
    }
  }()

  this.connect = function () {
    onScreenOrientationChangeEvent() // run once on load
    // iOS 13+
    if (window.DeviceOrientationEvent !== undefined && typeof window.DeviceOrientationEvent.requestPermission === 'function') {

      window.DeviceOrientationEvent.requestPermission().then(function (response) {
        if (response == 'granted') {
          window.addEventListener('orientationchange', onScreenOrientationChangeEvent, false)
          window.addEventListener('deviceorientation', onDeviceOrientationChangeEvent, false)
        }
      }).catch(function (error) {
        console.error('THREE.DeviceOrientationControls: Unable to use DeviceOrientation API:', error)
      })
    } else {
      window.addEventListener('orientationchange', onScreenOrientationChangeEvent, false)
      window.addEventListener('deviceorientation', onDeviceOrientationChangeEvent, false)
    }
    scope.enabled = true
  }

  this.disconnect = function () {
    window.removeEventListener('orientationchange', onScreenOrientationChangeEvent, false)
    window.removeEventListener('deviceorientation', onDeviceOrientationChangeEvent, false)
    scope.enabled = false
  }

  this.update = function () {
    if (scope.enabled === false) return
    let device = scope.deviceOrientation
    if (device) {
      let alpha = device.alpha ? THREE.Math.degToRad(device.alpha) + scope.alphaOffset : 0 // Z
      let beta = device.beta ? THREE.Math.degToRad(device.beta) : 0 // X'
      let gamma = device.gamma ? THREE.Math.degToRad(device.gamma) : 0 // Y''
      let orient = scope.screenOrientation ? THREE.Math.degToRad(scope.screenOrientation) : 0 // O
      const direction = new Vector3
      scope.object.getWorldDirection(direction)
      let directionCamera = new Vector3(direction.x, direction.y, direction.z)

      // setObjectQuaternion(scope.meshRings.quaternion, alpha, beta, gamma, orient)
      setObjectQuaternion(scope.object.quaternion, alpha, beta, gamma, orient) // gamma Math.PI /2
      new AimControl(scope.object, directionCamera, scope.paramsEvent)
      // scope.meshRings.rotation.setFromVector3(new Vector3(Math.PI * 2, 0, 0))
      // scope.meshRings.applyQuaternion(scope.object.quaternion)
    }
  }

  this.dispose = function () {
    scope.disconnect()
  }

  this.connect()

}

const ThreeDeviceOrientationControls = THREE.DeviceOrientationControls

export default ThreeDeviceOrientationControls