export let DotData = (store) => {
  // Initial state
  let defaultObj = {
    x: 0,
    y: 0,
    r: 5,
    id: 0
  }
  store.on("@init", () => ({
    DotData: defaultObj
  }));
  // Reducers returns only changed part of the state
  store.on("DotData", ({ DotData }, data) => {
    if (data === "clear") {
      return { DotData: defaultObj };
    }
    return {
      DotData: data,
    };
  });
};
