import React from "react";
import AuthorizationAndRegistrLayout from "../../Views/Layout/AuthorizationAndRegistrLayout";
import FormSignIn from "../../common/FormSignIn";
import Layout from "../../Views/Layout";

const AuthorizationPage = (props) => {
  return (
    <Layout title={"Авторизация"} isVisibleHeader={false}>
      <AuthorizationAndRegistrLayout {...props}>
        <FormSignIn />
      </AuthorizationAndRegistrLayout>
    </Layout>
  );
};

export default AuthorizationPage;
