import { stateSerializedVLC } from "./stateSerializedVLC";
import { wiresAndConnectorSerialized } from "./wiresAndConnectorSerialized";
import { deviceConnectorSerialized } from "./deviceConnectorSerialized";
import { collisionsHandlerWiresSerialized } from "./collisionsHandlerWiresSerialized";
import { addWires } from "./addWires";
import { setColorWireData } from "./setColorWireData";
import { savedMinifyObjects } from "./savedMinifyObjects";
import { vlsDTB } from "./vlsDTB";
import { getNewComponentData } from "./getNewComponentData";
import { couplingSleeveSerialazer } from "./couplingSleeveSerialazer";
import { couplingSleeveStateCreator } from "./couplingSleeveStateCreator";
import { generateSaveData } from "./generateSaveData";
import { parseDotForStraight } from "./parseDotForStraight";
import { arcToDotData } from "./arcToDotData";
import { addPointToRenderDots } from "./addPointToRenderDots";
import { getDotFromPrimitivesGraf } from "./getDotFromPrimitivesGraf";
import { dataPrimitivesSerialize } from "./dataPrimitivesSerialize";
import { SercheSubareas } from "./SercheSubareas";
import { UpdateDeviceList } from "./UpdateDeviceList";
import { AddTemporarySections } from "./AddTemporarySections";
import { GetAllPointsFromSubareas } from "./GetAllPointsFromSubareas";

export default {
  GetAllPointsFromSubareas,
  AddTemporarySections,
  UpdateDeviceList,
  SercheSubareas,
  dataPrimitivesSerialize,
  getDotFromPrimitivesGraf,
  addPointToRenderDots,
  arcToDotData,
  parseDotForStraight,
  getNewComponentData,
  vlsDTB,
  savedMinifyObjects,
  addWires,
  setColorWireData,
  collisionsHandlerWiresSerialized,
  stateSerializedVLC,
  wiresAndConnectorSerialized,
  deviceConnectorSerialized,
  couplingSleeveSerialazer,
  couplingSleeveStateCreator,
  generateSaveData,
};
