import React from 'react'
import Section from './Section'
import InputValueData from '../../Views/Layout/InputValueData'
import Select from '../../Views/Layout/Select'


const TriangulationGrooves = (props) => {
  const {
    selectTypeEnvData,
    optionsTypeEnv,
    selectSelectParamsName,
    optionsNameParams,
    magnetizationCoords,
    setMagnetizationCoords
  } = props
  const changeTypeEnv = (e) => {
    props.setSelectTypeEnvData({
      ...selectTypeEnvData,
      value: e.target.value
    })
  }
  const changeNameParams = (e) => {

  }
  const changeCoordMagnetic = (e) => {
    let newData = []
    magnetizationCoords.forEach(element => {
      if (element.id === e.target.id) {
        newData.push({
          ...element,
          value: e.target.value
        })
      } else {
        newData.push(element)
      }
    });
    setMagnetizationCoords(newData)
  }
  return (
    <Section>
      <div className={"modal-params_subareas-wrapper-content-section-triangulation_grooves"}>
        <h4 className={"modal-params_subareas-wrapper-content-section-triangulation_grooves-title"}>
          Возможность построения выемок <br /> при триангуляции подобласти
          </h4>
        <div className={"modal-params_subareas-wrapper-content-section-triangulation_grooves-wrapper_select"}>
          <Select
            className={selectTypeEnvData.className}
            label={"Тип среды:"}
            options={optionsTypeEnv}
            onChange={changeTypeEnv}
          />
        </div>
        <div className={"modal-params_subareas-wrapper-content-section-triangulation_grooves-wrapper_select"}>
          <Select
            className={selectSelectParamsName.className}
            label={"Название характеристики:"}
            options={optionsNameParams}
            onChange={changeNameParams}
          />
        </div>
        <h4 className={"modal-params_subareas-wrapper-content-section-triangulation_grooves-title"}>
          Координаты вектора намагниченности:
          </h4>
        <div className={"modal-params_subareas-wrapper-content-section-triangulation_grooves-params_wrap"}>
          <div>
            {
              magnetizationCoords.map((el, i) => {
                return <InputValueData
                  key={i}
                  firstItemClassName={" modal-params_subareas-wrapper-content-section-triangulation_grooves-params_wrap-input"}
                  className={'data '}
                  defaultValue={el.value}
                  firstDescription={el.nameOfValue}
                  secondDescription={el.units}
                  id={el.id}
                  onChange={changeCoordMagnetic}
                />
              })
            }
          </div>
          <div className={"modal-params_subareas-wrapper-content-section-triangulation_grooves-params_wrap-text_info"}>
            <p>
              Задаются в пределе <br />
              {`-1 < = Px, Рy < =1`}
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}
export default TriangulationGrooves