
/**
 * подставит правильно объект муфты , засунет его в объект с которым прошла колизия и повернет муфту нужной стороной
 * @param {*} params 
 */
export const couplingSleeveSerialazer = (params) => {
    const { objectDetectedCollizion, contextElement, scene } = params;
    let { _customAttribute } = objectDetectedCollizion.object
    let contextObj = scene.getObjectByProperty("uuid", contextElement.uuid)
    let targetObj = objectDetectedCollizion.object
    if (!targetObj.children.length) {
        contextObj.position.set(0, 0, 0)
        contextObj.rotation.set(0, 0, _customAttribute.direction)
        targetObj.add(contextObj)
    }
}