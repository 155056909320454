import React from "react";

class ModalGenerateOnlyCanvas extends React.Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
      content: null,
    };
  }

  setContentAndShow = (data) => {
    const { visibleModal } = data;
    this.setState({
      show: visibleModal.isVisible,
      content: visibleModal.data.content,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.visibleModal.isVisible !== this.props.visibleModal.isVisible) {
      this.setContentAndShow(this.props);
    }
  }

  componentDidMount() {
    this.setContentAndShow(this.props);
  }
  render() {
    const { show, content } = this.state;

    if (!show) return null;
    return <div className={"modal_canvas-wrapper"}>{content}</div>;
  }
}

export default ModalGenerateOnlyCanvas;
