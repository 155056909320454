import dot from "../../assets/images/dot.svg";
import arc from "../../assets/images/arc.svg";
import straight from "../../assets/images/straight.svg";
import subareas from "../../assets/images/subareas.svg";
import array from "../../assets/images/array.svg";
import section from "../../assets/images/section.svg";
import dotDark from "../../assets/images/dot_dark.svg";
import arcDark from "../../assets/images/arc_dark.svg";
import straightDark from "../../assets/images/straight_dark.svg";
import subareasDark from "../../assets/images/subareas_dark.svg";
import arrayDark from "../../assets/images/array_dark.svg";
import sectionDark from "../../assets/images/section_dark.svg";

export default () => {
  let theme = localStorage.getItem("theme") === "dark" ? true : false;
  console.log("theme:", theme);
  return [
    {
      id: 1,
      image: theme ? dot : dotDark,
      type: "dot",
      title: "Точка",
      active: false,
      description:
        "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
    },
    {
      id: 2,
      image: theme ? straight : straightDark,
      type: "straight",
      title: "Прямая",
      active: false,
      description:
        "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
    },
    {
      id: 3,
      image: theme ? arc : arcDark,
      type: "arc",
      title: "Кривая",
      approximationRadius: 1,
      active: false,
      description:
        "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
    },
    {
      id: 4,
      image: theme ? array : arrayDark,
      type: "rotatingArray",
      title: "Вращающийся массив",
      active: false,
      description:
        "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
    },
    {
      id: 5,
      image: theme ? section : sectionDark,
      type: "section",
      title: "Секция",
      active: false,
      description:
        "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
    },
    {
      id: 6,
      image: theme ? subareas : subareasDark,
      type: "subareas",
      title: "Подобласть",
      active: false,
      description:
        "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
    },
  ];
};
