import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Container from "../../../common/Container";
import DefaultHeader from "../../../common/DefaultHeader";
import DefaultFooter from "../../../common/DefaultFooter";
// import Footer from "../../../common/";
import ButtonMain from "../ButtonMain";
import { PATH } from "../../../const";
// import DefaultHeader from "../../../common/DefaultHeader/DefaultHeader";
import ModalQuestion from "../../../common/ModalQuestion";
import ModalApply from "../../../common/ModalApply";
import ModalOk from "../../../common/ModalOk";
import ModalNotOk from "../../../common/ModalNotOk";
import ModalDefaultLayout from "../../../Views/Layout/ModalDefaultLayout/ModalDefaultLayout";
import QuestionImg from "../../../assets/images/questionModal.svg";
import ApplyImg from "../../../assets/images/applyModal.svg";
import NotOkImg from "../../../assets/images/notOkModal.svg";
import OkImg from "../../../assets/images/okModal.svg";

class DefaultPageLayout extends React.Component {
  constructor(props) {
    super();
    this.enumTypesContentModal = {
      'question': {
        'key': 'question',
        'title': 'Отправить вопрос',
        'img': QuestionImg,
        'btnText': 'Отправить'
      },
      'apply': {
        'key': 'apply',
        'title': 'Оставить заявку',
        'img': ApplyImg,
        'btnText': 'Отправить'
      },
      'ok': {
        'key': 'ok',
        'title': 'Отправлено',
        'img': OkImg,
        'btnText': 'Хорошо'
      },
      'notOk': {
        'key': 'notOk',
        'title': 'Не удалось отправить',
        'img': NotOkImg,
        'btnText': 'Попробовать заново'
      },
    }
    this.state = {
      isShow: false,
      contentForModal: null,
      titleModal: "",
      imgModal: "",
      btnText: ""
    }
  }
  
  closeModal = () => {
    this.setState({
      isShow: false,
      contentForModal: null,
      titleModal: "",
      imgModal: "",
      btnText: ""
    })
  }

  getContentFromType = (type) => {
    switch(type) {
      case this.enumTypesContentModal.question.key:
        return <ModalQuestion closeModal={this.closeModal} />
      case this.enumTypesContentModal.apply.key:
        return <ModalApply closeModal={this.closeModal} />
      default:
        return 'default content';
    }
  }

  getTitleFromType = (type) => {
    switch(type) {
      case this.enumTypesContentModal.question.key:
        return this.enumTypesContentModal.question.title;
      case this.enumTypesContentModal.apply.key:
        return this.enumTypesContentModal.apply.title;
      default:
        return 'default title';
    }
  }

  getImgFromType = (type) => {
    switch(type) {
      case this.enumTypesContentModal.question.key:
        return this.enumTypesContentModal.question.img;
      case this.enumTypesContentModal.apply.key:
        return this.enumTypesContentModal.apply.img;
      default:
        return 'default content';
    }
  }

  getBtnTextFromType = (type) => {
    switch(type) {
      case this.enumTypesContentModal.question.key:
        return this.enumTypesContentModal.question.btnText;
      case this.enumTypesContentModal.apply.key:
        return this.enumTypesContentModal.apply.btnText;
      default:
        return 'default content';
    }
  }

  openModal = (type) => {
    this.setState({
      isShow: true,
      contentForModal: this.getContentFromType(type),
      titleModal: this.getTitleFromType(type),
      imgModal: this.getImgFromType(type),
      btnText:this.getBtnTextFromType(type)
    })
  }


  render() {
    const { FullWidthContent = false } = this.props;
    const { isShow, contentForModal, closeModal, titleModal, imgModal, btnText } = this.state;
    return (
      <React.Fragment>
        <ModalDefaultLayout isShow={isShow} contentForModal={contentForModal} titleModal={titleModal} imgModal={imgModal} btnText={btnText} closeModal={this.closeModal}/>
        <DefaultHeader/>
        <Container>
          <div className={"default_page-content"}>{this.props.children}</div>
        </Container>
        {FullWidthContent ? <FullWidthContent {...this.props} /> : null}

        <DefaultFooter openModal={this.openModal} modalType={this.enumTypesContentModal.question.key} textDemo={"Купить сейчас"} linkDemo={"#"} />
      </React.Fragment>
    );
  }
}
export default DefaultPageLayout;
