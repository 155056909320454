import BaseApi from '@garpix/base-api'
import { BASE_URL } from "../const";
import UserApi from "./UserApi";
import ContentApi from "./ContentApi";
import ProjectApi from "./ProjectApi";
import ResearchApi from './ResearchApi'
import SocketApiVLS from './SocketApiVLS'
import MaterialApi from './MaterialApi'

class Api extends BaseApi {
  constructor(base_url, data) {
    super();
    this.UserApi = new UserApi(base_url);
    this.ContentApi = new ContentApi(base_url);
    this.MaterialApi = new MaterialApi(base_url);
    this.ProjectApi = new ProjectApi(base_url);
    this.ResearchApi = new ResearchApi(base_url);
    this.Socket = (data) => new SocketApiVLS(data)
  }
}
let api = new Api(BASE_URL);

export default api;
