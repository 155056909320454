import React from "react";
import ResearchEnvironment from "../ResearchEnvironment";
import DotDataSidebar from "../DotDataSidebar";
import DefaultDataSidebar from "../DefaultDataSidebar";
import SectionSidebar from "../SectionSidebar";
import StraightSidebar from "../StraightSidebar";
import SubareasSidebar from "../SubareasSidebar";
import PrimitivesSidebar from "../PrimitivesSidebar";
import isEqual from "react-fast-compare";
import connect from "storeon/react/connect";

class SidebarPFML extends React.Component {
  constructor(props) {
    super();
    this.state = {
      contentRender: [],
    };
  }

  setContentRender = (data) => {
    this.setState({
      contentRender: [data],
    });
  };

  checkTool = (data) => {
    if (!data) return this.setContentRender(<DefaultDataSidebar />);
    switch (data.type) {
      case "dot":
        this.setContentRender(<DotDataSidebar element={data.element} />);
        break;
      case "arc":
        this.setContentRender(<PrimitivesSidebar />);
        break;
      case "straight":
        this.setContentRender(<StraightSidebar />);
        break;
      case "subareas":
        this.setContentRender(<SubareasSidebar element={data.element} />);
        break;
      case "section":
        this.setContentRender(<SectionSidebar element={data.element} />);
        break;
      default:
        this.setContentRender(<DefaultDataSidebar />);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isEqual(this.props.SelectedToolPFML, prevProps.SelectedToolPFML)) {
      this.checkTool(this.props.SelectedToolPFML);
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.checkTool();
  }

  render() {
    const { contentRender } = this.state;
    let theme = this.props.ProjectTheme === "dark" ? "" : "light";
    return (
      <div className={`sidebar ${theme}`}>
        {contentRender.map((el, i) => (
          <React.Fragment key={i}>{el}</React.Fragment>
        ))}
      </div>
    );
  }
}

export default connect("ProjectTheme", "SelectedToolPFML", SidebarPFML);
