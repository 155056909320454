import React from 'react';
import SubmitForm from '../SubmitForm';
import isEqual from 'react-fast-compare';
import InputValueData from '../../Views/Layout/InputValueData';
import { Form } from 'react-bootstrap';
import RadioButton from '../../Views/Layout/RadioButton';
import CheckBox from '../../Views/Layout/CheckBox';
import ConfirmComponent from '../ConfirmComponent';
import connect from 'storeon/react/connect';
import { TorusBufferGeometry } from 'three';

class OptionPFMLDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limitResolutionMM: 5.0e-6,
      stepGridPx: 5,
      length3D: 1,
      maxN: 1,
      numberDisplayedFieldLines: 20,
      stepAproximations: 20,
      maxCountIter: 4,
      axleLength: 5.0e-6,
      Xmin: 5.0e-6,
      Xmax: 5.0e-6,
      Ymin: 5.0e-6,
      Ymax: 5.0e-6,
      typeTask: {
        magneticField: true,
        electricField: false,
        thermalField: false,
        spreadField: false,
        diffusionField: false,
        deformationField: false,
      },
      coeficentP: {
        frequencyNone: true,
        frequencyOdd: false,
        frequencyEven: false,
      },
    };
  }

  handleTypeTask = (type, nameBlock, initialDataBlock) => {
    let newData = {};
    for (const key in initialDataBlock) {
      if (Object.hasOwnProperty.call(initialDataBlock, key)) {
        if (key === type) {
          newData[key] = true;
        } else {
          newData[key] = false;
        }
      }
    }
    this.setState({
      [nameBlock]: newData,
    });
  };

  handlerClickSubmitBtn = (e) => {
    this.props.submitSettings(this.state);
  };

  setInitialValue = (data) => {
    this.setState({
      ...data
    })
  }

  componentDidUpdate(prevProps) {
    const { loadedSettingsPFML } = this.props;
    if (loadedSettingsPFML !== prevProps.loadedSettingsPFML) {
      this.setInitialValue(loadedSettingsPFML);
    }
  }

  componentDidMount() {
    const { loadedSettingsPFML } = this.props;
    this.setInitialValue(loadedSettingsPFML);
  }

  render() {
    const theme = this.props.ProjectTheme === 'light' ? 'light' : 'dark';
    const {
      typeTask,
      coeficentP,
      Xmin,
      Xmax,
      Ymin,
      Ymax,
      limitResolutionMM,
      length3D,
      maxN,
      numberDisplayedFieldLines,
      maxCountIter,
      stepGridPx,
      stepAproximations,
      axleLength,
    } = this.state;
    const { close } = this.props;
    return (
      <React.Fragment>
        <div className={`pfml-drop_down pfml-drop_down__options ${theme}`}>
          <div className={'pfml-drop_down_wrap'}>
            <div className={'pfml-drop_down_wrap-title'}>Тип задачи:</div>
            <Form.Group className={'pfml-drop_down_wrap-form_group'}>
              <Form.Label className={'pfml-drop_down_wrap-form_label'}>
                <RadioButton
                  checked={typeTask.magneticField}
                  onClick={(e) => {
                    this.handleTypeTask('magneticField', 'typeTask', typeTask);
                  }}
                  label="1 - Магнитное поле"
                />
              </Form.Label>
              <Form.Label className={'pfml-drop_down_wrap-form_label'}>
                <RadioButton
                  checked={typeTask.electricField}
                  onClick={(e) => {
                    this.handleTypeTask('electricField', 'typeTask', typeTask);
                  }}
                  label="2 - Электрическое поле"
                />
              </Form.Label>
              <Form.Label className={'pfml-drop_down_wrap-form_label'}>
                <RadioButton
                  checked={typeTask.thermalField}
                  onClick={(e) => {
                    this.handleTypeTask('thermalField', 'typeTask', typeTask);
                  }}
                  label="3 - Тепловое поле"
                />
              </Form.Label>
              <Form.Label className={'pfml-drop_down_wrap-form_label'}>
                <RadioButton
                  checked={typeTask.spreadField}
                  onClick={(e) => {
                    this.handleTypeTask('spreadField', 'typeTask', typeTask);
                  }}
                  label="4 - Поле ратекания токов"
                />
              </Form.Label>
              <Form.Label className={'pfml-drop_down_wrap-form_label'}>
                <RadioButton
                  checked={typeTask.diffusionField}
                  onClick={(e) => {
                    this.handleTypeTask('diffusionField', 'typeTask', typeTask);
                  }}
                  label="5 - Поле диффузии"
                />
              </Form.Label>
              <Form.Label className={'pfml-drop_down_wrap-form_label'}>
                <RadioButton
                  checked={typeTask.deformationField}
                  onClick={(e) => {
                    this.handleTypeTask('deformationField', 'typeTask', typeTask);
                  }}
                  label="6 - Поле деформаций"
                />
              </Form.Label>
            </Form.Group>
          </div>
          <div className={'pfml-drop_down_wrap'}>
            <div className={'pfml-drop_down_wrap-title'}>Координаты окна, мм:</div>
            <div className={'pfml-drop_down_wrap_group'}>
              <InputValueData
                firstDescription="Xmin"
                onChange={(e) => {
                  this.setState({
                    Xmin: e.target.value,
                  });
                }}
                value={Xmin}
              />
              <InputValueData
                firstDescription="Ymin"
                onChange={(e) => {
                  this.setState({
                    Ymin: e.target.value,
                  });
                }}
                value={Ymin}
              />
              <InputValueData
                firstDescription="Xmax"
                onChange={(e) => {
                  this.setState({
                    Xmax: e.target.value,
                  });
                }}
                value={Xmax}
              />
              <InputValueData
                firstDescription="Ymax"
                onChange={(e) => {
                  this.setState({
                    Ymax: e.target.value,
                  });
                }}
                value={Ymax}
              />
            </div>
          </div>
          <div className={'pfml-drop_down_wrap'}>
            <div className="pfml-drop_down_wrap-row">
              <div className={'pfml-drop_down_wrap-title'}>Предельное разрешение, мм:</div>
              <InputValueData
                firstDescription="D"
                onChange={(e) => {
                  this.setState({
                    limitResolutionMM: e.target.value,
                  });
                }}
                value={limitResolutionMM}
              />
            </div>
          </div>
          <div className={'pfml-drop_down_wrap'}>
            <div className="pfml-drop_down_wrap-row">
              <div className={'pfml-drop_down_wrap-title'}>Шаг опорной сетки, пикс:</div>
              <InputValueData
                firstDescription="d"
                onChange={(e) => {
                  this.setState({
                    stepGridPx: e.target.value,
                  });
                }}
                value={stepGridPx}
              />
            </div>
          </div>
          <div className={'pfml-drop_down_wrap'}>
            <div className="pfml-drop_down_wrap-row">
              <div className={'pfml-drop_down_wrap-title'}>Длина в 3-м измерении, мм:</div>
              <InputValueData
                firstDescription="Ld"
                onChange={(e) => {
                  this.setState({
                    length3D: e.target.value,
                  });
                }}
                value={length3D}
              />
            </div>
          </div>
          <div className={'pfml-drop_down_wrap'}>
            <div className={'pfml-drop_down_wrap-title'}>Коэффициент периодичности:</div>
            <Form.Group className={'pfml-drop_down_wrap-form_group'}>
              <Form.Label className={'pfml-drop_down_wrap-form_label'}>
                <RadioButton
                  checked={coeficentP.frequencyNone}
                  onClick={(e) => {
                    this.handleTypeTask('frequencyNone', 'coeficentP', coeficentP);
                  }}
                  label="Периодичность отсутствует"
                />
              </Form.Label>
              <Form.Label className={'pfml-drop_down_wrap-form_label'}>
                <RadioButton
                  checked={coeficentP.frequencyOdd}
                  onClick={(e) => {
                    this.handleTypeTask('frequencyOdd', 'coeficentP', coeficentP);
                  }}
                  label="Нечетная периодичность"
                />
              </Form.Label>
              <Form.Label className={'pfml-drop_down_wrap-form_label'}>
                <RadioButton
                  checked={coeficentP.frequencyEven}
                  onClick={(e) => {
                    this.handleTypeTask('frequencyEven', 'coeficentP', coeficentP);
                  }}
                  label="Четная периодичность"
                />
              </Form.Label>
            </Form.Group>
          </div>
          <div className={'pfml-drop_down_wrap'}>
            <div className="pfml-drop_down_wrap-row">
              <div className={'pfml-drop_down_wrap-title'}>
                Шаг аппроксимации (по&nbsp;умолчанию),&nbsp;мм:
              </div>
              <InputValueData
                firstDescription="R"
                onChange={(e) => {
                  this.setState({
                    stepAproximations: e.target.value,
                  });
                }}
                value={stepAproximations}
              />
            </div>
          </div>
          <div className={'pfml-drop_down_wrap'}>
            <div className="pfml-drop_down_wrap-row">
              <div className={'pfml-drop_down_wrap-title'}>Макс. кол-во итераций:</div>
              <InputValueData
                firstDescription="Nmax"
                onChange={(e) => {
                  this.setState({
                    maxCountIter: e.target.value,
                  });
                }}
                value={maxCountIter}
              />
            </div>
          </div>
          <div className={'pfml-drop_down_wrap'}>
            <div className="pfml-drop_down_wrap-row">
              <div className={'pfml-drop_down_wrap-title'}>Макс. допустимая невязка:</div>
              <InputValueData
                firstDescription="Fmax"
                onChange={(e) => {
                  this.setState({
                    maxN: e.target.value,
                  });
                }}
                value={maxN}
              />
            </div>
          </div>
          <div className={'pfml-drop_down_wrap'}>
            <div className="pfml-drop_down_wrap-row">
              <div className={'pfml-drop_down_wrap-title'}>
                Кол-во отображаемых силовых&nbsp;линий:
              </div>
              <InputValueData
                firstDescription="Ns"
                onChange={(e) => {
                  this.setState({
                    numberDisplayedFieldLines: e.target.value,
                  });
                }}
                value={numberDisplayedFieldLines}
              />
            </div>
          </div>
          <div className={'pfml-drop_down_wrap_confirm'}>
            <ConfirmComponent
              callbackOk={this.handlerClickSubmitBtn}
              callbackCancellation={close}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect('ProjectTheme', OptionPFMLDropDown);
