import BaseApi from '@garpix/base-api';
import { getSerializedItemNews } from './Serialized';

class ContentApi extends BaseApi {
  getNewsContent = async (params = {}) => {
    const res = await this.get(`/content/news/`, params);
    const data = res.data;
    data.results = data.results.map(getSerializedItemNews);
    console.log(`data`, data)
    return data;
  };
  getNewsInSlug = async (slug, params = {}) => {
    const res = await this.get(`/content/news/${slug}/`, params);
    const data = res.data;
    return data.data;
  };
  getQuestionsForFaq = async (params = {}) => {
    const res = await this.get(`/content/faq/`, params);
    const data = res.data;
    return data.data;
  };
  getLandingData = async (params) => {
    const res = await this.get(`/content/main_landing/`, params);
    const data = res.data;
    return data;
  };
  postQuestrion = async (params = {}) => {
    const res = await this.post(`/content/questions/`, params);
    const data = res.data;
    return data;
  };
  postRequests = async (params = {}) => {
    const res = await this.post(`/content/requests/`, params);
    const data = res.data;
    return data;
  };
}

export default ContentApi;
