import React from "react";
import Button from "../../Views/Layout/Button";
import { NavLink } from "react-router-dom";
import Container from "../Container";
import { PATH } from "../../const";
import Logo from "../../assets/images/logo.svg";


const DefaultFooter = (props) => {
  const { textDemo = "Попробовать DEMO", linkDemo = "#", main = false } = props
  const clickScrollTop = (e) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };
  document.addEventListener("scroll", (e) => {
    if (document.documentElement.scrollTop || document.body.scrollTop) {
      document.querySelector('.landing-footer-wrapper-bottom-btn').classList.add("active");
    } else {
      document.querySelector('.landing-footer-wrapper-bottom-btn').classList.remove("active");
    }
  })
  return (
    <footer className={"landing-footer"}>
      <Container>
        <div className="landing-footer-wrapper">
          <div className={"landing-footer-wrapper-top"}>
            <div className={"landing-footer-wrapper-info"}>
              <NavLink to={PATH.MainPage} className={"landing-footer-wrapper-info-logo"}>
                <div className={"landing-footer-wrapper-info-logo_img"}>
                  <img src={Logo} alt="logo"/>
                </div>
                <div className={"landing-footer-wrapper-info-logo_text"}>
                  GARPIX <br/> DIGITAL <br/> TWIN
                </div>
              </NavLink>

              {/* <NavLink
                className={"landing-footer-wrapper-info-link"}
                to={linkDemo}
              >
                {textDemo}
              </NavLink> */}
            </div>
            <div className={"landing-footer-wrapper-menu"}>
              <nav>
                <ul>
                  <li>
                    <a href={`${ main ? "#link-service_info" : `${PATH.MainPage}#link-service_info`}`}>О сервисе</a>
                  </li>
                  <li>
                    <a href={`${ main ? "#link-whom_is_it_suitable" : `${PATH.MainPage}#link-whom_is_it_suitable`}`}>Для кого</a>
                  </li>
                  {/* <li>
                    <a href={"#link-how_does_it_work"}>Инструкция</a>
                  </li> */}
                  <li>
                    <NavLink to={PATH.Home}>Новости</NavLink>
                  </li>
                  {/* <li>
                    <a href={PATH.MainPage}>Тарифы</a>
                  </li> */}
                  <li>
                    <NavLink to={PATH.Faq}>FAQ</NavLink>
                  </li>
                </ul>
              </nav>
            </div>
            <div className={"landing-footer-wrapper-contact"}>
              <div className="landing-footer-wrapper-contact-items">
                <a href="tel:88002345056" className={"landing-footer-wrapper-contact-phone"}>
                8 800 234-50-56
                </a>
                <a href="mailto:inbox@garpix.com" className={"landing-footer-wrapper-contact-email"}>
                inbox@garpix.com
                </a>
              </div>
              <Button
                name={"Задать вопрос"}
                className={" landing-footer-wrapper-contact-button"}
                onClick={() => {
                  props.openModal(props.modalType);
                }}
              />
            </div>
          </div>
          <div className={"landing-footer-wrapper-bottom"}>
            <span className={"landing-footer-wrapper-bottom-license"}>
              © 2020 GARPIX. Все права защищены.{" "}
            </span>
            <div className={"landing-footer-wrapper-bottom-right"}>
              <a
                className={"landing-footer-wrapper-bottom-politics"}
                href="/politics.pdf"
              >
                Политика конфиденциальности
              </a>
              <Button
                onClick={clickScrollTop}
                name={""}
                className={" landing-footer-wrapper-bottom-btn"}
              />
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default DefaultFooter;
