import React from 'react'
import SettingPersonal from '../../components/SettingPersonal'
import PersonalPageLayout from '../../Views/Layout/PersonalPageLayout'
import Layout from '../../Views/Layout';

const SettingPage = ()=> {
    return (
      <Layout title={"Настройки"}>
			  <PersonalPageLayout>
            <SettingPersonal/>
			  </PersonalPageLayout>
		</Layout>
    );
}

export default SettingPage;