import React from "react";
import { ReactComponent as Image } from "./Image.svg";

class LoaderSpiner extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    const classNameCustom = `loader-wrap ${
      this.props.className ? this.props.className : ""
    }`;
    return (
      <React.Fragment>
        <div className={classNameCustom}>
          <Image width={"40px"} height={"40px"} />
        </div>
      </React.Fragment>
    );
  }
}
export default LoaderSpiner;
