import React from 'react';
import { AbstractObject, GenericGroupObject, Raycast } from '../../../gengine';
import {
  CatmullRomCurve3,
  BufferGeometry,
  Line,
  LineBasicMaterial,
  Float32BufferAttribute,
  BoxBufferGeometry,
  MeshBasicMaterial,
  Mesh,
  Ray,
  DoubleSide,
  Raycaster,
  MathUtils,
  Vector2,
  Vector3,
  MeshStandardMaterial,
  BoxGeometry,
  Geometry,
  Plane,
  Face3,
  RepeatWrapping,
  ExtrudeGeometry,
  Shape,
  TextureLoader,
} from 'three';
import { sercheAllObjectByProperty, collisionHandler } from '../../../utils';
import { DATA_OBJECT_SCENE } from '../../../const';
import serialisers from '../../../utils/SerializedObject';
import isEqual from 'react-fast-compare';
import connect from 'storeon/react/connect';

class SubregionCreator extends AbstractObject {
  constructor(props) {
    super();
    this.state = {};
  }
  //dell
  createRaycast = (event, dataObject = [], camera) => {
    if (dataObject.length) {
      let raycast = new Raycaster();
      let coords = {
        x: (event.data.originalEvent.clientX / window.innerWidth) * 2 - 1,
        y: -(event.data.originalEvent.clientY / window.innerHeight) * 2 + 1,
      };
      raycast.setFromCamera(coords, camera);
      let intersection = raycast.intersectObjects(dataObject, false);
      return intersection;
    }
    return false;
  };

  createContextObjectForCheckDistanse = (coordsPoint, scene) => {
    if (!coordsPoint) return;
    let geometry = new BoxGeometry(1, 1, 1, 1, 1, 1);
    let material = new MeshStandardMaterial({
      // transparent: true,
      // opacity: 0,
      wireframe: true,
      color: 'red',
    });
    let object = new Mesh(geometry, material);
    let pos = coordsPoint;
    object.name = DATA_OBJECT_SCENE.TEMPORARY_BOX_CHECKER.name;
    object.position.set(pos.x, 1, pos.z);
    scene.add(object);
    return object;
  };

  /**
   * соберет из воркера данные для генерации геометрии для последущей закраски
   * @param {*} event
   * @param {*} renderDots
   */
  collectorVerticesForMesh = async (event, renderDots, scene) => {
    let params = {
      dots: renderDots,
      point: {
        x: event.intersects[0].point.x,
        y: event.intersects[0].point.y,
        z: event.intersects[0].point.z,
      },
    };

    let resultWorker = await serialisers
      .SercheSubareas(params)
      .then((el) => el)
      .catch((err) => console.error('Ошибка поиска подобласти', err));
    
    let { vertices = [], result = [], faces = [] } = resultWorker?.data;
    return { vertices, result, faces };
  };

  createExtrude = (verticesForCreateMesh, scene, faces, vertices) => {
    const shape = new Shape();
    shape.autoClose = true;
    shape.moveTo(verticesForCreateMesh[0][0].x, verticesForCreateMesh[0][0].y);
    for (let i = 0; i <= verticesForCreateMesh[0].length - 1; i++) {
      const element = verticesForCreateMesh[0][i];
      shape.lineTo(element.x, element.y);
    }
    const extrudeSettings = {
      steps: 0,
      depth: 0,
      bevelEnabled: false,
      bevelThickness: 0,
      bevelSize: 0,
      bevelOffset: 0,
      bevelSegments: 0,
    };
    let color = '#000000'.replace(/0/g, function () {
      return (~~(Math.random() * 16)).toString(16);
    });
    const geometry = new ExtrudeGeometry(shape, extrudeSettings);
    const material = new MeshBasicMaterial({
      color: color,
      // wireframe: true,
    });
    const mesh = new Mesh(geometry, material);
    mesh.name = DATA_OBJECT_SCENE.SUBAREAS.name;
    mesh.rotation.set(MathUtils.degToRad(90), MathUtils.degToRad(0), MathUtils.degToRad(0));
    mesh.position.set(mesh.position.x, mesh.position.y - 5, mesh.position.z);
    mesh.on('click', this.clickSubareas);
    mesh.on('mouseout', (e) => {
      this.defaultColorSet(e, color);
    });
    scene.add(mesh);
  };

  defaultColorSet = (e, color) => {
    let object = e.data.target;
    object.material.map = null;
    object.material.color.set(color);
    object.material.needsUpdate = true;
  };

  clickSubareas = (e) => {
    let object = e.data.target;
    object.material.color.set('#fff');
    const texture = new TextureLoader().load('/global-assets/grid.png');
    texture.wrapS = texture.wrapT = RepeatWrapping;
    object.material.needsUpdate = true;
    object.material.map = texture;
  };

  createMeshFromVertices = (verticesForCreateMesh, scene, faces, vertices, coordsPoint) => {
    if (Array.isArray(verticesForCreateMesh)) {
      if (verticesForCreateMesh.length) {
        this.createExtrude(verticesForCreateMesh, scene, faces, vertices);
        this.createContextObjectForCheckDistanse(coordsPoint, scene);
      }
    }
  };

  clearMemory = () => {
    let allSubareasMesh = sercheAllObjectByProperty(
      'name',
      DATA_OBJECT_SCENE.SUBAREAS.name,
      this.props.scene,
    );
    allSubareasMesh.forEach((el) => {
      el.geometry.dispose();
      el.material.dispose();
      this.props.scene.remove(el);
    });
  };

  collectorRenderSubareas = (subareas, scene) => {
    this.clearMemory();
    const getPositionFromEl = (el) => {
      let x, z;
      el.paramsDeviceDataNamefields.forEach((elParams) => {
        if (elParams.nameOfValue === 'Xр = ') x = elParams.value;
        if (elParams.nameOfValue === 'Yр = ') z = elParams.value;
      });
      return { x: x, y: 1, z: z };
    };
    subareas.forEach((el, i) => {
      let coordsPoint = getPositionFromEl(el);
      this.createMeshFromVertices(
        el.verticesForCreateMesh,
        scene,
        undefined,
        undefined,
        coordsPoint,
      );
    });
  };

  init = (event, scene, renderDots, subareas) => {
    let coordsPoint = {
      x: event.intersects[0].point.x,
      y: event.intersects[0].point.y,
      z: event.intersects[0].point.z,
    };
    this.collectorVerticesForMesh(event, renderDots, scene).then((response) => {
      const { result: verticesForCreateMesh, faces, vertices } = response;
      if (verticesForCreateMesh) {
        if (verticesForCreateMesh.length) {
          this.props.dispatch('CreateSubareas', {
            isShowModal: true,
            coordsPoint: coordsPoint,
            count: subareas.length + 1,
            verticesForCreateMesh: verticesForCreateMesh,
            callback: () =>
              this.createMeshFromVertices(
                verticesForCreateMesh,
                scene,
                faces,
                vertices,
                coordsPoint,
              ),
          });
        }
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.subareas !== prevProps.subareas) {
      this.collectorRenderSubareas(this.props.subareas, this.props.scene);
    }
    if (this.props.eventClickFullForSubareas && this.props.clickEvt) {
      this.props.setSinglClick(false);
      this.init(
        this.props.eventClickFullForSubareas,
        this.props.scene,
        this.props.renderDots,
        this.props.subareas,
      );
    }
  }

  componentDidMount() {
    this.collectorRenderSubareas(this.props.subareas, this.props.scene);
    if (this.props.eventClickFullForSubareas && this.props.clickEvt) {
      this.props.setSinglClick(false);
      this.init(
        this.props.eventClickFullForSubareas,
        this.props.scene,
        this.props.renderDots,
        this.props.subareas,
      );
    }
  }

  render() {
    return null;
  }
}

export default connect('CreateSubareas', SubregionCreator);
