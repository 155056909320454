import React from 'react'
import Tabs from '../../Views/Layout/Tabs'
import TableGenerate from '../../common/TableGenerate'
import RadioButton from '../../Views/Layout/RadioButton'
import InputValueData from '../../Views/Layout/InputValueData';
import isEqual from 'react-fast-compare';

class TableDataForOscilloscope extends React.Component {
  constructor(props) {
    super()
    this.state = {
      titleClassName: "vls-table_data-oscilloscope-title",
      tabs: [
        {
          name: 'Напряжение',
          active: true
        }, {
          name: 'Потенциал',
          active: false
        },
      ],
      TableHeadData: [
        [
          {
            name: 'По оси Х (время)',
            attr: { rowSpan: 2 }
          }, { name: 'По оси Y (напряжение)', attr: { colSpan: 2 } }],
        [{ name: 'Цена деления', attr: {} }, { name: 'Начало отчёта', attr: {} }]
      ],
      TableBodyData: this.generateTableBody([]),
      dataForTable: [],
      variant: "vls-table_data-oscilloscope-table"
    }
  }

  updateDeviceData = (data) => {
    this.setState(data, () => {
      let data = {
        dataForTable: this.state.dataForTable,
        tabs: this.state.tabs
      }
      this.props.changeTable(data)
    })
  }

  dataForTableChange = (data) => {
    const { dataForTable } = this.state
    const getData = () => {
      return dataForTable.map((el, i) => {
        if (data.type === "radio") {
          if (el.id === data.target.id) {
            el[data.key].active = !el[data.key].active
            return el
          } else {
            return el
          }
        } else {
          if (el.id === data.target.id) {
            el[data.key] = data.target.value
            return el
          } else {
            return el
          }
        }
      })
    }
    let result = {
      dataForTable: getData()
    }
    this.updateDeviceData(result)
  }

  generateTableBody = (data) => {
    let arr = []
    for (let i = 0; i < data.length; i++) {
      const element = data[i]
      let xAxias = {
        tdParams: {},
        content: (
          <div key={i}>
            <RadioButton
              checked={element.timeXAsias.active}
              disabled={element.timeXAsias.disabled}
              classNameLabel={"vls-table_data-oscilloscope-label_for_radio_btn"}
              key={i}
              id={element.id}
              label={element.timeXAsias.title}
              onClick={(e) => {
                let params = {
                  linkIter: i,
                  target: e.target,
                  key: "timeXAsias",
                  type: "radio"
                }
                this.dataForTableChange(params)
              }}
            />
          </div>
        ),
      };
      let valueDivision = {
        tdParams: { className: "td_padding_null" },
        content: (
          <div key={i}>
            <InputValueData
              wrapperClassName={'vls-table_data-oscilloscope-input_wrapper'}
              className={"data vls-table_data-oscilloscope-input"}
              type={'number'}
              value={element.valueDivision}
              id={element.id}
              onChange={(e) => {
                let params = {
                  linkIter: i,
                  target: e.target,
                  key: "valueDivision",
                  type: "input"
                }
                this.dataForTableChange(params)
              }}
            />
          </div>
        ),
      };
      let startCount = {
        tdParams: { className: "td_padding_null" },
        content: <div key={i} className={``}>
          <InputValueData
            wrapperClassName={'vls-table_data-oscilloscope-input_wrapper'}
            className={"data vls-table_data-oscilloscope-input"}
            type={'number'}
            id={element.id}
            value={element.startCount}
            onChange={(e) => {
              let params = {
                linkIter: i,
                target: e.target,
                key: "startCount",
                type: "input"
              }
              this.dataForTableChange(params)
            }}
          />
        </div>,
      };
      arr.push([xAxias, valueDivision, startCount])

    }
    return arr
  }

  tabsHandler = (e) => {
    const { tabs } = this.state
    const setTabsData = () => {
      return tabs.map((el, i) => {
        let name = e.target.outerText
        let user = window.navigator
        if (user.appCodeName === 'Mozilla') {
          name = e.target.textContent
        }
        if (el.name === name) {
          return {
            ...el,
            active: true
          }
        } else {
          return {
            ...el,
            active: false
          }
        }
      })
    }
    let data = {
      tabs: setTabsData()
    }
    this.updateDeviceData(data)

  }

  setDataTable = (props) => {
    const { measurementQuantity } = props
    this.setState({
      titleClassName: "vls-table_data-oscilloscope-title",
      tabs: [
        {
          name: 'Напряжение',
          active: measurementQuantity.voltage
        }, {
          name: 'Потенциал',
          active: measurementQuantity.pairPotential
        },
      ],
      TableHeadData: [
        [
          {
            name: 'По оси Х (время)',
            attr: { rowSpan: 2 }
          }, { name: 'По оси Y (напряжение)', attr: { colSpan: 2 } }],
        [{ name: 'Цена деления', attr: {} }, { name: 'Начало отчёта', attr: {} }]
      ],
      dataForTable: measurementQuantity.tableData,
      TableBodyData: this.generateTableBody(measurementQuantity.tableData),
      variant: "vls-table_data-oscilloscope-table"
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.measurementQuantity, prevProps.measurementQuantity)) {
      this.setDataTable(this.props)
    }
    if (prevState.dataForTable !== this.state.dataForTable) {
      this.setState({
        TableBodyData: this.generateTableBody(this.state.dataForTable),
      })
    }
  }

  componentDidMount() {
    this.setDataTable(this.props)
  }

  render() {
    const {
      tabs,
      titleClassName,
      TableHeadData, TableBodyData, variant
    } = this.state
    return (
      <React.Fragment >
        <div className='wrapper-device-setting vls-table_data-oscilloscope'>
          <Tabs
            tabs={tabs}
            addClassWrapper={"vls-table_data-oscilloscope"}
            aboutOfTabs='Измеряемая величина'
            titleClassName={titleClassName}
            onClick={this.tabsHandler}
            helpText={"max.напряжение, выдерживаемое прибором (B)= ххххх"}
          />
          <TableGenerate
            variant={variant}
            TableHeadData={TableHeadData}
            TableBodyData={TableBodyData}
          />
        </div>
        <div className='content-border-line vls-table_data-oscilloscope-line_out'></div>
      </React.Fragment >
    )
  }
}
export default TableDataForOscilloscope