import PromiseWorker from '../../WorkerUtils'

/**
 * соберет объект подключений
 * @return <object>[]
 * @param el = {id,type,polarity,color,connections,vertices}
 * @param minObjDrag = {}
 * @param lastConnector = {}
 */
const generateConnections = (el, minObjDrag, lastConnector) => {
  let newArrConnections = el.connections
  if (!newArrConnections) {
    newArrConnections = []
  }
  if (lastConnector && minObjDrag) {
    el.vertices.map((el2, i) => {
      if (el2.id === minObjDrag.uuidConnector) {
        newArrConnections.push({
          isConnected: true,
          firstConnector: minObjDrag,
          lastConnector: lastConnector,
        })
      }
    });
  }
  return newArrConnections;
};
/**
		@param wiresData 		===== объект с вершинами линий и прочими параметрами
		@param lineData 		===== объект с координатами в масиве где лежит вершина по которой кликнули
		@param newVector		===== новые данные которые втыкаются на место старого
 		@param onCollisions ===== булево значение говорящее от том произошлали колизия
 		@param minObjDrag		===== объект с которым произошла колизия
 		@param lastConnector ===== {}
 		@return вернет новый объект с вершинами для линий и конекторами на концах
 */
export const stateSerializedVLCA = ({
  wiresData,
  lineData,
  newVector,
  onCollisions,
  minObjDrag,
  lastConnector,
}) => {
  //todo: чини исчезновение линий
  let newData = wiresData.map((el, i) => {
    return {
      ...el,
      vertices: el.vertices.map((el2, i2) => {
        if (
          !onCollisions &&
          i === lineData.iterArrToAllVertices &&
          i2 === lineData.iterArrToContextVertices
        )
          return {
            id: el2.id,
            position: minObjDrag.position,
            type: "Mesh",
            idMesh: minObjDrag.uuid,
            uuidConnector: minObjDrag.uuidConnector,
            mesh: lastConnector ? lastConnector : minObjDrag,
          };
        else if (
          onCollisions &&
          i === lineData.iterArrToAllVertices &&
          i2 === lineData.iterArrToContextVertices
        ) {
          return {
            id: el2.id,
            position: minObjDrag.position,
            type: "Mesh",
            idMesh: minObjDrag.uuid,
            uuidConnector: minObjDrag.uuidConnector,
            mesh: lastConnector ? lastConnector : minObjDrag,
          }; //todo: подумать над оптимизацией из объекта меша вынимать только то что нужно
        } else {
          return el2;
        }
      }),
      verticesLength: el.vertices.length,
      connections: generateConnections(el, minObjDrag, lastConnector),
    };
  });
  newData = newData.filter((el) => el.typeOf === undefined);



  return newData;
};
export const stateSerializedVLC = async (data) => {
  if(data.lastConnector) {
    data.lastConnector.scene = undefined
    data.lastConnector.parent = undefined
  }
  return new PromiseWorker({
    url: "/web-worker/WireStateSerializedVLC.js"
  }).init().invoke(data).then((el) => {
    return el
  })
};
