import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { PATH } from '../../const';
import SubmitForm from '../SubmitForm';
import {
  valuePhoneNumberRU,
  valueEmailRU,
  replaceResponseHelpText,
  valueNameUser,
} from '../../utils';
import Api from '../../api';
import ModalApply from '../../common/ModalApply';
import QuestionImg from '../../assets/images/questionModal.svg';
import ModalDefaultLayout from '../../Views/Layout/ModalDefaultLayout';
import Notification from '../../common/Notification';
import useStoreon from 'storeon/react';

const ApiContent = Api.ContentApi;

const RegisterInSystem = (props) => {
  const { dispatch, Notification } = useStoreon('Notification');
  const { openErrorBlockHead, closeErrorBlockHead } = props;
  const [isShowModalQuestrion, setIsShowModalQuestrion] = useState(false);
  const textError =
    'Нам не удалось найти аккаунт, соответствующий введенному вами адресу электронной почты и паролю. Пожалуйста, проверьте свою электронную почту и пароль и попробуйте снова.';
  let notifiData = {
    content: {
      contentHeader: null,
      contentBody: <span>Заявка отправлена!</span>,
    },
    isShow: true,
    close: () => {
      dispatch('Notification', {
        content: {
          contentHeader: null,
          contentBody: null,
        },
        isShow: false,
        close: () => {},
      });
    },
  };
  // //контроль за уведомлениями
  // const [notifiData, setNotifiData] = useState(defaultDataNotifi);
  //контроль за данными
  const [viewPass, setViewPass] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('+7 (___) __-__-___');
  const [email, setEmail] = useState({ value: '', isValid: false });
  const [password, setPassword] = useState('');
  const [helpTextName, setHelpTextName] = useState('');
  const [helpTextPhone, setHelpTextPhone] = useState('');
  const [helpTextEmail, setHelpTextEmail] = useState('');
  const [helpTextPassword, setHelpTextPassword] = useState('');
  //контроль за ошибками
  const [errorName, setErrorName] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  //задать вопрос
  const defaultQuestrionData = {
    name: { valid: false, value: '' },
    email: { valid: false, value: '' },
    fase: { valid: false, value: '' },
    phone: { valid: false, value: '' },
  };
  const [questrionData, setQuestrionData] = useState(defaultQuestrionData);
  const setDataQuestrionHandler = (e, isValid = false) => {
    const key = e.target.dataset?.name;
    setQuestrionData({
      ...questrionData,
      [key]: {
        value: e.target.value,
        valid: isValid,
      },
    });
  };
  const submitBtnHandler = () => {
    let requiredFieldIsEmpty = false;
    for (const key in questrionData) {
      if (Object.hasOwnProperty.call(questrionData, key)) {
        const element = questrionData[key];
        if (!element.valid) {
          requiredFieldIsEmpty = true;
        }
      }
    }
    if (!requiredFieldIsEmpty) {
      ApiContent.postRequests({
        name: questrionData.name.value,
        email: questrionData.email.value,
        phone: questrionData.phone.value,
        company_title: questrionData.fase.value,
      })
        .then((res) => {
          openOrCloseModalQuestrion(false);
          dispatch('Notification', notifiData);
        })
        .catch((err) => {});
    }
  };
  //
  const isValidParams = () => {
    //
  };
  const registrations = (e) => {
    e.preventDefault();
    let isValid = isValidParams();
    if (isValid) return;
    const userApi = Api.UserApi;
    let params = {
      email: email.value,
      first_name: name,
      last_name: name,
      patronymic: name,
      phone: phone,
      password: password,
      password_confirm: password,
      consent: true,
    };
    SubmitForm(
      userApi.registration,
      params,
      (e) => {
        closeErrorBlockHead();
        if (e.status === 201) window.location.href = PATH.Home;
      },
      (err) => {
        if (err.response.status === 400) {
          let { data } = err.response;

          openErrorBlockHead(textError);
          if (data.first_name) {
            setErrorName('error-input');
            setHelpTextName(data.first_name);
          } else {
            setErrorName('');
            setHelpTextName('');
          }
          if (data.email) {
            setErrorEmail('error-input');
            setHelpTextEmail(data.email);
          } else {
            setErrorEmail('');
            setHelpTextEmail('');
          }
          if (data.phone) {
            setErrorPhone('error-input');
            setHelpTextPhone(data.phone);
          } else {
            setErrorPhone('');
            setHelpTextPhone('');
          }
          if (data.password) {
            setErrorPassword('error-input');
            setHelpTextPassword(replaceResponseHelpText(data.password));
          } else {
            setErrorPassword('');
            setHelpTextPassword('');
          }
        }
      },
    );
  };

  const openOrCloseModalQuestrion = (data) => {
    setIsShowModalQuestrion(data);
  };
  return (
    <React.Fragment>
      <div>
        <ModalDefaultLayout
          isShow={isShowModalQuestrion}
          contentForModal={
            <ModalApply
              onChangeData={setDataQuestrionHandler}
              dataStates={questrionData}
              closeModal={() => openOrCloseModalQuestrion(false)}
            />
          }
          titleModal={'Заявка на регистрацию'}
          imgModal={QuestionImg}
          btnText={'оставить заявку'}
          submitBtnHandler={submitBtnHandler}
          closeModal={() => openOrCloseModalQuestrion(false)}
        />
        <div className={'auth_and_reg-right_form-error_block'}></div>
        <Form className={'auth_and_reg-right_form auth_and_reg-right_form_reg'}>
          <Form.Label className={'auth_and_reg-right_form-heder'}>Регистрация</Form.Label>
          <Form.Group controlId="formName">
            <Form.Label className={'auth_and_reg-right_form-label'}>Имя</Form.Label>
            <Form.Control
              value={name}
              onChange={(e) => {
                let value = e.target.value;
                value = valueNameUser(value);
                setName(value);
              }}
              className={`auth_and_reg-right_form-input_style ${errorName}`}
              type="name"
              placeholder="Имя"
            />
            <Form.Text
              className={`auth_and_reg-right_form-help_text ${helpTextName.length ? 'active' : ''}`}
            >
              {helpTextName}
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicPhone">
            <Form.Label className={'auth_and_reg-right_form-label'}>Мобильный телефон</Form.Label>
            <Form.Control
              value={phone}
              onChange={(e) => {
                let value = e.target.value;
                value = valuePhoneNumberRU(value);
                setPhone(value);
              }}
              className={`auth_and_reg-right_form-input_style ${errorPhone}`}
              type="name"
              placeholder="+7 (___) __-__-___"
            />
            <Form.Text
              className={`auth_and_reg-right_form-help_text ${
                helpTextPhone.length ? 'active' : ''
              }`}
            >
              {helpTextPhone}
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label className={'auth_and_reg-right_form-label'}>Email</Form.Label>
            <Form.Control
              value={email.value}
              onChange={(e) => {
                let value = e.target.value;
                let isValid = valueEmailRU(value);
                setEmail({
                  value: value,
                  isValid: isValid,
                });
              }}
              className={`auth_and_reg-right_form-input_style ${errorEmail}`}
              type="email"
              placeholder="Введите адрес эл.почты"
            />
            <Form.Text
              className={`auth_and_reg-right_form-help_text ${
                helpTextEmail.length ? 'active' : ''
              }`}
            >
              {helpTextEmail}
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label className={'auth_and_reg-right_form-label'}>
              Пароль (8+ символов)
            </Form.Label>
            <div>
              <div className="auth_and_reg-right_form-input">
                <Button
                  onClick={() => setViewPass(!viewPass)}
                  className={`auth_and_reg-right_form-btn_view_pass_registration ${
                    !viewPass ? ' deactive' : ' active'
                  }`}
                ></Button>
                <Form.Control
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={`auth_and_reg-right_form-input_style ${errorPassword}`}
                  type={`${!viewPass ? 'password' : 'text'}`}
                  placeholder="Введите пароль"
                />
              </div>
            </div>
            <Form.Text
              className={`auth_and_reg-right_form-help_text ${
                helpTextPassword.length ? 'active' : ''
              }`}
            >
              {helpTextPassword}
            </Form.Text>
          </Form.Group>
          <Button
            // onClick={registrations}//todo: в необходимое время вернуть возможность регистрации
            onClick={() => openOrCloseModalQuestrion(true)}
            className={'auth_and_reg-right_form-sign_in_btn'}
            // type="submit"
          >
            {/* Зарегистрироваться */}
            Заявка на регистрацию
          </Button>
          <Form.Group controlId="formBasicCheckbox">
            {/* <Form.Check type="checkbox" label="Check me out" /> */}
            <Form.Label className={'auth_and_reg-right_form-terms_use'}>
              <span>
                Продолжая, вы соглашаетесь с
                <span className={'auth_and_reg-right_form-terms_use_link'}>
                  <NavLink to={'#'}>
                    {' '}
                    Условиями использования и Политикой конфиденциальности{' '}
                  </NavLink>
                </span>{' '}
                <span className={'auth_and_reg-right_form-terms_use_link-last_el'}>
                  Garpix Digital Twin.
                </span>
              </span>
            </Form.Label>
            <div className={'auth_and_reg-right_form-registrations_text'}>
              Уже есть аккаунт?{' '}
              <NavLink
                className={'auth_and_reg-right_form-registrations_link'}
                to={PATH.Authorization}
              >
                Войти.
              </NavLink>
            </div>
          </Form.Group>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default RegisterInSystem;
