import React from "react";
import { NavLink } from "react-router-dom";
import { PATH } from "../../const";

const Error404 = (props) => {
  return (
    <div className={"error_page-not_found"}>
      <div className={"error_page-not_found-info"}>
        <span className={"error_page-not_found-info-green_text"}>404</span>
        <p className={"error_page-not_found-info-under_text"}>
          Извините, страница не найдена.
        </p>
        <div className={"error_page-not_found-info-link_to_home"}>
          <NavLink to={PATH.Home}>На главную</NavLink>
        </div>
      </div>
    </div>
  );
};
export default Error404;
