export let DataCountAllPrimitiveScenePFML = (store) => {
    let defaultData = {
        dots: 0,
        primitive: 0,
        subareas: 0,
        nodes: 0,
        elements: 0
    }
    store.on("@init", () => ({ DataCountAllPrimitiveScenePFML: defaultData }));
    store.on("DataCountAllPrimitiveScenePFML", ({ DataCountAllPrimitiveScenePFML }, data) => {
        if (data === "clear") {
            return { DataCountAllPrimitiveScenePFML: defaultData };
        }
        return {
            DataCountAllPrimitiveScenePFML: data,
        };
    });

};