import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ButtonMain from '../ButtonMain'

class ModalInfoSuccessLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      showSuccessInfoModal,
      closeSuccessInfoModal,
      paramsSuccessInfoModal,
    } = this.props;

    return (
      <Modal
        animation={false}
        show={showSuccessInfoModal}
        backdropClassName={"modal-wrap-backdrop"}
        className={"modal-wrap"}
        onHide={closeSuccessInfoModal}
      >
        <div
          onClick={closeSuccessInfoModal}
          className={"modal-close_btn"}
        ></div>
        <img
          className={"modal-icon"}
          src={paramsSuccessInfoModal.icon}
          alt={paramsSuccessInfoModal.altForIcon}
        />
        <div className={"modal-text_info_block"}>
          {paramsSuccessInfoModal.text}
        </div>
        <div>
          {/* //modal_component_wrap */}
          <ButtonMain onClick={() => {
            if (paramsSuccessInfoModal.callbackClickBtn)
              paramsSuccessInfoModal.callbackClickBtn()
            closeSuccessInfoModal()
          }} className={"modal-btn_success"}>Хорошо</ButtonMain>
        </div>
      </Modal>
    );
  }
}
export default ModalInfoSuccessLayout;
