export let Notification = (store) => {
  const defaultDataNotifi = {
    content: {
      contentHeader: null,
      contentBody: null,
    },
    isShow: false,
    close: () => {}
  };
  store.on('@init', () => ({ Notification: defaultDataNotifi }));
  store.on('Notification', ({ Notification }, data) => {
    return { Notification: data };
  });
};
