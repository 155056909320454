import React from 'react'


const Section = (props) => {
    return (
        <div className={"modal-params_subareas-wrapper-content-section"}>
            {
                props.children
            }
        </div>
    )
}

export default Section