import React from "react";
import Api from "../../api";
import { ReactComponent as PlusVector } from "../../assets/images/PlusVector.svg";
import { ReactComponent as MinusForFaq } from "../../assets/images/minusForFaq.svg";
import ModalQuestion from "../../common/ModalQuestion";
import ModalApply from "../../common/ModalApply";
import ModalOk from "../../common/ModalOk";
import ModalNotOk from "../../common/ModalNotOk";
import ModalDefaultLayout from "../../Views/Layout/ModalDefaultLayout/ModalDefaultLayout";
import QuestionImg from "../../assets/images/questionModal.svg";
import ApplyImg from "../../assets/images/applyModal.svg";
import NotOkImg from "../../assets/images/notOkModal.svg";
import OkImg from "../../assets/images/okModal.svg";
const ApiContent = Api.ContentApi;
class FaqHomePageComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      faqRenderList: [],
      isVisibleResponseTextId: false,
      isShow: false,
      contentForModal: null,
      titleModal: "",
      imgModal: "",
      btnText: ""
    };
    this.enumTypesContentModal = {
      'question': {
        'key': 'question',
        'title': 'Отправить вопрос',
        'img': QuestionImg,
        'btnText': 'Отправить'
      },
      'apply': {
        'key': 'apply',
        'title': 'Оставить заявку',
        'img': ApplyImg,
        'btnText': 'Отправить'
      },
      'ok': {
        'key': 'ok',
        'title': 'Отправлено',
        'img': OkImg,
        'btnText': 'Хорошо'
      },
      'notOk': {
        'key': 'notOk',
        'title': 'Не удалось отправить',
        'img': NotOkImg,
        'btnText': 'Попробовать заново'
      },
    };

  }

  closeModal = () => {
    this.setState({
      isShow: false,
      contentForModal: null,
      titleModal: "",
      imgModal: "",
      btnText: ""
    })
  }

  getContentFromType = (type) => {
    switch(type) {
      case this.enumTypesContentModal.question.key:
        return <ModalQuestion closeModal={this.closeModal} />
      case this.enumTypesContentModal.apply.key:
        return <ModalApply closeModal={this.closeModal} />
      default:
        return 'default content';
    }
  }

  getTitleFromType = (type) => {
    switch(type) {
      case this.enumTypesContentModal.question.key:
        return this.enumTypesContentModal.question.title;
      case this.enumTypesContentModal.apply.key:
        return this.enumTypesContentModal.apply.title;
      default:
        return 'default title';
    }
  }

  getImgFromType = (type) => {
    switch(type) {
      case this.enumTypesContentModal.question.key:
        return this.enumTypesContentModal.question.img;
      case this.enumTypesContentModal.apply.key:
        return this.enumTypesContentModal.apply.img;
      default:
        return 'default content';
    }
  }

  getBtnTextFromType = (type) => {
    switch(type) {
      case this.enumTypesContentModal.question.key:
        return this.enumTypesContentModal.question.btnText;
      case this.enumTypesContentModal.apply.key:
        return this.enumTypesContentModal.apply.btnText;
      default:
        return 'default content';
    }
  }

  openModal = (type) => {
    this.setState({
      isShow: true,
      contentForModal: this.getContentFromType(type),
      titleModal: this.getTitleFromType(type),
      imgModal: this.getImgFromType(type),
      btnText:this.getBtnTextFromType(type)
    })
  }

  clickScrollTop = (e) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  componentDidMount() {
    ApiContent.getQuestionsForFaq({}).then((res) => {
      this.setState({
        faqRenderList: res,
      },() => {        
        this.clickScrollTop();
      });
    });
  }

  render() {
    const { faqRenderList, isVisibleResponseTextId, isShow, contentForModal, closeModal, titleModal, imgModal, btnText } = this.state;

    return (
      <React.Fragment>
        <ModalDefaultLayout isShow={isShow} contentForModal={contentForModal} titleModal={titleModal} imgModal={imgModal} btnText={btnText} closeModal={this.closeModal}/>
        <div className={"faq_page-wrap"}>
          <h2 className={"faq_page-wrap-title"}>FAQ</h2>
          <span className={"faq_page-wrap-text_under_title"}>
            Перед вами лист с самыми популярными вопросами и ответами. Если вы не
            найдете ответ на свой вопрос, то вы можете <span
            onClick={() => {
              this.openModal(this.enumTypesContentModal.question.key);
            }}> &nbsp;отправить нам запрос </span> и мы ответим на ваш e-mail
          </span>
          <div className={"faq_page-wrap-content_wrap"}>
            {faqRenderList.map((el, i) => {
              return (
                <div
                key={i}
                className={"faq_page-wrap-wrap_question_block"}
                onClick={(e) => {
                  this.setState({
                    isVisibleResponseTextId:
                      isVisibleResponseTextId === i ? false : i,
                  });
                }}>
                  <div
                    className={`faq_page-wrap-wrap_question_block-question ${
                      isVisibleResponseTextId === i ? "active" : ""
                    }`}
                  >
                    {el.question}
                    {isVisibleResponseTextId === i ? (
                      <MinusForFaq
                        className={"faq_page-wrap-wrap_question_block-minus"}
                      />
                    ) : (
                      <PlusVector
                        className={"faq_page-wrap-wrap_question_block-plus"}
                      />
                    )}
                  </div>

                  <div
                    className={`faq_page-wrap-wrap_question_block-response ${
                      isVisibleResponseTextId === i ? "active" : ""
                    }`}
                  >
                    {el.answer}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FaqHomePageComponent;
