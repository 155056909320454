import { sercheObjectByProperty } from '../../index'
let { Vector3 } = require("three")
/**
 * просто гетер проверяющий тип предоставляемых данных и на их основе вовзращяет данные
 * @param {*} data 
 */
const getPositionMesh = (data) => {
  let result = [];
  if (data.position instanceof Vector3 || data.position instanceof Object) {
    result = [
      data.position.x,
      data.position.y,
      data.position.z,
    ]
  }
  if (Array.isArray(data.position)) {
    result = [
      data.position[0],
      data.position[1],
      data.position[2],
    ]
  }
  return result
}
/**
 * просто гетер проверяющий тип предоставляемых данных и на их основе вовзращяет данные
 * @param {*} data 
 */
const getIdMesh = (data) => {
  let result = null;
  if (!data.idMesh) return null
  if (typeof data.idMesh === "object") {
    result = data.mesh.uuid
  } else if (typeof data.idMesh === "string") {
    result = data.idMesh
  } else {
    result = null
  }
  return result
}
/**
 * просто гетер проверяющий тип предоставляемых данных и на их основе вовзращяет данные
 * @param {*} data 
 */
const getParentId = (data) => {
  let result = null;
  if (data.parent) {
    if (typeof data.parent === "object") {
      return data.parent.uuid
    }
  }
  if (!data.parentUuid) return null
  if (typeof data.parentUuid === "object") {
    result = data.parent.uuid
  } else if (typeof data.parentUuid === "string") {
    result = data.parentUuid
  } else {
    result = null
  }
  return result
}
/**
 *  получает все данные из состояния сцены и вовзращяет их минифицированную версию
 * @param { wiresData: [], componentData: [], couplingSleeve: [] } data
 * @param scene - link to object scene
 * @return { wiresData: [<min>], componentData: [<min>] }
 */
export const savedMinifyObjects = (data, scene) => {
  // получит масив конекторов для объекта терминала
  const getConnectorsForIsTerminalObj = (objectTerminal) => {
    for (let i = 0; i < data.componentData.length; i++) {
      const element = data.componentData[i];
      if (element.id === objectTerminal.parent.uuid) {
        let connectors = element.connectors
        if (connectors)
          return connectors
      }
    }
  }

  // проверит терминал это или нет
  const getIsTerminalObj = (obj) => {
    if (obj)
      if (obj.parentUuid) {
        let device = scene.getObjectByProperty("uuid", obj.parentUuid)
        if (device) {
          let terminalObjectFromDevice = device.getObjectByProperty("name", "terminal")
          if (terminalObjectFromDevice) {
            return true
          } else {
            return false
          }
        }

      }
  }
  //для установки простых данных в подключения
  const setConnectors = (obj) => {
    return {
      name: obj.name,
      isTerminal: getIsTerminalObj(obj),
      position: getPositionMesh(obj),
      uuid: obj.uuid,
      parentUuid: getParentId(obj),
      uuidConnector: obj.uuidConnector
    };
  };

  // фильтрует большие объекты из данных проводов
  const wiresFilterMinify = (wires) => {
    for (let i = 0; i < wires.length; i++) {
      const el = wires[i];
      let { vertices } = el;
      let newConnectorsElement = []
      el.connections.map(el => {
        if (el.isConnected) {
          newConnectorsElement.push({
            ...el,
            firstConnector: setConnectors(el.firstConnector),
            lastConnector: setConnectors(el.lastConnector),
          })
        } else {
          newConnectorsElement.push({
            ...el
          })
        }
      })
      el.connections = newConnectorsElement
      for (let j = 0; j < vertices.length; j++) {
        const elVertices = vertices[j];
        vertices[j] = {
          id: elVertices.id,
          idMesh: getIdMesh(elVertices),
          position: getPositionMesh(elVertices),
          type: elVertices.type,
        };
      }
    }
    return wires;
  };
  // уменьшает объект с компонентами сцены в конкретном случае все элементы цепи
  const componentDataFilterMinify = (component) => {
    return component;
  };
  const couplingSleeveDataFilterMinify = (couplingSleeve) => {
    return couplingSleeve
  }
  // собирает конечный объект и вовзращяет его из сериалайзера
  let newData = {
    wiresData: wiresFilterMinify(data.wiresData),
    componentData: componentDataFilterMinify(data.componentData),
    couplingSleeve: couplingSleeveDataFilterMinify(data.couplingSleeve)
  };
  return newData;
};
