import React from "react";

const Th = (props) => {
  const { TableHeadData } = props;
  if (!TableHeadData) {
    return null;
  }
  return (
    <React.Fragment>
      {TableHeadData.map((el, i) => {
        return (
          <tr key={i}>
            {el.map((el, j) => {
              // if()
              if (typeof el === 'string') {
                return <th key={j}>{el}</th>;
              } else if (typeof el === 'object') {
                return <th {...el.attr} key={j}>{el.name}</th>;
              }

            })}
          </tr>
        );
      })}
    </React.Fragment>
  );
};

export default Th;
