import { AmbientLight as ThreeAmbientLight } from 'three'
import Light from './Light'


class AmbientLight extends Light {
    constructor({
        scene,
        name = 'AmbientLight',
        color=0xFFFFFF,
        intensity = 1
    } = {}) {
        super()
        this.obj = new ThreeAmbientLight(color, intensity);
        this.obj.name = name;
        this.uuid = this.obj.uuid;
        this.addToScene(scene)
    }
}

export default AmbientLight;