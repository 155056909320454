import React from 'react';
import ParamsDeviceValue from '../../Views/Layout/ParamsDeviceValue';
import { Tabs as RBTabs, Tab } from 'react-bootstrap';
import ChooseCurrent from '../../Views/Layout/ChooseCurrent';
import TableInfo from '../../Views/Layout/TableInfo';
import uuid from 'uuid';
import InputValueData from '../../Views/Layout/InputValueData';
import isEqual from 'react-fast-compare';
import SubmitForm from '../../common/SubmitForm';
import v4 from 'uuid/v4';
import Api from '../../api';
import connect from 'storeon/react/connect'

const ApiResearch = Api.ResearchApi;
class VoltageSourceComponentForSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabKey: 'base',
      valueNumberPhases: 3,
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Имя', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'ЭДС (В)', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Внутр.сопротивление', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Частота, Гц', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Предел напряжения', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Min. частота, Гц', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Max. частота, Гц', value: 9.9999e42 },
      ],
      paramsDeviceCustomMeta: {
        title: 'Род тока',
        activeChecked: false,
        titleClassName: 'vls-sidebar-title_device_meta_data',
        valueRadioButton: [
          {
            titleClassName: 'vls-sidebar-title_device_setings',
            title: 'Соединение',
            options: [
              { id: uuid.v4(), name: 'звезда', active: false, disabled: false },
              { id: uuid.v4(), name: 'треугольник', active: true, disabled: false },
            ],
          },
          {
            titleClassName: 'vls-sidebar-title_device_setings',
            title: 'Регулировка',
            options: [
              { id: uuid.v4(), name: 'частоты', active: true, disabled: false },
              { id: uuid.v4(), name: 'напряжения', active: false, disabled: false },
            ],
          },
        ],
      },
      tableInfo: [
        { designation: '№', data: ['1', '2', '3'] },
        { designation: 'Фаза (град)', data: [0.1, 0.1, 0.1] },
      ],
    };
  }

  submitData = () => {
    let { hash_id, type } = this.props.element;
    const { paramsDeviceCustomMeta, paramsDeviceDataNamefields, tableInfo } = this.state;
    if (!hash_id) hash_id = this.props.element.id;
    let params = {
      device_id: hash_id,
      params: {
        type: type,
        meta: {
          defaultSettings: {
            name: paramsDeviceDataNamefields[0].value,
            EMF: paramsDeviceDataNamefields[1].value,
            internalResistance: paramsDeviceDataNamefields[2].value,
            frequencyGz: paramsDeviceDataNamefields[3].value,
            voltageLimit: paramsDeviceDataNamefields[4].value,
            minFrequencyGz: paramsDeviceDataNamefields[5].value,
            maxFrequencyGz: paramsDeviceDataNamefields[6].value,
          },
          kindOfCurrent: {
            alternatingCurrent: paramsDeviceCustomMeta.activeChecked,
            DC: !paramsDeviceCustomMeta.activeChecked,
            compound: paramsDeviceCustomMeta.valueRadioButton[0].options,
            adjustment: paramsDeviceCustomMeta.valueRadioButton[1].options,
          },
          numberOfPhases: tableInfo,
        },
      },
    };
    this.props.dispatch('SidebarDataFromDevice', {
      isUpdate: true,
      hash_id: hash_id,
      data: params.params.meta,
    });
    SubmitForm(ApiResearch.putResearchUpdateDeviceData, params, this.successSubmit);
  };
  successSubmit = (response) => {
    console.log(response);
  };

  updateDeviceData = (data) => {
    this.setState(data, this.submitData);
  };

  defaultDeviceDataChange = (e) => {
    const { paramsDeviceDataNamefields } = this.state;
    let resultsUpdate = [];
    for (let i = 0; i < paramsDeviceDataNamefields.length; i++) {
      const element = paramsDeviceDataNamefields[i];
      if (element.id === e.target.id) {
        resultsUpdate.push({
          ...element,
          value: e.target.value,
        });
      } else {
        resultsUpdate.push(element);
      }
    }
    let newData = {
      paramsDeviceDataNamefields: resultsUpdate,
    };
    this.updateDeviceData(newData);
  };

  changeContextPhases = (e) => {
    const { tableInfo } = this.state;
    let lintToObjectIter = e.currentTarget.dataset.iteration;
    const getTableInfoData = () => {
      let results = [];
      results = tableInfo.map((el, i) => {
        let newData = [];
        el.data.forEach((elData, iter) => {
          if (iter === Number(lintToObjectIter)) {
            if (typeof elData !== 'string') {
              elData = Number(e.currentTarget.value);
              newData.push(elData);
            } else {
              newData.push(elData);
            }
          } else {
            newData.push(elData);
          }
        });
        return {
          ...el,
          data: newData,
        };
      });
      return results;
    };
    let newData = {
      tableInfo: getTableInfoData(),
    };
    this.updateDeviceData(newData);
  };

  handlerNumberPhases = (e) => {
    const { tableInfo } = this.state;
    const getTableInfoData = () => {
      let results = [];
      results = tableInfo.map((el, i) => {
        let newData = [];
        for (let j = 1; j <= e.target.value; j++) {
          if (i === 0) {
            newData.push(`${j}`);
          } else {
            newData.push(el.data[j] ? el.data[j] : 240.00001);
          }
        }
        return {
          ...el,
          data: newData,
        };
      });
      return results;
    };
    let newData = {
      valueNumberPhases: e.target.value,
      tableInfo: getTableInfoData(),
    };
    this.updateDeviceData(newData);
  };

  handlerTogglerSwitcher = (e) => {
    const { paramsDeviceCustomMeta } = this.state;
    let newData = {
      paramsDeviceCustomMeta: {
        ...paramsDeviceCustomMeta,
        activeChecked: !paramsDeviceCustomMeta.activeChecked,
      },
    };
    this.updateDeviceData(newData);
  };

  handlerChooseRadioButton = (e) => {
    const { paramsDeviceCustomMeta } = this.state;
    const setvalueRadioButtonData = () => {
      return paramsDeviceCustomMeta.valueRadioButton.map((el, i) => {
        let changeCheck = false;
        let result = el.options.map((elOption, i) => {
          if (elOption.id === e.target.id) {
            changeCheck = true;
            return {
              ...elOption,
              active: true,
            };
          } else {
            return {
              ...elOption,
              active: false,
            };
          }
        });
        return {
          ...el,
          options: changeCheck ? result : el.options,
        };
      });
    };
    let newData = {
      paramsDeviceCustomMeta: {
        ...paramsDeviceCustomMeta,
        valueRadioButton: setvalueRadioButtonData(),
      },
    };
    this.updateDeviceData(newData);
  };

  setDataDevice = (props) => {
    const { element } = props;
    const { defaultSettings, kindOfCurrent, numberOfPhases } = element.meta;
    this.setState({
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Имя', value: defaultSettings.name },
        { id: v4(), nameOfValue: 'ЭДС (В)', value: defaultSettings.EMF },
        { id: v4(), nameOfValue: 'Внутр.сопротивление', value: defaultSettings.internalResistance },
        { id: v4(), nameOfValue: 'Частота, Гц', value: defaultSettings.frequencyGz },
        { id: v4(), nameOfValue: 'Предел напряжения', value: defaultSettings.voltageLimit },
        { id: v4(), nameOfValue: 'Min. частота, Гц', value: defaultSettings.minFrequencyGz },
        { id: v4(), nameOfValue: 'Max. частота, Гц', value: defaultSettings.maxFrequencyGz },
      ],
      paramsDeviceCustomMeta: {
        title: 'Род тока',
        activeChecked: kindOfCurrent.alternatingCurrent,
        titleClassName: 'vls-sidebar-title_device_meta_data',
        valueRadioButton: [
          {
            titleClassName: 'vls-sidebar-title_device_setings',
            title: 'Соединение',
            options: kindOfCurrent.compound,
          },
          {
            titleClassName: 'vls-sidebar-title_device_setings',
            title: 'Регулировка',
            options: kindOfCurrent.adjustment,
          },
        ],
      },
      valueNumberPhases: numberOfPhases[0].data.length,
      tableInfo: numberOfPhases,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.element, prevProps.element)) {
      this.setDataDevice(this.props);
    }
  }

  componentDidMount() {
    this.setDataDevice(this.props);
  }

  render() {
    const {
      activeTabKey,
      paramsDeviceDataNamefields,
      paramsDeviceCustomMeta,
      tableInfo,
      valueNumberPhases,
    } = this.state;
    return (
      <React.Fragment>
        <span className={'vls-sidebar-device_title'}>ИСТОЧНИК НАПРЯЖЕНИЯ</span>
        <RBTabs
          id="vls-sidebar_tabs_wraper"
          defaultActiveKey={'base'}
          activeKey={activeTabKey}
          onSelect={this.selectTab}
        >
          <Tab
            tabClassName={'vls-sidebar_tabs_wraper-tab vls-sidebar_tabs_wraper-tab_only'}
            eventKey="base"
            title="Параметры"
          >
            <ParamsDeviceValue
              onChange={this.defaultDeviceDataChange}
              classNameForInput={'vls-sidebar_tabs_wraper-tab-input'}
              data={paramsDeviceDataNamefields}
            />
            <ChooseCurrent
              handlerTogglerSwitcher={this.handlerTogglerSwitcher}
              handlerChooseRadioButton={this.handlerChooseRadioButton}
              paramsDeviceCustomMeta={paramsDeviceCustomMeta}
            />
            <TableInfo onChange={this.changeContextPhases} data={tableInfo}>
              <div className={'vls-voltage_source-input_number'}>
                <InputValueData
                  min={1}
                  max={3}
                  classNameFirstDescription={'vls-sidebar_tabs_wraper-tab-input_number_label'}
                  className={'data vls-sidebar_tabs_wraper-tab-input_number'}
                  firstDescription={'Количество фаз'}
                  type={'number_custom'}
                  value={valueNumberPhases}
                  onChange={this.handlerNumberPhases}
                />
              </div>
            </TableInfo>
          </Tab>
        </RBTabs>
      </React.Fragment>
    );
  }
}
export default connect('SidebarDataFromDevice', VoltageSourceComponentForSidebar);
