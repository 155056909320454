import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ConfirmComponent from "../../../common/ConfirmComponent";
import LoaderSpiner from '../../../common/LoaderSpiner';
import connect from 'storeon/react/connect'

class ModalConfirmWarningLayout extends Component {
  render() {
    const {
      showConfirmModal,
      closeConfirmWarningModal,
      paramsConfirmWarningOrSuccessModal,
      loader
    } = this.props;

    let theme = this.props.ProjectTheme === 'dark' ? '' : 'light'

    return (
      <Modal
        animation={false}
        show={showConfirmModal}
        backdropClassName={"modal-wrap-backdrop"}
        className={"modal-wrap"}
        onHide={closeConfirmWarningModal}
      >
        {
          loader ? (
            <React.Fragment>
              <LoaderSpiner className={""} />
              <p className={"modal-load-text"}>Сохранение...</p>
            </React.Fragment>
          ) : (
              <React.Fragment>
                <div className={`${theme}`}>
                  <div
                    onClick={closeConfirmWarningModal}
                    className={"modal-close_btn"}
                  ></div>
                  <img
                    className={"modal-icon"}
                    src={paramsConfirmWarningOrSuccessModal.icon}
                    alt={paramsConfirmWarningOrSuccessModal.altForIcon}
                  />
                  <div className={"modal-text_info_block"}>
                    {paramsConfirmWarningOrSuccessModal.text}
                  </div>
                  <div>
                    <ConfirmComponent
                      callbackOk={paramsConfirmWarningOrSuccessModal.callbackOk}
                      callbackCancellation={
                        !paramsConfirmWarningOrSuccessModal.callbackCansel ?
                          closeConfirmWarningModal :
                          paramsConfirmWarningOrSuccessModal.callbackCansel
                      }
                      cansel={paramsConfirmWarningOrSuccessModal?.btnConfig?.cansel}
                      loader={loader}
                      addClassName={"modal-confirm_component_wrap"}
                    />
                  </div>
                </div>
              </React.Fragment>
            )
        }
      </Modal>
    );
  }
}
export default connect("ProjectTheme", ModalConfirmWarningLayout);
