import React, { useState } from 'react'
import TableGenerate from '../TableGenerate'
import defaultDataTable from './defaultDataTable'

const ContentForXLTable = () => {
    // ableHeadData, TableBodyData, variant
    const [tableHeadData, setTableHeadData] = useState(defaultDataTable.initialTableHeadData)
    return (
        <React.Fragment>
            <div className={"modal-xl_table-back_side"}></div>
            <div className={"modal-xl_table-wrapper"}>
                <div className={"modal-xl_table-wrapper-header"}>
                    <h5 className={"modal-xl_table-wrapper-title"}>Механическая характеристика</h5>
                    <div className={"modal-xl_table-wrapper-close_btn"}></div>
                </div>
                <div className={"modal-xl_table-wrapper-content"}>
                    <TableGenerate variant={"modal-xl_table-wrapper-content-table"} tableHeadData={tableHeadData} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ContentForXLTable