import React from 'react'

const ModalPersonalPage = ({
	content = <></>,
	close,
	className,
	show,
}) => {
	if (!show) return null
	return (
		<React.Fragment>
			<div onClick={close} className={`wrapper-modal-setting`}></div>
			<div className={`modal-setting ${className}`}>
				<div onClick={close} className='modal-setting_form-close'></div>
				{content}
			</div>
		</React.Fragment>
	)
}
export default ModalPersonalPage