import React, { useState, useEffect } from 'react';
import { valueEmailRU } from '../../utils';

const defaultData = {
  name: { valid: false, value: '' },
  email: { valid: false, value: '' },
  questrion: { valid: false, value: '' },
};
const defaultFun = () => {};
const ModalQuestion = ({ dataStates = defaultData, onChangeData = defaultFun }) => {
  const [errorName, setErrorName] = useState(dataStates.name.valid);
  const [errorEmail, setErrorEmail] = useState(dataStates.email.valid);
  const [errorQuestrion, setErrorQuestrion] = useState(dataStates.questrion.valid);
  useEffect(() => {
    setErrorName(dataStates.name.valid);
    setErrorEmail(dataStates.email.valid);
    setErrorQuestrion(dataStates.questrion.valid);
  }, [dataStates]);
  return (
    <React.Fragment>
      <form className="modal-default__form">
        <label htmlFor="" className="modal-default__form-label">
          <span className="modal-default__form-label-span">Имя</span>
          <input
            data-name={'name'}
            value={dataStates.name.value}
            type="text"
            className={`modal-default__form-input ${errorName ? '' : 'error-input'}`}
            placeholder="Имя"
            onChange={(e) => onChangeData(e, true)}
            required
          />
        </label>
        <label htmlFor="" className="modal-default__form-label">
          <span className="modal-default__form-label-span">Email</span>
          <input
            data-name={'email'}
            value={dataStates.email.value}
            type="email"
            className={`modal-default__form-input ${errorEmail ? '' : 'error-input'}`}
            placeholder="Введите адрес эл. почты"
            onChange={(e) => {
              let value = e.target.value;
              let isValid = valueEmailRU(value);
              onChangeData(e, isValid);
            }}
            required
          />
        </label>
        <label htmlFor="" className="modal-default__form-label">
          <span className="modal-default__form-label-span">Опишите подробно ваш вопрос</span>
          <textarea
            data-name={'questrion'}
            value={dataStates.questrion.value}
            className={`modal-default__form-textarea ${errorQuestrion ? '' : 'error-input'}`}
            placeholder="Введите текст сообщения"
            onChange={(e) => onChangeData(e, true)}
            required
          />
        </label>
      </form>
    </React.Fragment>
  );
};
export default ModalQuestion;
