import { sercheAllObjectByProperty } from "../../index";
import { DATA_OBJECT_SCENE } from "../../../const";
import { Vector3 } from "three";
/**
 * Удалит не удаленные трансформ контролеры (тк их много создается при перемещении объекта бывает что остаетося немного и мешают ньормально удалять события)
 * @param {} scene
 */
const removeRemainingTranformControl = (scene) => {
  let transformController = sercheAllObjectByProperty("name", DATA_OBJECT_SCENE.transformControler.name, scene)
  transformController.forEach(el => {
    el.detach()
    el.dispose();
    scene.remove(el);
  })

};
/**
 * соберет новые данные для вывода из сериалайзера и записи в стейт
 */
const generateNewData = (lastConnector, objectDrag) => {
  let objectCollisionFromScene = lastConnector.scene.getObjectByProperty(
    "uuid",
    lastConnector.uuid
  );
  let objectDragFromScene = lastConnector.scene.getObjectByProperty(
    "uuid",
    objectDrag.uuid
  );
  // собираю объект для вывода
  let newObjectCollisionData = {
    name: lastConnector.name,
    parent: objectCollisionFromScene.parent,
    parentUuid: objectCollisionFromScene.parent.uuid,
    position: objectCollisionFromScene.position,
    scene: lastConnector.scene,
    uuidConnector: objectDrag.uuid,
    uuid: lastConnector.uuid,
  };
  let newObjectDrag = {
    geometry: objectDragFromScene.geometry,
    matrix: objectDragFromScene.matrix,
    parentUuid: objectCollisionFromScene.parent.uuid,
    name: objectDrag.name,
    position: objectDragFromScene.position,
    uuidConnector: objectDrag.uuid,
    uuid: lastConnector.uuid,
  };
  return {
    lastConnector: newObjectCollisionData,
    objectDrag: newObjectDrag,
  };
};
/**
 * удалит события с объекта
 * @param {*} obj
 */
const removeSyntheticEventListenerFromObj = (obj) => {
  if (obj.detach) {
    obj.detach()
  }
  if (obj._listeners)
    obj.off("click", obj._listeners.click[0])
  obj.cursor = 'pointer';
};
/**
 * просто найдет нужный мне объект( и его позицию )по которому сработала колизия
 * сделано так потому что у меня нету доступа к полному объекту из-за
 * его размера
 * @param {*} uuid
 * @param {*} scene
 */
const getPositionObjectCollision = (uuid, scene) => {
  let targetPosition = new Vector3();
  let obj = scene.getObjectByProperty("uuid", uuid);
  obj.getWorldPosition(targetPosition);
  return targetPosition;
};
/**
 * получает объект(мини) для установки новой позиции сцену для поиска по ид объекта
 * и новую позицию
 * @return (мини) объект и изменить у него позициб на сцене
 * @param {*} obj
 * @param {*} scene
 * @param {*} pos
 */
const setPositionObjectDrag = (obj, scene, pos) => {
  let objectFromScene = scene.getObjectByProperty("uuid", obj.uuid);
  objectFromScene.position.setX(pos.x);
  objectFromScene.position.setZ(pos.z);
  return obj;
};
/**
	@return вернет объект для обновления данных о произошедшей колизии и в зависимости от данных подменит конектор на меш
 * @param data {*}
 */
export const collisionsHandlerWiresSerialized = (data) => {
  const {
    lastConnector,
    objectDrag,
  } = data;
  const { scene, uuid: ObjColUuid, parent: ObjConParent } = lastConnector;
  if (data.objectDrag.name === DATA_OBJECT_SCENE.connector.name) {
    let posObjectCollision = getPositionObjectCollision(ObjColUuid, scene); //data.lastConnector.parent.position;

    //создаем новую позицию для объекта колизии
    //(тут можно добавить дополнительных обработчиков для более точного позиционирования проводов)
    let createNewPositionForObjDrag = new Vector3(
      posObjectCollision.x,
      posObjectCollision.y,
      posObjectCollision.z
    );
    removeRemainingTranformControl(scene);
    //устанавливаем новую позицию для объекта который перемещяем
    setPositionObjectDrag(objectDrag, scene, createNewPositionForObjDrag);
    // removeSyntheticEventListenerFromObj(ObjConParent);
    // removeSyntheticEventListenerFromObj(
    //   scene.getObjectByProperty("uuid", ObjColUuid)
    // );
    // removeSyntheticEventListenerFromObj(
    //   scene.getObjectByProperty("uuid", objectDrag.uuid)
    // );
  }

  return generateNewData(lastConnector, objectDrag); //todo: сделай обработку колизии чтоб нельзя было наезжать одним боксом на другой
};
