import React from "react";

const Tb = (props) => {
  const { TableBodyData } = props;
  if(!TableBodyData) {
    return null
  }
  return (
    <React.Fragment>
      {TableBodyData.map((el, i) => {
        return (
            <tr key={i}>
            {el.map((el, j) => {
              return <td {...el.tdParams} key={j}>{el.content}</td>
            })}
          </tr>
        );
      })}
    </React.Fragment>
  );
};
export default Tb;
