export let SceneHandler = (store) => {
  store.on("@init", () => ({
    SceneHandler: {
      clear: false
    }
  }));
  store.on("SceneHandler", ({ SceneHandler }, data) => {

    if (data === "clear") {
      return { SceneHandler: {} };
    }
    SceneHandler = {}
    return {
      SceneHandler: data,
    };
  });

};
