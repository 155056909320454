import React from 'react'
import HelloHeadLine from '../../Views/Layout/HelloHeadLine';
import TogglerSwitcher from '../../Views/Layout/TogglerSwitcher';
import connect from 'storeon/react/connect'

class SettingPersonal extends React.Component {
  constructor(props) {
    super(props);
  }
  toggleTheme=()=>{
    this.props.dispatch('ProjectTheme', this.props.ProjectTheme)
  }
  
  render() {
    const {ProjectTheme} = this.props;
    return (
        <>
          <HelloHeadLine 
            className={'setting'} 
            title={'НАСТРОЙКИ'} styleWelcome='center'/>
          <TogglerSwitcher 
            onChange={this.toggleTheme} 
            firstTogglerValue='Тема' 
            className={ProjectTheme === 'dark' ? 'toggler-switcher-for-setting' : 'theme-light_toggler-switcher'} /> 
        </>
    );
  }
}
export default connect('ProjectTheme', SettingPersonal);
