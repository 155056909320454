import moment from "moment";
import localization from "moment/locale/ru";
import defaultImageNewsItem from "../../../assets/images/defaultImageNewsItem.svg";
moment.updateLocale("ru", localization);

export const getSerializedItemNews = (el) => {
  return {
        id: el.slug,
        type: el.category,
        imgSrc: el.preview ? el.preview : defaultImageNewsItem,
        altImg: el.title,
        text: el.text_preview,
        date: moment(el.pub_date).format("DD MMMM YYYY"),
      };
};
