import React from "react";
import AuthorizationAndRegistrLayout from "../../Views/Layout/AuthorizationAndRegistrLayout";
import ForgotPassword from "../../common/ForgotPassword";
import Layout from "../../Views/Layout";

const ForgotPasswordPage = (props) => {
  return (
    <Layout title={"Востановление пароля"} isVisibleHeader={false}>
      <AuthorizationAndRegistrLayout {...props}>
        <ForgotPassword />
      </AuthorizationAndRegistrLayout>
    </Layout>
  );
};

export default ForgotPasswordPage;
