export let UpdateComponentDevice = store => {
    // Initial state
    let defaultState = false
    store.on('@init', () => ({
        UpdateComponentDevice: defaultState
    }))
    // Reducers returns only changed part of the state
    store.on("UpdateComponentDevice", (UpdateComponentDevice, data) => {
        if (data === "clear") {
            return { UpdateComponentDevice: defaultState };
        }
        let selected = data
        return {
            UpdateComponentDevice: selected
        };
    });
}