import { unicArr } from '../../../utils'
export let ElementsVLS = store => {
	// Initial state
	store.on('@init', () => ({ElementsVLS: []}))
	// Reducers returns only changed part of the state
	store.on("ElementsVLS", ({ ElementsVLS }, data) => {
		if (data === "clear") {
			return { ElementsVLS: [] };
		}
		let newElementVLS = [...ElementsVLS,data]
		return {
			ElementsVLS: newElementVLS
		};
	});
}