import React from 'react';
import {
  AbstractObject,
  GenericGroupObject,
  GLTF,
  Box,
  Cylinder,
  Plane,
  Ring,
} from '../../../../gengine';
import {
  generateLabelMaterial,
  generateCartForCanvasDevice,
  sercheAllObjectByProperty,
  createLinearGradient,
} from '../../../../utils';
import { DoubleSide, RingBufferGeometry, MeshBasicMaterial, MathUtils, RingGeometry } from 'three';
import { v4 } from 'uuid';

/**
 * todo: разложить на подметоды
 */
export default class ComponentCreator extends AbstractObject {
  /**
   * Создаст копонент дисплэя и засунет его в свою групу и запишет в renderComponent
   * @param {*} displayData
   */
  addRenderDisplay = (displayData) => {
    const { renderComponent } = this.state;
    const {
      GLTF_POSITION,
      GLTF_ROTATION,
      GLTF_QUATERNION,
      GLTF_SCALE,
      GLTF_URL,
      GLTF_PIVOT,
      position,
      rotation,
      scale,
      type,
      id,
      value,
      name = 'display',
    } = displayData;
    const { onLoadComplete, startLoadGLTF, progressLoadGLTF, errorLoadGLTF } = this.props;

    const max = 230,
      min = 121;
    let _percent = ((max - min) * Math.round(value)) / 100 + min;
    const y = MathUtils.degToRad((-value / 100) * 240 + 120);
    GLTF_ROTATION[1] = y
    renderComponent.push({
      content: (
        <GenericGroupObject
          position={position}
          rotation={rotation}
          scale={scale}
          name={name}
          uuid={id}
          // visible={true}
          {...this.props}
        >
          <GLTF
            onLoadComplete={onLoadComplete}
            startLoadGLTF={startLoadGLTF}
            progressLoadGLTF={progressLoadGLTF}
            errorLoadGLTF={errorLoadGLTF}
            // scale={GLTF_SCALE}
            pivot={GLTF_PIVOT}
            quaternion={GLTF_QUATERNION}
            position={GLTF_POSITION}
            rotation={GLTF_ROTATION}
            url={GLTF_URL}
          />
        </GenericGroupObject>
      ),
      id: id,
    });
    this.setState({
      renderComponent: renderComponent,
    });
  };

  generateCart = (params = false) => {
    let texture = generateCartForCanvasDevice(params);
    return texture;
  };
  /**
   * декоратор для создания текстур
   * @param {*} value
   */
  generateTexture = (value, color, textParams) => {
    let { width, height } = textParams;
    let texture = generateLabelMaterial(value, color, width, height, textParams);

    return texture;
  };
  /**
   * Создает дисплей с цифрами работает на кастомных текстурах
   * @param {*} displayData
   */
  addRenderdisplayCustomTexture = (displayData) => {
    let {
      type,
      id,
      name = 'custom text texture',
      value,
      color,
      textParams,
      GROUP_NAME,
      GROUP_POSITION,
      GROUP_ROTATION,
      GROUP_SCALE,
      MESH_SCALE,
    } = displayData;
    if (!value) {
      value = '0.0000';
    }
    const setValidValue = (value) => {
      let newValue = `${value}`.substring(0, 6);
      if (newValue.length < 5) {
        switch (Math.sign(Number(newValue))) {
          case -1:
            newValue = `0.000${Number(newValue)}`;
            break;
          case 0:
            newValue = `0.0000`;
            break;
          case 1:
            newValue = `${Number(newValue)}.0000`;
            break;
        }
      }
      return newValue;
    };
    let newValue = GROUP_NAME === 'displayCustomTexture-number' ? setValidValue(value) : value;
    let paramsForRender = {
      dataGroup: {
        name: GROUP_NAME,
        position: GROUP_POSITION,
        rotation: GROUP_ROTATION,
        scale: GROUP_SCALE,
        id: id,
      },
      dataComponenetGroup: {
        scale: MESH_SCALE,
        material: {
          map: this.generateTexture(newValue, color, textParams),
          transparent: true,
          color: false,
        },
      },
    };
    this.renderComponentAdd(paramsForRender);
  };

  /**
   * просто конструктор для создания интерфеса прибора
   *
   * @param {*} param0
   * @param bool если фолс то не будет рисовать внутренний блок
   */
  renderComponentAdd = ({ dataGroup = {}, dataComponenetGroup = {} }, boxRender = true) => {
    const { renderComponent } = this.state;
    renderComponent.push({
      content: (
        <GenericGroupObject uuid={dataGroup.id} {...dataGroup} {...this.props}>
          {boxRender ? <Box {...dataComponenetGroup} /> : null}
        </GenericGroupObject>
      ),
      id: dataGroup.id,
    });
    this.setState({
      renderComponent: renderComponent,
    });
  };

  /**
   * Создаст кнопку вкл выкл и при правильно построеной  gltf красиво будет менять текстурки
   * @param {*} displayData
   */
  addRenderButtonOffOrIn = (displayData) => {
    let { type, state, id, GROUP_NAME, GROUP_POSITION, GROUP_ROTATION, GROUP_SCALE } = displayData;
    const { renderComponent } = this.state;
    console.log('displayData', displayData);
    let newState = state === 'off' ? this.offBtnChanged(displayData) : 'on';
    state = newState;
    renderComponent.push({
      content: (
        <GenericGroupObject
          name={GROUP_NAME}
          uuid={id}
          position={GROUP_POSITION}
          rotation={GROUP_ROTATION}
          scale={GROUP_SCALE}
          // visible={true}
          callback={[
            {
              type: 'click',
              event: () => {
                let state = this.clickBtnOffOrOn(displayData);
                this.submitData(id, { state: state });
              },
            },
          ]}
          customAttribute={{
            state: state,
          }}
          {...this.props}
        ></GenericGroupObject>
      ),
      id: id,
    });
    this.setState({
      renderComponent: renderComponent,
    });
  };

  offBtnChanged = (displayData) => {
    let _object = this.props.scene.getObjectByProperty('uuid', displayData.id);
    if (!_object) {
      let _objectSingl = this.props.scene.getObjectByProperty('uuid', displayData.deviceId);
      if (!_objectSingl) {
        console.error(
          'Не найден объект с кнопкой на сцене (проблема в беке и переинициализации идентификаторов)',
        );
        _objectSingl = this.props?.parent.getObjectByProperty('name', 'device');
        if (!_objectSingl) {
          _objectSingl = this.props?.parent.getObjectByProperty('name', 'voltageSource');
        }
      }
      _object = _objectSingl.getObjectByProperty('type', 'Group');
      if (!_object) {
        _object = _objectSingl.getObjectByProperty('name', 'device');
      }
    }
    if (!_object) {
      return displayData.state;
    }
    let _leverArm = _object.parent.getObjectByProperty('name', 'Polzynok');
    let _offElement = _object.parent.getObjectByProperty('name', 'off');
    let _onElement = _object.parent.getObjectByProperty('name', 'on');
    let _coeficent = 0.022;
    _leverArm.position.setX(_leverArm.position.x);
    _leverArm.position.setY(_leverArm.position.y);
    _leverArm.position.setZ(_leverArm.position.z - _coeficent);
    // смена текстуры
    _offElement.visible = true;
    _onElement.visible = false;
    return 'off';
  };
  /**
   * создаст кнопку со знаком вопроса при нажатии на которую будет вылезать тултип с иформацией по конкретному прибору
   * @param {*} displayData
   */
  addRenderButtonQuestion = (displayData) => {
    const {
      id,
      type,
      GROUP_NAME,
      GROUP_POSITION,
      GROUP_ROTATION,
      GROUP_SCALE,
      Question_NAME,
      Question_POSITION,
      Question_ROTATION,
      Question_SCALE,
    } = displayData;
    const { renderComponent } = this.state;
    renderComponent.push(
      <GenericGroupObject
        name={GROUP_NAME}
        position={GROUP_POSITION}
        rotation={GROUP_ROTATION}
        scale={GROUP_SCALE}
        // visible={true}
        callback={[
          {
            type: 'click',
            event: (e) => this.clickBtnQuestrion(e, displayData),
          },
        ]}
        {...this.props}
      >
        <Cylinder
          radiusTop={0.5}
          radiusBottom={0.5}
          height={0.5}
          radialSegments={15}
          name={Question_NAME}
          position={Question_POSITION}
          rotation={Question_ROTATION}
          scale={Question_SCALE}
          material={{
            color: '#fff',
            transparent: true,
          }}
        />
      </GenericGroupObject>,
    );
    this.setState({
      renderComponent: renderComponent,
    });
  };
  /**
   * обработает клик по знаку вопроса(универсальный для всех копонент)
   * !передавай минифицированный объект объедини событие и данные
   * @param {*} event
   * @param {*} displayData
   */
  clickBtnQuestrion = (event, displayData) => {
    this.props.handleToolTipsElement(event);
  };
  /**
   * обработает клик по выключателю примет деталку события и параметры объекта
   * @param {*} event
   * @param {*} displayData
   */
  clickBtnOffOrOn = (displayData) => {
    //todo: добавь запрос на отключение прибора
    let _object = this.props.scene.getObjectByProperty('uuid', displayData.id);
    if (!_object) {
      let _objectSingl = this.props.scene.getObjectByProperty('uuid', displayData.deviceId);
      _object = _objectSingl.getObjectByProperty('type', 'Group');
      if (!_object) {
        _object = _objectSingl.getObjectByProperty('name', 'device');
      }
    }
    if (!_object) {
      return displayData.state;
    }
    let _leverArm = _object.parent.getObjectByProperty('name', 'Polzynok');
    let _offElement = _object.parent.getObjectByProperty('name', 'off');
    let _onElement = _object.parent.getObjectByProperty('name', 'on');
    let _coeficent = 0.022;
    if (!_object._customAttribute) {
      _object._customAttribute = { state: displayData.state };
    }
    if (_object._customAttribute.state === 'off') {
      // смена позиции бегунка
      _leverArm.position.setX(_leverArm.position.x);
      _leverArm.position.setY(_leverArm.position.y);
      _leverArm.position.setZ(_leverArm.position.z + _coeficent);
      // смена текстуры
      _offElement.visible = false;
      _onElement.visible = true;
      _object._customAttribute.state = 'on';
      return 'on';
    } else if (_object._customAttribute.state === 'on') {
      // смена позиции бегунка
      _leverArm.position.setX(_leverArm.position.x);
      _leverArm.position.setY(_leverArm.position.y);
      _leverArm.position.setZ(_leverArm.position.z - _coeficent);
      // смена текстуры
      _onElement.visible = false;
      _offElement.visible = true;
      _object._customAttribute.state = 'off';
      return 'off';
    }
  };
  /**
   * отрисовывает графики в приборе (осцилограф)//todo: добавь примитив плэйн и замени для всех
   * @param {*} displayData
   */
  addRenderChart = (displayData) => {
    let {
      GROUP_NAME,
      GROUP_POSITION,
      GROUP_ROTATION,
      GROUP_SCALE,
      CHART_NAME,
      CHART_SCALE,
      CHART_POSITION,
      CHART_ROTATION,
      CHART_WIDTH,
      CHART_HEIGHT,
      CHART_SEGMENTS,
      lengthChart,
      id,
      value,
    } = displayData;
    const { renderComponent } = this.state;
    renderComponent.push({
      content: (
        <GenericGroupObject
          {...this.props}
          key={id}
          uuid={id}
          name={GROUP_NAME}
          position={GROUP_POSITION}
          rotation={GROUP_ROTATION}
          scale={GROUP_SCALE}
          // visible
        >
          <Plane
            name={CHART_NAME}
            width={CHART_WIDTH}
            height={CHART_HEIGHT}
            segments={CHART_SEGMENTS}
            position={CHART_POSITION}
            rotation={CHART_ROTATION}
            scale={CHART_SCALE}
            material={{
              map: this.generateCart(value),
              transparent: true,
              color: '#F8FBFF',
              userData: value,
            }}
          />
        </GenericGroupObject>
      ),
      id: id,
    });
    this.setState({
      renderComponent: renderComponent,
    });
  };
  /**
   * !можно сделать лучше + надо добавить обработку кликов по кнопкам + и -
   * Обработает собития mousedown во время работы события создаст событие mouseup
   * для завершения работы с событиями
   * @param {*} event
   */
  rangeStart = (event) => {
    let obj = event.data.target.getObjectByProperty('name', 'range Ring');
    let singlScreenX = null;
    let singlScreenY = null;
    let singlValue = (obj.geometry.parameters.thetaLength / this.maxThetaLength) * 100;
    let thetaLength = 0;
    let direction = null;
    const setSinglScreenX = (newSinglScreenX) => {
      singlScreenX = newSinglScreenX;
    };
    const setSinglScreenY = (newSinglScreenY) => {
      singlScreenX = newSinglScreenY;
    };
    const mouseMoveHandler = (e) => {
      if (!singlScreenX) {
        setSinglScreenX(event.data.originalEvent.screenX);
      }
      if (!singlScreenY) {
        setSinglScreenY(e.screenY);
      }
      //todo: Накосячил с отловом позиции скорее всего проблема в 1 клике (моус доуне)
      if (singlScreenX <= e.screenX) {
        setDirection('left');
      } else {
        setDirection('right');
      }
      if (direction === 'right') {
        setRangeProcent('positive');
      } else if (direction === 'left') {
        setRangeProcent('negative');
      }
    };
    const clearSinglVariables = () => {
      singlScreenX = null;
      singlScreenY = null;
      direction = null;
    };
    const setDirection = (newDirection) => {
      if (!direction) {
        direction = newDirection;
      }
    };
    const setRangeProcent = (type) => {
      if (type === 'positive') {
        singlValue += 0.5;
        if (singlValue >= 100) return;
        thetaLength = (this.maxThetaLength * singlValue) / 100;
        setGeometryRing(thetaLength);
      } else {
        singlValue -= 0.5;
        if (singlValue <= 0) return;
        thetaLength = (this.maxThetaLength * singlValue) / 100;
        setGeometryRing(thetaLength);
      }
    };
    const setGeometryRing = (thetaLength) => {
      obj.geometry = new RingBufferGeometry(
        obj.geometry.parameters.innerRadius,
        obj.geometry.parameters.outerRadius,
        obj.geometry.parameters.thetaSegments,
        obj.geometry.parameters.phiSegments,
        obj.geometry.parameters.thetaStart,
        thetaLength,
      );
      obj.geometry.elementsNeedUpdate = true;
    };
    const mouseUpHandler = (e) => {
      clearSinglVariables();
      window.removeEventListener('mousemove', mouseMoveHandler);
      window.removeEventListener('mouseup', mouseUpHandler);
    };
    window.addEventListener('mousemove', mouseMoveHandler);
    window.addEventListener('mouseup', mouseUpHandler);
  };
  /**
   * Контролирует изменение ползунка и обновляет меш а именно его позицию
   * @param {*} event
   * @param {*} value
   * @param {*} max
   * @param {*} min
   */
  verticalRangeStart = (componentUuid, event, typeObject, min = 0.05769738, max = -0.03466891) => {
    let _leverArm = event.data.target.parent.getObjectByProperty('name', 'Polzynok');
    let _defaultYAxias = event.data.originalEvent.screenY;
    let _coeficent = 0.001;
    let _count = null;
    let _percent = 0;
    const getPercent = (data) => {
      return (100 * (data - min)) / (max - min);
    };
    const setScaleResizeHandler = (newPosZ) => {
      _percent = this.reostatScaleResize(getPercent(newPosZ));
    };
    const _mouseMoveHandler = (e) => {
      if (_defaultYAxias < e.screenY) {
        setPositionLeverArm('positive');
      } else {
        setPositionLeverArm('negative');
      }
    };
    const _mouseUpHandler = (e) => {
      if (typeObject.reostat) {
        this.submitData(componentUuid, { value: _percent });
      } else {
        this.submitData(componentUuid, { value: getPercent(_count) });
      }
      window.removeEventListener('mouseup', _mouseUpHandler);
      window.removeEventListener('mousemove', _mouseMoveHandler);
    };
    const setPositionLeverArm = (type) => {
      if (type === 'positive') {
        if (_leverArm)
          if (_leverArm.position.z < min) {
            let newPosZ = _leverArm.position.z + _coeficent;
            _leverArm.position.setZ(newPosZ);
            if (typeObject.reostat) {
              setScaleResizeHandler(newPosZ);
            } else {
              _count = newPosZ;
            }
            return;
          }
      } else {
        if (_leverArm)
          if (_leverArm.position.z > max) {
            let newPosZ = _leverArm.position.z - _coeficent;
            _leverArm.position.setZ(newPosZ);
            if (typeObject.reostat) {
              setScaleResizeHandler(newPosZ);
            } else {
              _count = newPosZ;
            }
            return;
          }
      }
    };
    window.addEventListener('mouseup', _mouseUpHandler);
    window.addEventListener('mousemove', _mouseMoveHandler);
  };
  /**
   * создается обработчик для вертиканого ренжа
   * @param {*} data
   */
  addRenderVerticalRange = (data) => {
    const { type, value, id, GROUP_NAME, GROUP_POSITION, GROUP_ROTATION, GROUP_SCALE } = data;
    const { renderComponent } = this.state;

    const max = -0.045,
      min = 0.08;
    let _percent = ((max - min) * value) / 100 + min;
    const setDefaultProcentValue = (_percent) => {
      let _leverArm = this.props.parent.getObjectByProperty('name', 'Polzynok');
      if (_leverArm) _leverArm.position.setZ(_percent);
    };
    setDefaultProcentValue(_percent);
    renderComponent.push({
      content: (
        <GenericGroupObject
          name={GROUP_NAME}
          uuid={id}
          position={GROUP_POSITION}
          rotation={GROUP_ROTATION}
          scale={GROUP_SCALE}
          // visible={true}
          callback={[
            {
              type: 'mousedown',
              event: (e) => this.verticalRangeStart(id, e, value),
            },
          ]}
          {...this.props}
        ></GenericGroupObject>
      ),
      id: id,
    });
    this.setState({
      renderComponent: renderComponent,
    });
  };
  /**
   * Обновляет ренжевой ползунок в источнике напряжения сразу с текстурой
   * @param {*} e
   * @param {*} direction
   */
  updateRangePercent = (e, direction) => {
    let _currentRangeObject = e.data.target.parent;
    let _percentRangeObject = _currentRangeObject.parent.getObjectByProperty(
      'name',
      'displayCustomTexture-procent',
    );
    let _currentValue = _currentRangeObject._customAttribute.value;
    if (direction < 0) {
      if (_currentRangeObject._customAttribute.value > 0) {
        _currentValue -= 1;
        _currentRangeObject._customAttribute.value = _currentValue;
      }
    } else {
      if (_currentRangeObject._customAttribute.value <= 99) _currentValue += 1;
      _currentRangeObject._customAttribute.value = _currentValue;
    }
    let _rangeChildObject = _currentRangeObject.getObjectByProperty('name', 'range Ring');
    let _thetaLength = (this.maxThetaLength * _currentValue) / 100;
    //?данные не магические они дефолтные для колец
    let _ringGeometry = new RingGeometry(4.3, 5, 30, 30, 0, _thetaLength);
    _rangeChildObject.geometry = _ringGeometry;
    _rangeChildObject.geometry.verticesNeedUpdate = true;
    _rangeChildObject.geometry.elementsNeedUpdate = true;

    let newImage = this.generateTexture(`${_currentValue} %`, '#fff', {
      fillTextXAxias: 0,
      fillTextYAxias: 20,
      font: '12pt a_lcdnova',
      width: 50,
      height: 40,
    });
    _percentRangeObject.children[0].material.map.needsUpdate = true;
    _percentRangeObject.children[0].material.needsUpdate = true;
    _percentRangeObject.children[0].material.map = newImage;
    return {
      value: _currentValue,
      idTextureBlock: _percentRangeObject.uuid,
    };
  };
  /**
   * накручивает логику на ренжевой ползунок
   * @param {*} displayData
   */
  addRenderRangeCilinder = (displayData) => {
    const {
      value,
      id,
      type,
      RANGE_NAME,
      INC_NAME,
      DEC_NAME,
      RANGE_POSITION,
      RANGE_ROTATION,
      RANGE_SCALE,
      RANGE_RING_POSITION,
      RANGE_RING_ROTATION,
      RANGE_RING_SCALE,
    } = displayData;
    const { renderComponent } = this.state;

    // if(RANGE_RING_ROTATION[2] === 3.12) RANGE_RING_POSITION[2]
    let thetaLength = (this.maxThetaLength * value) / 100;
    // background: linear-gradient(48.65deg, rgba(204, 229, 255, 0.7) 27.65%, rgba(0, 126, 255, 0.7) 86.61%);
    renderComponent.push({
      content: (
        <GenericGroupObject
          name={RANGE_NAME}
          position={RANGE_POSITION}
          rotation={RANGE_ROTATION}
          scale={RANGE_SCALE}
          customAttribute={{
            value: value,
          }}
          {...this.props}
        >
          <Box
            position={[-1.86, 0, 0.59]}
            scale={[0.7, 2, 0.7]}
            name={INC_NAME}
            material={{
              opacity: 0,
              transparent: true,
            }}
            callback={[
              {
                type: 'click',
                event: (e) => {
                  let { value, idTextureBlock } = this.updateRangePercent(e, -1);
                  this.submitData(id, { value: value });
                  this.submitData(idTextureBlock, { value: `${value} %` });
                },
              },
            ]}
          />
          <Box
            name={DEC_NAME}
            position={[1.86, 0, 0.59]}
            scale={[0.7, 2, 0.7]}
            material={{
              opacity: 0,
              transparent: true,
            }}
            callback={[
              {
                type: 'click',
                event: (e) => {
                  let { value, idTextureBlock } = this.updateRangePercent(e, 1);
                  this.submitData(id, { value: value });
                  this.submitData(idTextureBlock, { value: `${value} %` });
                },
              },
            ]}
          />
          <Ring
            rotation={RANGE_RING_ROTATION}
            position={RANGE_RING_POSITION}
            scale={RANGE_RING_SCALE}
            innerRadius={4.3}
            outerRadius={5}
            thetaLength={thetaLength}
            name={`${RANGE_NAME} Ring`}
            material={{
              //textureForRangeVoltageSource.jpg
              map: createLinearGradient(),
              side: DoubleSide,
            }}
          />
        </GenericGroupObject>
      ),
      id: id,
    });
    this.setState({
      renderComponent: renderComponent,
    });
  };

  /**
   * создаст кнопку со знаком вопроса при нажатии на которую будет вылезать тултип с иформацией по конкретному прибору
   * @param {*} displayData
   */
  addRenderButtonQuestrion = (displayData) => {
    const {
      id,
      type,
      GROUP_NAME,
      GROUP_POSITION,
      GROUP_ROTATION,
      GROUP_SCALE,
      QUESTRION_NAME,
      QUESTRION_POSITION,
      QUESTRION_ROTATION,
      QUESTRION_SCALE,
    } = displayData;
    const { renderComponent } = this.state;

    renderComponent.push({
      content: (
        <GenericGroupObject
          name={GROUP_NAME}
          position={GROUP_POSITION}
          rotation={GROUP_ROTATION}
          scale={GROUP_SCALE}
          // visible={true}
          callback={[
            {
              type: 'click',
              event: (e) => this.clickBtnQuestrion(e, displayData),
            },
          ]}
          {...this.props}
        >
          <Cylinder
            radiusTop={0.5}
            radiusBottom={0.5}
            height={2}
            radialSegments={15}
            name={QUESTRION_NAME}
            position={QUESTRION_POSITION}
            rotation={QUESTRION_ROTATION}
            scale={QUESTRION_SCALE}
            material={{
              color: '#fff',
              transparent: true,
              opacity: 0,
            }}
          />
        </GenericGroupObject>
      ),
      id: id,
    });
    this.setState({
      renderComponent: renderComponent,
    });
  };

  /**
   * создаст место для подключения муфт аналог конекторов но имеет немного другой принцип обработки колизией
   * @param {*} displayData
   */
  addRenderOuterShaft = (displayData) => {
    const { direction, name, position, rotation, scale } = displayData;
    const { renderComponent } = this.state;
    renderComponent.push({
      content: (
        <Box
          {...this.props}
          name={name}
          position={position}
          rotation={rotation}
          scale={scale}
          material={{
            color: 'green',
            wireframe: true,
          }}
          customAttribute={{
            direction: direction,
          }}
        />
      ),
      id: v4(),
    });
    this.setState({
      renderComponent: renderComponent,
    });
  };

  incrementForRange = (event, value, max = -4.999999999999998, _percent) => {
    if (value === 100) {
      return value;
    } else {
      let objRange = this.props.parent.getObjectByProperty('name', 'rangeInput');
      let newValue = value;
      const setRotationTwistRegulator = () => {
        if (objRange.rotation.y >= max) {
          objRange.rotation.y = objRange.rotation.y - _percent;
        }
      };
      setRotationTwistRegulator();
      return (newValue += 1);
    }
  };

  decrimentForRange = (event, value, min, _percent) => {
    if (value === 0) {
      return value;
    } else {
      let objRange = this.props.parent.getObjectByProperty('name', 'rangeInput');
      let newValue = value;
      const setRotationTwistRegulator = () => {
        if (objRange.rotation.y <= min) {
          objRange.rotation.y = objRange.rotation.y + _percent;
        }
      };
      setRotationTwistRegulator();
      return (newValue -= 1);
    }
  };

  addRenderIncOrDecBtn = (data) => {
    let {
      value,
      id,
      type,
      RANGE_NAME,
      INC_NAME,
      DEC_NAME,
      RANGE_POSITION,
      RANGE_ROTATION,
      RANGE_SCALE,
      INC_POSITION,
      INC_ROTATION,
      INC_SCALE,
      DEC_POSITION,
      DEC_ROTATION,
      DEC_SCALE,
    } = data;
    const { renderComponent, IncOrDecBtn } = this.state;

    const coeficent = 1;
    const max = 4.999999999999998;
    const min = 0;
    let _percentCoeficent = ((max - min) * coeficent) / 100 + min;
    let valueDef = IncOrDecBtn ? IncOrDecBtn : value;
    renderComponent.push({
      content: (
        <GenericGroupObject
          name={RANGE_NAME}
          uuid={id}
          position={RANGE_POSITION}
          rotation={RANGE_ROTATION}
          scale={RANGE_SCALE}
          // visible={true}
          {...this.props}
        >
          <Box
            name={INC_NAME}
            position={INC_POSITION}
            rotation={INC_ROTATION}
            scale={INC_SCALE}
            material={{
              transparent: true,
              opacity: 0,
            }}
            callback={[
              {
                type: 'click',
                event: (e) => {
                  let incData = this.incrementForRange(e, valueDef, undefined, _percentCoeficent);
                  value = incData;
                  this.submitData(id, { value: incData });
                  this.setState({
                    IncOrDecBtn: incData,
                  });
                  //todo: добавиь запрос на изменение значения
                },
              },
            ]}
          />
          <Box
            name={DEC_NAME}
            position={DEC_POSITION}
            rotation={DEC_ROTATION}
            scale={DEC_SCALE}
            material={{
              transparent: true,
              opacity: 0,
            }}
            callback={[
              {
                type: 'click',
                event: (e) => {
                  let decData = this.decrimentForRange(e, valueDef, min, _percentCoeficent);
                  value = decData;
                  this.submitData(id, { value: decData });
                  this.setState({
                    IncOrDecBtn: decData,
                  });
                  //todo: добавиь запрос на изменение значения
                },
              },
            ]}
          />
        </GenericGroupObject>
      ),
      id: id,
    });
    this.setState({
      IncOrDecBtn: value,
      renderComponent: renderComponent,
    });
  };

  reostatScaleResize = (value) => {
    let _max = 3.2;
    let _min = 0.1;
    let _minPos = 0.08594232;
    let _maxPos = 0.02030295;
    let _percent = ((_max - _min) * value) / 100 + _min;
    let _percentPosition = ((_maxPos - _minPos) * value) / 100 + _minPos;
    let _scaleBlue = this.props.parent.getObjectByProperty('name', 'shkala_blue');
    let _allScaleGreen = sercheAllObjectByProperty('name', 'shkala_green', this.props.parent);
    for (let i = 0; i < _allScaleGreen.length; i++) {
      const _element = _allScaleGreen[i];
      _element.scale.setZ(_percent);
      _element.position.setZ(_percentPosition);
    }
    _scaleBlue.scale.setZ(_percent);
    _scaleBlue.position.setZ(_percentPosition);
    return value;
  };

  addRenderVerticalRangeForReostat = (data) => {
    const { type, value, id, GROUP_NAME, GROUP_POSITION, GROUP_ROTATION, GROUP_SCALE } = data;
    const { renderComponent } = this.state;
    const max = -0.045,
      min = 0.08;
    let _percent = ((max - min) * value) / 100 + min;
    const setDefaultProcentValue = () => {
      let _leverArm = this.props.parent.getObjectByProperty('name', 'Polzynok');
      if (_leverArm) {
        this.reostatScaleResize(value);
        _leverArm.position.setZ(_percent);
      }
    };
    setDefaultProcentValue();
    renderComponent.push({
      content: (
        <GenericGroupObject
          name={GROUP_NAME}
          uuid={id}
          position={GROUP_POSITION}
          rotation={GROUP_ROTATION}
          scale={GROUP_SCALE}
          // visible={true}
          callback={[
            {
              type: 'mousedown',
              event: (e) => {
                this.verticalRangeStart(id, e, { reostat: true }, min, max);
              },
            },
          ]}
          {...this.props}
        ></GenericGroupObject>
      ),
      id: id,
    });
    this.setState({
      renderComponent: renderComponent,
    });
  };
}
