import React from 'react'
import connect from 'storeon/react/connect'
import ToMyRate from '../../components/ToMyRate'
import PersonalAreaPage from '../PersonalAreaPage'



const PersonAreaMyRate = (props)=>{
    return(
        <PersonalAreaPage title={'Продолжайте творить!'} className={'personal-page'} styleWelcome='top' sale={true}>
            <ToMyRate /> 
        </PersonalAreaPage>
    )
}
export default connect('ProjectTheme', PersonAreaMyRate) 

