import React from 'react'

class RenderNestingStructure extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      onClose: true,
    }
  }

  serializeSceneChildToData = (scene) => {
    let resultsData = []

    let recursiveSerializedScene = (scene, parent, children) => {
      if (scene) {
        if (children) {
          children.map((el, i) => {
            let elementResult = children[i].length ?
              (<li className={'title'} onClick={this.openFolder}>{el.name}</li>) : (
              <li className={'cr boolean'}>
                <span className="property-name">name</span>
                <div className="c">
                  <div><i className="icon-visible"/></div>
                </div>
              </li>
            )
            resultsData.push(elementResult)
           return  recursiveSerializedScene(el, children[i], el.children )
          })
        } else {
          return false
        }
      }

    }
    recursiveSerializedScene(scene, null, scene.children)
    return resultsData
  }

  openFolder = () => {

  }

  componentDidMount () {
    let { scene } = this.props
    this.setState({
      parseDataScene: this.serializeSceneChildToData(scene)
    })
  }

  render () {
    const { onClose } = this.state
    let { scene } = this.props
    return (
      <ul className={!onClose ? 'closed' : ''}>
        <li className={'title'} onClick={this.openFolder}>Структура</li>
        {
          this.serializeSceneChildToData(scene).map(el => el)
        }
      </ul>
    )
  }
}

export default RenderNestingStructure