import { AbstractObject } from "../../../gengine";
import {
  BoxGeometry,
  Mesh,
  MeshBasicMaterial,
} from "three";
import { DATA_OBJECT_SCENE } from "../../../const";

class AddChildConnector extends AbstractObject {
  constructor(props) {
    super();
  }

  componentDidMount() {
    const {
      parent,
      rotation,
      position,
      scale,
      uuid: uuidProps,
      addWireFromConnectorCallback = () => {}
    } = this.props;
    let meshConnectorGeometry = new BoxGeometry(...scale);
    let meshConnectorMaterial = new MeshBasicMaterial({
      // color: 0xff0000,
      // wireframe: true,
      transparent: true,
      opacity:0
    });
    let meshConnector = new Mesh(meshConnectorGeometry, meshConnectorMaterial);
    meshConnector.name = DATA_OBJECT_SCENE.objectConnector.name; //DATA_OBJECT_SCENE.deviceConnector.name;
    meshConnector.position.set(...position);
    meshConnector.scale.set(...scale);
    meshConnector.rotation.set(...rotation);
    meshConnector.uuid = uuidProps;
    meshConnector.on('click', (ev) => {
      addWireFromConnectorCallback(ev, meshConnector)
    })
    parent.add(meshConnector);
  }

  render() {
    return null;
  }
}

export default AddChildConnector;
