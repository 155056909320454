import {
  Vector3,
  MathUtils,
  MeshBasicMaterial,
  TextureLoader,
  BoxGeometry,
  LoadingManager,
} from 'three';
import { generateLabelMaterial, generateCartForCanvasDevice } from '../../../../utils';

class UpdateCustomComponent {
  constructor(props) {
    // super()
    this.minValueRotationDisplay = 121;
    this.maxValueRotationDisplay = 230;
  }
  /**
   * декоратор для создания текстур
   * @param {*} value
   */
  generateTexture = (value, color, textParams) => {
    let { width, height } = textParams;
    let texture = generateLabelMaterial(value, color, width, height, textParams);

    return texture;
  };

  updateChart = (data, scene) => {
    let object = scene.getObjectByProperty('uuid', data.id);
    let carthOBJ = object.children[0];
    let newImage = generateCartForCanvasDevice(data.value);
    carthOBJ.material.map.needsUpdate = true;
    carthOBJ.material.needsUpdate = true;
    carthOBJ.material.map = newImage;
  };
  /**
   * обновит координаты стрелки на приборах
   * @param {*} data
   * @param {*} scene
   */
  updateDisplayData = (data, scene) => {
    let object = scene.getObjectByProperty('uuid', data.id);
    let GLTF = object.children[0];
    const max = 230,
      min = 121;
    let _percent = ((max - min) * Math.round(data.value)) / 100 + min;
    const y = MathUtils.degToRad((-data.value / 100) * 240 + 120);
    GLTF.rotation.set(...[GLTF.rotation.x, y, GLTF.rotation.z]);
  };
  /**
   * Обновит показатели на циферблатах
   * @param {*} data
   * @param {*} scene
   */
  updateDisplayCustomTexture = (data, scene) => {
    if (data.GROUP_NAME === 'displayCustomTexture-number') {
      let object = scene.getObjectByProperty('uuid', data.id);
      // debugger
      let OBJ = object.children[0];
      let value = data.value.toFixed(4);
      value.substring(0, 4);
      let newImage = this.generateTexture(value, data.color, data.textParams);
      OBJ.material.map.needsUpdate = true;
      OBJ.material.needsUpdate = true;
      OBJ.material.map = newImage;
    }
  };
}

export default UpdateCustomComponent;
