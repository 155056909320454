import React from "react";
import Layout from "../../Views/Layout";
import DefaultPageLayout from "../../Views/Layout/DefaultPageLayout";
import NewsHomePageComponent from '../../components/NewsHomePageComponent'

const HomePage = (props) => {
  return (
    <Layout title={"Новости"} isVisibleHeader={false}>
      <DefaultPageLayout>
        <NewsHomePageComponent />
      </DefaultPageLayout>
    </Layout>
  );
};

export default HomePage;
