import React, { createRef } from 'react';
import TableGenerate from '../TableGenerate';
import ButtonMain from '../../Views/Layout/ButtonMain';
import connect from 'storeon/react/connect';
import Api from '../../api';
import { PATH } from '../../const';
import ToolTipCreator from '../ToolTipCreator';
import ContentForContextMenuProjects from '../ContentForContextMenuProjects';
import ContentForContextMenuTrash from '../ContentForContextMenuTrash';

const ApiProject = Api.ProjectApi;
class ProjectFolderRenderList extends React.Component {
  constructor(props) {
    super(props);
    this.targetRef = React.createRef();
    this.textAreaRef = React.createRef();
    this.state = {
      dataProject: null,
      changeName: false,
      target: null,
      showContextMenu: false,
      TableHeadData: [['Имя', '', 'Дата создания', 'Размер', 'Вид']],
      TableBodyData: [],
      refTextArea: [],
      contentForModal: <></>,
    };
  }
  restoreElement = (id) => {
    this.fadeOutContextMenu();
    ApiProject.pathProject(id, { is_trash: false }).then((res) => {
      //todo: add notification
      if (this.props.updateGetProjects) {
        this.props.updateGetProjects();
      }
      this.props.isRerenderProjects();
    });
  };
  fullDeliteElement = (id) => {
    this.fadeOutContextMenu();
    ApiProject.deleteProject(id).then((res) => {
      //todo: add notification
      if (this.props.updateGetProjects) {
        this.props.updateGetProjects();
      }
      this.props.isRerenderProjects();
    });
  };
  deleteElement = (id) => {
    this.fadeOutContextMenu();
    ApiProject.pathProject(id, { is_trash: true }).then((res) => {
      //todo: add notification
      if (this.props.updateGetProjects) {
        this.props.updateGetProjects();
      }
      this.props.isRerenderProjects();
    });
  };
  addFavorite = (id) => {
    this.fadeOutContextMenu();
    ApiProject.pathProject(id, { favorites: true }).then((res) => {
      //todo: add notification
      if (this.props.updateGetProjects) {
        this.props.updateGetProjects();
      }
      this.props.isRerenderProjects();
    });
  };

  downloadProject = (id) => {
    this.fadeOutContextMenu();
    // ApiProject.pathProject(id, { favorites: true }).then((res) => {
    //   //todo: add notification
    //   if (this.props.updateGetProjects) {
    //     this.props.updateGetProjects();
    //   }
    //   this.props.isRerenderProjects();
    // });
  };

  contentForContextMenu = (projectData) => {
    const { id, archive } = projectData;
    return this.props.typePage !== 'trash' ? (
      <ContentForContextMenuProjects
        id={id}
        archive={archive}
        addFavorite={this.addFavorite}
        changeNameHandler={this.changeNameHandler}
        fadeOutContextMenu={this.fadeOutContextMenu}
        deliteElement={this.deleteElement}
        downloadProject={this.downloadProject}
      />
    ) : (
      <ContentForContextMenuTrash
        id={id}
        fullDeliteElement={this.fullDeliteElement}
        restoreElement={this.restoreElement}
      />
    );
  };
  fadeOutContextMenu = (e) => {
    this.setState({
      target: null,
      showContextMenu: false,
    });
  };

  blurChangeNameInput = (e) => {
    e.persist();
    this.setState(
      {
        changeName: false,
      },
      () => {
        this.props.changeNameProject(e);
      },
    );
  };

  changeNameHandler = (e) => {
    const { refTextArea, dataProject } = this.state;
    let textArea = null;
    refTextArea.map((el, i) => {
      if (el.id === dataProject.id) {
        textArea = el;
      }
    });
    this.setState(
      {
        changeName: true,
        showContextMenu: false,
      },
      () => textArea.focus(),
    );
  };
  doubleClickEvent = (id) => {
    window.location.href = `${PATH.DetailsProjectFormPersonalPage}${id}`;
  };
  serializePropsProjectToTableData = (data = [], props) => {
    let { changeName } = this.state;
    let arr = [];
    let refTextArea = [];
    arr = data.map((el, i) => {
      let name = {
        tdParams: { className: 'tb' },
        content: (
          <div
            key={i}
            className={`folder_list-name ${el.isEmpty ? 'filled' : 'empty'} ${
              el.favorites ? 'star' : ''
            }`}
            onDoubleClick={(e) => {
              this.doubleClickEvent(el.id);
            }}
          >
            <textarea
              id={el.id}
              readOnly={!changeName}
              ref={(ref) => refTextArea.push(ref)}
              onChange={props.changeNameProject}
              defaultValue={el.name}
              className={`folder_list-name-textarea ${changeName ? 'active' : ''}`}
              onBlur={this.blurChangeNameInput}
            />
          </div>
        ),
      };
      let context = {
        tdParams: {},
        content: (
          <div key={i} id={el.id} ref={this.targetRef}>
            <ButtonMain
              className={`folder_list-context_menu`}
              onClick={(e) => {
                e.persist();
                this.setState({
                  target: e.target,
                  dataProject: el,
                  showContextMenu: true,
                  contentForModal: this.contentForContextMenu(el),
                });
              }}
            ></ButtonMain>
          </div>
        ),
      };
      let date = {
        tdParams: {},
        content: (
          <div key={i} className={`folder_list-date`}>
            {el.date}
          </div>
        ),
      };
      let size = {
        tdParams: {},
        content: (
          <div key={i} className={`folder_list-size`}>
            {el.size}
          </div>
        ),
      };
      let typeView = {
        tdParams: {},
        content: (
          <div key={i} className={`folder_list-type`}>
            {el.typeView}
          </div>
        ),
      };
      return [name, context, date, size, typeView];
    });
    this.setState({
      refTextArea: refTextArea,
    });
    return arr;
  };

  createDataModal = (props) => {
    let { projects: dataProjects } = props;

    this.setState({
      TableBodyData: this.serializePropsProjectToTableData(dataProjects, props),
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.changeName !== this.state.changeName) {
      this.createDataModal(this.props);
    }
    if (prevProps.projects !== this.props.projects) {
      this.createDataModal(this.props);
    }
  }

  componentDidMount() {
    this.createDataModal(this.props);
  }

  render() {
    const {
      target,
      showContextMenu,
      TableHeadData,
      TableBodyData,
      dataProject,
      contentForModal,
    } = this.state;
    return (
      <React.Fragment>
        <ToolTipCreator
          typeClosed={'click'}
          target={{ current: target }}
          fadeOutContextMenu={this.fadeOutContextMenu}
          show={showContextMenu}
          contentForModal={contentForModal}
        />
        <TableGenerate
          variant={'all_project'}
          TableHeadData={TableHeadData}
          TableBodyData={TableBodyData}
        />
      </React.Fragment>
    );
  }
}

export default connect('ProjectTheme', ProjectFolderRenderList);
