import {
  CatmullRomCurve3,
  Geometry,
  LineBasicMaterial,
  Line,
  Vector3,
} from "three";
import { DATA_OBJECT_SCENE } from "../../../const";
/**
 * ?линейная интерполяция
 * @param {*} v1
 * @param {*} v2
 * @param {*} alpha
 */
const lerp = (v1, v2, alpha) => {
  let x = v1.x + (v2.x - v1.x) * alpha;
  let y = v1.y + (v2.y - v1.y) * alpha;
  return { x, y };
};
/**
 *
 * @param {sectionTemporaryData,tempPosition,linkToSceneObject} params
 */
export const AddTemporarySections = (params) => {
  const { sectionTemporaryData, tempPosition, linkToSceneObject } = params;
  let lastPosition = lerp(
    { x: sectionTemporaryData.x, y: sectionTemporaryData.z },
    { x: tempPosition.x, y: tempPosition.z },
    0.95
  );
  let vertices = [
    sectionTemporaryData,
    new Vector3(lastPosition.x, sectionTemporaryData.y, lastPosition.y),
  ];
  let numPoints = vertices.length;
  let spline = new CatmullRomCurve3(vertices, false, undefined, 1);
  let points = spline.getPoints(numPoints);
  let geometry = new Geometry().setFromPoints(points);
  geometry.computeBoundingSphere();
  let material = new LineBasicMaterial({
    color: "blue",
    linewidth: 5,
  });
  let mesh = new Line(geometry, material);
  mesh.name = DATA_OBJECT_SCENE.temporaryLineSection.name;
  linkToSceneObject.add(mesh);
};
