import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ReactComponent as ReactLogo } from '../../../assets/images/authAnimate.svg';
import ErrorBlockAuth from '../../../common/ErrorBlockAuth';
import { PATH } from '../../../const';
import Logo from '../../../assets/images/logo.svg';
import connect from 'storeon/react/connect';
import Notification from '../../../common/Notification';

class AuthorizationAndRegistrLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textError: '',
      isVisibleError: false,
    };
  }

  openErrorBlockHead = (text) => {
    this.setState({
      textError: text,
      isVisibleError: true,
    });
  };

  closeErrorBlockHead = () => {
    this.setState({
      textError: '',
      isVisibleError: false,
    });
  };

  render() {
    const { textError, isVisibleError } = this.state;
    return (
      <>
        <Notification
          isShow={this.props.Notification.isShow}
          content={this.props.Notification.content}
          close={this.props.Notification.close}
        />
        <ErrorBlockAuth
          closeErrorBlockHead={this.closeErrorBlockHead}
          isVisible={isVisibleError}
          text={textError}
        />
        <div className={'auth_and_reg'}>
          <div className={'auth_and_reg-left'}>
            <div className="auth_and_reg-left-top">
              <NavLink to={PATH.MainPage} className={'auth_and_reg-left-logo'}>
                <div className={'auth_and_reg-left-logo_img'}>
                  <img src={Logo} alt="logo" />
                </div>
                <div className={'auth_and_reg-left-logo_text'}>
                  GARPIX <br /> DIGITAL <br /> TWIN
                </div>
              </NavLink>
              <div className={'auth_and_reg-left-text'}>
                Авторская технология создания цифровых двойников электрооборудования и
                энергосистемы.
              </div>
            </div>
            <div className="auth_and_reg-left-bottom">
              <ReactLogo
                width={'100%'}
                height={'100%'}
                className={'auth_and_reg-left-bottom_animate_img'}
              />
            </div>
          </div>
          <div className={'auth_and_reg-right'}>
            <div className={'auth_and_reg-right-top'}>
              <div
                onClick={() => {
                  if (!this.props.children.props.history) {
                    this.props.history.goBack();
                  } else {
                    this.props.children.props.history.goBack();
                  }
                }}
                className={'auth_and_reg-right_back'}
              >
                <span>Назад</span>
              </div>
              <a href={PATH.MainPage} className={'auth_and_reg-right_logo'}>
                <div className={'auth_and_reg-right_logo-text'}>
                  Garpix
                  <br />
                  digital
                  <br />
                  twin
                </div>
              </a>
            </div>
            <div className={'auth_and_reg-right-bottom'}>
              {React.Children.map(this.props.children, (child) =>
                React.cloneElement(child, {
                  openErrorBlockHead: this.openErrorBlockHead,
                  closeErrorBlockHead: this.closeErrorBlockHead,
                }),
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default connect('Notification', AuthorizationAndRegistrLayout);
