import React from "react";
import uuid from 'uuid'

const RadioButton = ({
  disabled = false,
  id = uuid.v4(),
  onClick = (e) => { },
  onChange = (e) => { },
  checked = false,
  label,
  classNameLabel,
  ...props }) => {
  return (
    <div className={`default_components-radio_btn`}>

      <label className={classNameLabel} htmlFor={id}>
        <input
          type="radio"
          id={id}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          onClick={onClick}
          {...props}
        />
        {label}
      </label>
    </div>
  );
};
export default RadioButton;
