import React from 'react';
import { Geometry, Mesh } from 'three';
import { v4 } from 'uuid';
/**
 * - глубокий поиск всех элементов подходящих по параметрам
 * @param {*} property
 * @param {*} value
 * @param {*} startNode
 */
const sercheAllObjectByProperty = (property, value, startNode) => {
  let result = [];
  /**
   * DFS
   * @param {*} start
   * @param {*} target
   */
  const getObjectByProperty = (start, target) => {
    if (start[property] === target) {
      // debugger
      // if (start.children.length)
      result.push(start);
    }
    for (let i = 0; i < start.children.length; i++) {
      getObjectByProperty(start.children[i], target);
    }
    return null;
  };
  getObjectByProperty(startNode, value);
  return result;
};

class SyncGeometry extends React.Component {
  constructor(props) {
    super();
    this.unicNameForCollectorComponent = v4();
  }

  sycnManageSceneMesh = (props) => {
    const { scene, name = '' } = props;
    let allStraight = sercheAllObjectByProperty('name', name, scene);
    let allSyncMesh = sercheAllObjectByProperty('name', this.unicNameForCollectorComponent, scene);
    allStraight.push(...allSyncMesh);
    let geometryAll = [];
    let singleGeometry = new Geometry();
    let materials = [];
    this.clearMemory(this.props);
    allStraight.forEach((item, i) => {
      geometryAll.push(item.geometry);
      materials.push(item.material);
      item.updateMatrix();
      singleGeometry.merge(item.geometry, item.matrix, i);
    });
    let mesh = new Mesh(singleGeometry, ...materials);
    mesh.name = this.unicNameForCollectorComponent;
    scene.add(mesh);
    allStraight.forEach((item) => {
      item.geometry.dispose();
      item.material.dispose();
      if (item.parent) item.parent.remove(item);
    });
  };

  clearMemory = (props) => {
    const { scene } = props;
    let allTrash = sercheAllObjectByProperty('name', this.unicNameForCollectorComponent, scene);
    allTrash.forEach((element) => {
      element.parent.remove(element);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.sycnManageSceneMesh(this.props);
    }
  }

  componentDidMount() {
    this.sycnManageSceneMesh(this.props);
  }

  render() {
    return null;
  }
}

export default SyncGeometry;
