import React, { useState, useEffect } from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import connect from 'storeon/react/connect';

const ControlsViewGridProjects = (props) => {
  const stateEnum = {
    name: 'Имя',
    date: 'Дата создания',
    size: 'Размер',
    recent: 'Недавние',
    // state for view content
    grid: 'grid',
    list: 'list',
  };
  const [count, setCount] = useState(stateEnum.recent);
  const [viewContent, setViewContent] = useState(props.typeViewContent);
  const setViewContentDecor = (data) => {
    props.setViewContent(data === stateEnum.grid ? 'grid' : 'list');
    return setViewContent(data);
  };
  let theme = props.ProjectTheme === 'dark' ? '' : 'light-overlay_trigger';
  const { setFilters } = props;
  const popover = (
    <Popover arrowProps={null} id="popover-basic" className={`overlay_trigger ${theme}`}>
      <Popover.Content>
        <ul className={`overlay_trigger-ul`}>
          <li
            onClick={(e) => {
              setCount(stateEnum.name);
              setFilters({ is_trash: true });
              props.isRerenderProjects();
            }}
            className={stateEnum.name === count ? 'active' : ''}
          >
            Имя
          </li>
          <li
            onClick={(e) => {
              setCount(stateEnum.recent);
              setFilters({ is_favorite: 'True' });
              props.isRerenderProjects();
            }}
            className={stateEnum.recent === count ? 'active' : ''}
          >
            Недавние
          </li>
          <li
            onClick={(e) => {
              setCount(stateEnum.date);
              setFilters({ is_favorite: true });
              props.isRerenderProjects();
            }}
            className={stateEnum.date === count ? 'active' : ''}
          >
            Дата создания
          </li>
          <li
            onClick={(e) => {
              setCount(stateEnum.size);
              setFilters({ is_favorite: true });
              props.isRerenderProjects();
            }}
            className={stateEnum.size === count ? 'active' : ''}
          >
            Размер
          </li>
        </ul>
      </Popover.Content>
    </Popover>
  );
  console.log('viewContent', viewContent);
  return (
    <div className={`personal_page-content-control_view_grid`}>
      <div className={`personal_page-content-control_view_grid-buttons`}>
        <div
          className={`personal_page-content-control_view_grid-buttons-view_grid ${
            viewContent === stateEnum.grid ? 'active' : ''
          }`}
          onClick={(e) => setViewContentDecor(stateEnum.grid)}
        ></div>
        <div
          className={`personal_page-content-control_view_grid-buttons-view_list  ${
            viewContent === stateEnum.list ? 'active' : ''
          }`}
          onClick={(e) => setViewContentDecor(stateEnum.list)}
        ></div>
      </div>

      {/*
  todo: раскоментироваить
*/}
      {/* <div className={`personal_page-content-control_view_grid-sorts`}>
        <OverlayTrigger trigger="focus" placement="bottom" overlay={popover}>
          <button
            className={
              `personal_page-content-control_view_grid-sorts-select_filters`
            }
          >
            {count}
            <div
              className={`personal_page-content-control_view_grid-sorts-icon`}
            ></div>
            <div
              className={
                `personal_page-content-control_view_grid-sorts-icon_straight`
              }
            >
            </div>
            <div
              className={
                `personal_page-content-control_view_grid-sorts-icon_arrow`
              }
            ></div>
          </button>
        </OverlayTrigger>
      </div> */}
    </div>
  );
};

export default connect('ProjectTheme', ControlsViewGridProjects);
