import React from 'react'
import RadioButton from '../RadioButton'


const ChooseRadioButton = (props = []) => {
  if (!props.data) return null
  return (
    <>
      <div className='wrapper-choose-setting'>
        {props.data.map((item, i) => {
          return (
            <div className={`choose-setting ${item.addClassWrapper ? item.addClassWrapper : ''}`} key={i}>
              <p className={item.titleClassName}>{item.title}</p>
              <div className='choose-setting__radio-button'>
                {item.options.map((item2, index) => {
                  return <RadioButton key={index}
                    id={item2.id}
                    label={item2.name}
                    onChange={props.onChange}
                    onClick={props.onClick}
                    checked={!item2.isActive ? item2.active : item2.isActive}
                    disabled={!item2.disabled ? item2.isDisabled : item2.disabled}
                    classNameLabel={"choose-setting__radio-button-label"}
                  />
                })}
              </div>
            </div>
          )
        })}
      </div>
    </>

  )
}
export default ChooseRadioButton

