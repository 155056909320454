import { RingBufferGeometry, Mesh, MeshLambertMaterial, } from 'three'
import { Object3D } from '../../Object3D'
import { addEventCustomListener } from '../../utils'

class Ring extends Object3D {
    constructor({
        scene,
        enableShadows,
        innerRadius,
        outerRadius,
        phiSegments,
        thetaLength,
        thetaStart,
        thetaSegments,
        color,
        scale,
        texture,
        material,
        position,
        rotation,
        callback,
        mesh,
        parent,
        name
    } = {}) {
        super()
        const geometry = new RingBufferGeometry(
            innerRadius,
            outerRadius,
            thetaSegments,
            phiSegments,
            thetaStart,
            thetaLength,
        )
        let objectMaterial = {
            color: color
        }
        if (material) {
            objectMaterial = material
        } else {
            this.setColor(color);
        }
        this.material = new MeshLambertMaterial(objectMaterial)

        this.obj = new Mesh(geometry, this.material)
        if (mesh) {
            this.obj = mesh
        }
        addEventCustomListener(this.obj, callback)
        this.setPosition(position)
        //      
        this.obj.rotation.set(...rotation)
        this.setRotation(rotation)
        this.setScale(scale)
        if (texture) {
            this.setTexture(texture)
        }
        this.obj.castShadow = enableShadows
        this.obj.receiveShadow = enableShadows
        this.uuid = this.obj.uuid
        this.obj.name = name

        if (parent) {
            parent.add(this.obj)
        } else {
            this.addToScene(scene)
        }
    }
}

export default Ring