export const UpdateDeviceList = (data, componentData, devices) => {
  let newComponentData = [...componentData];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const elementData = data[key];
      let newDevice
      newComponentData.forEach((el, i) => {
        if (el.id === key) {
          newDevice = el
        }
      })
      for (const keyComponent in elementData) {
        if (elementData.hasOwnProperty(keyComponent)) {
          const elementComponentData = elementData[keyComponent];
          newDevice.component = newDevice.component.map(el => {
            if (el.id === keyComponent) {
              return elementComponentData
            }
            return el
          })
        }
      }
      newComponentData = newComponentData.map(el => {
        if (el.id === newDevice.id) {
          return newDevice
        }
        return el
      })
    }
  }
  let devicesNew = {
    ...devices,
    componentData: newComponentData,
  };
  return devicesNew;
}