import React, { Component } from "react";
import {
  createArcPreStraight,
  createArcAllPoints,
} from "../CustomComponentToCanvas/MeshBuilder";
import serializers from "../../utils/SerializedObject";
import {
  checkDirectionForVector2,
  nugol,
  sercheAllObjectByProperty,
} from "../../utils";
import { v4 } from "uuid";
import { DATA_OBJECT_SCENE } from "../../const";
import { Vector2, Mesh } from "three";
import { checkConnectedDots } from "./checkConnectedDots";

class AbstractVLS extends Component {
  constructor(props) {
    super();
  }
  /**
   * проверит необходимость у этого инструмента проверки позиции главного блока отвечающего за получении координат мышки
   */
  getUslesCheckPosMouse = (tool) => {
    switch (tool?.type) {
      case this.toolEnum.section:
        return false;
      case this.toolEnum.subareas:
        return false;
      case this.toolEnum.straight:
        return false;
      case this.toolEnum.arc:
        return false;
      case this.toolEnum.dot:
        return false;
      default:
        return true;
    }
  };
  /**
   * парсит данные и на их основе пополняет данные в сайдбаре
   * @param {*} data
   */
  parseDataForCounterResearchEnv = (data) => { 
    const setData = (el) => {
      this.addCounterReasercheEnv("primitive", el.edge.length / 2);
      el.edge.forEach((edge) =>
        this.addCounterReasercheEnv("nodes", edge.aproximationVertices.length)
      );
    };
    data.sections.forEach((el) => this.addCounterReasercheEnv("elements", 1));
    data.subareas.forEach((el) => this.addCounterReasercheEnv("subareas", 1));
    data.renderDots.forEach((el) => {
      if (el.type !== "arc") {
        this.addCounterReasercheEnv("dots", 1);
        setData(el);
      } else {
        this.addCounterReasercheEnv("dots", 3);
        //todo: добавить обработку кривых
      }
    });
  };
  /**
   * пополняет данные в сайдбаре
   * @param {*} key
   * @param {*} value
   */
  addCounterReasercheEnv = (key, value) => {
    this.props.dispatch("DataCountAllPrimitiveScenePFML", {
      ...this.props.DataCountAllPrimitiveScenePFML,
      [key]: (this.props.DataCountAllPrimitiveScenePFML[key] += value),
    });
  };
  /**
   * добавит подобласть с настройками полученными из модалки с параметрами подобласти
   * @param {*} data
   */
  addSubareas = (data) => {
    const { subareas } = this.state;
    subareas.push(data);
    this.addCounterReasercheEnv("subareas", 1);
    this.setState({
      subareas: subareas,
    });
  };
  /**
   *
   * @param {*} data
   */
  setSinglClick = (data) => {
    this.setState({
      clickEvt: data,
    });
  };
  /**
   * просто обновит параметры полученные из сайдбаров
   * @param {*} data
   */
  setPrimitiveData = (data) => {
    const { paramsPrimitiveData } = this.state;
    let paramsFromData = serializers.dataPrimitivesSerialize(
      data,
      paramsPrimitiveData
    );
    this.setState({
      paramsPrimitiveData: {
        ...paramsPrimitiveData,
        ...paramsFromData,
      },
    });
  };

  clearMemory = () => {
    const { linkToSceneObject } = this.state;
    this.setDefaultColorSelectedPrimitive();
    const clearScene = (data = []) => {
      data.forEach((el) => {
        el.geometry.dispose();
        el.material.dispose();
        linkToSceneObject.remove(el);
      });
    };
    let line = sercheAllObjectByProperty(
      "name",
      DATA_OBJECT_SCENE.line_direction_for_create_arc.name,
      linkToSceneObject
    );
    let colizionHandlerLine = sercheAllObjectByProperty(
      "name",
      DATA_OBJECT_SCENE.PRIMITIVE_LINE_CHECKED.name,
      linkToSceneObject
    );
    let temporaryLineSection = sercheAllObjectByProperty(
      "name",
      DATA_OBJECT_SCENE.temporaryLineSection.name,
      linkToSceneObject
    );
    clearScene(temporaryLineSection);
    clearScene(colizionHandlerLine);
    clearScene(line);
  };
  /**
   * обработает потерю наведения мышки на элемент
   * @param {*} group
   */
  addEventListenerDotWrapperOut = (group) => {
    const { tool, straightTargetMoveOrOut, arcTargetMoveOrOut } = this.state;
    const { target } = group.data;
    if (tool) {
      if (tool.type === this.toolEnum.straight) {
        if (straightTargetMoveOrOut) {
          this.setDefaultColorDot(straightTargetMoveOrOut);
          this.setState({
            straightTargetMoveOrOut: null, // !!!!!!!
          });
        }
      } else if (tool.type === this.toolEnum.arc) {
        if (arcTargetMoveOrOut) {
          this.setDefaultColorDot(arcTargetMoveOrOut);
          this.setState({
            arcTargetMoveOrOut: null,
          });
        }
      }
    }
  };
  /**
   * обработает наведение мышкой на точку
   * @param {*} group
   */
  addEventListenerDotWrapperMove = (group) => {
    const { target } = group.data;
    const {
      tool,
      straightTargetMoveOrOut,
      activeDotForCreatePrimitive,
      arcTargetMoveOrOut,
      renderDots,
      linkToSceneObject,
      widthCanvas,
      heightCanvas,
      paramsPrimitiveData,
    } = this.state;

    const straightCreateTemporaryLine = () => {
      if (!straightTargetMoveOrOut && activeDotForCreatePrimitive.length) {
        let checkDot = checkConnectedDots(
          activeDotForCreatePrimitive[0].uuid,
          target.uuid,
          renderDots,
          this.clearMemory,
          linkToSceneObject
        );
        if (checkDot)
          this.setState(
            {
              straightTargetMoveOrOut: target,
            },
            () => {
              this.setHoverColorDot(target);
            }
          );
      }
    };

    const arcCreateTemporaryLine = () => {
      if (!arcTargetMoveOrOut && activeDotForCreatePrimitive.length === 2) {
        this.clearMemory();
        let startDot = new Vector2(
          activeDotForCreatePrimitive[1].position.x + widthCanvas,
          activeDotForCreatePrimitive[1].position.z + heightCanvas
        );
        let twoDot = new Vector2(
          activeDotForCreatePrimitive[0].position.x + widthCanvas,
          activeDotForCreatePrimitive[0].position.z + heightCanvas
        );
        let aproximationVector = new Vector2(
          target.position.x + widthCanvas,
          target.position.z + heightCanvas
        );
        let verticesFromAproximationArc = this.aproximationVerticesCollectorForArc(
          startDot,
          twoDot,
          aproximationVector,
          activeDotForCreatePrimitive[1]._customAttribute.coeficentR,
          target._customAttribute.coeficentR
        );
        let enableCurveDrawing = createArcAllPoints(
          aproximationVector,
          twoDot,
          verticesFromAproximationArc,
          linkToSceneObject,
          this.clearMemory,
          heightCanvas,
          widthCanvas,
          activeDotForCreatePrimitive,
          renderDots
        );
        this.setState(
          {
            arcTargetMoveOrOut: target,
            enableCurveDrawing: enableCurveDrawing,
          },
          () => {
            this.setHoverColorDot(target);
          }
        );
      }
    };

    if (tool) {
      if (tool.type === this.toolEnum.straight) {
        straightCreateTemporaryLine();
      } else if (tool.type === this.toolEnum.arc) {
        if (paramsPrimitiveData.linkToDotsChangeCheckbox)
          arcCreateTemporaryLine();
      }
    }
  };
  /**
   * принимает ссылку на событиые группы точки и отвечает за клик по точкам
   * @param {*} group
   */
  addEventListenerDotWrapperClick = (group) => {
    let { tool, activeDotForCreatePrimitive } = this.state;
    this.straightGenerate(tool, activeDotForCreatePrimitive, group);
    this.arcGenerateFromDots(tool, activeDotForCreatePrimitive, group);
    if (!tool) {
      return this.setState({
        activeDot: group.data.target,
      });
    }
  };
  /**
   * соберет масив точек с шагом апроксимации
   * !кусочно линейная апроксимация для прямых
   * @param {*} dataForAproximationCollector = startPointVector endPointVector directionr rAproximationStartDot rAproximationEndDot
   */
  aproximationVerticesCollector = (dataForAproximationCollector) => {
    const {
      startPointVector,
      endPointVector,
      direction,
      rAproximationStartDot,
      rAproximationEndDot,
    } = dataForAproximationCollector;
    let result = [];
    let angle = nugol(
      startPointVector.x,
      startPointVector.y,
      endPointVector.x,
      endPointVector.y
    );
    if (rAproximationStartDot !== rAproximationEndDot) {
      if (
        rAproximationStartDot < direction &&
        rAproximationEndDot < direction
      ) {
        let coeficent =
          (direction - rAproximationStartDot) /
          (direction - rAproximationEndDot);
        let countDot = Math.round(
          Math.log10(rAproximationEndDot / rAproximationStartDot) /
            Math.log10(coeficent) +
            1
        );
        if (countDot < 1) {
          countDot = 1;
        } else {
          let rAproximationChanged =
            (direction * (coeficent - 1)) / (Math.pow(coeficent, countDot) - 1);
          let offsetX = 0;
          let offsetY = 0;

          for (let i = 0; i < countDot; i++) {
            offsetX = offsetX + rAproximationChanged * Math.cos(angle);
            offsetY = offsetY + rAproximationChanged * Math.sin(angle);
            rAproximationChanged = rAproximationChanged * coeficent;
            result.push({
              x: startPointVector.x + offsetX,
              y: startPointVector.y + offsetY,
              uuid: v4(),
            });
          }
        }
      }
    }
    if (rAproximationStartDot === rAproximationEndDot) {
      let countDot = Math.round(direction / rAproximationStartDot);
      if (countDot < 1) {
        countDot = 1;
      }
      let offsetXForLineSegment = rAproximationStartDot * Math.cos(angle);
      let offsetYForLineSegment = rAproximationStartDot * Math.sin(angle);
      for (let i = 1; i < countDot; i++) {
        result.push({
          x: startPointVector.x + offsetXForLineSegment * i,
          y: startPointVector.y + offsetYForLineSegment * i,
          uuid: v4(),
        });
      }
    }
    this.addCounterReasercheEnv("nodes", result.length);
    return result;
  };
  /**
   * !кусочно линейная апроксимация для кривых
   * за нейминг не пиздить это все Ельдар
   */
  aproximationVerticesCollectorForArc = (
    P1 = { x: 200, y: 134 },
    P2 = { x: 353, y: 104 },
    P3 = { x: 365, y: 330 },
    Ra1 = 20,
    Ra2 = 20
  ) => {
    const nugol2 = (x1, y1, x2, y2) => {
      let xf = x2 - x1;
      let yf = y2 - y1;
      let result = 0;
      if (xf !== 0) {
        result = Math.atan(yf / xf);
      } else {
        result = (Math.PI * 3) / 2;
      }
      if (xf < 0) {
        result += Math.PI;
      }
      if (result < 0) {
        result += Math.PI * 2;
      }
      if (result >= Math.PI * 2) {
        result -= Math.PI * 2;
      }
      return result;
    };
    let result = [];
    let step1 = Ra1;
    let step2 = Ra2;
    let rad1 = Math.sqrt(Math.pow(P2.x - P1.x, 2) + Math.pow(P2.y - P1.y, 2));
    let rad2 = Math.sqrt(Math.pow(P3.x - P1.x, 2) + Math.pow(P3.y - P1.y, 2));
    if (rad1 <= 0 || rad2 <= 0) {
      return false;
    }
    let un = nugol2(P1.x, P1.y, P2.x, P2.y);
    let uk = nugol2(P1.x, P1.y, P3.x, P3.y);
    if (uk <= un) {
      uk += 2 * Math.PI;
    }
    let direction = uk - un;
    step1 = Ra1 / rad1;
    step2 = Ra2 / rad2;
    let q, nt;
    if (step1 < direction && step2 < direction) {
      q = (direction - step1) / (direction - step2);
      if (q === 1) {
        nt = Math.round(direction / step1);
      }
      if (q === 1) {
        nt = Math.round(direction / step1);
        if (nt < 1) {
          nt = 1;
        }
        step1 = direction / nt;
      } else {
        nt = Math.round(Math.log10(step2 / step1) / Math.log10(q) + 1);
        if (nt < 1) {
          nt = 1;
        }
        if (nt > 1) {
          step1 = (direction * (q - 1)) / (Math.pow(q, nt) - 1);
        }
      }
      let du = 0;
      for (let i = 1; i < nt; i++) {
        du = du + step1;
        let u = un + du;
        let rad = rad1 + ((rad2 - rad1) / direction) * du;
        let x = P1.x + rad * Math.cos(u);
        let y = P1.y + rad * Math.sin(u);
        step1 *= q;
        result.push(new Vector2(x, y));
      }
    }
    this.addCounterReasercheEnv("nodes", result.length);
    return result;
  };
  /**
   * добавил прямую в граф точек
   * @param {*} target
   * @param {*} active
   */
  addStraight = (target, active) => {
    const {
      renderDots,
      activeDotForCreatePrimitive,
      straightTargetMoveOrOut,
    } = this.state;
    const addDataForArr = (arr, content) => {
      arr.push(content);
      return arr;
    };
    const getDotData = (uuid) => {
      for (let i = 0; i < renderDots.length; i++) {
        const element = renderDots[i];
        if (element.uuidDot === uuid) {
          return element;
        }
        if (element.type === "arc") {
          if (element.startPoint.uuidDot === uuid) {
            return element.startPoint;
          }
          if (element.centerPoint.uuidDot === uuid) {
            return element.centerPoint;
          }
          if (element.endPoint.uuidDot === uuid) {
            return element.endPoint;
          }
        }
      }
    };
    // !optimize plz
    const collectorGrafPrimitive = (el) => {
      if (el.uuidDot === active.uuid) {
        let startPointVector = { x: el.x, y: el.y };
        let endPointVector = { x: target.position.x, y: target.position.z };
        let direction = checkDirectionForVector2(
          startPointVector,
          endPointVector
        );
        let rAproximationStartDot = el.coeficentR;
        let rAproximationEndDot = getDotData(target.uuid);
        //!объект необходимый для функции кусочно линейной апроксимации
        let dataForAproximationCollector = {
          startPointVector,
          endPointVector,
          direction,
          rAproximationStartDot: Number(rAproximationStartDot),
          rAproximationEndDot: Number(rAproximationEndDot.coeficentR),
        };
        this.addCounterReasercheEnv("primitive", 1);
        return {
          ...el,
          edge: addDataForArr(el.edge, {
            direction: direction,
            color: "red",
            idEndLink: target.uuid,
            aproximationVertices: this.aproximationVerticesCollector(
              dataForAproximationCollector
            ),
          }),
          linkToStraight: addDataForArr(el.linkToStraight, target.uuid),
        };
      } else if (el.uuidDot === target.uuid) {
        let startPointVector = { x: el.x, y: el.y };
        let endPointVector = { x: active.position.x, y: active.position.z };
        let direction = checkDirectionForVector2(
          startPointVector,
          endPointVector
        );
        return {
          ...el,
          edge: addDataForArr(el.edge, {
            direction: direction,
            color: "red",
            idEndLink: active.uuid,
            aproximationVertices: [],
          }),
          linkToStraight: addDataForArr(el.linkToStraight, active.uuid),
        };
      } else {
        return false;
      }
    };
    let newRenderDots = renderDots.map((el) => {
      if (el.type !== "arc") {
        let result = collectorGrafPrimitive(el);
        return result ? result : el;
      } else {
        let result = el;
        let startPoint = collectorGrafPrimitive(el.startPoint);
        let centerPoint = collectorGrafPrimitive(el.centerPoint);
        let endPoint = collectorGrafPrimitive(el.endPoint);
        if (startPoint) {
          result = {
            ...el,
            startPoint,
          };
        }
        if (centerPoint) {
          result = {
            ...el,
            centerPoint,
          };
        }
        if (endPoint) {
          result = {
            ...el,
            endPoint,
          };
        }

        return result;
      }
    });
    this.setDefaultColorDot(activeDotForCreatePrimitive[0]);
    if (straightTargetMoveOrOut)
      this.setDefaultColorDot(straightTargetMoveOrOut);
    this.setState({
      renderDots: newRenderDots,
      activeDotForCreatePrimitive: [],
      straightTargetMoveOrOut: null,
    });
  };
  /**
   * соберет набор для кривых из набора активных мешей точек
   * !сюда только набор мешей, сериализованные точки не сюда!
   * @param {*} data
   */
  createArrayRenderArcFromActiveDots = (data) => {
    const { renderDots } = this.state;
    return data.map((el, i) => {
      return {
        coeficentR: el._customAttribute.coeficentR,
        position: el.position,
        x: el.position.x,
        y: el.position.z,
        countId: renderDots.length,
        uuidDot: el.uuid,
        edge: el._customAttribute.edge,
        linkToStraight: el._customAttribute.linkToStraight,
      };
    });
  };
  /**
   * создаст кривую
   * @param {*} tool
   * @param {*} activeDotForCreatePrimitive
   * @param {*} group
   */
  arcGenerateFromDots = (tool, activeDotForCreatePrimitive, group) => {
    if (tool) {
      if (tool.type === this.toolEnum.arc) {
        const { enableCurveDrawing, renderDots } = this.state;
        if (activeDotForCreatePrimitive.length <= 1) {
          activeDotForCreatePrimitive.push(group.data.target);
          return this.setState({
            activeDotForCreatePrimitive: activeDotForCreatePrimitive,
            activeDot: false,
          });
        } else {
          if (Array.isArray(enableCurveDrawing)) {
            activeDotForCreatePrimitive.push(group.data.target);
            let paramsArc = {
              radiusAStartPoint:
                activeDotForCreatePrimitive[0]._customAttribute.coeficentR,
              radiusAEndPoint:
                activeDotForCreatePrimitive[1]._customAttribute.coeficentR,
              renderDots: renderDots,
              verticesForArc: enableCurveDrawing,
              renderArc: this.createArrayRenderArcFromActiveDots(
                activeDotForCreatePrimitive
              ),
            };
            let newPointArc = serializers.addPointToRenderDots(paramsArc);
            this.clearMemory();
            this.setState({
              renderArc: [],
              verticesForArc: [],
              enableCurveDrawing: false,
              renderDots: newPointArc,
              activeDotForCreatePrimitive: [],
            });
            this.clearMemory();
          }
        }
      }
    }
  };
  /**
   * отвечает за созданые линии
   * @param {*} tool
   * @param {*} activeDotForCreatePrimitive
   * @param {*} group
   */
  straightGenerate = (tool, activeDotForCreatePrimitive, group) => {
    const { renderDots, linkToSceneObject } = this.state;
    if (tool) {
      if (tool.type === this.toolEnum.straight) {
        if (activeDotForCreatePrimitive.length <= 0) {
          activeDotForCreatePrimitive.push(group.data.target);
          return this.setState({
            activeDotForCreatePrimitive: activeDotForCreatePrimitive,
            activeDot: false,
          });
        } else {
          let checkDoobleClick =
            activeDotForCreatePrimitive[0].uuid === group.data.target.uuid;
          if (checkDoobleClick) {
            this.setDefaultColorDot(group.data.target);
            this.setState({
              activeDotForCreatePrimitive: [],
            });
          } else {
            let checkDot = checkConnectedDots(
              activeDotForCreatePrimitive[0].uuid,
              group.data.target.uuid,
              renderDots,
              this.clearMemory,
              linkToSceneObject
            );
            if (checkDot) {
              this.addStraight(
                group.data.target,
                activeDotForCreatePrimitive[0]
              );
            } else {
              console.log("mis");
            }
          }
        }
      }
    }
  };
  /**
   * отвечает за созданые кривой
   * @param {*} position
   */
  arcGenerate = (position) => {
    let { renderDots, renderArc, isAddLastDotForArc } = this.state;
    let dataForDot = {
      coeficentR: this.getDataDotFromSidebar().r,
      position: position,
      x: position.x,
      y: position.z,
      countId: renderDots.length,
      uuidDot: v4(),
      edge: [],
      linkToStraight: [],
    };
    if (renderArc.length === 2) {
      if (isAddLastDotForArc) {
        renderArc.push(dataForDot);
      }
    } else {
      renderArc.push(dataForDot);
    }

    this.setState({
      renderArc: renderArc,
    });
  };
  /**
   * запишет позицию и передаст в компонент для обработки луча
   * @param {*} evt
   */
  subareasGenerate = (evt) => {
    this.setState({
      eventClickFullForSubareas: evt,
    });
  };
  /**
   * запишет данные о секции
   * todo: дописать с данными из сайд бара описывающими секцию (нет информации о полях)
   * @param {*} evt
   */
  sectionGenerate = (evt) => {
    const { sectionTemporaryData, sections } = this.state;
    if (sectionTemporaryData) {
      let vertices = [evt.intersects[0].point, sectionTemporaryData];
      sections.push({
        vertices: vertices,
      });
      this.addCounterReasercheEnv("elements", 1);
      this.setState({
        sections: sections,
        sectionTemporaryData: null,
      });
    }
  };
  /**
   * получит данные из сайд бара для точек
   */
  getDataDotFromSidebar = () => {
    const { x, y, r, id } = this.props.DotData;
    return { x, y, r, id };
  };
  /**
   * принимает позицию и на её основе соберет новую точку
   * @param {*} positionParams
   */
  createDot = (positionParams) => {
    let { renderDots } = this.state;
    this.addCounterReasercheEnv("dots", 1);
    renderDots.push({
      position: positionParams.position,
      coeficentR: this.getDataDotFromSidebar().r,
      x: this.getDataDotFromSidebar().x,
      y: this.getDataDotFromSidebar().y,
      countId: renderDots.length,
      uuidDot: v4(),
      edge: [],
      linkToStraight: [],
    });
    this.setState({
      renderDots: renderDots,
    });
  };
  /**
   * метод отвечающий за подготовку к созданию прямой
   */
  startCreateStraight = () => {
    const { activeDot, uslesCheckPosMouse } = this.state;
    if (activeDot || !uslesCheckPosMouse) {
      this.setState({
        uslesCheckPosMouse: true,
        activeDot: false,
      });
    }
  };
  /**
   * метод отвечающий за подготовку к созданию кривой
   */
  startCreateArc = () => {
    const { activeDot, uslesCheckPosMouse, paramsPrimitiveData } = this.state;
    if (activeDot || !uslesCheckPosMouse) {
      this.setState({
        uslesCheckPosMouse: paramsPrimitiveData.linkToDotsChangeCheckbox,
        activeDot: false,
      });
    }
  };
  /**
   * метод отвечающий за подготовку к созданию секции
   * @param {*} positionParams
   */
  startCreatesections = (positionParams) => {
    this.setState({
      sectionTemporaryData: positionParams.position,
    });
  };

  /**
   * @positionParams = { position, distance }
   * !init creator
   * выбирает новые объекты для рендера исходя из инструмента
   */
  addRenderElemet = (positionParams) => {
    let { tool } = this.state;
    if (!tool) return;
    console.log("tool", tool);
    switch (tool.type) {
      case this.toolEnum.dot:
        this.setState(
          {
            uslesCheckPosMouse: false,
          },
          () => this.createDot(positionParams)
        );
        break;
      case this.toolEnum.straight:
        this.startCreateStraight(positionParams);
        break;
      case this.toolEnum.arc:
        this.setState(
          {
            uslesCheckPosMouse: false,
          },
          () => this.startCreateArc(positionParams)
        );
        break;
      case this.toolEnum.section:
        this.startCreatesections(positionParams);
        break;
      default:
        return;
    }
  };
  /**
   * Получит ссылку на объект сцены для дальнешего использования в основном для поиска елементов
   * @param {*} scene
   */
  getLinkScene = (scene) => {
    this.setState({
      linkToSceneObject: scene,
    });
  };
  /**
   * Получит размеры холста
   * @param {*} scene
   */
  getSizeCanvasDomEl = (heightCanvas, widthCanvas) => {
    this.setState({
      heightCanvas: heightCanvas,
      widthCanvas: widthCanvas,
    });
  };
  /**
   * соберет верменную кривую из входных параметров
   */
  canvasMoveToCreateArc = (renderArc, position, DotData) => {
    const {
      heightCanvas,
      widthCanvas,
      linkToSceneObject,
      renderDots,
      verticesForArc,
    } = this.state;
    if (renderArc.length === 1) {
      let startVector = new Vector2(
        position.x + widthCanvas,
        position.z + heightCanvas
      );
      let stopVector = new Vector2(
        renderArc[0].x + widthCanvas,
        renderArc[0].y + heightCanvas
      );
      createArcPreStraight(
        startVector,
        stopVector,
        linkToSceneObject,
        this.clearMemory
      );
    } else if (renderArc.length === 2) {
      this.clearMemory();
      let startDot = new Vector2(
        renderArc[0].x + widthCanvas,
        renderArc[0].y + heightCanvas
      );
      let twoDot = new Vector2(
        renderArc[1].x + widthCanvas,
        renderArc[1].y + heightCanvas
      );
      let aproximationVector = new Vector2(
        position.x + widthCanvas,
        position.z + heightCanvas
      );
      let verticesFromAproximationArc = this.aproximationVerticesCollectorForArc(
        startDot,
        twoDot,
        aproximationVector,
        renderArc[1].coeficentR,
        DotData.r
      );
      let newVerticesForArc = createArcAllPoints(
        aproximationVector,
        twoDot,
        verticesFromAproximationArc,
        linkToSceneObject,
        this.clearMemory,
        heightCanvas,
        widthCanvas,
        renderArc,
        renderDots
      );
      if (Array.isArray(newVerticesForArc)) {
        this.setState({
          isAddLastDotForArc: true,
          verticesForArc: newVerticesForArc,
        });
      } else {
        this.setState({
          verticesForArc: [],
          isAddLastDotForArc: false,
        });
      }
    } else if (renderArc.length >= 2) {
      let paramsArc = {
        radiusAStartPoint: renderArc[1].coeficentR,
        radiusAEndPoint: DotData.r,
        renderDots: renderDots,
        verticesForArc: verticesForArc,
        renderArc: renderArc,
      };
      let newPointArc = serializers.addPointToRenderDots(paramsArc);
      this.setState({
        renderArc: [],
        verticesForArc: [],
        renderDots: newPointArc,
      });
      this.clearMemory();
    }
  };
  /**
   * соберет временную секцию
   * @param {*} position
   * @param {*} sectionTemporaryData
   */
  canvasMoveToCreateSection = (sectionTemporaryData, position) => {
    const { linkToSceneObject } = this.state;
    this.clearMemory();
    let params = {
      sectionTemporaryData: sectionTemporaryData,
      tempPosition: position,
      linkToSceneObject: linkToSceneObject,
    };
    serializers.AddTemporarySections(params);
  };
  /**
   * Получает позицию мышки когда выбран инструмент
   * @param {*} position
   */
  getPositionCanvasMove = ({ position }) => {
    const { DotData } = this.props;
    const {
      tool,
      renderArc,
      paramsPrimitiveData,
      sectionTemporaryData,
    } = this.state;
    if (tool) {
      if (
        tool.type === this.toolEnum.section ||
        tool.type === this.toolEnum.subareas
      ) {
        if (tool.type === this.toolEnum.section) {
          if (sectionTemporaryData)
            this.canvasMoveToCreateSection(sectionTemporaryData, position);
        }
      } else {
        //обновляем данные в сайдбаре
        this.props.dispatch("DotData", {
          x: position.x,
          y: position.z, //сделал так потому что мы смотрим в проекции  сверху а не с боку
          r: DotData.r,
          id: DotData.id,
        });
        // работаем с построением временных мешей
        if (!paramsPrimitiveData.linkToDotsChangeCheckbox) {
          if (tool.type === this.toolEnum.arc) {
            this.canvasMoveToCreateArc(renderArc, position, DotData);
          }
        }
      }
    }
  };
  /**
   * по клику возьмет координаты и попытается добавить выбранный элемент
   * @param {*} { position, evt }
   */
  getPositionCanvasClick = ({ position, evt }) => {
    const { tool, paramsPrimitiveData } = this.state;
    this.setState(
      {
        clickEvt: true,
      },
      () => {
        let positionParams = {
          position,
          evt,
        };

        this.addRenderElemet(positionParams);
        if (!paramsPrimitiveData.linkToDotsChangeCheckbox) {
          if (tool)
            if (tool.type === this.toolEnum.arc) {
              this.arcGenerate(position);
            } else if (tool.type === this.toolEnum.subareas) {
              this.subareasGenerate(evt);
            } else if (tool.type === this.toolEnum.section) {
              this.sectionGenerate(evt);
            }
        }
      }
    );
  };
  /**
   * принимает точку и востановит ей дефолтный цвет
   * @param {*} dot
   */
  setDefaultColorDot = (dot) => {
    dot.children[0].material.color.set("#f54248");
    dot.children[1].material.color.set("#d1db16");
  };
  /**
   * принимает точку и установит ей цвет выбранной точки
   * @param {*} dot
   */
  setSelectedColorDot = (dot) => {
    dot.children[0].material.color.set("#4a65ff");
    dot.children[1].material.color.set("#4a65ff");
  };
  /**
   * принимает точку и установит ей цвет при на вередении
   * @param {*} dot
   */
  setHoverColorDot = (dot) => {
    dot.children[0].material.color.set("red");
    dot.children[1].material.color.set("red");
  };
  /**
   * перекрасит все выделенные примитивы в дефолтные цвета
   * !что-то не пашет
   */
  setDefaultColorSelectedPrimitive = () => {
    const { activeDotForCreatePrimitive } = this.state;
    activeDotForCreatePrimitive.forEach((el) => {
      if (el instanceof Mesh) {
        this.setDefaultColorDot(el);
      }
    });
  };
}

export default AbstractVLS;
