import React, { useState, useRef } from 'react';
import Tooltip from '../../Layout/Tooltip'
import v4 from 'uuid/v4';
/**
 * Для влс спец инпут
 * @param {*} param
 */
const InputValueData = (props) => {
  let {
    wrapperClassName = '',
    firstDescription = '',
    secondDescription = '',
    type = 'text',
    defaultValue,
    className = 'data',
    classNameFirstDescription = "",
    firstItemClassName,
    onChange = (e) => { },
    value,
    min = Infinity,
    max = Infinity,
    id = v4(),
    iteration,
    disabled = false,
    defaultClassWrapperDisabled = false
  } = props
  const [target, setTarget] = useState(null)
  const [eventData, setEventData] = useState(null)
  const inputEl = useRef(null);
  let timeOut
  const onMouseEnter = (e) => {
    e.persist()
    timeOut = setTimeout(() => {
      setTarget(e.target)
      setEventData(e)
    }, 2000)
  }
  const onMouseLeave = (e) => {
    e.persist()
    clearTimeout(timeOut)
    setTarget(null)
    setEventData(null)
  }

  const clickIncrementValue = (e) => {
    let test = e.currentTarget.parentNode.parentNode.childNodes[0]
    let num = Number(test.value)
    if (max === num) return
    test.value = num += 1
    onChange({ target: test })
  }
  const clickDectimentValue = (e) => {
    let test = e.currentTarget.parentNode.parentNode.childNodes[0]
    let num = Number(test.value)
    if (min === num) return
    test.value = num -= 1
    onChange({ target: test })
  }
  return (
    <div className={`${defaultClassWrapperDisabled ? "" : "wrapper__input"} ${wrapperClassName}`}>
      {
        firstDescription !== ''
          ? <label className={`${classNameFirstDescription}`}>{firstDescription}</label>
          : ''
      }
      {
        type === 'number_custom' ? (
          <div className={"wrapper__input-wrapper_add_btn"}>
            <input
              ref={inputEl}
              min={min}
              max={max}
              onChange={onChange}
              onMouseLeave={onMouseLeave}
              onMouseEnter={onMouseEnter}
              type={'number'}
              disabled={disabled}
              value={value ? value : defaultValue}
              className={`input__${className} ${firstItemClassName}`}
              id={id}
              data-iteration={iteration}
            />
            <div className={"wrapper__input-input"}>
              <button onClick={clickIncrementValue} className={"wrapper__input-input-inc_btn"}></button>
              <button onClick={clickDectimentValue} className={"wrapper__input-input-dec_btn"}></button>
            </div>
          </div>
        ) : (
          <div className={`input__${className}-wrapper`}>
            <input
              ref={inputEl}
              min={min}
              max={max}
              onChange={onChange}
              onMouseLeave={onMouseLeave}
              onMouseEnter={onMouseEnter}
              type={type}
              disabled={disabled}
              value={value ? value : defaultValue}
              className={`input__${className} ${firstItemClassName}`}
              id={id}
              data-iteration={iteration}
            />
          </div>
          )
      }
      {
        secondDescription !== ''
          ? <label>{secondDescription}</label>
          : ''
      }
      {/* <Tooltip 
        event={eventData}
        target={target}
        text='Может стоять слово “немаг”. Тогда аморфная сталь рассматривется как среда с постоянной магнитной проницаемостью.'
      /> */}
    </div>
  )
}
export default InputValueData