import Chart from 'chart.js';
/**
 * получит цвет линии
 * @param {*} key
 */
const getColorBorder = (key) => {
  switch (key) {
    case 'group_1':
      return ['rgba(3, 157, 252, 0.6)'];
    case 'group_2':
      return ['rgba(252, 3, 53, 0.6)'];
    case 'group_3':
      return ['rgba(3, 252, 57, 0.6)'];
    case 'group_4':
      return ['rgba(252, 252, 3, 0.6)'];
    case 'group_5':
      return ['rgba(194, 3, 252, 0.6)'];
  }
};
/**
 * получит набор цветов для каждого канала по отдельности
 * @param {*} key
 */
const getColorChanel = (key) => {
  switch (key) {
    case 'group_1':
      return ['rgba(3, 157, 252, 0)'];
    case 'group_2':
      return ['rgba(252, 3, 53, 0)'];
    case 'group_3':
      return ['rgba(3, 252, 57, 0)'];
    case 'group_4':
      return ['rgba(252, 252, 3, 0)'];
    case 'group_5':
      return ['rgba(194, 3, 252, 0)'];
  }
};

const getLabel = (key) => {
  switch (key) {
    case 'group_1':
      return '1 канал';
    case 'group_2':
      return '2 канал';
    case 'group_3':
      return '3 канал';
    case 'group_4':
      return '4 канал';
    case 'group_5':
      return '5 канал';
  }
};
/**
 * отрисует график
 * @param {*} data
 */
export const drawChart = (data) => {
  let canvas = document.getElementById('charts-generator');
  let ctx = canvas.getContext('2d');
  canvas.width = 700;
  canvas.height = 400;
  let dataSet = [];
  let dataSetLabelsGroup = [];
  delete data['data'];
  delete data['id'];
  delete data['t'];
  delete data['group_2'];
  delete data['group_3'];
  delete data['group_4'];
  delete data['group_5'];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      let group = data[key];
      let dataSetFirstGroup = group.slice(-25).map((el) => el[1]);
      if (!dataSetFirstGroup.length) {
        dataSetFirstGroup.push(0);
        dataSetFirstGroup.push(0);
      } else {
        if (!dataSetLabelsGroup.length) {
          dataSetLabelsGroup = group.slice(-25).map((el) => el[0].toFixed(2));
        }
      }
      dataSet.push({
        data: dataSetFirstGroup,
        backgroundColor: getColorChanel(key),
        borderColor: getColorBorder(key),
        label: getLabel(key),
        borderWidth: 2,
      });
    }
  }
  let cart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: dataSetLabelsGroup,
      datasets: dataSet,
    },
    options: {
      animation: {
        duration: 0,
      },
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'ОСЦИЛЛОГРАФ',
      },
      responsive: false,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
  });
  const image = new Image();
  image.crossOrigin = 'anonymous';
  image.src = cart.toBase64Image();
  return image;
};
