import React from 'react'
import Layout from '../../Views/Layout'
import PersonalPageLayout from '../../Views/Layout/PersonalPageLayout'
import SearchProject from '../../components/SearchProject'

const PersonalPageSearchProject = ()=>{
  return(
    <>
     <Layout title={"Поиск"}>
       <PersonalPageLayout>
         <SearchProject />
       </PersonalPageLayout>
     </Layout>
    </>
  )
}
export default PersonalPageSearchProject