import { Vector3 } from "three";

export const arcToDotData = (arcData) => {
    let result = []
    arcData.forEach(element => {
        result.push({
            ...element,
            position: new Vector3(element.x, 2, element.y)
        })
    });
    return result
}