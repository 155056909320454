import React from "react";
import ButtonMain from "../../Views/Layout/ButtonMain";

const ZoomBlock = (props) => {
  return <div className={"vls-zoom_block"}>
    <ButtonMain onClick={props.clickSetZoomInc} className={"vls-zoom_block-icon_plus"}></ButtonMain>
    <ButtonMain onClick={props.clickSetZoomDec} className={"vls-zoom_block-icon_minus"}></ButtonMain>
  </div>;
};

export default ZoomBlock;
