import React from 'react';
import ParamsDeviceValue from '../../Views/Layout/ParamsDeviceValue';
import DeviceSetting from '../../Views/Layout/DeviceSetting';
import { Tabs as RBTabs, Tab } from 'react-bootstrap';
import CheckBox from '../../Views/Layout/CheckBox';
import Chart from '../../Views/Layout/Chart';
import ButtonMain from '../../Views/Layout/ButtonMain';
import InputValueData from '../../Views/Layout/InputValueData';
import ChooseRadioButton from '../../Views/Layout/ChooseRadioButton';
import TableGenerate from '../../common/TableGenerate';
import { v4 } from 'uuid';
import isEqual from 'react-fast-compare';
import Api from '../../api';
import SubmitForm from '../../common/SubmitForm';
import connect from 'storeon/react/connect';

const ApiResearch = Api.ResearchApi;
class TranformerComponentForSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabKey: 'settings',
      valueNumberPhases: 3,
      dataChart: [0, 0, 50, 0, 0, 0, 50, 0, 50, 0, 50],
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Ном. мощность (кВА)', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Ном.напряжение (В)', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Число витков первич.обр.', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Сопротивл. первич. обм.', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Индукт-ть первичн. обм.', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Число витков вторич.обм.', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Сопротивл. вторич. обм.', value: 9.9999e42 },
        { id: v4(), nameOfValue: 'Индукт-ть вторич.обм.', value: 9.9999e42 },
      ],
      TableHeadData: [
        [
          { name: 'Сила тока (А)', attr: {} },
          { name: 'L (Гн)', attr: {} },
        ],
      ],
      initTableData: [],
      TableBodyData: [],
      variant: 'vls-table_data-oscilloscope-table',
      paramsDeviceCustomMeta: {
        titleClassName: 'vls-sidebar-title_device_meta_data',
        valueRadioButton: [
          {
            titleClassName: 'vls-sidebar-title_device_setings',
            title: 'Число фаз',
            addClassWrapper: 'custom_width',
            options: [
              { id: v4(), name: '1-фазный', active: false },
              { id: v4(), name: '2-фазный', active: false },
              { id: v4(), name: '3-фазный', active: true },
            ],
          },
          {
            titleClassName: 'vls-sidebar-title_device_setings',
            title: 'Исполнение',
            addClassWrapper: 'custom_max_width_label',
            options: [
              { id: v4(), name: 'без измен. напряжения', active: false },
              { id: v4(), name: 'с изменением напряжен.', active: true },
            ],
          },
        ],
      },
    };
  }

  submitData = () => {
    let { hash_id } = this.props.element;
    if (!hash_id) hash_id = this.props.element.id;
    const { paramsDeviceDataNamefields, paramsDeviceCustomMeta, initTableData } = this.state;
    let params = {
      device_id: hash_id,
      params: {
        meta: {
          defaultSettings: {
            power: paramsDeviceDataNamefields[0].value,
            voltage: paramsDeviceDataNamefields[1].value,
            turningNumber1: paramsDeviceDataNamefields[2].value,
            resistive1: paramsDeviceDataNamefields[3].value,
            inductance1: paramsDeviceDataNamefields[4].value,
            turningNumber2: paramsDeviceDataNamefields[5].value,
            resistive2: paramsDeviceDataNamefields[6].value,
            inductance2: paramsDeviceDataNamefields[7].value,
          },
          deviceSettings: {
            phaseNumber: paramsDeviceCustomMeta.valueRadioButton[0].options,
            implementation: paramsDeviceCustomMeta.valueRadioButton[1].options,
          },
          table: initTableData,
        },
      },
    };
    this.props.dispatch('SidebarDataFromDevice', {
      isUpdate: true,
      hash_id: hash_id,
      data: params.params.meta,
    });
    SubmitForm(ApiResearch.putResearchUpdateDeviceData, params, this.successSubmit);
  };

  successSubmit = (response) => {
    console.log(response);
  };

  updateDeviceData = (data) => {
    this.setState(data, this.submitData);
  };

  handlerNumberPhases = (e) => {};

  defaultDeviceDataChange = (e) => {
    const { paramsDeviceDataNamefields } = this.state;
    let resultsUpdate = [];
    for (let i = 0; i < paramsDeviceDataNamefields.length; i++) {
      const element = paramsDeviceDataNamefields[i];
      if (element.id === e.target.id) {
        resultsUpdate.push({
          ...element,
          value: e.target.value,
        });
      } else {
        resultsUpdate.push(element);
      }
    }
    let newData = {
      paramsDeviceDataNamefields: resultsUpdate,
    };
    this.updateDeviceData(newData);
  };

  changeTableDataForCharts = (data) => {
    const { initTableData } = this.state;
    this.updateDeviceData({
      changeIn: true,
      initTableData: initTableData.map((el, i) => {
        if (data.iter === i) {
          el[data.key] = Number(data.target.value);
          return el;
        } else {
          return el;
        }
      }),
    });
  };

  generateTableBody = (arr) => {
    let results = [];
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      let valueDivision = {
        tdParams: { className: 'td_padding_null' },
        content: (
          <div key={i}>
            <InputValueData
              wrapperClassName={'vls-table_data-oscilloscope-input_wrapper'}
              className={'data vls-table_data-oscilloscope-input'}
              type={'number'}
              value={element.FA}
              onChange={(e) => {
                let data = {
                  target: e.target,
                  iter: i,
                  key: 'FA',
                };
                this.changeTableDataForCharts(data);
              }}
            />
          </div>
        ),
      };
      let startCount = {
        tdParams: { className: 'td_padding_null' },
        content: (
          <div key={i} className={``}>
            <InputValueData
              wrapperClassName={'vls-table_data-oscilloscope-input_wrapper'}
              className={'data vls-table_data-oscilloscope-input'}
              type={'number'}
              value={element.F}
              onChange={(e) => {
                let data = {
                  target: e.target,
                  iter: i,
                  key: 'F',
                };
                this.changeTableDataForCharts(data);
              }}
            />
          </div>
        ),
      };
      results.push([valueDivision, startCount]);
    }
    return results;
  };

  selectTab = (e) => {
    this.setState({
      activeTabKey: e,
    });
  };

  handlerChooseRadioButton = (e) => {
    const { paramsDeviceCustomMeta } = this.state;
    const setvalueRadioButtonData = () => {
      return paramsDeviceCustomMeta.valueRadioButton.map((el, i) => {
        let changeCkeck = false;
        let result = el.options.map((elOption, i) => {
          if (elOption.id === e.target.id) {
            changeCkeck = true;
            return {
              ...elOption,
              active: true,
            };
          } else {
            return {
              ...elOption,
              active: false,
            };
          }
        });
        return {
          ...el,
          options: changeCkeck ? result : el.options,
        };
      });
    };
    this.updateDeviceData({
      paramsDeviceCustomMeta: {
        ...paramsDeviceCustomMeta,
        valueRadioButton: setvalueRadioButtonData(),
      },
    });
  };

  setDataDevice = (props) => {
    const { element } = props;
    const { defaultSettings, deviceSettings, table } = element.meta;
    this.setState({
      paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Ном. мощность (кВА)', value: defaultSettings.power },
        { id: v4(), nameOfValue: 'Ном.напряжение (В)', value: defaultSettings.voltage },
        {
          id: v4(),
          nameOfValue: 'Число витков первич.обр.',
          value: defaultSettings.turningNumber1,
        },
        { id: v4(), nameOfValue: 'Сопротивл. первич. обм.', value: defaultSettings.resistive1 },
        { id: v4(), nameOfValue: 'Индукт-ть первичн. обм.', value: defaultSettings.inductance1 },
        {
          id: v4(),
          nameOfValue: 'Число витков вторич.обм.',
          value: defaultSettings.turningNumber2,
        },
        { id: v4(), nameOfValue: 'Сопротивл. вторич. обм.', value: defaultSettings.resistive2 },
        { id: v4(), nameOfValue: 'Индукт-ть вторич.обм.', value: defaultSettings.inductance2 },
      ],
      paramsDeviceCustomMeta: {
        titleClassName: 'vls-sidebar-title_device_meta_data',
        valueRadioButton: [
          {
            titleClassName: 'vls-sidebar-title_device_setings',
            title: 'Число фаз',
            addClassWrapper: 'custom_width',
            options: deviceSettings.phaseNumber,
          },
          {
            titleClassName: 'vls-sidebar-title_device_setings',
            title: 'Исполнение',
            addClassWrapper: 'custom_max_width_label',
            options: deviceSettings.implementation,
          },
        ],
      },
      initTableData: table,
      TableBodyData: this.generateTableBody(table),
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.element, prevProps.element)) {
      this.setDataDevice(this.props);
    }
    if (prevState.initTableData !== this.state.initTableData) {
      this.setState({
        TableBodyData: this.generateTableBody(this.state.initTableData),
      });
    }
  }

  componentDidMount() {
    this.setDataDevice(this.props);
  }

  render() {
    const {
      activeTabKey,
      paramsDeviceDataNamefields,
      paramsDeviceCustomMeta,
      valueNumberPhases,
      dataChart,
      TableHeadData,
      TableBodyData,
      variant,
    } = this.state;
    return (
      <React.Fragment>
        <span className={'vls-sidebar-device_title'}>ТРАНСФОРМАТОР</span>
        <RBTabs
          id="vls-sidebar_tabs_wraper"
          defaultActiveKey={'base'}
          activeKey={activeTabKey}
          onSelect={this.selectTab}
        >
          <Tab tabClassName={'vls-sidebar_tabs_wraper-tab'} eventKey="settings" title="Параметры">
            <ParamsDeviceValue
              onChange={this.defaultDeviceDataChange}
              classNameForInput={'vls-sidebar_tabs_wraper-tab-input'}
              data={paramsDeviceDataNamefields}
            />
            <DeviceSetting
              handlerChooseRadioButton={this.handlerChooseRadioButton}
              data={paramsDeviceCustomMeta}
            />
          </Tab>
          <Tab tabClassName={'vls-sidebar_tabs_wraper-tab'} eventKey="chart" title="Ф = f(F)">
            <Chart width={280} height={230} dataChart={dataChart} />
            <ButtonMain className={'button__option-menu vls-btn-rebuild-inductance'}>
              Перестроить
            </ButtonMain>
            <div className={'vls-inductance-table_wrapper'}>
              <TableGenerate
                variant={variant}
                TableHeadData={TableHeadData}
                TableBodyData={TableBodyData}
              />
            </div>
            <div className="content-border-line"></div>
          </Tab>
        </RBTabs>
      </React.Fragment>
    );
  }
}

export default connect('SidebarDataFromDevice', TranformerComponentForSidebar);
