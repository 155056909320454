import React from "react";
import Container from "../Container";

const FeaturesSection = (props) => {
  const itemsFeatures = [
    {
      title: "Использование",
      type: "use",
      contentText:
        "быстродействующих полевых динамических моделей с учетом влияния особенностей конструкции на электрическую сеть, характера протекания процессов.",
    },
    {
      title: "Учёт",
      type: "count",
      contentText:
        "• нелинейности характеристик • несимметрии в конструкции • нетиповых вариантов конструктивного исполнения и прочее.",
    },
    {
      title: "Сервер",
      type: "server",
      contentText:
        "отдельно выделенный с переносом части вычислительных процессорных мощностей в облачную среду.",
    },
    {
      title: "Применение",
      type: "useTwoType",
      contentText:
        "технологии порождающего проектирования (нет аналогов в мире), которая базируется на доступных программных средствах.",
    },
    {
      title: "Своя среда",
      type: "env",
      contentText:
        "для имитации эксперимента, создания физических цепей на базе модуля МИМФЦ.",
    },
    {
      title: "Две библиотеки",
      type: "twoLib",
      contentText:
        "динамические, могут интегрироваться в любые приложения. Комбинация их функционала позволяет осуществлять моделирование нестандартных физических полей.",
    },
  ];
  const generateClassNameForIconItem = (type) => {
    switch (type) {
      case "use":
        return `landing-features_section-wrapper-grid_features-item-icon ${"use"}`;
      case "count":
        return `landing-features_section-wrapper-grid_features-item-icon ${"cont"}`;
      case "server":
        return `landing-features_section-wrapper-grid_features-item-icon ${"server"}`;
      case "useTwoType":
        return `landing-features_section-wrapper-grid_features-item-icon ${"useTwoType"}`;
      case "env":
        return `landing-features_section-wrapper-grid_features-item-icon ${"env"}`;
      case "twoLib":
        return `landing-features_section-wrapper-grid_features-item-icon ${"twoLib"}`;
      default:
        return "landing-features_section-wrapper-grid_features-item-icon use";
    }
  };
  const generateClassNameForIconItemTitle = (type) => {
    // "landing-features_section-wrapper-grid_features-item-title"
    switch (type) {
      case "use":
        return `landing-features_section-wrapper-grid_features-item-title ${"use"}`;
      case "count":
        return `landing-features_section-wrapper-grid_features-item-title ${"cont"}`;
      case "server":
        return `landing-features_section-wrapper-grid_features-item-title ${"server"}`;
      case "useTwoType":
        return `landing-features_section-wrapper-grid_features-item-title ${"useTwoType"}`;
      case "env":
        return `landing-features_section-wrapper-grid_features-item-title ${"env"}`;
      case "twoLib":
        return `landing-features_section-wrapper-grid_features-item-title ${"twoLib"}`;
      default:
        return "landing-features_section-wrapper-grid_features-item-title";
    }
  };
  return (
    <section className={"landing-features_section"}>
      <Container>
        <div className="landing-features_section-wrapper">
          <h3 className={"landing-features_section-wrapper-title"}>
          Особенности
          </h3>
          <h4 className={"landing-features_section-wrapper-subtitle"}>
          <span>В основе GARPIX Digital Twin</span> авторская технология моделирования
          режимов работы электротехнических устройств с использованием
          быстродействующих полевых динамических моделей, разработанная при
          участии научной группы ИГЭУ под руководством Тихонова А. И.
          </h4>
          <div className={"landing-features_section-wrapper-grid_features"}>
          {itemsFeatures.map((el, i) => {
          return (
          <div
          key={i}
          className={
          "landing-features_section-wrapper-grid_features-item"
          }
          >
          <div className="landing-features_section-wrapper-grid_features-item-header">
          <h4 className={generateClassNameForIconItemTitle(el.type)}>
          {el.title}
          </h4>
          <div className={generateClassNameForIconItem(el.type)}></div>
          </div>
          <div
          className={
          "landing-features_section-wrapper-grid_features-item-content"
          }
          dangerouslySetInnerHTML={{ __html: el.contentText }}
          >
          </div>
          </div>
          );
          })}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FeaturesSection;
