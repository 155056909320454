import React from 'react'

class ContextMenu extends React.Component {
  render () {
    return (
      <div style={{position: 'absolute'}}>

      </div>
    )
  }
}
export default ContextMenu