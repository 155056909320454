import BaseApi from "@garpix/base-api";
import { getSerializedItemProjects, getSerializedVLS } from "./Serialized";

class ProjectApi extends BaseApi {
  /**
   * запрошивает проекты 
   * @param {*} params 
   */
  getProjects = async (params = {}) => {
    const res = await this.get(`/project/`, params);
    const data = res.data;
    data.results = data.results.map(getSerializedItemProjects);
    return data;
  };
  getProjectData = async (hash_id, params = {}) => {
    const res = await this.get(`/project/${hash_id}/`, params);
    const data = res.data;
    return data;
  };
  /**
   * Спросит у бека все данные по сохраненым стендам для деталки проекта
   * @param {*} hash_id 
   * @param {*} params 
   */
  getProjectVLS = async (hash_id, params = {}) => {
    const PATH = `/project/${hash_id}/vls/`
    const res = await this.get(PATH, params)
    let data = res.data;
    data = data.map(getSerializedVLS);
    return data;
  }
  /**
   * Спросит у бека все данные по сохраненым данным полей для деталки проекта
   * @param {*} hash_id 
   * @param {*} params 
   */
  getProjectPFML = async (hash_id, params = {}) => {
    const PATH = `/project/${hash_id}/pfml/`
    const res = await this.get(PATH, params)
    let data = res.data;
    data = data.map(getSerializedVLS);
    return data;
  }
  /**
   * создаст новый проект
   * @param {*} params 
   */
  createProject = async (params = {}) => {
    const PATH = "/project/"
    const res = await this.post(PATH, params)
    const data = res.data;
    // data.results = data.results.map(getSerializedVLS);
    return data;
  }
  /**
   * частично или полностью обновит проект
   * @param {*} hash_id 
   * @param {*} params 
   */
  pathProject = async (hash_id, params = {}) => {
    const res = await this.patch(`/project/${hash_id}/`, params)
    const data = res.data;
    return data;
  }
  /**
   * Удаляет проект
   * @param {*} hash_id 
   * @param {*} params 
   */
  deleteProject = async (hash_id, params = {}) => {
    const res = await this.delete(`/project/${hash_id}/`, params)
    const data = res.data;
    return data;
  }
}

export default ProjectApi;
