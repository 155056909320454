import React from "react";
import ComponentCreator from "./Components/ComponentCreator";
import SubmitForm from "../../../common/SubmitForm";
import Api from "../../../api";
import isEqual from "react-fast-compare";
import UpdateCustomComponent from './Components/UpdateCustomComponent'
import { v4 } from "uuid";
import { sercheAllObjectByProperty } from "../../../utils";
import { DATA_OBJECT_SCENE } from "../../../gengine/constants";

const ApiResearch = Api.ResearchApi;
class ComponentCreatorForModels extends ComponentCreator {
  constructor(props) {
    super();
    this.updateComponentMixin = new UpdateCustomComponent()
    this.maxThetaLength = 3.15; //это максимально возможное значение заполнения range
    this.state = {
      groupData: {
        position: [0, 0, 0],
        rotation: [0, 0, 0],
        scale: [1, 1, 1],
      },
      IncOrDecBtn: null,
      renderComponent: [], 
    };
  }

  submitData = (componentId, data) => {
    const { uuid } = this.props.parent;
    let params = {
      device_id: uuid,
      params: {
        type: this.props.device_type,
        component: {
          [componentId]: data,
        },
      },
    };
    SubmitForm(
      ApiResearch.putResearchUpdateDeviceData,
      params,
      this.successSubmit
    );
  };

  successSubmit = (response) => {
    console.log(response);
  };

  clearMemory = () => {
    let allTransforControler = sercheAllObjectByProperty("name", DATA_OBJECT_SCENE.transformControler.name, this.props.scene)
    allTransforControler.forEach((el) => {
      el.dispose()
      this.props.scene.remove(el)
    })
  }

  updateComponentNotRerendor = (data) => {
    this.clearMemory()
    data.map((el, i) => {
      switch (el.type) {
        case "display":
          this.updateComponentMixin.updateDisplayData(el, this.props.scene);
          break
        case "displayCustomTexture":
          this.updateComponentMixin.updateDisplayCustomTexture(el, this.props.scene);
          break
        case "chart":
          this.updateComponentMixin.updateChart(el, this.props.scene);
          break
      }
    });
  }
  /**
   * инициализирует создание дополнительных компонент и решает что отрисовать
   * @param {*} data пропсы
   * @param {*} boolean флаг говорящий за то что это обновление
   */
  initCreatorComponent = (data) => {
    if (data)
      data.map((el, i) => {
        switch (el.type) {
          case "display":
            return this.addRenderDisplay(el);
          case "displayCustomTexture":
            return this.addRenderdisplayCustomTexture(el);
          case "buttonOffOrIn":
            return this.addRenderButtonOffOrIn(el);
          case "buttonQuestion":
            return this.addRenderButtonQuestrion(el);
          case "chart":
            this.addRenderChart(el);
            break;
          case "range":
            return this.addRenderRangeCilinder(el);
          case "verticalRange":
            return this.addRenderVerticalRange(el);
          case "outerShaft":
            return this.addRenderOuterShaft(el);
          case "IncOrDecBtn":
            return this.addRenderIncOrDecBtn(el);
          case "reostatRange":
            return this.addRenderVerticalRangeForReostat(el);
          default:
            return false;
        }
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.dataComponent, prevProps.dataComponent)) {
      this.updateComponentNotRerendor(this.props.dataComponent);
      return false;
    }
    return false;
  }

  componentDidMount() {
    this.initCreatorComponent(this.props.dataComponent, this.props.update);
  }

  render() {
    const { renderComponent } = this.state;
    if (renderComponent.length) {
      return renderComponent.map((el, i) => {
        return <React.Fragment key={el.id}>{el.content}</React.Fragment>;
      });
    } else {
      return null;
    }
  }
}
export default ComponentCreatorForModels
