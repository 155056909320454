import React from 'react'
import AbstractObject from '../../abstract/AbstractObject'
import { Cylinder as AsbtractCylinder } from '../../../abstract/primitives/Cylinder'

/**
 * Примитивный объект - Цилиндр.
 */
class Cylinder extends AbstractObject {
  constructor(props) {
    super()
    this.timeOutControls = null;
    this.state = {
      objectScene: null
    }
  }

  componentWillUnmount() {
    const { objectScene } = this.state;
    if (objectScene !== null) {
      // debugger
      objectScene.remove();
      clearTimeout(this.timeOutControls);
    }
  }

  componentDidMount() {
    const {
      // default
      scene,
      enableShadows,
      // custom
      // scale = [1, 1, 1],
      radiusTop = 1,
      radiusBottom = 1,
      height = 1,
      radialSegments = 32,
      color = '#ffffff',
      texture = null,
      material = null,
      position = [0, 0, 0],
      rotation = [0, 0, 0],
      callback = null,
      parent
    } = this.props
    this.initComponent()
    let paramsCylinder = {
      scene,
      enableShadows,
      radiusTop,
      radiusBottom,
      height,
      radialSegments,
      color,
      texture,
      material,
      position,
      rotation,
      callback,
      parent
    }
    let objectScene = new AsbtractCylinder(paramsCylinder)
    this.obj = objectScene.obj
    this.setState({
      objectScene: this.obj
    })
    this.onPropsUpdate({}, this.props)
    this.timeOutControls = setTimeout(() => {
      this.readyComponent()
    }, 1)
  }

  render() {
    const { color } = this.props
    this.setColor(color)
    return null
  }
}

export default Cylinder
