import wire from "../../assets/images/wire.svg";
import Oscilograf from "../../assets/images/Oscilograf.svg";
import Reostat from "../../assets/images/Reostat.svg";
import MeasuringDevice from "../../assets/images/MeasuringDevice.svg";
import Rezistor from "../../assets/images/Rezistor.svg";
import Capacitor from "../../assets/images/Capacitor.svg"; //конденсатор
import Inductor from "../../assets/images/Inductor.svg"; //катушка индуктивности
import CircuitBreaker from "../../assets/images/CircuitBreaker.svg"; //Выключатель
import VoltageSource from "../../assets/images/VoltageSource.svg"
import Fork from "../../assets/images/Fork.svg"; //Развился
import ElectricGear from "../../assets/images/ElectricGear.svg"; //электрическая машина
import Coupling from "../../assets/images/Coupling.svg"; //муфта
import Thermistor from "../../assets/images/Thermistor.svg"; //трансформатор
import MechanicalLoad from "../../assets/images/MechanicalLoad.svg"; //Механическая нагрузка
import OwnDevice from "../../assets/images/OwnDevice.svg"; //свой прибор
// icon for white theme
import wireWhite from "../../assets/images/wire_white.svg";
import OscilografWhite from "../../assets/images/Oscilograf_white.svg";
import ReostatWhite from "../../assets/images/Reostat_white.svg";
import MeasuringDeviceWhite from "../../assets/images/MeasuringDevice_white.svg";
import RezistorWhite from "../../assets/images/Rezistor_white.svg";
import VoltageSourceWhite from "../../assets/images/VoltageSource_white.svg";
import CapacitorWhite from "../../assets/images/Capacitor_white.svg"; //конденсатор
import InductorWhite from "../../assets/images/Inductor_white.svg"; //катушка индуктивности
import CircuitBreakerWhite from "../../assets/images/CircuitBreaker_white.svg"; //Выключатель
import ForkWhite from "../../assets/images/Fork_white.svg"; //Развился
import ElectricGearWhite from "../../assets/images/ElectricGear_white.svg"; //электрическая машина
import CouplingWhite from "../../assets/images/Coupling_white.svg"; //муфта
import ThermistorWhite from "../../assets/images/Thermistor_white.svg"; //трансформатор
import MechanicalLoadWhite from "../../assets/images/MechanicalLoad_white.svg"; //Механическая нагрузка
import OwnDeviceWhite from "../../assets/images/OwnDevice_white.svg"; //свой прибор

let theme = localStorage.getItem('theme') === 'dark' ? false : true;
export default () => {
  return [
    {
      id: 1,
      image: theme ? wire : wireWhite,
      type: "wire",
      title: "Провод",
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть."
    },
    {
      id: 5,
      title: "Источник сети",
      image: theme ? VoltageSource : VoltageSourceWhite,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
      type: "voltageSource"
    },
    {
      id: 2,
      title: "Измерительные прибор",
      menu: [
        {
          type: "voltmeter",
          keyCode: "(V)",
          name: 'Прибор "Вольтметр"',
        },
        {
          type: "ampermeter",
          keyCode: "(A)",
          name: 'Прибор "Амперметр"',
        },
        {
          type: "watmeter",
          keyCode: "(W)",
          name: 'Прибор "Ватметр"',
        },
      ],
      isView: false,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
      image: theme ? MeasuringDevice : MeasuringDeviceWhite,
      type: "MeasuringDevice",
    },
    {
      id: 3,
      title: "Осциллограф",
      image: theme ? Oscilograf : OscilografWhite,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
      type: "oscilograf",
    },
    {
      id: 8,
      title: "Реостат",
      menu: [
        {
          type: "oneComponentReostat",
          keyCode: "(1)",
          name: "Реостат с 1 элементом",
        },
        {
          type: "twoComponentReostat",
          keyCode: "(2)",
          name: "Реостат с 2 элементом",
        },
        {
          type: "threeComponentReostat",
          keyCode: "(3)",
          name: "Реостат с 3 элементом",
        },
      ],
      isView: false,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
      image: theme ? Reostat : ReostatWhite,
      type: "reostat",
    },

    {
      id: 5,
      title: "Резистор",
      image: theme ? Rezistor : RezistorWhite,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
      type: "rezistor"
    },
    {
      id: 6,
      title: "Конденсатор",
      image: theme ? Capacitor : CapacitorWhite,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
      type: "capacitor"
    },
    {
      id: 7,
      title: "Катушка индуктивности",
      image: theme ? Inductor : InductorWhite,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
      type: "inductor"
    },
    {
      id: 8,
      title: "Автоматический выключатель",
      menu: [
        {
          type: "oneComponentVendingMachine",
          keyCode: "(1)",
          name: "Автомат с 1 элементом",
        },
        {
          type: "twoComponentVendingMachine",
          keyCode: "(2)",
          name: "Автомат с 2 элементом",
        },
        {
          type: "threeComponentVendingMachine",
          keyCode: "(3)",
          name: "Автомат с 3 элементом",
        },
      ],
      isView: false,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
      image: theme ? CircuitBreaker : CircuitBreakerWhite,
      type: "circuitBreaker",
    },
    {
      id: 9,
      title: "Развилка (терминал)",
      menu: [
        {
          type: "terminal",
          keyCode: "(T)",
          name: "Терминал 3",
        },
        {
          type: "terminal_4",
          keyCode: "(T)",
          name: "Терминал 4",
        },
        {
          type: "terminal_5",
          keyCode: "(T)",
          name: "Терминал 5",
        },
        {
          type: "terminal_6",
          keyCode: "(T)",
          name: "Терминал 6",
        },
        {
          type: "terminal_7",
          keyCode: "(T)",
          name: "Терминал 7",
        },
        {
          type: "terminal_8",
          keyCode: "(T)",
          name: "Терминал 8",
        },
        {
          type: "terminal_9",
          keyCode: "(T)",
          name: "Терминал 9",
        },
        {
          type: "terminal_10",
          keyCode: "(T)",
          name: "Терминал 10",
        },
        {
          type: "elementEarth",
          keyCode: "(11)",
          name: 'Элемент "Земля"',
        },
      ],
      isView: false,
      image: theme ? Fork : ForkWhite,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
      type: "Fork",
    },
    {
      id: 10,
      title: "Электрическая машина",
      menu: [
        {
          type: "constantCurrentMachine",
          keyCode: "(М)",
          name: "Машина с постоянным током",
        },
        {
          type: "asyncMachine",
          keyCode: "(М)",
          name: "Асинхронная машина",
        },
      ],
      isView: false,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
      image: theme ? ElectricGear : ElectricGearWhite,
      type: "ElectricGear",
    },
    {
      id: 11,
      title: "Муфта",
      menu: [
        {
          type: "dadCouplingSleeve",
          keyCode: "(R)",
          name: "Муфта папа",
        },
        {
          type: "mumCouplingSleeve",
          keyCode: "(L)",
          name: "Муфта мама",
        },
      ],
      isView: false,
      image: theme ? Coupling : CouplingWhite,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
      type: "Coupling",
    },
    {
      id: 12,
      title: "Трансформатор",
      menu: [
        {
          type: "onePhaseTransformer",
          keyCode: "(1)",
          name: "Трансформатор с 1 фазой",
        },
        {
          type: "twoPhaseTransformer",
          keyCode: "(2)",
          name: "Трансформатор с 2 фазами",
        },
        {
          type: "threePhaseTransformer",
          keyCode: "(3)",
          name: "Трансформатор с 3 фазами",
        },
      ],
      isView: false,
      image: theme ? Thermistor : ThermistorWhite,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
      type: "thermistor",
    },
    {
      id: 13,
      title: "Механическая нагрузка",
      image: theme ? MechanicalLoad : MechanicalLoadWhite,
      description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
      type: "mechanicalLoad",
    },
    // {
    //   id: 14, 
    //   title: "Создать свой прибор",
    //   image: theme ? OwnDevice : OwnDeviceWhite,
    //   description: "Имеется спорная точка зрения,  степени предоставлены сами себе. А ещё диаграммы связей призывают нас к новым свершениям, которые, в свою очередь, должны быть.",
    //   type: "customDevice"
    // },
  ];
};
