import React from "react";
import Slider from "react-slick";
import Container from "../../common/Container";
import Api from "../../api";
import moment from "moment";
import connect from "storeon/react/connect";
import LoaderSpiner from "../../common/LoaderSpiner";

const apiNews = Api.ContentApi;
function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"details_news-article-slider-arrow_slider-left"}
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className={"details_news-article-slider-arrow_slider-right"}
      onClick={onClick}
    />
  );
}
class DetailsNewsComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      dataNews: null,
    };
  }

  getNewsToSlug = (slug) => {
    apiNews.getNewsInSlug(slug, {}).then((res) => {
      this.props.dispatch("NewsCategoryForNewsDetails", res.category);
      this.setState({
        dataNews: res,
      });
    });
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.slag !== this.props.match.params.slag) {
      window.scrollTo(0, 0);
      this.getNewsToSlug(this.props.match.params.slag);
    }
  }

  componentDidMount() {
    this.getNewsToSlug(this.props.match.params.slag);
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      // centerPadding: 30,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: true,
            arrows: false
          }
        },
      ]
    };
    if (!this.state.dataNews) {
      return <LoaderSpiner />;
    }
    const {
      category,
      content,
      photos,
      preview,
      pub_date,
      slug,
      text_preview,
      title,
    } = this.state.dataNews;

    return (
      <React.Fragment>
        <div
          className={"details_news-back_btn"}
          onClick={() => this.props.history.goBack()}
        >
          Назад
        </div>
        <div className={"details_news-article"}>
          <div className="details_news-article-wrap">
            <h2 className={"details_news-article-title"}>{title}</h2>
            <span className={"details_news-article-under_title_text"}>
              {text_preview}
            </span>
            <span className={"details_news-article-under_title_date"}>
              {moment(pub_date).format("DD MMMM YYYY")}
            </span>
          </div>
          {photos.length ? (
            <div className={"details_news-article-slider"}>
              <Slider {...settings}>
              {photos.map((el, i) => {
                return (
                  <Container key={i}>
                  <div
                    key={i}
                    className={"details_news-article-slider-wrap_slide"}
                  >
                    <img
                      className={"details_news-article-slider-img"}
                      src={el.image}
                    />
                    <span
                      className={
                        "details_news-article-slider-text_under_img"
                      }
                    >
                      {el.title}
                    </span>
                  </div>
                  </Container>
                );
              })}
              </Slider>
            </div>
          ) : null}

          <div className="details_news-article-wrap">
            <div className={"details_news-article-content"}>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
              <div className={"details_news-article-content-author"}>
                Татьяна Дятел, Роман Кряжев, Нижний Новгород
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect("NewsCategoryForNewsDetails", DetailsNewsComponent);
