import React from "react";
import LoaderSpiner from "../LoaderSpiner";

export default (props) => {
  return (
    <div className={"modal_loader-wrapper"}>
      <div className={"modal_loader-content"}>
        <h2 className={"modal_loader-title"}>
          {props.title ? props.title : "Сохранение..."}
        </h2>
        <LoaderSpiner className={"modal_loader-spiner"} />
      </div>
    </div>
  );
};
