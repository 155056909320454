import { v4 } from 'uuid'
export default {
    paramsDeviceDataNamefields: [
        { id: v4(), nameOfValue: 'Xр = ', units: "(мм)", value: 0, },
        { id: v4(), nameOfValue: 'Yр = ', units: "(мм)", value: 0, },
        { id: v4(), nameOfValue: 'Rр = ', units: "(мм)", value: 5, },
    ],
    initialNumberSubareas: 0,
    magnetizationCoords: [
        { id: v4(), nameOfValue: 'Mu = ', units: "(мм)", value: 0, },
        { id: v4(), nameOfValue: 'Px = ', units: "(мм)", value: 0, },
        { id: v4(), nameOfValue: 'Py = ', units: "(мм)", value: 0, },
    ],
    initialOptionsNameParams: [
        {
            disabled: false,
            selected: true,
            value: "not",
            name: "not",
        },
    ],
    initialOptionsTypeEnv: [
        {
            disabled: false,
            selected: true,
            value: "nonMagneticMaterial",
            name: "немагнитный материал",
        },
        {
            disabled: false,
            selected: false,
            value: "magneticParalelMagnetization",
            name: "Магнит с параллельной намагниченностью",
        },
        {
            disabled: false,
            selected: false,
            value: "magneticCenterMagnetization",
            name: "Магнит с центральной намагниченностью",
        },
        {
            disabled: false,
            selected: false,
            value: "feroMagneticMaterial",
            name: "Ферромагнитный материал",
        }
    ],
    initialSelectTypeEnvData: {
        autoComplete: false,
        disabled: false,
        value: null,
        className: "modal-params_subareas-wrapper-content-section-triangulation_grooves-select_type_env",
    },
    initialSelectParamsName: {
        autoComplete: false,
        disabled: false,
        value: null,
        className: "modal-params_subareas-wrapper-content-section-triangulation_grooves-select_params_name",
    },
    initialAllParams: [
        {
            id: v4(),
            title: "Кол-во проводников в подобласти",
            label: "W=",
            value: "1.0000",
            disabled: false
        },
        {
            id: v4(),
            title: "Ток в проводнике, А:",
            label: "I=",
            value: "1.0000",
            disabled: false
        },
        {
            id: v4(),
            title: "Плотность тока в подобласти, А/мм²",
            label: "I=",
            value: "1.0000",
            disabled: true
        },
        {
            id: v4(),
            title: "Площадь подобласти/мм²",
            label: "I=",
            value: "1.0000",
            disabled: true
        }
    ]
}