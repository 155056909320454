import React from "react";
import SubmitForm from "../SubmitForm";
import isEqual from "react-fast-compare";
import {Form} from "react-bootstrap";
import RadioButton from "../../Views/Layout/RadioButton";
import CheckBox from "../../Views/Layout/CheckBox";
import ConfirmComponent from "../ConfirmComponent";
import connect from "storeon/react/connect";

class ViewsSettigsFromPFML extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      viewGrid: true,
      viewDots: true,
      viewRaduis: true,
      viewBorders: true,
      viewFEGrid:true,
      viewFENodes: true,
      viewSections: true,
      viewCalcArea: false,
      viewColorDiagram: false,
      viewFullWindow: false,
      //todo: object froup
      fillNone: true,
      fillMaterial: false,
      fillInductance: false,
      linesNone: true,
      linesEquipotential: false,
      linesInductance: false
    } 
  }
  // fillNone
  // fillMaterial
  // fillInductance
  componentDidMount() {
    //todo: тут сделать запрос за уже существующими данными и загнать в стейт
  }

  render() {
    const theme = this.props.ProjectTheme === 'light' ? 'light' : 'dark'
    const {
      viewGrid,
      viewDots,
      viewRaduis,
      viewBorders,
      viewFEGrid,
      viewFENodes,
      viewSections,
      viewCalcArea,
      viewColorDiagram,
      viewFullWindow,
      fillNone,
      fillMaterial,
      fillInductance,
      linesNone,
      linesEquipotential,
      linesInductance,
    } = this.state
    const {close} = this.props

    //todo: сделать вёрстку формы
    return <React.Fragment>
      <div className={`pfml-drop_down pfml-drop_down__view ${theme}`}>
        <div className={`pfml-drop_down__view-left`}>
          <div className={"pfml-drop_down_block"}>
            <div className={"pfml-drop_down_wrap"}>
              <CheckBox
                checked={viewGrid}
                onChange={(e) => {
                  this.setState({
                    viewGrid: e.target.checked
                  })
                }}
                label={"Отображать опорную сетку"}
              />
              <CheckBox
                checked={viewDots}
                onChange={(e) => {
                  this.setState({
                    viewDots: e.target.checked
                  })
                }}
                label={"Отображать опорные точки"}
              />
            </div>
          </div>
          <div className={"pfml-drop_down_block"}>
            <div className={"pfml-drop_down_wrap"}>
              <CheckBox
                checked={viewRaduis}
                onChange={(e) => {
                  this.setState({
                    viewRaduis: e.target.checked
                  })
                }}
                label={"Отображать радиус аппроксимации"}
              />
              <CheckBox
                checked={viewBorders}
                onChange={(e) => {
                  this.setState({
                    viewBorders: e.target.checked
                  })
                }}
                label={"Отображать границы раздела сред"}
              />
              <CheckBox
                checked={viewFEGrid}
                onChange={(e) => {
                  this.setState({
                    viewFEGrid: e.target.checked
                  })
                }}
                label={"Отображать КЭ-сетку"}
              />
              <CheckBox
                checked={viewFENodes}
                onChange={(e) => {
                  this.setState({
                    viewFENodes: e.target.checked
                  })
                }}
                label={"Отображать узлы КЭ-сетки"}
              />
              <CheckBox
                checked={viewSections}
                onChange={(e) => {
                  this.setState({
                    viewSections: e.target.checked
                  })
                }}
                label={"Отображать секции обмоток"}
              />
            </div>
          </div>
          <div className={"pfml-drop_down_block"}>
            <div className={"pfml-drop_down_wrap"}>
              <CheckBox
                checked={viewCalcArea}
                onChange={(e) => {
                  this.setState({
                    viewCalcArea: e.target.checked
                  })
                }}
                label={"Отображать расчётную область"}
              />
              <CheckBox
                checked={viewColorDiagram}
                onChange={(e) => {
                  this.setState({
                    viewColorDiagram: e.target.checked
                  })
                }}
                label={"Отображать цветовую диаграмму"}
              />
              <CheckBox
                checked={viewFullWindow}
                onChange={(e) => {
                  this.setState({
                    viewFullWindow: e.target.checked
                  })
                }}
                label={"Отображать по размеру окна"}
              />
            </div>
          </div>
        </div>
        <div className={`pfml-drop_down__view-right`}>
          <div className={"pfml-drop_down_block"}>
            <div className={"pfml-drop_down_wrap"}>
              <div className={"pfml-drop_down_wrap-title"}>
              Закраска элементов КЭ-сетки:
              </div>
              <Form.Group
                  className={"pfml-drop_down_wrap-form_group"}
                >
                  <Form.Label
                    className={"pfml-drop_down_wrap-form_label"}
                  >
                    <RadioButton
                      checked={fillNone}
                      onClick={(e) => {
                        this.setState({
                          fillNone: true,
                          fillMaterial: false,
                          fillInductance: false,
                        })
                      }}
                      label="Без закраски элементов"
                    />
                  </Form.Label>
                  <Form.Label>
                    <RadioButton
                      checked={fillMaterial}
                      onClick={(e) => {
                        this.setState({
                          fillNone: false,
                          fillMaterial: true,
                          fillInductance: false,
                        })
                      }}
                      label="Закраска по типу материалов"
                    />
                  </Form.Label>
                  <Form.Label>
                    <RadioButton
                      checked={fillInductance}
                      onClick={(e) => {
                        this.setState({
                          fillNone: false,
                          fillMaterial: false,
                          fillInductance: true,
                        })
                      }}
                      label="Закраска по величине индукции"
                    />
                    
                  </Form.Label>
              </Form.Group>
            </div>
            <div className={"pfml-drop_down_wrap"}>
              <div className={"pfml-drop_down_wrap-title"}>
              Режим отображения изолиний:
              </div>
              <Form.Group
                  className={"pfml-drop_down_wrap-form_group"}
                >
                  <Form.Label
                    className={"pfml-drop_down_wrap-form_label"}
                  >
                    <RadioButton
                      checked={linesNone}
                      onClick={(e) => {
                        this.setState({
                          linesNone: true,
                          linesEquipotential: false,
                          linesInductance: false,
                        })
                      }}
                      label="Без отображения изолиний"
                    />
                  </Form.Label>
                  <Form.Label>
                    <RadioButton
                      checked={linesEquipotential}
                      onClick={(e) => {
                        this.setState({
                          linesNone: false,
                          linesEquipotential: true,
                          linesInductance: false,
                        })
                      }}
                      label="Отображать эквипотенциали"
                    />
                  </Form.Label>
                  <Form.Label>
                    <RadioButton
                      checked={linesInductance}
                      onClick={(e) => {
                        this.setState({
                          linesNone: false,
                          linesEquipotential: false,
                          linesInductance: true,
                        })
                      }}
                      label="Отображать линии равной индукции"
                    />
                    
                  </Form.Label>
              </Form.Group>
            </div>
            <div className={"pfml-drop_down_wrap_confirm"}>
              <ConfirmComponent callbackCancellation={close} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>;
  }
}

export default connect('ProjectTheme', ViewsSettigsFromPFML);