import React from "react";
import AuthorizationAndRegistrLayout from "../../Views/Layout/AuthorizationAndRegistrLayout";
import RegisterInSystem from "../../common/RegisterInSystem";
import Layout from "../../Views/Layout";

const RegistrationPage = (props) => {
  return (
    <Layout title={"Регистрация"} isVisibleHeader={false}>
      <AuthorizationAndRegistrLayout>
        <RegisterInSystem {...props} />
      </AuthorizationAndRegistrLayout>
    </Layout>
  );
}; 

export default RegistrationPage;
