import { unicArr } from "../../../utils";
export let ElementsPFML = (store) => {
  // Initial state
  store.on("@init", () => ({ ElementsPFML: [] }));
  // Reducers returns only changed part of the state
  store.on("ElementsPFML", ({ ElementsPFML }, data) => {
    if (data === "clear") {
      return { ElementsPFML: [] };
    }
    let newElementVLS = [];
    if (data.type === "clearDontActiveElement") {
      newElementVLS = [data.data];
    } else {
      if (!data.length) newElementVLS = [...ElementsPFML];
      newElementVLS = [...ElementsPFML, data];
    }

    return {
      ElementsPFML: newElementVLS,
    };
  });
};
