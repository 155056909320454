import React, { Component } from 'react';
import OptionPFMLDropDown from '../../../common/OptionPFMLDropDown/OptionPFMLDropDown';
import CanvasPFML from '../../../components/CanvasPFML';
import { Container, Row, Col } from 'react-bootstrap';
import SidebarPFML from '../../../components/SidebarPFML';
import ElementsToPFMLPanel from '../../../components/ElementsToPFMLPanel';
import connect from 'storeon/react/connect';
import { NavLink } from 'react-router-dom';
import { PATH } from '../../../const';
import ButtonMain from '../ButtonMain';
import CheckBox from '../CheckBox';
import ToolTipCreator from '../../../common/ToolTipCreator';
import LayoutForPFMLForSettings from '../LayoutForPFMLForSettings/LayoutForPFMLForSettings';
import ModalCreatorPFML from '../../../common/ModalCreatorPFML';
import ContentForXLTable from '../../../common/ContentForXLTable';
import ParamsSubareas from '../../../common/ParamsSubareas';
import ViewsSettigsFromPFML from '../../../common/ViewsSettigsFromPFML';
import LoaderServicePFML from '../../../common/LoaderServicePFML';
import SuccessServicePFML from '../../../common/SuccessServicePFML';
import ErrorServicePFML from '../../../common/ErrorServicePFML';
import WarningIcon from '../../../assets/images/alert-triangle.svg';
import SuccessIcon from '../../../assets/images/shield-ok.svg';
import Api from '../../../api';

const ApiResearch = Api.ResearchApi;
class LayoutPFML extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataObject: null,
      projectName: 'Библиотека магнитных полей/',
      title: 'Проект 1',
      dropMenuPFML: false,
      showContextMenu: false,
      typeMenu: 'classic',
      content: null,
      target: null,
      addClassNameDropDownWrap: '',
      contextMenuCustomWidth: '',
      serviceModalIsShow: false,
      serviceModalContent: null,
      loadedDataModelPFML: {},
    };
    this.fileRef = React.createRef();
    this.optionsRef = React.createRef();
    this.viewRef = React.createRef();
    this.calculationRef = React.createRef();
    this.tringlRef = React.createRef();
    this.typeServiceModalEnum = {
      saveSettings: 'saveSettings',
      savePFML: 'savePFML',
      load: 'load',
    };
    this.typeParamsInfoService = {
      success: 'success',
      error: 'error',
    };
    this.statusServiceEnum = {
      load: 'load',
      success: 'success',
      error: 'error',
    };
  }

  submitSettings = (data) => {
    const { id } = this.props.match.params;
    this.openModalService(this.typeServiceModalEnum.saveSettings, this.statusServiceEnum.load);
    ApiResearch.putResearch({
      hash_id: id,
      params: {
        type_name: 'PFML',
        settings: data,
      },
    })
      .then((res) => {
        this.openModalService(
          this.typeServiceModalEnum.saveSettings,
          this.statusServiceEnum.success,
        );
      })
      .catch((err) => {
        this.openModalService(this.typeServiceModalEnum.saveSettings, this.statusServiceEnum.error);
      });
  };

  closeModalService = () => {
    this.setState({
      serviceModalIsShow: false,
      serviceModalContent: null,
    });
  };

  openModalService = (type, status) => {
    let content = null;
    switch (type) {
      case this.typeServiceModalEnum.saveSettings:
        content = this.renderLoaderSaveSettings(status);
        break;
      case this.typeServiceModalEnum.savePFML:
        content = this.renderLoaderSaveSettings(
          status,
          'Сохранение модели...',
          'Модель успешно сохранена!',
          'Ошибка сохранения модели!',
        );
        break;
      case this.typeServiceModalEnum.load:
        content = this.renderLoaderSaveSettings(
          status,
          'Загрузка данных модели...',
          'miss',
          'Ошибка загрузки модели!',
        );
        break;
      default:
        content = null;
    }
    this.setState({
      serviceModalIsShow: true,
      serviceModalContent: content,
      showContextMenu: false,
    });
  };

  renderLoaderSaveSettings = (
    status = this.statusServiceEnum.load,
    titleLoad,
    titleSuccess,
    titleError,
  ) => {
    if (status === this.statusServiceEnum.load) {
      return <LoaderServicePFML title={titleLoad ? titleLoad : 'Сохранение настроек...'} />;
    } else if (status === this.statusServiceEnum.success) {
      if (titleSuccess !== 'miss') {
        return (
          <SuccessServicePFML
            paramsSuccessInfoModal={this.getParamsInfoModal(
              this.typeParamsInfoService.success,
              titleSuccess,
            )}
            closeSuccessInfoModal={this.closeModalService}
          />
        );
      }
    } else if (status === this.statusServiceEnum.error) {
      return (
        <ErrorServicePFML
          paramsSuccessInfoModal={this.getParamsInfoModal(
            this.typeParamsInfoService.error,
            titleError,
          )}
          closeSuccessInfoModal={this.closeModalService}
        />
      );
    }
  };

  getParamsInfoModal = (type = 'default', title) => {
    let params = {};
    switch (type) {
      case this.typeParamsInfoService.success:
        params = {
          text: title ? title : 'Настройки сохранены!',
          icon: SuccessIcon,
          altForIcon: 'success icon',
          callbackClickBtn: this.closeModalService,
        };
        break;
      case this.typeParamsInfoService.error:
        params = {
          text: title ? title : 'Ошибка сохранения, попробуйте позже!',
          icon: WarningIcon,
          altForIcon: 'error icon',
          callbackClickBtn: this.closeModalService,
        };
        break;
    }
    return params;
  };

  saveDispatcher = (data) => {
    const { id } = this.props.match.params;
    this.openModalService(this.typeServiceModalEnum.savePFML, this.statusServiceEnum.load);
    ApiResearch.putResearch({
      hash_id: id,
      params: {
        type_name: 'PFML',
        model: data,
      },
    })
      .then((res) => {
        this.openModalService(this.typeServiceModalEnum.savePFML, this.statusServiceEnum.success);
      })
      .catch((err) => {
        this.openModalService(this.typeServiceModalEnum.savePFML, this.statusServiceEnum.error);
      });
  };

  savePFML = () => {
    this.props.dispatch('SavePFML', {
      isSave: true,
      saveFunCallback: this.saveDispatcher,
    });
  };

  setInnerWindowTargetWidth = (innerWidth) => {
    // if (innerWidth < 1800) {
    //   this.setState({
    //     typeMenu: "burger",
    //   });
    // }
  };

  setResizeDom = (event) => {
    // if (event.target.innerWidth < 1800) {
    //   this.setState({
    //     typeMenu: "burger",
    //   });
    // } else {
    // this.setState({
    //   typeMenu: "classic",
    // });
    // }
  };
  toggleContextMenu = (toggle) => {
    this.setState({ showContextMenu: toggle });
  };
  dropMenuPFML = () => {
    this.setState({ dropMenuPFML: !this.state.dropMenuPFML }, () => this.toggleContextMenu());
  };
  fadeOutContextMenu = () => {
    this.setState({
      showContextMenu: false,
    });
  };
  contentFileDropDownMenu = () => {
    return (
      <ul>
        <li>Открыть модель...</li>
        <li onClick={this.savePFML}>Сохранить модель...</li>
        <li>Сохранить модель как...</li>
        <li className={'context_menu-border'}></li>
        <li>Очистить</li>
        <li>Редактировать характеристики</li>
        <li className={'context_menu-border'}></li>
        <li>Выход</li>
      </ul>
    );
  };
  contentTringlDropDownMenu = () => {
    return (
      <ul>
        <li>Построить КЭ-сетку</li>
        <li>Оптимизировать трангуляцию</li>
        <li>Детализировать КЭ-сетку</li>
        <li className={'context_menu-border'}></li>
        <li>Оптимизировать нумерацию узлов</li>
        <li className={'context_menu-border'}></li>
        <li>Удалить КЭ-сетку</li>
      </ul>
    );
  };
  contentViewsDropDownMenu = () => {
    return <ViewsSettigsFromPFML close={this.fadeOutContextMenu} />;
  };
  contentOptionDropDownMenu = () => {
    const { loadedSettingsPFML } = this.state;
    return (
      <OptionPFMLDropDown
        loadedSettingsPFML={loadedSettingsPFML}
        close={this.fadeOutContextMenu}
        submitSettings={this.submitSettings}
      />
    );
  };
  getContentForMenu = (target) => {
    if (target === this.fileRef) {
      return this.contentFileDropDownMenu();
    } else if (target === this.viewRef) {
      this.setState({
        contextMenuCustomWidth: 'width_auto',
      });
      return this.contentViewsDropDownMenu();
    } else if (target === this.optionsRef) {
      return this.contentOptionDropDownMenu();
    } else if (target === this.tringlRef) {
      return this.contentTringlDropDownMenu();
    }
  };
  openDropDownMenu = (target) => {
    return this.setState({
      content: this.getContentForMenu(target),
      target: target,
      showContextMenu: true,
      addClassNameDropDownWrap: this.getDropDownMenuBsPrefix(),
    });
  };
  getDropDownMenuBsPrefix = () => {
    const { dropMenuPFML, typeMenu } = this.state;
    if (dropMenuPFML) {
      if (typeMenu === 'classic') {
        return `pfml-menu-classic-custom_translate`;
      }
    }
  };

  clickLogout = () => {
    window.location.href = PATH.PersonalPageAllProject;
  };

  initLoadPFML = () => {
    const { id } = this.props.match.params;
    this.openModalService(this.typeServiceModalEnum.load, this.statusServiceEnum.load);
    ApiResearch.getResearch(id)
      .then((res) => {
        console.log(res);
        this.setState(
          {
            loadedDataModelPFML: res.model,
            loadedSettingsPFML: res.settings,
          },
          () => {
            this.openModalService(this.typeServiceModalEnum.load, this.statusServiceEnum.success);
          },
        );
      })
      .catch((err) => {
        this.openModalService(this.typeServiceModalEnum.load, this.statusServiceEnum.error);
      });
  };

  componentWillUnmount() {}

  componentDidMount() {
    this.initLoadPFML();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    //todo: сделать адекватное обновление ато он при каждых новых пропсах будет перерендеривать компоненту
    if (nextProps.ElementsContext !== this.props.ElementsContext) {
      this.setState({
        dataObject: nextProps.ElementsContext,
      });
    }
    return true;
  }

  render() {
    console.log(this.props);
    //todo: Поменять на панель для полей
    const {
      dataObject,
      title,
      projectName,
      dropMenuPFML,
      typeMenu,
      showContextMenu,
      content,
      target,
      addClassNameDropDownWrap,
      contextMenuCustomWidth,
      serviceModalIsShow,
      serviceModalContent,
      loadedDataModelPFML,
      loadedSettingsPFML,
    } = this.state;
    let theme = this.props.ProjectTheme === 'dark' ? '' : 'light'; 
    // console.log("object", content);
    // console.log(typeMenu);
    // console.log(target);
    // console.log(contextMenuCustomWidth);
    return (
      <React.Fragment>
        <ModalCreatorPFML show={false} content={<ContentForXLTable />} />
        <ModalCreatorPFML
          show={this.props.CreateSubareas.isShowModal}
          content={
            <ParamsSubareas
              coordsPoint={this.props.CreateSubareas.coordsPoint}
              count={this.props.CreateSubareas.count}
              verticesForCreateMesh={this.props.CreateSubareas.verticesForCreateMesh}
            />
          }
        />
        <ModalCreatorPFML show={serviceModalIsShow} content={serviceModalContent} />
        <Container className={`pfml-container ${theme}`}>
          <Row className={`pfml-row_header`}>
            <div className="pfml-header-block">
              <NavLink
                className={`pfml-header-logo`}
                to={PATH.MainPage}
                title={'Главная'}
              ></NavLink>
              <div className={`pfml-header-menu-classic`}>
                <ul>
                  <li
                    ref={this.fileRef}
                    onClick={(e) => {
                      this.openDropDownMenu(this.fileRef);
                    }}
                  >
                    Файл
                  </li>
                  <li
                    ref={this.optionsRef}
                    onClick={(e) => {
                      this.openDropDownMenu(this.optionsRef);
                    }}
                  >
                    Параметры
                  </li>
                  <li
                    ref={this.viewRef}
                    onClick={(e) => {
                      this.openDropDownMenu(this.viewRef);
                    }}
                  >
                    Вид
                  </li>
                  <li
                    ref={this.tringlRef}
                    onClick={(e) => {
                      this.openDropDownMenu(this.tringlRef);
                    }}
                  >
                    Триангуляция
                  </li>
                </ul>
              </div>
            </div>
            <div className="pfml-header-block">
              <div className={`pfml-header-project_info`}>
                <span className={`pfml-header-project_info-text`}>{projectName}</span>
                <span className={`pfml-header-project_info-project_name`}>{title}</span>
              </div>
            </div>
            <div className="pfml-header-block">
              <div className={`pfml-header-profile`}>
                <NavLink
                  to={PATH.PersonalPagePersonalAreaSettingProfile}
                  className={`pfml-header-profile-link_avatar`}
                >
                  Y
                </NavLink>
                <div className={`pfml-header-profile-will_share`}>
                  {/* <ButtonMain className={`pfml-header-profile-will_share-button`}>
                    Поделиться
                  </ButtonMain> */}
                </div>
              </div>
              <ButtonMain onClick={this.clickLogout} className={`pfml-header-logout`}></ButtonMain>
            </div>
          </Row>
          <Row className={'pfml-row_canvas'}>
            <Col bsPrefix={'col_pfml'} xs={`header-menu_left`}>
              <ElementsToPFMLPanel {...this.props} ofOrIn={'disabled'} />
            </Col>
            <Col bsPrefix={'col_vls'} xs={'canvas'} className={'margin-def'}>
              <CanvasPFML model={loadedDataModelPFML} />
            </Col>
            <Col bsPrefix={'col_pfml'} xs={'sidebar'}>
              <SidebarPFML />
            </Col>
          </Row>
          <ToolTipCreator
            show={showContextMenu}
            typeClosed={'move'}
            isWrapper={typeMenu}
            contentForModal={content}
            viewPosition={'bottom'}
            typeClosed={'move'}
            isWrapper={typeMenu}
            target={target}
            contextMenuCustomWidth={contextMenuCustomWidth}
            fadeOutContextMenu={this.fadeOutContextMenu}
          />
        </Container>
      </React.Fragment>
    );
  }
}

export default connect('ElementsContext', 'CreateSubareas', 'ProjectTheme', 'SavePFML', LayoutPFML);
