import React from 'react'
import Layout from "../../Views/Layout";
import LandingPage from '../../components/LandingPage'
const MainPage = (props) => {
  return (
    <Layout title={"Главная"} isVisibleHeader={false}>
      <LandingPage />
    </Layout>
  );
};

export default MainPage;
