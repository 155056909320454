import React from "react";

class ToolTipVLSDevice extends React.Component {
  constructor() {
    super();
    this.ToolTipVLSDevice = React.createRef();
    this.state = {
      title: "Прибор «Источник напряжения»",
      content: "Независимые государства подвергнуты целой серии независимых исследований. Каждый из нас понимает очевидную вещь: постоянный.",
      styleWrapper: {
        translateX: 0,
        translateY: 0,
      },
    };
  }

  positionRender = (props) => {
    if (props.visibleToolTipVLSDevice.isVisible) {
      //todo сделать запрос за данными о приборе
      let { offsetX, offsetY } = props.visibleToolTipVLSDevice.data.data.originalEvent;
      this.setState({
        styleWrapper: {
          translateX: offsetX + 10,
          translateY: offsetY - 30,
        },
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.visibleToolTipVLSDevice !== nextProps.visibleToolTipVLSDevice) {
      this.positionRender(nextProps);
      return true;
    }
    return this.state.styleWrapper !== nextState.styleWrapper;
  }

  componentDidMount() {
    this.positionRender(this.props);
  }

  render() {
    const { styleWrapper, title, content } = this.state;
    const {
      visibleToolTipVLSDevice,
      callbackCloseTooltipDeviceVLS
    } = this.props;
    if (!this.props.visibleToolTipVLSDevice.isVisible) return null
    return (
      <>
        <div
          ref={(ref) => {
            this.ToolTipVLSDevice = ref;
          }}
          style={{
            transform: `translateX(${styleWrapper.translateX}px)translateY(${styleWrapper.translateY}px)`,
          }}
          className={
            visibleToolTipVLSDevice
              ? "tooltip_vls-wrap_visible"
              : "tooltip_vls-wrap_visible_false"
          }
        >
          <h3 className={"tooltip_vls-title"}>{title}</h3>
          <span className={"tooltip_vls-content"}>
            {content}
          </span>
        </div>
        <div onClick={callbackCloseTooltipDeviceVLS} className={"tooltip_vls-wrap_close"}></div>
      </>
    );
  }
}

export default ToolTipVLSDevice;
