export let CreateSubareasState = store => {
    let initialState = false
    store.on('@init', () => ({ CreateSubareasState: initialState }));
    store.on("CreateSubareasState", ({ CreateSubareasState }, data) => {
        if (data === "clear") {
            return { CreateSubareasState: initialState };
        }
        return {
            CreateSubareasState: data
        };
    });
};