export const getDotFromPrimitivesGraf = (data) => {
  //проверит был-ли добавлен в список отрисовки примитив с переданным идентификатором
  const checkEqualsResultFun = (data, id) => {
    let result = false;
    data.forEach((element) => {
      if (element === id) result = true;
    });
    return result;
  };
  let result = [];
  let checkId = [];
  if (data) {
    data.forEach((el) => {
      if (!el.type) {
        let checkEqualsResult = checkEqualsResultFun(checkId, el.uuidDot);
        if (!checkEqualsResult) result.push(el);
        checkId.push(el.uuidDot);
      } else if (el.type === 'arc') {
        let checkEqualsResultStartPoint = checkEqualsResultFun(checkId, el.startPoint.uuidDot);
        let checkEqualsResultSenterPoint = checkEqualsResultFun(checkId, el.centerPoint.uuidDot);
        let checkEqualsResultEndPoint = checkEqualsResultFun(checkId, el.endPoint.uuidDot);

        if (!checkEqualsResultStartPoint) result.push(el.startPoint);
        if (!checkEqualsResultSenterPoint) result.push(el.centerPoint);
        if (!checkEqualsResultEndPoint) result.push(el.endPoint);

        checkId.push(el.startPoint.uuidDot);
        checkId.push(el.centerPoint.uuidDot);
        checkId.push(el.endPoint.uuidDot);
      }
    });
  }

  return result;
};
