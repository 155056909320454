import React from 'react'
import connect from 'storeon/react/connect'
import isEqual from 'react-fast-compare'


class SectionSidebar extends React.Component {
  constructor(props) {
    super()
    this.state = {

    }
  }

  componentDidUpdate(prevProps) {

  }

  componentDidMount() {

  }

  render() {
    const {
      dots,
      primitive,
      subareas,
      nodes,
      elements
    } = this.state
    return (
      <React.Fragment>
        <span className={"pfml-sidebar-device_title"}>ЭЛЕМЕНТ</span>
        <div className={"pfml-sidebar-researche_env"}>
       
        </div>

      </React.Fragment>
    )
  }
}

export default SectionSidebar