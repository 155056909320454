import React from 'react';
import { AbstractObject, GenericGroupObject, GLTF, Box } from '../../../gengine';
import AddChildConnector from '../AddChildConnector';
import ComponentCreatorForModels from '../ComponentCreatorForModels';
import { DATA_OBJECT_SCENE } from '../../../const';
import { sercheAllObjectByProperty } from '../../../utils';

class AbstractComponentFactory extends AbstractObject {
  constructor(props) {
    super();
    this.timeOutForLoadGLTF = null;
    this.state = {
      componentModelsRender: false,
      componentData: [],
      isClear: false,
      render: [],
    };
  }

  clearMemory = () => {
    let transformControl = sercheAllObjectByProperty(
      'name',
      DATA_OBJECT_SCENE.transformControler.name,
      this.props.scene,
    );
    let ShaderSelectedObject = sercheAllObjectByProperty(
      'name',
      DATA_OBJECT_SCENE.ShaderSelectedObject.name,
      this.props.scene,
    );
    if (ShaderSelectedObject.length) {
      ShaderSelectedObject.forEach((el, i) => {
        el.material.dispose();
        el.geometry.dispose();
        el.parent.remove(el);
      });
    }
    transformControl.forEach((element) => {
      element.detach();
      // element.dispose();
      // element.parent.remove(transformControl);
    });
  };

  onLoadCompleteGLTF = () => {
    this.setState({
      componentModelsRender: true,
    });
    this.props.readyComponentGLTF();
  };

  startLoadGLTF = () => {
    this.timeOutForLoadGLTF = setTimeout(() => {
      this.props.startLoadGLTF();
    }, 1);
    this.setState({
      clearTimeOut: true,
    });
  };

  setComponentsData = (data, isUpdate) => {
    this.generateGGE(data, isUpdate);
  };

  generateGGE = (data, isUpdate) => {
    if (!this.props.componentData) return null;
    const {
      startLoadGLTF,
      progressLoadGLTF,
      errorLoadGLTF,
      handleToolTipsElement,
      addWireFromConnectorCallback,
    } = this.props;
    let render = data.map((el, i) => {
      const {
        position,
        scale,
        rotation,
        url,
        id,
        scaleToGLTF,
        positionGLTF,
        nameToGLTF = 'device',
        component,
        type,
        device_type,
      } = el;
      if (!url) return null;
      let groupName =
        device_type === 'dadCouplingSleeve' || device_type === 'mumCouplingSleeve' ? false : true;
      if (!groupName) return null;
      return (
        <GenericGroupObject
          key={el.id}
          // visible={true}
          uuid={id}
          scale={scale}
          position={position}
          name={DATA_OBJECT_SCENE.deviceConnector.name}
          callback={[
            {
              type: 'click',
              event: (ev) => {
                this.props.callbackOnClick(ev.data.target.uuid);
              },
            },
            {
              type: 'rightclick',
              event: (ev) => {
                this.props.handleRightClick(ev);
              },
            },
          ]}
          {...this.props}
        >
          {/* //todo:  место модельки добавлять примитив для оптимизации */}
          <GLTF
            onLoadComplete={this.onLoadCompleteGLTF}
            startLoadGLTF={this.startLoadGLTF}
            progressLoadGLTF={progressLoadGLTF}
            errorLoadGLTF={errorLoadGLTF}
            scale={scaleToGLTF}
            name={nameToGLTF}
            position={positionGLTF}
            url={url}
          >
            <ComponentCreatorForModels
              // {...this.props}
              handleToolTipsElement={handleToolTipsElement}
              nameToGLTF={nameToGLTF}
              dataComponent={component}
              onLoadComplete={this.onLoadCompleteGLTF}
              startLoadGLTF={startLoadGLTF}
              progressLoadGLTF={progressLoadGLTF}
              errorLoadGLTF={errorLoadGLTF}
              device_type={type ? type : device_type}
              update={isUpdate}
            />
          </GLTF>
          {el.connectors
            ? el.connectors.map((elConnector, i2) => {
                const { position, scale, rotation, id } = elConnector;
                return (
                  <AddChildConnector
                    addWireFromConnectorCallback={addWireFromConnectorCallback}
                    key={id}
                    uuid={id}
                    position={position}
                    scale={scale}
                    rotation={rotation}
                  />
                );
              })
            : null}
        </GenericGroupObject>
      );
    });
    this.setState({
      render: render,
    });
  };

  componentDidUpdate(prevProps) {
    if (Array.isArray(this.props.componentData)) {
      if (this.state.clearTimeOut) {
        clearTimeout(this.timeOutForLoadGLTF);
      }
      if (
        !this.props.componentData.length &&
        prevProps.componentData.length &&
        !this.state.isClear
      ) {
        this.setComponentsData([], true);
        return false;
      }
      if (this.props.isUpdate) {
        this.props.setUpdate(false);
        this.clearMemory();
        this.setComponentsData(this.props.componentData, false);
        return true;
      }
      if (prevProps.componentData.length !== this.props.componentData.length) {
        this.setComponentsData(this.props.componentData, false);
        return false;
      }
    }

    return false;
  }

  componentDidMount() {
    this.setComponentsData(this.props.componentData);
  }

  render() {
    const { render } = this.state;
    return render.map((el, i) => {
      return <React.Fragment key={i}>{el}</React.Fragment>;
    });
  }
}

export default AbstractComponentFactory;
