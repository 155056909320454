import React from "react";
import Layout from "../../Views/Layout";
import DefaultPageLayout from "../../Views/Layout/DefaultPageLayout";
import DetailsNewsComponent from "../../components/DetailsNewsComponent";
import NewsAddForNewsDetails from "../../components/NewsAddForNewsDetails";
const DetailsNewsPage = (props) => {
  return (
    <Layout title={"Новости"} isVisibleHeader={false}>
      <div className={"details_news"}>
        <DefaultPageLayout {...props}  FullWidthContent={NewsAddForNewsDetails}>
          <DetailsNewsComponent {...props} />
        </DefaultPageLayout>
      </div>
    </Layout>
  );
};

export default DetailsNewsPage;
