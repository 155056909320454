import React from "react";
import Layout from "../../Views/Layout";
import DefaultPageLayout from "../../Views/Layout/DefaultPageLayout";
import FaqHomePageComponent from "../../components/FaqHomePageComponent";

const FaqPage = (props) => {
  return (
    <Layout title={"FAQ"} isVisibleHeader={false}>
      <DefaultPageLayout>
        <FaqHomePageComponent />
      </DefaultPageLayout>
    </Layout>
  );
};

export default FaqPage;
