import React from 'react'
import { Row, Col } from 'react-bootstrap'
import InputValueData from '../InputValueData'


const TableInfo = (props) => {

  return (
    <>
      {props.children}
      <Row className='wrapper_table-info'>
        {props.data.map((item, index) => {
          return (
            <Col className='sidebar_table-info' key={index}>
              <p>{item.designation}</p>
              {item.data.map((item, index) => {
                if (typeof item === 'string') {
                  return <Row className='sidebar_table-info_vertical-header' key={index}><p>{item}</p></Row>
                } else {
                  return (
                    <Row key={index}><InputValueData onChange={props.onChange} iteration={index} defaultValue={item} /></Row>
                  )
                }
              })}
            </Col>
          )
        })}
      </Row>
      <div className='content-border-line'></div>
    </>
  )
}
export default TableInfo