import React from 'react';
import { AbstractObject, GenericGroupObject } from '../../../gengine';
import {
  CatmullRomCurve3,
  BufferGeometry,
  Line,
  Geometry,
  LineBasicMaterial,
  Float32BufferAttribute,
  BoxBufferGeometry,
  MeshBasicMaterial,
  Mesh,
  DoubleSide,
  PlaneGeometry,
} from 'three';
import serialisers from '../../../utils/SerializedObject';
import { sercheAllObjectByProperty } from '../../../utils';
import { DATA_OBJECT_SCENE } from '../../../const';
import { Plane } from 'three';
import { MathUtils } from 'three';

class StraightCreator extends AbstractObject {
  constructor(props) {
    super();
    this.coeficentParentScale = 45;
    this.defaultOffset = 0.05;
    this.state = {
      fullLineRender: [],
      positionDotMoveMouse: false,
    };
  }

  geometryStraightCollector = (renderStraight = []) => {
    let geometrys = [];
    if (renderStraight.length) {
      renderStraight.forEach((el) => {
        // let geometry = new BufferGeometry();
        let material = new LineBasicMaterial({
          //  vertexColors: true, morphTargets: true
          side: DoubleSide,
          color: 'red',
          linewidth: 0.1,
          linecap: 'round', //ignored by WebGLRenderer
          linejoin: 'round', //ignored by WebGLRenderer
        });
        let spline2 = new CatmullRomCurve3(el.vertices);
        let geometry = new Geometry();
        let splinePoints2 = spline2.getPoints(el.vertices.length);
        geometry.lineDistancesNeedUpdate = true;
        for (let i = 0; i < splinePoints2.length; i++) {
          geometry.vertices.push(splinePoints2[i]);
        }
        let line = new Line(geometry, material);
        line.name = DATA_OBJECT_SCENE.PRIMITIVE_LINE.name;
        this.props.scene.add(line);
        geometrys.push(geometry);
      });
    } else {
      return false;
    }
  };

  geometryDotCollector = (renderDotAproximation) => {
    renderDotAproximation.forEach((item) => {
      let geometry = new PlaneGeometry(0.2, 0.2);
      let material = new MeshBasicMaterial({
        color: 'red',
      });
      let mesh = new Mesh(geometry, material);
      mesh.position.set(item.position.x, 1, item.position.z);
      mesh.uuid = item.uuid;
      mesh.name = DATA_OBJECT_SCENE.PRIMITIVE_DOT_APROXIMATION.name;
      mesh.cursor = 'pointer';
      mesh.rotation.set(MathUtils.degToRad(-90), 0, 0);
      this.props.scene.add(mesh);
    });
  };

  memoryClear = () => {
    const { scene } = this.props;
    let staightAll = sercheAllObjectByProperty(
      'name',
      DATA_OBJECT_SCENE.PRIMITIVE_LINE.name,
      scene,
    );
    let dotAproximationAll = sercheAllObjectByProperty(
      'name',
      DATA_OBJECT_SCENE.PRIMITIVE_DOT_APROXIMATION.name,
      scene,
    );
    dotAproximationAll.forEach((el, i) => {
      el.geometry.dispose();
      el.material.dispose();
      el.parent.remove(el);
    });
    staightAll.forEach((el, i) => {
      el.geometry.dispose();
      el.material.dispose();
      el.parent.remove(el);
    });
  };

  setDataVerticesLine = (props) => {
    const { renderDots = [] } = props;
    this.memoryClear();
    let { renderStraight, renderDotAproximation } = serialisers.parseDotForStraight(renderDots);
    this.geometryStraightCollector(renderStraight);
    this.geometryDotCollector(renderDotAproximation);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.renderDots !== prevProps.renderDots) {
      this.setDataVerticesLine(this.props);
      return true;
    }
    return true;
  }

  componentDidMount() {
    this.setDataVerticesLine(this.props);
  }

  render() {
    return null;
  }
}

export default StraightCreator;
