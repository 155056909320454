import { creatorCheckedMesh } from "../CustomComponentToCanvas/MeshBuilder";
/**
 * @param uuidSelected
 * @param uuidMoved
 */
const checkCollizionStraight = (
  objSelected,
  objMoved,
  renderDots,
  linkToSceneObject
) => {
  let result = true;
  let createCheckedMesh = creatorCheckedMesh(
    objSelected,
    objMoved,
    linkToSceneObject,
    renderDots
  );
  if (!createCheckedMesh) {
    result = false;
  }
  return result;
};
/**
 * проверит можно ли подключить прямую или кривую к конкретной точке
 * @param {*} uuid
 */
export const checkConnectedDots = (
  uuidSelected,
  uuidMoved,
  renderDots,
  clearMemory = () => {},
  linkToSceneObject
) => {
  //проверит и подставит новые знаечения
  const setDataDotForArc = (data, startPoint, centerPoint, endPoint) => {
    let newData = data;
    if (data === null) {
      if (startPoint) {
        newData = startPoint;
      } else if (centerPoint) {
        newData = centerPoint;
      } else if (endPoint) {
        newData = endPoint;
      }
    }
    return newData;
  };
  const setDataDotForStraight = (data, point) => {
    let newData = data;
    if (data === null) {
      if (point) {
        newData = point;
      }
    }
    return newData;
  };
  // проверит совпадаютли ид
  const checkUuid = (el) => {
    let objMovedDot = null,
      objSelectedDot = null;
    if (el.uuidDot === uuidMoved) {
      objMovedDot = el;
    }
    if (el.uuidDot === uuidSelected) {
      objSelectedDot = el;
    }
    return [objMovedDot, objSelectedDot];
  };
  let objSelectedDot = null;
  let objMovedDot = null;
  renderDots.forEach((el) => {
    if (el.type === "arc") {
      let [moveObjStartPoint, selectObjStartPoint] = checkUuid(el.startPoint);
      let [moveObjCenterPoint, selectObjCenterPoint] = checkUuid(
        el.centerPoint
      );
      let [moveObjEndPoint, selectObjEndPoint] = checkUuid(el.endPoint);
      objSelectedDot = setDataDotForArc(
        objSelectedDot,
        selectObjStartPoint,
        selectObjCenterPoint,
        selectObjEndPoint
      );
      objMovedDot = setDataDotForArc(
        objMovedDot,
        moveObjStartPoint,
        moveObjCenterPoint,
        moveObjEndPoint
      );
    } else {
      let [moveObj, selectObj] = checkUuid(el);
      objSelectedDot = setDataDotForStraight(objSelectedDot, selectObj);
      objMovedDot = setDataDotForStraight(objMovedDot, moveObj);
    }
  });
  clearMemory();
  let checkCollizion = checkCollizionStraight(
    objSelectedDot,
    objMovedDot,
    renderDots,
    linkToSceneObject
  );
  if (!checkCollizion) {
    return false;
  }
  if (objSelectedDot) {
    if (objSelectedDot !== objMovedDot) {
      let connected = true;
      objSelectedDot.linkToStraight.forEach((el) => {
        if (el === objMovedDot.uuidDot) {
          connected = false;
        }
      });
      objMovedDot.linkToStraight.forEach((el) => {
        if (el === objSelectedDot.uuidDot) {
          connected = false;
        }
      });
      return connected;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
