import React from "react";
import ProjectToPersonalPage from "../ProjectToPersonalPage";
import Api from "../../api";
import { LIMIT_GET_PROJECTS } from "../../const";

const ApiProject = Api.ProjectApi;
class AllprojectPersonalPage extends React.Component {
  constructor(props) {
    super(props);
  }
  getProjects = () =>{
    return ApiProject.getProjects({limit: LIMIT_GET_PROJECTS, is_trash: false})
  }
  render() {
    let { updatecomponent, stopupdate } = this.props
    updatecomponent = updatecomponent === "true"
    return (
      <ProjectToPersonalPage
        title={"ВСЕ ПРОЕКТЫ"}
        api={this.getProjects}
        stopUpdate={stopupdate}
        updateComponent={updatecomponent}
        limit={LIMIT_GET_PROJECTS}
      />
    );
  }
}
export default AllprojectPersonalPage;
