import React, { useState } from 'react';
import ChooseRadioButton from '../ChooseRadioButton'
import TogglerSwitcher from '../TogglerSwitcher'

const ChooseCurrent = (props) => {
  const { valueRadioButton = [], title, titleClassName, activeChecked } = props.paramsDeviceCustomMeta
  return (
    <>
      <p className={`current ${titleClassName}`}>{title}</p>
      <div className='wrapper-current'>
        <TogglerSwitcher
          active={activeChecked}
          onChange={props.handlerTogglerSwitcher}
          firstTogglerValue='Постоянный(=)'
          secondTogglerValue='Переменный(&#8776;)'
        />
      </div>
      <ChooseRadioButton onChange={props.handlerChooseRadioButton} data={valueRadioButton} />

      <div className='content-border-line'></div>
    </>
  )
}
export default ChooseCurrent