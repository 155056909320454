import React from "react";
import connect from "storeon/react/connect";
import GetElementsPFML from "./ElementsPFML";
import ElementToolPanelCanvasLayout from "../../Views/Layout/ElementToolPanelCanvasLayout";
import DataObjectScene from "./DataObjectScene";
import uuid from "uuid";
import Api from "../../api";

const ApiResearch = Api.ResearchApi;
class ElementsToPFMLPanel extends React.Component {
  constructor(props) {
    super(props);
    this.randPos = () => Math.random() * Math.random() * 100;
    this.randId = () => uuid.v4();
    this.state = {
      mounted: false,
      elementsPanelPFML: [],
      backOpenMenuIndex: null,
    };
  }

  openMenuElements = (element, index) => {
    const { backOpenMenuIndex, elementsPanelPFML } = this.state;
    let indexNew = index;

    if (backOpenMenuIndex !== null) {
      elementsPanelPFML[backOpenMenuIndex].isView = !elementsPanelPFML[
        backOpenMenuIndex
      ].isView;
      if (backOpenMenuIndex === indexNew) indexNew = null;
    }
    if (indexNew !== null)
      elementsPanelPFML[indexNew].isView = !elementsPanelPFML[indexNew].isView;

    this.setState({
      elementsPanelPFML: elementsPanelPFML,
      backOpenMenuIndex: indexNew,
    });
  };

  /**
   * закрывает меню на панели инструментов
   */
  closedMenuElements = () => {
    const { backOpenMenuIndex, elementsPanelPFML } = this.state;
    if (backOpenMenuIndex !== null) {
      elementsPanelPFML[backOpenMenuIndex].isView = !elementsPanelPFML[
        backOpenMenuIndex
      ].isView;
      this.setState({
        elementsPanelPFML: elementsPanelPFML,
        backOpenMenuIndex: null,
      });
    }
  };

  clickElementPFML = (params) => {
    const { type } = params;
    const { elementsPanelPFML } = this.state;
    let dataObject = null;
    const dispathCase = (type) => {
      let { id } = this.props.match.params;
      dataObject = DataObjectScene()[type];
      console.log(JSON.stringify(dataObject));
      this.setState({
        elementsPanelPFML: elementsPanelPFML.map((el, i) => {
          return {
            ...el,
            active: type === el.type && !el.active,
          };
        }),
      });
      if (this.props.SelectedToolPFML) {
        if (this.props.SelectedToolPFML.type === dataObject.type) {
          this.props.dispatch("SelectedToolPFML", "clear");
        } else {
          this.props.dispatch("SelectedToolPFML", dataObject);
        }
      } else {
        this.props.dispatch("SelectedToolPFML", dataObject);
      }
    };
    switch (type) {
      case "dot":
        dispathCase(type);
        break;
      case "straight":
        dispathCase(type);
        break;
      case "arc":
        dispathCase(type);
        break;
      case "array":
        dispathCase(type);
        break;
      case "section":
        dispathCase(type);
        break;
      case "subareas":
        dispathCase(type);
        break;
      case "rotatingArray":
        dispathCase(type);
        break;
      default:
        return;
    }
  };

  clickElement = (element, index) => {
    if (element.menu) {
      this.openMenuElements(element, index);
    } else {
      this.closedMenuElements();
      this.clickElementPFML(element);
    }
  };

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.socketState !== this.state.socketState) {
      this.socketStarter(this.state.socketState);
    }
  }

  /*
    после прогрузки компоненты получаем список элементов для отображения (подумать над оптимизацией в виде бесконечной прогрузки)
    тк элементов будет очень много
  */
  componentDidMount() {
    // console.log(JSON.stringify(DataObjectScene()))
    this.setState({
      mounted: true,
      elementsPanelPFML: GetElementsPFML(),
    });
  }

  render() {
    const { ofOrIn } = this.props;
    const { elementsPanelPFML, isPlay, isPaused } = this.state;
    const pfmlPanel = true;
    return (
      <ElementToolPanelCanvasLayout
        pfmlPanel={pfmlPanel}
        elementsPanel={elementsPanelPFML}
        // ofOrIn={ofOrIn}
        clickElement={this.clickElement}
      />
    );
  }
}

export default connect("ElementsPFML", "SelectedToolPFML", ElementsToPFMLPanel);
