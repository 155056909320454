import { CylinderBufferGeometry, Mesh, MeshLambertMaterial, } from 'three'
import { Object3D } from '../../Object3D'
import { addEventCustomListener } from '../../utils'

class Cylinder extends Object3D { 
  constructor({
    scene,
    enableShadows,
    radiusTop,
    radiusBottom,
    height,
    radialSegments,
    color,
    texture,
    material,
    position,
    rotation,
    callback,
    parent
  } = {}) {
    super()
    const geometry = new CylinderBufferGeometry(
      radiusTop,
      radiusBottom,
      height,
      radialSegments
    )
    let objectMaterial = {
      color: color
    }
    if (material) {
      objectMaterial = material
    } else {
      this.setColor(color);
    }
    this.material = new MeshLambertMaterial(objectMaterial)

    this.obj = new Mesh(geometry, this.material)
    addEventCustomListener(this.obj, callback)
    this.setPosition(position)
    this.setRotation(rotation)
    if (texture) {
      this.setTexture(texture)
    }
    this.obj.castShadow = enableShadows
    this.obj.receiveShadow = enableShadows
    this.uuid = this.obj.uuid
    if (parent) {
      parent.add(this.obj)
    } else {
      this.addToScene(scene)
    }
  }
}

export default Cylinder