import React from 'react';
import { Line as LineAbstract } from '../../../abstract/primitives/Line'
import AbstractObject from "../../abstract/AbstractObject";

class Line extends AbstractObject {
  constructor(props) {
    super();
    this.timeOutControls = null
    this.state = {
      objectScene: null
    }
  }

  componentWillUnmount() {
    // debugger
    if (this.obj && this.obj.parent) {
      this.obj.parent.remove(this.obj)
      // this.obj.removeObject(this.obj.parent)
    }
    clearTimeout(this.timeOutControls)
  }

  componentDidMount() {
    const {
      scene,
      enableShadows,
      color = '#ff001a',
      geometry,
      vertices,
      numPoints,
      linewidth = 1,
      opacity = 1,
      callback = null,
      parent = false,
      name
    } = this.props;
    this.initComponent();
    let objectScene = new LineAbstract({
      scene,
      enableShadows,
      color,
      geometry,
      vertices,
      numPoints,
      linewidth,
      opacity,
      callback,
      parent,
      name
    })
    this.obj = objectScene.obj
    this.setState({
      objectScene: this.obj
    })

    this.onPropsUpdate({}, this.props);
    this.timeOutControls = setTimeout(() => {
      this.readyComponent();
    }, 1);
  }

  render() {
    return null;
  }
}

export default Line;
