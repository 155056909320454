import React from 'react';
import LayoutPFML from '../../Views/Layout/LayoutPFML'
import Layout from '../../Views/Layout';

const PhysicalFieldModelingLibraryPage = (props) => {
	return (
		<Layout isVisibleHeader={false} title={"Полевая модель"}>
			<LayoutPFML {...props}/>
		</Layout>
	)
};

export default PhysicalFieldModelingLibraryPage