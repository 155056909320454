export let SelectedToolPFML = store => {
	// Initial state
	store.on('@init', () => ({ SelectedToolPFML: null }))
	// Reducers returns only changed part of the state
	store.on("SelectedToolPFML", (SelectedToolPFML, data) => {
		if (data === "clear") {
			return { SelectedToolPFML: null };
		}
		let selected = data === SelectedToolPFML ? null : data
		return {
			SelectedToolPFML: selected
		};
	});
}