import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { PATH } from "../../const";
import SubmitForm from "../SubmitForm";
import Api from "../../api";
import connect from 'storeon/react/connect'

const FormSignIn = (props) => {
  const { openErrorBlockHead, closeErrorBlockHead } = props;
  const [viewPass, setViewPass] = useState(false);
  const [login, setlogin] = useState("");
  const [password, setPassword] = useState("");
  const [errorClassNameEmail, setErrorClassNameEmail] = useState("");
  const [errorClassNamePassword, setErrorClassNamePassword] = useState("");
  const textError =
    "Нам не удалось найти аккаунт, соответствующий введенному вами адресу электронной почты и паролю. Пожалуйста, проверьте свою электронную почту и пароль и попробуйте снова.";
  const signIn = (e) => {
    e.preventDefault();
    const userApi = Api.UserApi;
    // if (!login || !password) {
    //   return;
    // } else {
      SubmitForm(
        userApi.login,
        {
          username: login,
          password: password,
        },
        (res) => {
          closeErrorBlockHead();
          if (res.status === 200) {
            let token = res.data.token;
            localStorage.setItem("auth_token", token);
            window.location.href = PATH.PersonalPageAllProject;
          }
        },
        (err) => {
          if (err.response)
            if (err.response.status === 400) {
              let { data } = err.response;
              if (data.username || data.non_field_errors) {
                openErrorBlockHead(textError);
                setErrorClassNameEmail("error-input");
                setErrorClassNamePassword("error-input");
              } else if (data.password) {
                openErrorBlockHead(textError);
                setErrorClassNameEmail("");
                setErrorClassNamePassword("error-input");
              }
            }
        }
      );
    // }
  };
  console.log(props)
  return (
    <div>
      <div className={"auth_and_reg-right_form-error_block"}></div>
      <Form className={"auth_and_reg-right_form"}>
        <Form.Label className={"auth_and_reg-right_form-heder"}>
          Авторизация
        </Form.Label>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className={"auth_and_reg-right_form-label"}>
            Эл. почта
          </Form.Label>
          <Form.Control
            className={`auth_and_reg-right_form-input_style ${errorClassNameEmail}`}
            type="email"
            placeholder="Введите адрес эл.почты"
            value={login}
            onChange={(e) => setlogin(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <div className="auth_and_reg-right_form-label_wrap">
          	<Form.Label className={"auth_and_reg-right_form-label"}>
          	  Пароль (8+ символов)
          	</Form.Label>
          	<NavLink
          	  className={"auth_and_reg-right_form-forgot_password_top"}
          	  to={PATH.ForgotPassword}
          	>
          	  Забыли пароль?
          	</NavLink>
          </div>
          <div className={"auth_and_reg-right_form-group_password"}>
            <div className="auth_and_reg-right_form-input">
              <Button
                onClick={() => setViewPass(!viewPass)}
                className={`auth_and_reg-right_form-btn_view_pass ${!viewPass ? " deactive" : " active"
                  }`}
              ></Button>
              <Form.Control
                className={`auth_and_reg-right_form-input_style password_autn_add ${errorClassNamePassword}`}
                type={`${!viewPass ? "password" : "text"}`}
                placeholder="Введите пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <NavLink
          	  className={"auth_and_reg-right_form-forgot_password_bottom"}
          	  to={PATH.ForgotPassword}
          	>
          	  Забыли пароль?
          	</NavLink>
          </div>
        </Form.Group>
        <Button
          className={"auth_and_reg-right_form-sign_in_btn"}
          type="submit"
          onClick={(e) => signIn(e)}
        >
          Войти
        </Button>
        <div className={"auth_and_reg-right_form-registrations_text"}>
          Еще не зарегистрировались в GDT? {" "}
          <NavLink
            className={
              "auth_and_reg-right_form-registrations_link"
            }
            to={PATH.Registration}
          >
            Регистрация.
          </NavLink>
        </div>
      </Form>
    </div>
  );
};

export default connect("UserData", FormSignIn);
