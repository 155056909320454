import React from "react";

const ButtonMain = (props) => {
  let defaultClassName = !props.className ? "default_components-button" : props.className;
  return (
    <button onClick={props.onClick} className={defaultClassName} {...props}>
      {props.children}
    </button>
  );
};

export default ButtonMain;
