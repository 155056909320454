import React, { Component } from 'react';
import { cloneDeep, dropRight } from 'lodash';
import { Vector3, BufferAttribute } from 'three';
import serialized from '../../utils/SerializedObject';
import { DATA_OBJECT_SCENE } from '../../const';
import ModalContentForGLTFLoader from '../../common/ModalContentForGLTFLoader';
import { DeleteObjectFromSceneAndState, sercheAllObjectByProperty } from '../../utils';
import DragController from './DragController';
import uuid from 'uuid';

class AbstractVLS extends Component {
  constructor(props) {
    super();
  }
  /**
   * Обновит данные для сайдбара в девайсах
   * @param {*} hash_id
   * @param {*} data
   */
  saveParamsDeviceFromSidebar = (hash_id, data) => {
    const { dataAllObjects } = this.state;
    const { componentData } = dataAllObjects;
    let newComponentData = componentData.map((el) => {
      if (el.id === hash_id) {
        return {
          ...el,
          meta: { ...data },
        };
      } else {
        return el;
      }
    });
    this.setState({
      dataAllObjects: {
        ...dataAllObjects,
        componentData: newComponentData,
      },
    });
  };
  /**
   * Обязательный проход по всем линиям и удалению их всех
   */
  clearLineFromWiresForDropAllScene = () => {
    const { linkToSceneObject } = this.state;
    linkToSceneObject.children.forEach((el) => {
      if (el.name === DATA_OBJECT_SCENE.line.name) {
        el.geometry.dispose();
        el.material.dispose();
        linkToSceneObject.remove(el);
      }
    });
  };
  /**
   * ?Отключит контроллер удалит ненужные элементы со сцены
   * !необходимо для оптимизации
   */
  clearMemoryAndUnmountController = () => {
    const { linkToSceneObject } = this.state;
    let shaderSelectedObject = sercheAllObjectByProperty(
      'name',
      DATA_OBJECT_SCENE.ShaderSelectedObject.name,
      linkToSceneObject,
    );
    let transformController = sercheAllObjectByProperty(
      'name',
      DATA_OBJECT_SCENE.transformControler.name,
      linkToSceneObject,
    );
    let dragController = sercheAllObjectByProperty(
      'name',
      DATA_OBJECT_SCENE.dragControls.name,
      linkToSceneObject,
    );
    // debugger
    this.setState(
      {
        objectsDrag: null,
      },
      () => {
        shaderSelectedObject.forEach((el, i) => {
          el.material.dispose();
          el.geometry.dispose();
          el.parent.remove(el);
        });
        transformController.forEach((el) => {
          el.detach();
          el.dispose();
          el.parent.remove(el);
        });
        dragController.forEach((el) => {
          el.detach();
          el.dispose();
          el.parent.remove(el);
        });
      },
    );
  };
  /**
   * тригернет изменение состояния обновления
   * @param {*} data
   */
  setUpdate = (data = false) => {
    this.setState({
      isUpdate: data,
    });
  };

  updateComponent = () => {
    const { wiresData } = this.state.dataAllObjects;
    this.setState({
      dataAllObjects: {
        ...this.state.dataAllObjects,
        wiresData: wiresData,
      },
    });
  };
  /**
   * обрабатывает клики по кнопкам увеличения или уменьшения зума
   * @param {*} e
   */
  clickSetZoomInc = (e) => {
    let { zoomOrhoganal } = this.state;
    // if (zoomOrhoganal <= 0.2) {
    this.setState({
      zoomOrhoganal: (zoomOrhoganal += 0.01),
    });
    // }
  };
  /**
   * обрабатывает клики по кнопкам увеличения или уменьшения зума
   * @param {*} e
   */
  clickSetZoomDec = (e) => {
    let { zoomOrhoganal } = this.state;
    // if (zoomOrhoganal >= 3) {
    this.setState({
      zoomOrhoganal: (zoomOrhoganal -= 0.01),
    });
    // }
  };
  /**
   * проверит можноли добавить провод из этой клемы
   * @param {*} wiresData
   * @param {*} meshConnector
   */
  checkConnectionsForWire = (wiresData, meshConnector) => {
    let result = false;
    wiresData.forEach((wire, i) => {
      wire.connections.forEach((el, i) => {
        if (meshConnector.uuid === el.firstConnector.uuid) {
          result = true;
        } else if (meshConnector.uuid === el.lastConnector.uuid) {
          result = true;
        }
      });
    });
    return result;
  };
  /**
   *  добавит провод по нажатию на клему на приборе
   * @param {*} data
   * @param {*} meshConnector
   */
  addWireFromConnectorCallback = (data, meshConnector) => {
    let { dataAllObjects } = this.state;
    let { wiresData } = dataAllObjects;
    let checkConnectionsForWire = this.checkConnectionsForWire(wiresData, meshConnector);
    if (!checkConnectionsForWire) {
      let newWiresData = cloneDeep(wiresData);
      let startVerticesPosition = new Vector3();
      meshConnector.getWorldPosition(startVerticesPosition);
      let firstVerticesUuid = uuid.v4();
      let lastVerticesUuid = uuid.v4();

      let createWire = {
        id: uuid.v4(),
        type: 'wire',
        polarity: true,
        status: 'justWire',
        outerTerminals: [],
        color: '#000000'.replace(/0/g, function () {
          return (~~(Math.random() * 16)).toString(16);
        }),
        connections: [
          {
            firstConnector: {
              isTerminal: false,
              name: 'connector',
              parentUuid: meshConnector.parent.uuid,
              position: [startVerticesPosition.x, 3, startVerticesPosition.z],
              uuid: meshConnector.uuid,
              uuidConnector: firstVerticesUuid,
            },
            lastConnector: {
              isTerminal: false,
              name: 'outer_terminal',
              parentUuid: meshConnector.parent.uuid,
              position: [startVerticesPosition.x, 3, startVerticesPosition.z],
              uuid: meshConnector.uuid,
              uuidConnector: firstVerticesUuid,
            },
          },
        ],
        vertices: [
          {
            id: firstVerticesUuid,
            position: [startVerticesPosition.x, 3, startVerticesPosition.z],
            idMesh: meshConnector.uuid,
            type: 'Mesh',
          },
          {
            id: lastVerticesUuid,
            position: [startVerticesPosition.x + 3, 3, startVerticesPosition.z + 3],
            type: 'line',
          },
        ],
        verticesLength: 2,
      };
      newWiresData.push(createWire);
      this.setState({
        dataAllObjects: {
          ...dataAllObjects,
          wiresData: newWiresData,
        },
      });
    }
  };
  /**
   * Добавит вершину к проводу тут возникала проблема с не глубоким копированием (надо быть акуратнее)
   * @param data = {*}
   */
  callbackAddPointToWires = (data) => {
    const { wiresData } = this.state.dataAllObjects;
    let oldWireData = [];
    wiresData.forEach((el) => oldWireData.push({ ...el }));
    const paramsToSerialized = Object.assign(
      {},
      {
        uuidTarget: data.target.uuid,
        wiresData,
      },
    );
    let newWires = serialized.addWires(paramsToSerialized);
    this.setState({
      objectsDrag: null,
      contextElementData: null,
      dataAllObjects: {
        ...this.state.dataAllObjects,
        wiresData: newWires,
      },
    });
  };
  callbackCloseTooltipDeviceVLS = (data) => {
    this.setState({
      visibleToolTipVLSDevice: {
        isVisible: false,
        data: null,
      },
    });
  };
  callbackCloseContextMenu = (data) => {
    this.setState({
      visibleContextMenu: {
        isVisible: false,
        data: null,
      },
    });
  };
  callbackColorPicker = (hex, target) => {
    let { wiresData } = this.state.dataAllObjects;
    let paramsSerializer = {
      wiresData,
      target,
      hex,
    };

    let newWiresData = serialized.setColorWireData(paramsSerializer);
    // target.material.color.set(hex);
    this.setState({
      dataAllObjects: {
        ...this.state.dataAllObjects,
        wiresData: newWiresData ? newWiresData : wiresData,
      },
    });
  };
  /**
   * удалит внешнюю клему и вернет обычную клему
   * @param {*} uuidWire
   * @param {*} uuidClem
   */
  callbackDeactivateOuterTerminal = (uuidWire, uuidClem) => {
    const { wiresData } = this.state.dataAllObjects;
    let newWiresData = wiresData.map((el) => {
      if (el.id === uuidWire) {
        return {
          ...el,
          status: 'justWire',
          outerTerminals: [],
        };
      } else {
        return el;
      }
    });
    this.setState({
      dataAllObjects: {
        ...this.state.dataAllObjects,
        wiresData: newWiresData,
      },
    });
  };
  /**
   * создаст выходную клему после чего можно сохранить расчет как 1 прибор
   * @param {*} data
   */
  callbackCreateOuterTerminal = (uuidWire, uuidClem) => {
    const { wiresData } = this.state.dataAllObjects;
    let checkStatusWire = false;
    let newWiresData = wiresData.map((el) => {
      if (el.id === uuidWire) {
        if (el.status === 'outerTerminal') checkStatusWire = true;
        if (el.outerTerminals) {
          el.outerTerminals.push(uuidClem);
        } else {
          el.outerTerminals = [uuidClem];
        }
        return {
          ...el,
          status: 'outerTerminal',
          outerTerminals: el.outerTerminals,
        };
      } else {
        return el;
      }
    });
    console.log('newWiresData', newWiresData);
    if (!checkStatusWire) {
      this.setState({
        dataAllObjects: {
          ...this.state.dataAllObjects,
          wiresData: newWiresData,
        },
      });
    }
  };
  /*
  методы обрабатывающий клики по объектам сцены получающий меш для дальнейшей обработки drag'n'drop.
   */
  handleClick = (dataComponent) => {
    const { dataAllObjects } = this.state;
    const { componentData } = dataAllObjects;

    const getelEmentDevice = (uuid) => {
      let result = false;
      for (let i = 0; i < componentData.length; i++) {
        const element = componentData[i];
        if (element.id === uuid) {
          result = element;
        }
      }
      if (!result) {
        //todo: сделать загрузку данных о типе прибора и отображаемых полях для этого прибюора
      }
      return result;
    };
    this.props.dispatch('ElementsContext', 'clear');
    this.setState(
      {
        objectsDrag: dataComponent,
      },
      () => {
        let element = getelEmentDevice(dataComponent);
        let objParams = {
          element: element,
          type: element.type,
          uuid: dataComponent,
        };
        if (element) {
          this.props.dispatch('DataSelectedElementForSidebar', objParams);
        }
        this.props.dispatch('ElementsContext', dataComponent);
      },
    );
  };
  handleCollisionsMesh = (data) => {
    this.props.dispatch('ElementsContext', data.obj);
  };
  /**
   * обработает клик по знаку вопроса на любом элементе сцены и разрешит показать попап
   * @param {*} data
   */
  handleToolTipsElement = (data) => {
    this.setState({
      visibleToolTipVLSDevice: {
        isVisible: true,
        data: data,
      },
    });
  };
  /**
   * обрабатывает клики правой кнопкой мыши
   * @param {*} data
   */
  handleRightClick = (data) => {
    this.setState({
      visibleContextMenu: {
        isVisible: true,
        data: data,
      },
    });
  };
  handleClickRandColor = (component) => {
    const randomColor = '#000000'.replace(/0/g, function () {
      return (~~(Math.random() * 16)).toString(16);
    });
    this.setState({
      color: randomColor,
    });
  };
  /**
   * @param data - {wireIter,connectorIter}
   * @param dataConnections - device id, clema
   */
  handlerClickDisconnectConnector = (data, dataConnections) => {
    const { wiresData } = this.state.dataAllObjects;
    const createVertices = (el) => {
      // if (el.vertices === 2) return undefined
      return el.vertices.map((el) => {
        // debugger
        if (el.idMesh === dataConnections.clema) {
          let newPosition = [0, 0, 0];
          if (el.position instanceof Vector3) {
            newPosition = [el.position.x - 1, el.position.y, el.position.z - 1];
          } else if (el.position instanceof Array) {
            newPosition = [el.position[0] - 1, el.position[1], el.position[2] - 1];
          } else if (el.position instanceof Object) {
            newPosition = [el.position.x - 1, el.position.y, el.position.z - 1];
          }

          // id: "ade79e88-6448-4bd1-85be-fe5c7f0e9a8b"
          // idMesh: "ade79e88-6448-4bd1-85be-fe5c7f0e9a8b"
          // mesh:
          // name: "connector"
          // parentUuid: undefined
          // position: {x: 14.117722762560163, y: 3, z: 10.375376191288861}
          // uuid: "ade79e88-6448-4bd1-85be-fe5c7f0e9a8b"
          // uuidConnector: undefined
          // __proto__: Object
          // position: {x: 14.117722762560163, y: 3, z: 10.375376191288861}
          // type: "Mesh"
          // uuidConnector: undefined
          return {
            id: el.id,
            mesh: {
              parentUuid: undefined,
              position: new Vector3(newPosition[0] - 1, newPosition[1], newPosition[2] - 1),
              uuid: el.id,
              uuidConnector: el.id,
            },
            position: new Vector3(newPosition[0] - 1, newPosition[1], newPosition[2] - 1),
            type: 'Mesh',
          };
        } else {
          return el;
        }
      });
    };
    let newWiresData = wiresData.map((el, i) => {
      if (i === data.wireIter) {
        // if (el.vertices === 2) return undefined
        return {
          ...el,
          connections: el.connections.filter(
            (connectionEl) => connectionEl.lastConnector.uuid !== dataConnections.clema,
          ),
          vertices: createVertices(el),
        };
      } else {
        return el;
      }
    });
    this.setState({
      dataAllObjects: {
        ...this.state.dataAllObjects,
        wiresData: newWiresData,
      },
    });
  };
  /*
    метод для создания новых элементов из объекта с элементами схемы
   */
  createElement = (oldData, newData) => {
    let { dataAllObjects, linkToSceneObject } = this.state;
    let { wiresData, componentData, couplingSleeve } = dataAllObjects;
    let camera = linkToSceneObject.getObjectByProperty('name', DATA_OBJECT_SCENE.MAIN_CAMERA.name);
    if (!newData) return;
    newData.map((el, i) => {
      el.position = [camera.position.x - 5, 0, camera.position.z];
      switch (el.type) {
        case 'wire':
          this.setState({
            dataAllObjects: {
              ...dataAllObjects,
              wiresData: [...wiresData, el],
            },
          });
          break;
        case 'dadCouplingSleeve':
        case 'mumCouplingSleeve':
          this.setState({
            dataAllObjects: {
              ...dataAllObjects,
              couplingSleeve: [...couplingSleeve, el],
            },
          });
          break;
        default:
          this.setState({
            dataAllObjects: {
              ...dataAllObjects,
              componentData: [...componentData, el],
            },
          });
          break;
        //return console.log("Нет такого типа элемента!");
      }
    });
  };
  /**
   * устанавливает контекстный конектор
   * @param onCollisions
   * @param newPosition
   * @param objectDrag
   * @param newVector
   */
  setContextDevice = (params) => {
    const { linkToSceneObject } = this.state;
    const { componentData, wiresData } = this.state.dataAllObjects;
    const { onCollisions, newPosition, minObjDrag, newVector, contextElementData } = params;
    //  обновит массив с компонентами
    let newComponentData = serialized.getNewComponentData({
      ...params,
      componentData,
      wiresData,
      linkToSceneObject,
    });
    this.setState({
      dataAllObjects: {
        ...this.state.dataAllObjects,
        componentData: newComponentData.componentData,
        wiresData: newComponentData.wiresData,
        contextElementData: contextElementData,
      },
    });
  };
  /**
   * @param contextElementData === context mesh
   * @param lastConnector === object
   * @param onCollisions === bool
   * @param newPosition  === vector
   * @param objectDrag   === object
   * @return вернет метод для обработки изменений конкретных элементов будь то
   * 				 провода либо же просто боксы (данные хранятся по разному практически
   * 				 в каждом случае по этому для примитивов свои серилайзеры)
   */
  setDataElements = (contextElementData, onCollisions, newPosition, minObjDrag, lastConnector) => {
    if (!contextElementData) return;
    let newVector = new Vector3(contextElementData.x, contextElementData.y, contextElementData.z);
    const typeObjDrag = minObjDrag.name;
    let paramsToSetPos = {
      onCollisions,
      newPosition,
      minObjDrag,
      newVector,
      contextElementData,
      lastConnector: lastConnector ? lastConnector : null,
    };
    switch (typeObjDrag) {
      case DATA_OBJECT_SCENE.deviceConnector.name:
        this.setContextDevice(paramsToSetPos);
        break;
      case DATA_OBJECT_SCENE.connector.name:
        this.setPositionWires(paramsToSetPos);
        break;
      default:
        return;
    }
  };
  /**
   * обновит вершины проводов
   * @param contextElementData === контекстный
   * @param onCollisions=== bool
   * @param newPosition === vector
   * @param objectDrag === object
   * @param newVector
   * @param lastConnector == object
   */
  setPositionWires = async (params) => {
    let {
      onCollisions,
      newPosition,
      minObjDrag,
      newVector,
      contextElementData,
      lastConnector,
    } = params;
    const { wiresData } = this.state.dataAllObjects;
    const { lineData } = this.state;
    //при появлении контекстного элемента мы обновляем координаты вершин линий
    let paramsSerializer = {
      wiresData,
      lineData,
      newVector,
      onCollisions,
      newPosition,
      minObjDrag,
      lastConnector,
    };
    //сериализатор принимающий главный объект с линиями,параметры вершины по которой кликнули
    // (её место в массиве) и новый вектор который надо вставить вместо старогго
    let newWiresData = await serialized.stateSerializedVLC(paramsSerializer).then((el) => el.data);
    this.setState({
      dataAllObjects: {
        ...this.state.dataAllObjects,
        contextElementData: contextElementData,
        wiresData: newWiresData,
      },
    });
  };
  callbackDragForCoplingSleeve = (dataDrag) => {
    let { dataAllObjects } = this.state;
    this.setState({
      dataAllObjects: serialized.couplingSleeveStateCreator(dataDrag, dataAllObjects),
    });
  };
  /**
   * @param onCollisions === bool
   * @param newPosition  === vector
   * @param objectDrag   === object
   * @param lastConnector {*}
   * @return void
   */
  callbackDrag = ({ onCollisions, newPosition, objectDrag, lastConnector }) => {
    let dragControls = new DragController({
      onCollisions,
      newPosition,
      objectDrag,
      lastConnector,
      state: this.state,
    }).getData();
    this.setDataElements(
      dragControls.objectDrag,
      dragControls.onCollisions,
      dragControls.newPosition,
      dragControls.minObjDrag,
      dragControls.lastConnector,
    );
  };
  /**
   * удалит выбранный объект со сцены или выбранную вершину провода
   * @param {*} target
   */
  callbackDeleteElementScene = (target) => {
    const { dataAllObjects } = this.state;
    const { parent: sceneParent } = target;
    this.props.dispatch('DataSelectedElementForSidebar', 'clear');
    let newDataAllObjects = DeleteObjectFromSceneAndState(sceneParent, target, dataAllObjects);
    this.setState(
      {
        dataAllObjects: {
          contextElementData: null,
          ...newDataAllObjects,
        },
      },
      () => this.clearOutLineFromWires(),
    );
  };

  /**
   * очистить не привязанные к стейту провода
   */
  clearOutLineFromWires = () => {
    const { wiresData } = this.state.dataAllObjects;
    const { linkToSceneObject } = this.state;
    let allWireFromScene = sercheAllObjectByProperty(
      'name',
      DATA_OBJECT_SCENE.line.name,
      linkToSceneObject,
    );
    allWireFromScene.forEach((el) => {
      let isDelete = true;
      wiresData.forEach((elWire) => {
        if (el.uuid === elWire.id) isDelete = false;
      });
      if (isDelete) {
        linkToSceneObject.remove(el);
      }
    });
  };
  /**
   * отвечает за обработку отключения проводов
   * @param {*} data
   */
  handlerIsDisconnectedWire = (data) => {
    this.setState({
      isDisconnectedWire: data,
    });
  };
  /**
   * сохраняет сцену и закидывает данные в стор
   * - async
   */
  saveScene = async () => {
    const { dataAllObjects, linkToSceneObject } = this.state;
    if (!dataAllObjects.wiresData) return false;
    this.clearMemory();
    let allData = cloneDeep(dataAllObjects);
    let newData = serialized.savedMinifyObjects(allData, linkToSceneObject);
    //console.log(JSON.stringify(newData))//!для примера выходного объекта
    //!exemple worker
    let dataForDTB = await serialized.vlsDTB(newData).then((el) => {
      return el;
    });
    return {
      ...newData,
      map: dataForDTB.data,
    };
  };

  /**
   * Получит ссылку на объект сцены для дальнешего использования в основном для поиска елементов
   * @param {*} scene
   */
  getLinkScene = (scene) => {
    this.setState({
      linkToSceneObject: scene,
    });
  };
  /**
   * должен удалять все из стейта что отвечает за объекты сцены а также должен подчищять стор
   */
  clearMemory = () => {
    // this.props.dispatch("ElementsContext", "clear");
    this.props.dispatch('ElementsVLS', 'clear');
    this.setState({
      isUpdateLine: false,
      color: '#999999',
      objectsDrag: null,
      contextElementData: null,
    });
  };
  /**
   * сработает в случае ошибки загрузки модели
   * @param {*} err
   */
  errorLoadGLTF = (err) => {
    this.setState({
      callStackLoadGLTF: [],
      visibleModal: {
        isVisible: false,
        data: {
          content: null,
        },
      },
      stateGLTFLoader: this.stateControler.error,
    });
  };
  /**
   * срабатывает пока грузится модель
   * @param {*} xhr
   */
  progressLoadGLTF = (xhr) => {
    this.setState({
      stateGLTFLoader: this.stateControler.progress,
      visibleModal: {
        isVisible: true,
        data: {
          content: <ModalContentForGLTFLoader progress={(xhr.loaded / xhr.total) * 100} />,
        },
      },
    });
  };
  /**
   * сработает когда модель загрузилась
   * @param obj = gltf
   */
  readyComponentGLTF = (obj) => {
    const { callStackLoadGLTF } = this.state;
    let newCallStackLoadGLTF = dropRight(callStackLoadGLTF);
    this.setState({
      callStackLoadGLTF: newCallStackLoadGLTF,
      visibleModal: {
        isVisible: newCallStackLoadGLTF.length,
        data: {
          content: newCallStackLoadGLTF.length ? <ModalContentForGLTFLoader progress={0} /> : null,
        },
      },
      stateGLTFLoader: this.stateControler.default,
    });
  };
  /**
   * сработает до начала загрузки модели (тут я открываю модалку с лоадером)
   */
  startLoadGLTF = () => {
    const { callStackLoadGLTF } = this.state;
    this.setState({
      visibleModal: {
        callStackLoadGLTF: callStackLoadGLTF.push(uuid.v4()),
        isVisible: true,
        data: {
          content: <ModalContentForGLTFLoader />,
        },
      },
      stateGLTFLoader: this.stateControler.start,
    });
  };
}

export default AbstractVLS;
