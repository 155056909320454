import React from 'react'
import PersonalPageLayout from '../../Views/Layout/PersonalPageLayout/'
import Layout from '../../Views/Layout'
import MyProjects from '../../components/MyProjects'
const PersonalPageMyProject = ()=>{
  return(
    <>
    <Layout title={'Мои проекты'}>
      <PersonalPageLayout>
        <MyProjects />
      </PersonalPageLayout>
      </Layout>
    </>
  )
}
export default PersonalPageMyProject