import React from "react";
import MenuNewsHomePage from "../../common/MenuNewsHomePage";
import NewsItemHomePage from "../../common/NewsItemHomePage";
import Paginations from "../../common/Paginations";
import { FetcherList, dataStates } from "@garpix/fetcher";
import LoaderSpiner from "../../common/LoaderSpiner";
import Api from "../../api";

const ContentApi = Api.ContentApi;
class NewsHomePageComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      paramsToGetNews: {},
    };
  }

  render() {
    return (
      <FetcherList api={ContentApi.getNewsContent}>
        {({
          count,
          results = [],
          activePage,
          loadData,
          isNext,
          showMore,
          status,
          filterParams
        }) => (
          <React.Fragment>
            <h2 className={"news-header-title"}>Новости</h2>
            <MenuNewsHomePage
              getCategories={loadData}
              activePage={activePage}
              filterParams={filterParams}
            />
            {status === "loading" ? (
              <LoaderSpiner />
            ) : (
              <React.Fragment>
                <div className={"news-content"}>
                  {results.map((el, i) => {                    
                    const { type, imgSrc, text, date, id } = el;
                    return (
                      <NewsItemHomePage
                        key={i}
                        id={id}
                        type={type}
                        imgSrc={imgSrc}
                        text={text}
                        date={date}
                      />
                    );
                  })}
                </div>
              <div className={"news-content-paginations"}>
                <Paginations count={count} activePage={activePage} />
              </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </FetcherList>
    );
  }
}
export default NewsHomePageComponent;
